import * as React from 'react';
import { Theme } from '../text-field/text-field';
import { Icon } from '../icon/icon';
import styles from './input-base.module.scss';
import { AriaComboboxInterface } from '../AccessibilityProps';
import { IconName } from '../icon/icon-SVGs';
import { getDataAttributes } from '../../util/attribute-util';

export interface InputBaseProps {
  /**
   * Optional dom Id
   */
  id?: string;

  /**
   * optional role property
   */
  role?: string;

  /**
   * The icon to render in the right hand side button of the input
   */
  icon: IconName;

  /**
   * Whether the icon button is allowed to get focus. Defaults to true.
   */
  iconButtonCanReceiveFocus?: boolean;

  /**
   * Whether to render the input in a focused state
   */
  hasFocus: boolean;

  /**
   * Callback for when the icon is clicked
   */
  iconClickHandler: () => any;

  /**
   * Optional theme which controls the appearance of the component
   */
  theme?: Theme;

  /**
   * Optional custom CSS styles
   */
  style?: React.CSSProperties;

  /**
   * Whether the drop down should be highlighted as an error, e.g. when text is required and none has been entered.
   * Defaults to false.
   */
  error?: boolean;

  aria?: AriaComboboxInterface;
  children?: React.ReactNode;
}

const componentName = 'InputBase';

export class InputBase extends React.Component<InputBaseProps, {}> {
  static defaultProps = {
    iconButtonCanReceiveFocus: true,
  };

  renderButtonTabIndex() {
    if (!this.props.iconButtonCanReceiveFocus) {
      return { tabIndex: -1, role: 'presentation' };
    }
    return null;
  }

  render() {
    const classes = [styles['component']];
    classes.push(this.props.theme !== undefined ? styles[Theme[this.props.theme]] : styles['Default']);

    if (this.props.hasFocus) {
      classes.push(styles['focus']);
    }
    if (this.props.error) {
      classes.push(styles['error']);
    }

    let role = {};

    if (this.props.role) {
      role = { role: this.props.role };
    }
    const id = {};
    this.props.id && (id['id'] = this.props.id);

    const iconButtonTabIndex = this.renderButtonTabIndex();
    return (
      <div
        {...id}
        {...role}
        {...this.props.aria}
        style={this.props.style}
        className={classes.join(' ')}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <div className={styles['item']}>{this.props.children}</div>
        <div className={[styles['item'], styles['button-container']].join(' ')}>
          <button type="button" onClick={this.props.iconClickHandler} {...iconButtonTabIndex}>
            <Icon size={16} name={this.props.icon || 'search'} />
          </button>
        </div>
      </div>
    );
  }
}

InputBase['displayName'] = componentName;
