import * as React from 'react';
import moment from 'moment';
import { DatePickerInput } from './date-picker-input';
import { AriaComboboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import { DatePickerLogic, Navigation } from './date-picker-logic';
import { LangUtil } from '../../client-shared';
import { IdEncoderUtil } from '../../util/id-encoder-util';

export type StartDay = 'Monday' | 'Sunday';
type InternalAria = 'aria-owns' | 'aria-expanded';

export interface DatePickerProps {
  aria?: Omit<AriaComboboxInterface, InternalAria>;

  /**
   * selectedDate: undefined is none selected. Format is specified by dateFormat
   */
  selectedDate?: string;

  /**
   * startDay: default to Monday. Weeks in picker will start on this day.
   */
  startDay?: StartDay;

  /**
   * Dates entered will be parsed with this date format.
   * e.g. dd-mm-yyyy
   * Defaults to dateFormat: DD/MM/YYYY
   */
  dateFormat?: string;

  /**
   * The placeholder text displayed in the input control.
   */
  placeholderText: string;

  /**
   * The aria-label value for the toggle button
   */
  toggleButtonAriaLabel: string;

  /**
   * When dates are picked, this event will be called with the selected date.
   * Note, it can be empty to indicate no selected date.
   */
  onChange?: (newValue: string) => void;
}

const componentName = 'DatePicker';

export class DatePicker extends React.Component<
  DatePickerProps,
  { startDate: moment.Moment; selectedDate: moment.Moment; focusedCell: moment.Moment; isOpen: boolean }
> {
  static defaultProps = {
    startDay: 'Monday' as StartDay,
    dateFormat: 'DD/MM/YYYY',
  };

  ariaPopupId = IdEncoderUtil.encodeId('datepicker', LangUtil.randomUuid());

  private datePickerPanelFocusable: { focus: () => void };
  private datePickerInputFocusable: { focus: () => void };

  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      startDate: undefined,
      selectedDate: undefined,
      focusedCell: undefined,
      isOpen: false,
    };
  }

  get selectedDateString() {
    if (this.state.selectedDate) {
      return this.state.selectedDate.format(this.props.dateFormat).toString();
    }
    return '';
  }

  componentWillReceiveProps(nextProps: Readonly<DatePickerProps>, nextContext: any): void {
    this.setup(nextProps);
  }

  componentWillMount() {
    this.setup(this.props);
  }

  componentDidUpdate(prevProps: Readonly<DatePickerProps>): void {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      const selectedDate = moment(this.state.selectedDate, this.props.dateFormat);
      if (selectedDate.isValid()) {
        this.handleDateChanged(moment(this.props.selectedDate, this.props.dateFormat), false);
      }
    }
  }

  render() {
    const ariaOwns = this.state.isOpen ? { 'aria-owns': this.ariaPopupId } : {};
    const aria: AriaComboboxInterface = {
      ...this.props.aria,
    };

    return (
      <div {...aria} {...ariaOwns} data-component={componentName}>
        <DatePickerInput
          datePickerPanelProps={{
            id: this.ariaPopupId,
            startDay: this.props.startDay,
            startDate: this.state.startDate,
            selectedDate: this.state.selectedDate,
            focusedCell: this.state.focusedCell,
            onDayClick: (date, autoClose) => this.handleDayClick(date, autoClose),
            onNavigate: (action) => this.handleNavigate(action),
            onClose: () => this.closePopup(true),
            getFocusable: (focusable) => (this.datePickerPanelFocusable = focusable),
            ...getDataAttributes(this.props),
          }}
          isCalloutOpen={this.state.isOpen}
          dateChanged={(newDate) => this.handleDateChanged(newDate, false)}
          onToggleButtonPressed={() => this.togglePickerPanel(true)}
          value={this.selectedDateString}
          placeholder={this.props.placeholderText}
          toggleButtonAriaLabel={this.props.toggleButtonAriaLabel}
          format={this.props.dateFormat}
          getFocusable={(focusable) => (this.datePickerInputFocusable = focusable)}
        />
      </div>
    );
  }

  openPopup(applyFocus: boolean): void {
    applyFocus && setTimeout(() => this.datePickerPanelFocusable && this.datePickerPanelFocusable.focus(), 0);
    this.setState({
      isOpen: true,
    });
  }

  private setup(props: DatePickerProps): void {
    this.setState((prev) => {
      const selectedDate = LangUtil.isDefined(props.selectedDate) ? moment(props.selectedDate, this.props.dateFormat) : null;
      const startDate = LangUtil.isDefined(prev.startDate)
        ? moment(prev.startDate, this.props.dateFormat)
        : LangUtil.isDefined(selectedDate)
        ? selectedDate
        : moment();
      return {
        selectedDate,
        startDate,
        focusedCell: startDate,
      };
    });
  }

  private handleDayClick(contextDay: moment.Moment, autoClose: boolean) {
    this.handleDateChanged(contextDay, autoClose);
  }

  private handleDateChanged(newDate: moment.Moment, autoClose: boolean) {
    const previousSelectedDate = this.selectedDateString;
    this.setState(
      () => {
        const startDate = newDate?.clone() ?? moment();
        const selectedDate = newDate?.clone() ?? undefined;
        return {
          startDate,
          selectedDate,
          isOpen: false,
        };
      },
      () => {
        if (autoClose) {
          this.closePopup(true);
        }
        if (this.selectedDateString !== previousSelectedDate) {
          this.props.onChange?.(this.selectedDateString);
        }
      }
    );
  }

  private closePopup(applyFocus: boolean): void {
    applyFocus && setTimeout(() => this.datePickerInputFocusable && this.datePickerInputFocusable.focus(), 0);
    this.setState({
      isOpen: false,
    });
  }

  private handleNavigate(navigation: Navigation) {
    const newDate = DatePickerLogic.navigateCursor(this.state.startDate, navigation);
    this.setState({
      startDate: newDate.clone(),
      focusedCell: newDate.clone(),
    });
  }

  private togglePickerPanel(focusAfterToggle: boolean) {
    if (this.state.isOpen) {
      this.closePopup(focusAfterToggle);
    } else {
      this.openPopup(focusAfterToggle);
    }
  }
}

DatePicker['displayName'] = componentName;
