import * as React from 'react';
import styles from './highchart.module.scss';
import * as Highcharts from 'highcharts';
import { LineChartOptions } from '../line-chart/line-chart';
import { getDataAttributes } from '../../util/attribute-util';

export interface HighchartPieDataPoint {
  name: string;
  y: number;
  drilldown?: string;
}

export interface HighChartLineDataPoint {
  name: string;
  y: number;
  drilldown?: string;
}

export interface BoostInterface {
  allowForce: boolean;
  enabled: boolean;
  seriesThreshold: number;
  useGPUTranslations: boolean;
}

export interface HighchartDataSeries {
  id: string;
  type: 'line' | 'bar' | 'pie' | 'column';
  name?: string;
  data: Array<HighChartLineDataPoint | HighchartPieDataPoint>;
}

export interface HighchartTitlesProps {
  title?: string;
  legendTitle?: string;
  xAxisTitle?: string;
  yAxisTitle?: string;
}

export interface HighchartProps extends HighchartTitlesProps {
  type: 'pie' | 'bar' | 'column' | 'line';
  lineChartOptions?: LineChartOptions;
  margin?: number;
  dataSeries: HighchartDataSeries[];
  drilldown?: HighchartDataSeries[];
  onClick?: (name: string) => any;
  zoomType?: 'x' | 'xy' | 'y';
  useLegend?: boolean;
  useToolTip?: boolean;
}

const componentName = 'Highchart';

export class Highchart extends React.Component<HighchartProps, {}> {
  elem: HTMLDivElement;

  chart: Highcharts.Chart = null;

  constructor(props: HighchartProps, context: any) {
    super(props, context);
  }

  get plotOptions(): Highcharts.PlotOptions {
    return {
      pie: {
        borderWidth: 2,
        showInLegend: true,
        allowPointSelect: true,
        dataLabels: {
          enabled: this.props.useLegend,
          distance: 15,
          formatter: function () {
            const maxCharactersToShow = 40;
            const dots = this.point.name.length > maxCharactersToShow ? '...' : '';
            return this.point.name.slice(0, maxCharactersToShow) + dots + ': ' + Highcharts.numberFormat(this.point.percentage, 1) + '%';
          },
          style: {
            color: 'grey',
            textOutline: 'none',
          },
          useHTML: false,
        },
        events: {
          click: (event) => {
            this.props.onClick && this.props.onClick(event.point.name);
          },
        },
      },
      line: {
        stacking: undefined,
        dataLabels: {
          useHTML: true,
        },
      },
      column: {
        stacking: 'normal',
        dataLabels: {
          useHTML: true,
        },
      },
      bar: {
        stacking: 'normal',
        dataLabels: {
          useHTML: true,
        },
      },
    };
  }

  get xAxisTitle(): Highcharts.XAxisTitleOptions {
    return {
      text: this.props.xAxisTitle,
    };
  }

  get yAxisTitle(): Highcharts.YAxisTitleOptions {
    return {
      text: this.props.yAxisTitle,
    };
  }

  get options(): Highcharts.Options {
    return {
      title: {
        text: this.props.title,
      },
      plotOptions: this.plotOptions,
      chart: {
        type: this.props.type,
        renderTo: this.elem,
        margin: this.props.margin,
        reflow: true,
        borderWidth: 0,
        plotBorderWidth: 0,
        zoomType: this.props.zoomType,
      },
      legend: {
        enabled: this.props.useLegend,
        layout: 'horizontal',
        useHTML: true,
        title: {
          text: this.props.legendTitle,
        },
      },
      tooltip: {
        enabled: this.props.useToolTip,
        formatter: function () {
          return '<b>' + this.series.name + '</b><br/>' + this.point.name + ': ' + this.y;
        },
        useHTML: false,
        followPointer: false,
      },
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        title: this.xAxisTitle,
      },
      yAxis: {
        labels: {
          enabled: true,
        },
        title: this.yAxisTitle,
      },
      series: this.props.dataSeries,
      drilldown: {
        series: this.props.drilldown,
      },
      exporting: {
        enabled: false,
        fallbackToExportServer: false,
      },
    };
  }

  setRef = (c) => {
    //set elem attribute
    this.elem = c;
  };

  render() {
    return <div ref={this.setRef} className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)} />;
  }

  initChart() {
    this.chart = Highcharts.chart(this.options);
  }

  componentDidMount(): void {
    if (!this.chart) {
      this.initChart();
    }
  }

  componentDidUpdate(prevProps: Readonly<HighchartProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (this.props.lineChartOptions && this.props.lineChartOptions.highlightedLineId !== prevProps.lineChartOptions.highlightedLineId) {
      this.highlightLine(this.props.dataSeries);
    }
  }

  /**
   * Updates the data series for the chart, taking into account whether any new entries have been added or existing ones have been removed.
   * @param dataSeries The new data series that the current chart series should update to
   */
  updateChart(dataSeries: HighchartDataSeries[]) {
    // REMOVE entries from chart.series that does not exist in dataSeries (length of chartSeries > dataSeries)
    const dataSeriesIds = new Set<string>();
    dataSeries.forEach((series) => dataSeriesIds.add(series.id));

    for (let i = 0; i < this.chart.series.length; i++) {
      if (!dataSeriesIds.has(this.chart.series[i].options.id)) {
        this.chart.series[i].remove(false);
        i--; // go one step back in the for loop, since we deleted an entry in the array
      }
    }

    // ADD new entries from dataSeries that does not exist in chart.series (length of dataSeries > chartSeries)
    const currentChartSeriesIds = new Set<string>();
    this.chart.series.forEach((series) => currentChartSeriesIds.add(series.options.id));

    dataSeries.forEach((series: HighchartDataSeries, index: number) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore note: colorIndex does not exist on SeriesObject.options.colorIndex, but rather directly on the SeriesObject
      const colorIndexUsageList: number[] = this.chart.series.map((chartSeries) => chartSeries.colorIndex);

      if (!currentChartSeriesIds.has(series.id)) {
        series['colorIndex'] = leastUsedChartColorIndex(colorIndexUsageList);
        series['index'] = index;
        this.chart.addSeries(series, false, true);
      }
    });

    // UPDATE already existing series if new points have been added or if existing points have changed
    const currentSeriesIds = this.chart.series.map((series) => series.options.id);
    dataSeries.forEach((series: HighchartDataSeries) => {
      const currentSeries = this.chart.series[currentSeriesIds.indexOf(series.id)];
      if (currentSeries.data.length !== series.data.length || !currentSeries.data.every((d, di) => series.data[di] === d)) {
        // update data of series
        currentSeries.setData(series.data, false, true, true);
      }
    });

    this.chart.redraw(true);
  }

  /**
   * Intended for line charts, this will highlight a specific line if it has a lineWidth value set.
   * - If a lineWidth value is set, the stroke-width of the specified line will be changed to this value.
   * - Furthermore it will make a line opaque if it has no lineWidth value set.
   * This creates a highlight effect of making a line with a lineWidth value stand out from the rest of the lines in the chart.
   * @param dataSeries - the data series to do highlighting on
   */
  highlightLine(dataSeries: HighchartDataSeries[]) {
    const { highlightedLineId, highlightedLineWidth } = this.props.lineChartOptions;

    const currentChartIds = this.chart.series.map((series) => series.options.id);

    dataSeries.forEach((series: HighchartDataSeries) => {
      // find the index of the series as it is in the chart series, not the dataSeries
      const currentChartIndex = currentChartIds.indexOf(series.id);
      const chartSeriesElements = this.elem.getElementsByClassName('highcharts-series-' + currentChartIndex);

      let chartSeriesElementPaths;
      if (chartSeriesElements && chartSeriesElements.length > 1) {
        chartSeriesElementPaths = chartSeriesElements[0].getElementsByClassName('highcharts-graph');
      }

      if (chartSeriesElementPaths && chartSeriesElementPaths.length > 0) {
        if (highlightedLineId !== '') {
          // a line is currently set to be highlighted
          if (series.id === highlightedLineId) {
            // highlight this line: Change line width of this series (and reset opacity to 1 in case it has changed)
            chartSeriesElements[0].setAttribute('style', 'opacity: 1');
            chartSeriesElements[1].setAttribute('style', 'opacity: 1');
            chartSeriesElementPaths[0].setAttribute('style', `stroke-width: ${highlightedLineWidth}px`);
          } else {
            // fade out this line: Set width to 1px and make lines and markers opaque
            chartSeriesElements[0].setAttribute('style', 'opacity: .1');
            chartSeriesElements[1].setAttribute('style', 'opacity: .1');
            chartSeriesElementPaths[0].setAttribute('style', 'stroke-width: 1px');
          }
        } else {
          // reset any width or opacity changes made
          chartSeriesElements[0].setAttribute('style', 'opacity: 1');
          chartSeriesElements[1].setAttribute('style', 'opacity: 1');
          chartSeriesElementPaths[0].setAttribute('style', `stroke-width: 2px`);
        }
      }
    });
  }

  componentWillReceiveProps(nextProps: Readonly<HighchartProps>, nextContext: any): void {
    this.updateChart(nextProps.dataSeries);
  }

  public reflow(): void {
    this.chart.reflow();
  }
}

/**
 * Util function that returns the color index that is least used by the series in the chart.
 */
export function leastUsedChartColorIndex(usedIndexes: number[]): number {
  // construct a map with 10 possible entries (one for each possible colorIndex)
  // [colorIndex: usage count]
  const colorIndexUsages = new Map<number, number>();
  for (let i = 0; i < 10; i++) {
    colorIndexUsages.set(i, 0);
  }

  for (const index of usedIndexes) {
    const currentColorIndexUsage = colorIndexUsages.get(index);
    colorIndexUsages.set(index, currentColorIndexUsage + 1);
  }

  let lowestUsageNumber: number;
  let leastUsedColorIndex = 9; // set to 9 since it is the last index in the colorIndex range, and should be most

  colorIndexUsages.forEach((colorIndexUsage: number, index: number) => {
    if (lowestUsageNumber === undefined) {
      lowestUsageNumber = colorIndexUsage;
      leastUsedColorIndex = index;
    } else {
      leastUsedColorIndex = lowestUsageNumber > colorIndexUsage ? index : leastUsedColorIndex;
      lowestUsageNumber = lowestUsageNumber > colorIndexUsage ? colorIndexUsage : lowestUsageNumber;
    }
  });

  return leastUsedColorIndex;
}

Highchart['displayName'] = componentName;
