import * as React from 'react';
import * as RDialog from '@radix-ui/react-dialog';
import styles from './dialog.module.scss';
import { IconButton } from '../icon-button/icon-button';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import { getDataAttributes } from '../../util/attribute-util';
import { ErrorBoundaryContentLabelProps } from '../error-boundary/error-boundary-content/error-boundary-content';
import { Styling, useStylingContext } from '../../style-context/styling-context-provider';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import { defaultErrorBoundary } from '../graphene-root';

const componentName = 'Dialog';
const RContent = RDialog.Content;

export interface DialogProps {
  id?: string; // Not necessary in Radix implementation
  isOpen: boolean; // Defaults to false, if undefined the dialog controls itself
  closeAriaLabel: string;
  /**
   * onCloseAutoFocus allows you to customize what element receives focus when the dialog closes.
   * Note: This overrides default focus behavior. Use at own risk.
   */
  onCloseAutoFocus?: (e: DialogOnCloseAutoFocusEvent) => void;
  onOpenAutoFocus?: (e: DialogOnCloseAutoFocusEvent) => void;
  /**
   * onClosed is called when the dialog has closed and parent should update its state
   */
  onClosed: () => void;
  /**
   * onClosing allows the parent to cancel closing before it happens
   */
  onClosing?: () => boolean;
  children?: React.ReactNode;

  /**
   * ErrorBoundary used if an error happens in the dialog.
   * Defaults to some defaults value for the error boundary
   */
  errorBoundary?: ErrorBoundaryContentLabelProps;
}

export type DialogOnCloseAutoFocusEvent = Parameters<React.ComponentProps<typeof RContent>['onCloseAutoFocus']>[0];

export function Dialog({ errorBoundary = defaultErrorBoundary, ...props }: DialogProps) {
  const { styling } = useStylingContext();
  const lastKnownFocus = React.useRef<HTMLElement>();

  // Keep track of the focused element before we change isOpen prop
  React.useEffect(() => {
    if (props.isOpen === true && document.activeElement !== document.body) {
      lastKnownFocus.current = document.activeElement as HTMLElement;
    }
  }, [props.isOpen]);

  const handleOpenChange = (isOpen: boolean) => {
    if (!isOpen) {
      props.onClosed?.();
    }
  };

  const handleClosingEvent = (e: DialogOnCloseAutoFocusEvent | undefined) => {
    const allowedToClose = props.onClosing?.();
    if (allowedToClose === false) {
      e?.preventDefault();
    }
  };

  const handleClosingPress = () => {
    const allowedToClose = props.onClosing?.();
    if (allowedToClose === false) {
      return;
    }
    props.onClosed?.();
  };

  // Default handler for onCloseAutoFocus
  const defaultOnCloseAutoFocus = () => {
    if (!lastKnownFocus.current) {
      console.warn(
        'Attempted to refocus after dialog closed, but failed. If this happens, then it failed to store an active elment before rendering the dialog.'
      );
    }
    lastKnownFocus.current?.focus();
  };

  const paletteClassName = getPaletteClassName({ name: getDefaultPalette().name });
  return (
    <RDialog.Root open={props.isOpen} onOpenChange={handleOpenChange}>
      <RDialog.Portal className={paletteClassName}>
        <RDialog.Overlay className={[paletteClassName, styles['overlay'], styles[styling]].join(' ')}>
          <RDialog.Content
            onCloseAutoFocus={props.onCloseAutoFocus ?? defaultOnCloseAutoFocus}
            onOpenAutoFocus={props.onOpenAutoFocus}
            onEscapeKeyDown={handleClosingEvent}
            onInteractOutside={handleClosingEvent}
            asChild
          >
            <WithControls
              onClosing={handleClosingPress}
              ariaCloseLabel={props.closeAriaLabel}
              styling={styling}
              errorBoundary={errorBoundary}
              {...getDataAttributes(props)}
            >
              {props.children}
            </WithControls>
          </RDialog.Content>
        </RDialog.Overlay>
      </RDialog.Portal>
    </RDialog.Root>
  );
}

const WithControls = React.forwardRef<
  HTMLDivElement,
  {
    styling: Styling;
    children: React.ReactNode;
    onClosing: () => void;
    ariaCloseLabel: string;
    errorBoundary?: ErrorBoundaryContentLabelProps;
  }
>(({ styling, onClosing, ariaCloseLabel, errorBoundary, ...props }, fwRef) => (
  <div
    data-component={componentName}
    {...getDataAttributes(props)}
    ref={fwRef}
    className={[styles['content'], styles[styling]].join(' ')}
    {...props}
  >
    <div className={styles['header']}>
      <div className={styles['iconWrapper']}>
        <IconButton type={'button'} aria={{ 'aria-label': ariaCloseLabel }} icon={'delete'} onClick={onClosing} />
      </div>
    </div>
    <div className={styles['container']}>
      <ErrorBoundary {...errorBoundary}>{props.children}</ErrorBoundary>
    </div>
  </div>
));

Dialog.displayName = componentName;
