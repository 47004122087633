import * as React from 'react';
import { MenuItemAny } from './types';
import { Text } from '../text/text';
import { Icon } from '../icon/icon';
import * as styles from './popup-menu.css';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'DisplayItem';

export const DisplayItem = ({ item, ...props }: { item: MenuItemAny }) => {
  return (
    <span data-key={item.id} className={styles.displayItem} data-component={componentName} {...getDataAttributes(props)}>
      {item.type === 'check' && item.checkDecorator && (
        <span className={styles.checkContainer}>
          {item.isSelected && (
            <span>
              <Icon fill="interaction" name={item.checkDecorator} size={16} />
            </span>
          )}
        </span>
      )}

      {item.icon && (
        <span style={{ marginRight: 5 }}>
          <Icon fill="interaction" name={item.icon} size={16} />
        </span>
      )}

      <Text size="font14">{item.label}</Text>
    </span>
  );
};

DisplayItem['displayName'] = componentName;
