import { SelectItem, SelectItemBuilder } from '../models/select-item';
import { TreeNode } from '../models/tree-node';
import { ChildNodeFunctions } from '../models/child-node';

export class ModelUtil {
  static filterSelectItem(item: SelectItem, searchString: string) {
    //If the label of a node matches then return the node as is, even if the children dont match.
    //This will make it possible to search for a group.
    const itemLabel = item.label ?? '';
    if (itemLabel.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
      return item;
    }

    let children: SelectItem[] = [];
    if (item.children.length > 0) {
      children = ModelUtil.filterSelectItems(item.children, searchString);
    }

    if (children.length > 0) {
      return new SelectItemBuilder(item.label, item.value, item.icon, children, item.isOpen, item.isSelectable).build();
    }
    return null;
  }

  static filterSelectItems(items: SelectItem[], searchString: string) {
    return items.map((itm) => ModelUtil.filterSelectItem(itm, searchString)).filter((itm) => !!itm);
  }

  static treeNodeAsFlatArray<T extends TreeNode<any>>(items: T[]): T[] {
    return items.reduce((p, c) => {
      p.push(...ChildNodeFunctions.asFlat(c));
      return p;
    }, [] as T[]);
  }
}
