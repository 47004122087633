import * as React from 'react';
import styles from './tree-selection.module.scss';
import { DecorativeSelectionIndicator, SelectionList, SelectionListProps } from '../selection-list/selection-list';
import { SelectItem } from '../../models/select-item';
import { getDataAttributes } from '../../util/attribute-util';
import { LocalizedMessage } from '../../util/localized-message';

export interface TreeSelectionProps {
  // styling and custom content
  className?: string;
  headerContent?: React.ReactElement<any> | string | LocalizedMessage;
  barContent?: React.ReactElement<any> | string | LocalizedMessage;
  bottomContent?: React.ReactElement<any> | string | LocalizedMessage;
  noItemsContent?: React.ReactElement<any>;

  /**
   * Label for selectedChildrenCount sub label. Will be placed after the count e.g. if label is "selected", it will be "2 selected"
   * If no label is given here, but showSelectedCounter is set to true, it will simply show the count
   */
  selectedChildrenCountLabel?: string;
  showSelectedCounter?: boolean;

  /**
   * If enabled, a checkbox or checkIcon is shown as a visual indicator of the selected state of each item.
   * The checkbox option is purely decorative and does not appear in the accessibility tree.
   * Note: If you want to only have this applied to some items, you can set showSelectionIndicator on a specific SelectItem
   */
  decorativeSelectionIndicator?: DecorativeSelectionIndicator;

  // items
  items: SelectItem[];
  selectedItems?: SelectItem[];
  /**
   *  Template map from className to React Component. Use this to enable the TreeSelection to handle TreeNode sub classes
   */
  itemTemplates?: SelectionListProps<SelectItem>['itemTemplates'];
  onSelectionChanged?: (selectedItems: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[]) => any;

  // drag and drop
  dragEnabled?: boolean;
  dropEnabled?: boolean;
  onDragStart?: (e: React.SyntheticEvent<DragEvent>) => any;
  onDragEnd?: (e: React.SyntheticEvent<DragEvent>) => any;
  onDropCallback?: (values: string[]) => {};

  // settings
  useSingleSelection?: boolean;
  showElementsAsOpen?: boolean;

  getExternalSelectedCounter?: (nodeId: string, selectedItems: SelectItem[]) => number;
  getInitialItem?: (nodeId: string) => any;
}

const componentName = 'TreeSelection';

export class TreeSelection extends React.Component<TreeSelectionProps, {}> {
  constructor(properties) {
    super(properties);
  }

  render() {
    let dropCallback = {};
    if (this.props.dropEnabled) {
      dropCallback = {
        onDrop: (e) => {
          if (this.props.onDropCallback) {
            this.props.onDropCallback(JSON.parse(e.dataTransfer.getData('Text')));
          } else {
            console.log('no dropcallback defined');
          }
          e.preventDefault();
        },
        /*for some absurd reason, drop events are not fired unless the dragover event defaults are cancelled*/
        onDragOver: (e) => {
          e.preventDefault();
          //do stuff
        },
        onDragLeave: (e) => {
          //do stuff
        },
      };
    }

    let draggable = {};
    if (this.props.dragEnabled) {
      draggable = {
        draggable: true,
        onDragStart: (e) => {
          try {
            e && e.dataTransfer && (e.dataTransfer.dropEffect = 'copy');
          } catch (_) {
            // ignored
          }
          //do not change the setData property without implementing a WORKAROUND for IE11
          // http://stackoverflow.com/questions/26213011/html5-dragdrop-issue-in-internet-explorer-datatransfer-property-access-not-pos
          e.dataTransfer.setData('Text', JSON.stringify(this.props.selectedItems.map((itm) => itm.id)));
          this.props.onDragStart && this.props.onDragStart(e);
        },
        onDragEnd: (e) => {
          this.props.onDragEnd && this.props.onDragEnd(e);
        },
      };
    }

    const classes = [styles['component']];

    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div className={classes.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.headerContent ? <div className={styles['header']}>{this.props.headerContent}</div> : ''}
        {this.props.barContent ? <div className={styles['bar']}>{this.props.barContent}</div> : ''}
        <div {...draggable} {...dropCallback} className={styles['listWrapper']}>
          {this.props.items.length > 0 ? (
            <SelectionList
              id="SelectionList"
              className={styles['list']}
              items={this.props.items}
              onItemsSelected={(items, newItems, rem) => {
                this.props.onSelectionChanged && this.props.onSelectionChanged(items, newItems, rem);
              }}
              showElementsAsOpen={this.props.showElementsAsOpen}
              useBorderIndicator={true}
              dragDisabled={true}
              selectedItems={this.props.selectedItems}
              itemTemplates={this.props.itemTemplates}
              onlyAllowSingleSelectedItem={this.props.useSingleSelection}
              decorativeSelectionIndicator={this.props.decorativeSelectionIndicator ? this.props.decorativeSelectionIndicator : 'checkbox'}
              showSelectedCounter={this.props.showSelectedCounter ?? true}
              selectedChildrenCountLabel={this.props.selectedChildrenCountLabel}
              getExternalSelectedCounter={this.props.getExternalSelectedCounter}
              getInitialItem={this.props.getInitialItem}
            />
          ) : this.props.noItemsContent ? (
            this.props.noItemsContent
          ) : (
            ''
          )}
        </div>
        {this.props.bottomContent ? <div className={styles['bottom']}>{this.props.bottomContent}</div> : ''}
      </div>
    );
  }
}

TreeSelection['displayName'] = componentName;
