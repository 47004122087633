import * as React from 'react';

import { Highchart, HighchartDataSeries, HighchartTitlesProps } from '../highchart/highchart';
import { getDataAttributes } from '../../util/attribute-util';

export interface BarChartDataPoint {
  label: string;
  value: number;
}

export interface BarChartProps extends HighchartTitlesProps {
  id: string;
  name: string;
  dataPoints: BarChartDataPoint[];
  onClick?: (name: string) => any;
  useToolTip?: boolean;
}

/**
 * @deprecated use HighchartsStyled instead
 */

const componentName = 'BarChart';
export class BarChart extends React.Component<BarChartProps> {
  private highchartRef: React.RefObject<Highchart> = React.createRef();

  get dataSeries(): HighchartDataSeries {
    return {
      id: this.props.id,
      name: this.props.name,
      type: 'bar',
      data: this.props.dataPoints.map((p) => ({
        name: p.label,
        y: p.value,
      })),
    };
  }

  render() {
    return (
      <div data-component={componentName} {...getDataAttributes(this.props)}>
        <Highchart
          ref={this.highchartRef}
          type="bar"
          title={this.props.title}
          onClick={this.props.onClick}
          dataSeries={[this.dataSeries]}
          xAxisTitle={this.props.xAxisTitle}
          yAxisTitle={this.props.yAxisTitle}
          useToolTip={this.props.useToolTip}
        />
      </div>
    );
  }

  public reflow(): void {
    this.highchartRef.current.reflow();
  }
}

BarChart['displayName'] = componentName;
