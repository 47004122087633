import { Styling, useStylingContext } from './styling-context-provider';
import * as React from 'react';

export interface OnStylingProps {
  styling: Styling;
  children: React.ReactNode;
}

/**
 * This component will conditionally render its children if the current styling matches the styling on props.
 *
 * The purpose is to help storybook only present some components for a specific styling choice.
 */

export const OnStyling: React.FunctionComponent<OnStylingProps> = (props) => {
  const { styling } = useStylingContext();

  return <>{styling === props.styling && props.children}</>;
};
