import * as React from 'react';
import styles from './HTML-cell-render.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface HTMLCellRenderProps {
  htmlString: string;
  style?: React.CSSProperties;
  className?: string;
}

const componentName = 'HTMLCellRender';

export class HTMLCellRender extends React.Component<HTMLCellRenderProps, {}> {
  render() {
    const cleanHTML = (this.props.htmlString ? this.props.htmlString : '').replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, ' ');
    const classes = [styles['component']];

    this.props.className && classes.push(this.props.className);

    return (
      <div style={this.props.style} className={classes.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        <div dangerouslySetInnerHTML={{ __html: cleanHTML }}></div>
      </div>
    );
  }
}

HTMLCellRender['displayName'] = componentName;
