import * as React from 'react';
import styles from './list.module.scss';
import { Label } from '../label/label';
import { S_M_L_Size } from '../../components/t-shirt-sizes';
import { LinkProps } from '../link/link';
import { ButtonProps } from '../button/button';
import { LangUtil } from '../../client-shared';

const componentName = 'List';

/**
 * This Component shows a list of items with an optional title
 */

export interface ListProps {
  /**
   * (Optional): Class name sufix for external styling.
   */
  className?: string;

  children: React.ReactElement<LinkProps>[] | React.ReactElement<ButtonProps>[];
  /**
   * (Optional): The title text for the list.
   */
  text?: string;
  /**
   * (Optional): The id passed to the title.
   * It is adviced to have a title text when applying this prop.
   */
  id?: string;

  size?: S_M_L_Size;
}

export const List = (props: ListProps): JSX.Element => {
  const { className, children, size, text, id } = props;

  const finalId = React.useMemo(() => id ?? LangUtil.randomUuid(), [id]);

  const classNames = [styles.component];
  className && classNames.push(className);
  size && classNames.push(styles[size]);

  const ulClassNames = [styles.list];
  props.text && ulClassNames.push(styles['list-seperator']);

  return (
    <div className={classNames.join(' ')} data-component={componentName}>
      {text && <Label className={styles.label} text={text} size={size} id={finalId} />}
      <ul className={ulClassNames.join(' ')} aria-labelledby={finalId}>
        {children.map((child: React.ReactElement, childIndex: number) => (
          <li key={childIndex}>{child}</li>
        ))}
      </ul>
    </div>
  );
};

List.defaultProps = {
  size: 'medium',
};

List.displayName = componentName;
