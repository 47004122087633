import * as React from 'react';
import styles from './metric-button.module.scss';
import { MetricItemSize } from './metric-item';
import { getDataAttributes } from '../../../util/attribute-util';

interface MetricButtonProps {
  /**
   * The label to show for the button link, e.g. "2 of 3"
   */
  label: string;

  /**
   * A descriptive label for the button that provides context for screen readers, e.g. "Eligible for REF: 2 of 3"
   */
  screenReaderLabel: string;

  size?: MetricItemSize;
}

const componentName = 'MetricButton';

export const MetricButton = React.forwardRef<any, MetricButtonProps>(({ screenReaderLabel, label, size, ...props }, ref) => {
  /** The MetricButton component is the call to action for opening a metric details callout in the metric lists.
   */

  let sizeStyling = 'size-large'; // Setting to same default as in MetricList, line 85-88 (top of render method)
  if (size) {
    sizeStyling = `size-${size}`;
  }

  const styling = [styles['component'], styles[sizeStyling]].join(' ');

  return (
    <button
      ref={ref}
      type="button"
      className={styling}
      aria-label={screenReaderLabel}
      aria-haspopup="dialog"
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      {label}
    </button>
  );
});

MetricButton['displayName'] = componentName;
