import * as React from 'react';
import { useState } from 'react';
import { Toast, ToastProps } from './toast';
import * as RadixToast from '@radix-ui/react-toast';
import styles from './toast.module.scss';

export type ToastFactoryProps = {
  children: React.ReactNode;
};
export type ToastFactoryContextProps = {
  createToast: (props: ToastProps) => void;
};

export const ToastFactoryContext = React.createContext<ToastFactoryContextProps>({
  createToast: () => {},
});

export const ToastFactory = ({ children }) => {
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const [toastKey, setToastKey] = useState<number>(0);

  return (
    <RadixToast.Provider>
      <ToastFactoryContext.Provider
        value={{
          createToast: (toast: ToastProps) => {
            setToastKey(toastKey + 1);
            setToasts(() => [{ ...toast, key: toastKey }, ...toasts]);
          },
        }}
      >
        {toasts.map((args) => (
          <Toast {...args} />
        ))}
        {children}
      </ToastFactoryContext.Provider>
      <RadixToast.Viewport className={styles.viewport} />
    </RadixToast.Provider>
  );
};

ToastFactory['displayName'] = 'ToastFactory';
