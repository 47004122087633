import * as React from 'react';
import { ContextType } from 'react';
import styles from './switch.module.scss';
import { AriaCheckboxInterface } from '../AccessibilityProps';
import StylingContext from '../../style-context/styling-context-provider';
import { S_M_L_Size } from '../t-shirt-sizes';
import { getDataAttributes } from '../../util/attribute-util';

export interface SwitchProps {
  onSelectedChanged?: (value: boolean) => void;
  isSelected?: boolean;
  isDisabled?: boolean;
  aria?: AriaCheckboxInterface;
  size?: S_M_L_Size;
}

const componentName = 'Switch';

export class Switch extends React.PureComponent<SwitchProps & { id: string }> {
  static defaultProps: SwitchProps = {
    isDisabled: false,
    isSelected: false,
    size: 'small',
    aria: {},
  };
  context: ContextType<typeof StylingContext>;

  render() {
    const { styling } = this.context;
    const switchClassNames = [styles['component'], styles[styling]].join(' ');
    const dotClassNames = [styles['switch'], styles[this.props.size]].join(' ');

    return (
      <div className={switchClassNames} data-component={componentName} {...getDataAttributes(this.props)}>
        <input
          id={this.props.id}
          type="checkbox"
          checked={this.props.isSelected}
          disabled={this.props.isDisabled}
          onChange={this.onChange}
          className={styles[this.props.size]}
          {...this.props.aria}
        />
        <div aria-hidden className={dotClassNames}>
          <div className={styles['ball']} />
        </div>
      </div>
    );
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.onSelectedChanged && this.props.onSelectedChanged((e.target as HTMLInputElement).checked);
  };
}

Switch['displayName'] = componentName;
Switch.contextType = StylingContext;
