import * as React from 'react';
import { Children } from 'react';
import { getDataAttributes } from '../../util/attribute-util';
import { ResponsiveSpace } from '../../vanilla-extract/atoms/atoms';
import { alignToFlexAlign } from '../../util/align';
import { Box, BoxProps } from '../box/box';
import { PRESENTATION } from '../../accessibility/aria-attributes';
import { useNegativeMarginTop } from '../../hooks/use-negative-margin/use-negative-margin-top';
import flattenChildren from 'react-keyed-flatten-children';

type VerticalAlignments = 'top' | 'bottom' | 'middle' | 'stretch';
type HorizontalAlignment = 'left' | 'center' | 'right';

const componentName = 'Stack';

export interface StackChildren {
  children?: React.ReactNode;
}

export interface StackProps extends StackChildren {
  spacing: ResponsiveSpace;

  verticalAlignment?: VerticalAlignments;

  horizontalAlignment?: HorizontalAlignment;
}

export const Stack = ({ children, horizontalAlignment, spacing = '0', verticalAlignment, ...props }: StackProps) => {
  // The height and justifyContent semantics here match the pre-Vanilla-Extract styling we had in Stack.scss.
  // This ensures that the styling upgrade is non-breaking for now.
  const height = verticalAlignment && verticalAlignment !== 'top' ? 'full' : undefined;
  let justifyContent: undefined | BoxProps['justifyContent'];
  if (verticalAlignment) {
    switch (verticalAlignment) {
      case 'top':
        justifyContent = 'flexStart';
        break;
      case 'middle':
        justifyContent = 'center';
        break;
      case 'bottom':
        justifyContent = 'flexEnd';
        break;
      case 'stretch':
        justifyContent = 'spaceBetween';
        spacing = 0;
        break;
    }
  }

  const alignItems = alignToFlexAlign(horizontalAlignment);
  const className = verticalAlignment === 'stretch' ? '' : useNegativeMarginTop(spacing);

  return (
    <Box
      role={PRESENTATION}
      display={'flex'}
      flexDirection={'column'}
      height={height}
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={className}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      {Children.map(flattenChildren(children), (child) =>
        child !== null && child !== undefined ? <Box marginTop={spacing}>{child}</Box> : null
      )}
    </Box>
  );
};

Stack['displayName'] = componentName;
