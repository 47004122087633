import * as React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'AreaBody';

export const AreaBody: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div data-component={componentName} {...getDataAttributes(props)}>
      {props.children}
    </div>
  );
};
AreaBody.displayName = componentName;
