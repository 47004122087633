import * as React from 'react';
import styles from './legend.module.scss';
import { LegendItem, LegendItemProps } from './legend-item';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'Legend';

export interface LegendProps {
  annotations: LegendItemProps[];
}

export class Legend extends React.Component<LegendProps, {}> {
  renderItems() {
    return this.props.annotations.map((a) => {
      return (
        <LegendItem
          key={a.identifier}
          data-key={`Legend-${a.identifier}`}
          identifier={a.identifier}
          title={a.title}
          explanation={a.explanation}
          iconName={a.iconName}
          exampleClassName={a.exampleClassName}
        />
      );
    });
  }

  render() {
    return (
      <div data-component={componentName} className={styles['component']} {...getDataAttributes(this.props)}>
        {this.renderItems()}
      </div>
    );
  }
}

Legend['displayName'] = componentName;
