import * as React from 'react';
import { Icon } from '../icon/icon';
import styles from './related-content-types-breadcrumb.module.scss';
import { PopupLink } from '../popup-link/popup-link';
import { SelectItem, SelectItemBuilder } from '../../models/select-item';
import { getIconNameFromAlias } from '../icon/icon-utils';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

/**
 * A node that represents a content type and actions relating to that content type
 */
export class RelatedContentTypeNode {
  /**
   * The child nodes
   */
  children: RelatedContentTypeNode[] = [];

  /**
   * Tasks for this node
   */
  tasks: RelatedContentTypeTask[] = [];

  /**
   * @param systemName the system name, e.g. 'Person'
   * @param label the localized text relating to the content type
   * @param familyLabel the localized name of the family this node represents
   * @param note a note to show after the label
   * @param onClick callback for when the node is clicked
   */
  constructor(
    public systemName: string,
    public label: string,
    public familyLabel: string,
    public note: string,
    public onClick: () => void
  ) {}

  addChild(child: RelatedContentTypeNode): this {
    this.children.push(child);
    return this;
  }

  addTask(task: RelatedContentTypeTask): this {
    this.tasks.push(task);
    return this;
  }
}

const componentName = 'RelatedContentTypesBreadcrumb';

export class RelatedContentTypeTask {
  /**
   * Represents a task to perform on a given content type
   */

  /**
   * @param id unique id of the task
   * @param label the label of the task
   * @param onClick callback for when the task is clicked
   */
  constructor(public id: string, public label: string, public onClick: () => any) {}
}

export interface RelatedContentTypesBreadcrumbProps {
  nodes: RelatedContentTypeNode[];
}

export class RelatedContentTypesBreadcrumb extends React.Component<RelatedContentTypesBreadcrumbProps, {}> {
  /**
   * A bread crumb that shows a hierarchy of content types
   */

  renderNode(node: RelatedContentTypeNode, child?: boolean) {
    const dot = node.label ? ' \u2022 ' : '';
    const renderAsLink = node.children.length;
    const classNames = [styles['node-label'], styles[renderAsLink ? 'node-label-disabled' : '']];
    const LabelOrLink: any = renderAsLink ? 'a' : 'label';
    const labelOrLinkProps = {};
    if (renderAsLink) {
      labelOrLinkProps['href'] = 'javascript://';
      labelOrLinkProps['onClick'] = node.onClick;
    }
    return (
      <div key={node.systemName}>
        <div className={classNames.join(' ')}>
          {child && <span key="line" className={styles['line-angle']} />}
          <Icon name={getIconNameFromAlias(node.systemName)} size={16} />
          <div className={styles['label-primary-task']}>
            <LabelOrLink {...(labelOrLinkProps as any)}>
              {node.label}
              {dot}
              <strong>{node.familyLabel}</strong>
              <span className={styles['note']}>{node.note}</span>
            </LabelOrLink>
            {this.renderTasks(node)}
          </div>
        </div>
        {this.renderChildNodes(node, child)}
      </div>
    );
  }

  renderTasks(node: RelatedContentTypeNode) {
    if (node.tasks.length) {
      const items = node.tasks.map((task) => new SelectItemBuilder(task.label, task.id).build());
      return (
        <div className={styles['node-tasks']}>
          <PopupLink
            items={items}
            alignMenu="right"
            selectedItems={[]}
            onItemsSelected={(selected: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[]) => {
              if (newItems?.length > 0) {
                const item = newItems[0];
                node.tasks.forEach((task) => {
                  if (task.id === item.value) {
                    task.onClick();
                  }
                });
              }
            }}
          />
        </div>
      );
    }
    return null;
  }

  renderChildNodes(parent: RelatedContentTypeNode, indent: boolean) {
    if (parent.children.length) {
      const classNames = [styles['node-children']];
      if (indent) {
        classNames.push(styles['node-children-indent']);
      }
      return <div className={classNames.join(' ')}>{parent.children.map((child) => this.renderNode(child, true))}</div>;
    }
    return null;
  }

  render() {
    if (!LangUtil.isDefined(this.props.nodes)) {
      return null;
    }
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.nodes.map((node) => this.renderNode(node))}
      </div>
    );
  }
}

RelatedContentTypesBreadcrumb['displayName'] = componentName;
