import * as React from 'react';
import { useEffect, useState } from 'react';
import styles from './day-toggle.module.scss';
import { Text } from '../text/text';
import { Weekday } from './day-picker';
import { AriaCheckboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';

export interface DayToggleProps {
  isSelected?: boolean;
  weekday: Weekday;
  onDaySelected: (dayName: string, isSelected: boolean) => void;
  aria?: AriaCheckboxInterface;
  isKeyboardFocused: boolean;
}

const componentName = 'DayToggle';

export const DayToggle: React.FC<DayToggleProps> = (props) => {
  const attributes: React.HTMLAttributes<HTMLElement> = {};

  const [selected, setSelected] = useState(props.isSelected ?? false);
  const [hover, setHover] = useState(false);

  props.isKeyboardFocused && (attributes['data-focus'] = true);
  hover && (attributes['data-hover'] = true);

  const onDaySelected = (isSelected: boolean) => {
    setSelected(isSelected);
    props.onDaySelected(props.weekday, isSelected);
  };

  /* This watched for updates to props.isSelected, then applies it to the current state */
  useEffect(() => {
    setSelected(props.isSelected ?? false);
  }, [props.isSelected]);

  return (
    <div
      className={styles['component']}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      <input
        id={props.weekday}
        aria-hidden="true"
        type={'checkbox'}
        checked={props.isSelected}
        onChange={(e) => onDaySelected((e.target as HTMLInputElement).checked)}
        tabIndex={-1}
        {...props.aria}
      />
      <div className={[styles['day-picker'], styles[selected ? 'selected' : 'unselected']].join(' ')} {...attributes}>
        <Text size={'font16'} maxLines={1}>
          {props.weekday[0]}
        </Text>
      </div>
    </div>
  );
};

DayToggle['displayName'] = componentName;
