import * as React from 'react';
import { Text, TextSizes } from '../text/text';
import * as styles from './header.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export type HeaderType =
  /** Main header on the page */
  | 'page'

  /** Sub header on the page */
  | 'subPage'

  /** For features on a page, e.g. project timeline, tasks, or the header of components such as dialogue and callout */
  | 'feature'

  /** For sub features on a page or as an variant of feature */
  | 'subFeature'

  /** For sections/parts within a feature */
  | 'section'

  /** Used to refer from a header back to a field for which the header and its associated components provide actions such as editing */
  | 'field'

  /** For a variant of field */
  | 'fieldVariant'

  /** A variant of 'field', but smaller */
  | 'list';

export type HeaderLevel = 1 | 2 | 3 | 4 | 5 | 6;

export interface HeaderProps {
  /**
   * Optional DOM id to assign to the header
   */
  id?: string;

  /** The headerType controls the appearance of the header (font-size, margins etc.) */
  headerType: HeaderType;

  /** The header level as expressed in the HTML spec, e.g. 1 for a h1 element, 2 for a h2 element etc. */
  headerLevel: HeaderLevel;

  /**
   * Optional number of wrapped lines to show before ending an overflow with ellipsis.
   * By default, the heading will wrap, using as many lines as needed.
   */
  maxLines?: number;
  children?: React.ReactNode;
}

const sizes = new Map<HeaderType, TextSizes>([
  ['page', 'font40'],
  ['subPage', 'font32'],
  ['feature', 'font24'],
  ['subFeature', 'font20'],
  ['section', 'font16'],
  ['field', 'font14'],
  ['fieldVariant', 'font14'],
  ['list', 'font12'],
]);

const componentName = 'Header';

export const Header: React.FunctionComponent<HeaderProps> = (props) => {
  const HeaderElement: any = 'h' + props.headerLevel;
  const type = props.headerType || 'page';
  return (
    <HeaderElement id={props.id} className={styles[type]} data-component={componentName} {...getDataAttributes(props)}>
      <Text size={sizes.get(type)} maxLines={props.maxLines}>
        {props.children}
      </Text>
    </HeaderElement>
  );
};
(Header as any).displayName = componentName;
