import * as React from 'react';
import { PopupMenu } from './popup-menu';
import { useSelectionList } from './use-selection-list';
import { SelectItem } from '../../models/select-item';
import { DecorativeSelectionIndicator } from '../selection-list/selection-list';
import { getDataAttributes } from '../../util/attribute-util';

export type PopupMenuAlignment = 'left' | 'right';

export interface PopupMenuBridgeProps {
  /**
   * The id of the DOM element that opens the popup menu in response to a click, enter etc.
   * @deprecated
   */
  popupOpenerId?: string;

  /**
   * The select items to show in the popup menu
   */
  items: SelectItem[];

  /**
   * The items that are currently selected, if any
   */
  selectedItems?: SelectItem[];

  /**
   * Whether the popup menu is shown as open
   */
  isOpen: boolean;

  /**
   * Callback for when the popup is about to close
   */
  onClose: () => void;

  /**
   * Callback for when a new item has been selected
   * @param item the next selected item
   */
  onItemsSelected?: (selected: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[], isMouseEvent?: boolean) => any;

  /**
   * If enabled, a checkbox or checkIcon is shown as a visual indicator of the selected state of each item.
   * The checkbox option is purely decorative and does not appear in the accessibility tree.
   * Note: If you want to only have this applied to some of the items, you can set showSelectionIndicator on a specific SelectItem
   */
  decorativeSelectionIndicator?: DecorativeSelectionIndicator;

  /**
   * Which edge of the opener that the popup menu should align with.
   * Defaults to left.
   * @deprecated Not supported yet
   */
  alignMenu?: PopupMenuAlignment;

  /**
   * If enabled, only one item can be selected at one time
   */
  onlyAllowSingleSelectedItem?: boolean;

  id: string;

  children?: React.ReactNode;

  onSelect?: (item: SelectItem) => void;
}

/**
 * @deprecated Use PopupMenu instead. This is intended to ease the transition for Pure.
 */

const componentName = 'PopupMenuBridge';

export function PopupMenuBridge(props: PopupMenuBridgeProps) {
  const [menuItems, menuHandler] = useSelectionList({
    items: props.items,
    selectedItems: props.selectedItems,
    allowMultiple: !props.onlyAllowSingleSelectedItem,
    onItemsSelected: props.onItemsSelected,
    onSelect: props.onSelect,
    checkDecorator: props.decorativeSelectionIndicator === 'checkIcon' ? 'check' : undefined,
  });

  return (
    <PopupMenu
      id={props.id}
      asChild
      items={menuItems}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSelect={menuHandler}
      defaultOpen={false}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      {props.children}
    </PopupMenu>
  );
}

PopupMenuBridge['displayName'] = componentName;
