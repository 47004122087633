import * as React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'AreaFooter';

export const AreaFooter: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div data-component={componentName} {...getDataAttributes(props)}>
      {props.children}
    </div>
  );
};
AreaFooter.displayName = componentName;
