import * as React from 'react';
import * as ToastPrimitive from '@radix-ui/react-toast';
import styles from './toast.module.scss';
import { Stack } from '../stack/stack';
import { Inline } from '../inline/inline';
import { Icon, IconColor } from '../icon/icon';
import { Header } from '../header/header';
import { Paragraph } from '../paragraph/paragraph';
import { CommandButton } from '../command-button/command-button';
import { getPaletteClassName, getDefaultPalette } from '../../util/theme-util';
import { IconName } from '../icon/icon-SVGs';
import { useState } from 'react';

const componentName = 'Toast';

export type ToastStatus = 'information' | 'error' | 'success';
type StatusMapType = 'color' | 'icon';

const mapStatus = (status: ToastStatus, mapType: StatusMapType): IconName | IconColor => {
  const iconMap = {
    information: 'alert-solid-square' as IconName,
    error: 'failed-solid-square' as IconName,
    success: 'confirmation-solid-square' as IconName,
  };

  const colorMap = {
    information: 'info' as IconColor,
    error: 'error' as IconColor,
    success: 'success' as IconColor,
  };

  return mapType === 'icon' ? iconMap[status] : colorMap[status];
};
export interface ToastProps {
  /**
   * (Optional): Action callback function ran when the user clicks the action label
   */
  onAction?: () => void;
  /**
   * (Optional): Cancelled callback function ran when the user clicks the closes the modal manually
   */
  onCancelled?: () => void;
  /**
   * (Optional): Label text present on the action button
   */
  actionLabel?: string;
  /**
   * A string or a React component that is rendered as the body of the Toast.
   */
  body: React.ReactNode | string;
  /**
   * (Optional): The text displayed on a button in the footer of the toast to close it
   */
  cancelLabel?: string;
  /**
   * (Optional): Sets the number of milliseconds the toast will be displayed for
   */
  duration?: number;
  /**
   * (Optional): React node that can be passed in and rendered instead of the default
   */
  footer?: React.ReactNode;
  /**
   * The text displayed on the heading of the toast
   */
  heading: string;
  /**
   * Controls the icon type that is displayed with the toast
   */
  status: ToastStatus;
  /**
   * (Optional): Controls whether or not the open
   */
  open?: boolean;
}

export const Toast: React.FunctionComponent<ToastProps> = (
  { onAction, onCancelled, body, duration = 15000, footer, heading, status, actionLabel, cancelLabel, ...props },
  openableRef
) => {
  const [toastKey, setToastKey] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const closeLabelText = cancelLabel ?? 'Dismiss';
  const actionLabelText = actionLabel ?? 'Action';

  return (
    <ToastPrimitive.Root
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={toastKey}
      duration={duration}
      {...props}
      className={[getPaletteClassName({ name: getDefaultPalette().name }), styles['container']].join(' ')}
      data-component={componentName}
    >
      <Inline spacing={'8dp'} verticalAlignment={'top'}>
        <Icon fill={mapStatus(status, 'color') as IconColor} name={mapStatus(status, 'icon') as IconName} size={24}></Icon>
        <Stack spacing={'16dp'}>
          <ToastPrimitive.Title className={styles.title}>
            <Header headerType="subFeature" headerLevel={3}>
              {heading}
            </Header>
          </ToastPrimitive.Title>
          <ToastPrimitive.Description className={styles.body}>
            {typeof body === 'string' ? <Paragraph color={isHovered ? 'default' : 'secondary'}>{body}</Paragraph> : body}
          </ToastPrimitive.Description>
        </Stack>
      </Inline>
      {footer ? (
        footer
      ) : (
        <Inline horizontalAlignment="right" spacing="24dp">
          <ToastPrimitive.Close asChild aria-label={closeLabelText}>
            <CommandButton
              size="small"
              label={closeLabelText}
              onClick={() => {
                setToastKey(0);
                onCancelled?.();
              }}
              type="button"
            />
          </ToastPrimitive.Close>
          {!!onAction && (
            <ToastPrimitive.Action asChild altText={actionLabelText} aria-label={actionLabelText}>
              <CommandButton size="small" label={actionLabelText} onClick={onAction} type="button" />
            </ToastPrimitive.Action>
          )}
        </Inline>
      )}
    </ToastPrimitive.Root>
  );
};

Toast['displayName'] = componentName;
