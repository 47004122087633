import * as React from 'react';
import styles from './tree-selection-selected-empty-content.module.scss';
import { Label } from '../label/label';
import { getDataAttributes } from '../../util/attribute-util';

export interface TreeSelectionSelectedEmptyContentProperties {
  isHighlighted?: boolean;
  children: React.ReactNode;
}

const componentName = 'TreeSelectionSelectedEmptyContent';

export class TreeSelectionSelectedEmptyContent extends React.Component<TreeSelectionSelectedEmptyContentProperties, {}> {
  render() {
    const classes = [styles['component']];

    this.props.isHighlighted && classes.push(styles['highlight']);
    return (
      <div className={classes.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        {<Label style={{ opacity: 0.75, whiteSpace: 'unset' }} text={this.props.children as string} type="small" />}
      </div>
    );
  }
}

TreeSelectionSelectedEmptyContent['displayName'] = componentName;
