import * as React from 'react';
import {
  ButtonBaseProps,
  ButtonIconProps,
  ButtonLabelProps,
  ButtonNavigationProps,
  ButtonSizeProps,
  ButtonTypeProps,
} from '../button-base/button-interfaces';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import styles from './command-button.module.scss';
import { Icon } from '../icon/icon';
import { Text, TextSizes } from '../text/text';
import { getDataAttributes } from '../../util/attribute-util';
import { useStylingContext } from '../../style-context/styling-context-provider';

export interface CommandButtonBaseProps extends ButtonBaseProps, ButtonLabelProps, ButtonNavigationProps, ButtonSizeProps, ButtonIconProps {
  /**
   * Alignment of the button icon (defaults to left)
   */
  iconAlignment?: 'left' | 'right';
}

export type CommandButtonProps = ButtonTypeProps & CommandButtonBaseProps;

const buttonSizeToTextSize = new Map<ButtonSizeProps['size'], TextSizes>([
  ['large', 'font16'],
  ['small', 'font14'],
]);

const buttonBrandSizeToTextSize = new Map<ButtonSizeProps['size'], TextSizes>([
  ['small', 'font16'],
  ['medium', 'font20'],
  ['large', 'font24'],
]);

const componentName = 'CommandButton';

const Content = React.forwardRef<any, CommandButtonProps & ButtonFocusHandlerChildProps & Selectable>((props, ref) => {
  const { styling } = useStylingContext();
  const classes = [styles['component'], styles[styling]];
  props.size && classes.push(styles[props.size]);
  props.icon && classes.push(styles['hasIcon']);
  props.iconAlignment === 'right' && classes.push(styles['isRightAlignedIcon']);
  props.selected && classes.push(styles['selected']);
  props.buttonFeature && props.buttonFeature === 'CanSelect' && classes.push(styles['button-mode-selectable']);

  const attributes: React.HTMLAttributes<HTMLElement> = { id: props.id };
  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const textSize = styling === 'brand' ? buttonBrandSizeToTextSize.get(props.size) : buttonSizeToTextSize.get(props.size) ?? 'font16';

  const labelContent: React.ReactNode = (
    <React.Fragment>
      {props.icon && props.iconAlignment === 'left' && <Icon size={16} name={props.icon} />}
      {props.label && (
        <Text size={textSize} maxLines={1}>
          {props.label}
        </Text>
      )}
      {props.icon && props.iconAlignment === 'right' && <Icon size={16} name={props.icon} />}
    </React.Fragment>
  );

  let content: React.ReactNode = labelContent;

  switch (props.navigationType) {
    case 'to-start':
    case 'navigate-left': {
      classes && classes.push(styles['navigation-left']);
      content = (
        <React.Fragment>
          <Icon size={16} name={props.navigationType} />
          {labelContent}
        </React.Fragment>
      );
      break;
    }

    case 'to-end':
    case 'navigate-right': {
      classes && classes.push(styles['navigation-right']);
      content = (
        <React.Fragment>
          {labelContent}
          <Icon size={16} name={props.navigationType} />
        </React.Fragment>
      );
      break;
    }
    default:
      break;
  }

  switch (props.type) {
    case 'button': {
      const buttonAttributes: React.HTMLAttributes<HTMLButtonElement> = { ...attributes };
      props.disabled && (buttonAttributes['disabled'] = true);
      return (
        <button
          ref={ref}
          data-component={componentName}
          {...buttonAttributes}
          type="button"
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...getDataAttributes(props)}
        >
          {content}
        </button>
      );
    }
    case 'anchor': {
      const anchorAttributes: React.HTMLAttributes<HTMLAnchorElement> = { ...attributes };
      props.disabled && (anchorAttributes['data-disabled'] = true);
      return (
        <a
          ref={ref}
          data-component={componentName}
          target={props.target}
          {...anchorAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...(props.disabled ? {} : { href: props.href })}
          {...getDataAttributes(props)}
        >
          {content}
        </a>
      );
    }
    case 'cosmetic': {
      const divAttributes: React.HTMLAttributes<HTMLDivElement> = { ...attributes };
      props.disabled && (divAttributes['disabled'] = true);
      return (
        <div
          ref={ref}
          data-component={componentName}
          role={props.role}
          {...divAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...getDataAttributes(props)}
        >
          {content}
        </div>
      );
    }
    default:
      console.log(props);
  }
});

export class CommandButton extends React.PureComponent<CommandButtonProps> {
  static defaultProps: Partial<CommandButtonProps> = {
    iconAlignment: 'left',
    size: 'medium',
    type: 'button',
  };

  render() {
    return (
      <ButtonFocusHandler
        initialFocus={this.props.hasFocus}
        render={({ ref, hasFocus, hasHover }) => <Content ref={ref} {...this.props} hasFocus={hasFocus} hasHover={hasHover} />}
      />
    );
  }
}

CommandButton['displayName'] = 'CommandButton';

export interface Selectable {
  buttonFeature?: 'CanSelect';
  selected?: boolean;
}

/**
 * SelectableCommandButton is an internal component and should not be exported out of Graphene.
 * It purpose is to have the CommandButton work in a selectable state. The main use-case at the moment
 * is from Pagination.
 **/
export type SelectableCommandButtonProps = CommandButtonProps & Selectable;
export const SelectableCommandButton = (props: SelectableCommandButtonProps) => {
  const navigationProps: SelectableCommandButtonProps = { ...props, buttonFeature: 'CanSelect' };
  return <CommandButton {...navigationProps} />;
};
