import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from './draggable-list-item.module.scss';
import { Draggable, DraggableProps, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { IdEncoderUtil } from '../../../util/id-encoder-util';
import { getDataAttributes } from '../../../util/attribute-util';

export interface DraggableListItemProps extends Omit<DraggableProps, 'children'> {
  /**
   * Should say what the item is, and how to operate it
   */
  ariaRoleDescription: string;
  children: React.ReactNode;
}

const componentName = 'DraggableListItem';
export class DraggableListItem extends React.Component<DraggableListItemProps, any> {
  portal: HTMLElement = null;

  encodedId = IdEncoderUtil.encodeId('draggableListItem', this.props.draggableId);

  componentDidMount(): void {
    let portal: HTMLElement = document.querySelector('.draggable-list-item-portal');
    if (!portal) {
      portal = document.createElement('div');
      portal.classList.add('draggable-list-item-portal');

      if (!document.body) {
        throw new Error('body not ready for portal creation!');
      }
      document.body.appendChild(portal);
    }
    this.portal = portal || document.querySelector('.draggable-list-item-portal');
  }

  /**
   * The use of portal is done to prevent breakage when a parent item has any css transforms
   * reference url: https://github.com/atlassian/react-beautiful-dnd/issues/499
   * @param provided
   * @param snapshot
   */
  private renderItem(provided: DraggableProvided, snapshot: DraggableStateSnapshot) {
    const itemStyle: string[] = [styles['component']];

    const item = (
      <div
        className={itemStyle.join(' ')}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        aria-roledescription={this.props.ariaRoleDescription}
      >
        {this.props.children}
      </div>
    );

    if (!snapshot.isDragging) {
      return item;
    }
    return ReactDOM.createPortal(item, this.portal);
  }

  render() {
    return (
      <Draggable
        key={this.encodedId}
        type={this.props.type}
        index={this.props.index}
        draggableId={this.props.draggableId}
        isDragDisabled={this.props.isDragDisabled}
        shouldRespectForcePress={this.props.shouldRespectForcePress}
        disableInteractiveElementBlocking={this.props.disableInteractiveElementBlocking}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => this.renderItem(provided, snapshot)}
      </Draggable>
    );
  }
}

DraggableListItem['displayName'] = componentName;
