import React, { useEffect, useState } from 'react';

export interface NoSSRProps {
  children?: React.ReactNode;
}

export const NoSSR = (props: NoSSRProps): JSX.Element => {
  const [onClient, setIsOnClient] = useState(false);

  useEffect(() => {
    setIsOnClient(true);
  }, []);

  return onClient === true ? <>{props.children}</> : null;
};
