import * as React from 'react';
import { Icon } from '../icon/icon';
import styles from './select-related-content-type.module.scss';
import { ChildNode } from '../../models/child-node';
import { CommandButton } from '../command-button/command-button';
import { IconName } from '../icon/icon-SVGs';
import { getIconNameFromAlias } from '../icon/icon-utils';
import { getDataAttributes } from '../../util/attribute-util';

/**
 * A node that represents a content type and actions relating to that content type
 */
export class ContentTypeNode implements ChildNode<ContentTypeNode> {
  /**
   * Tasks for this node
   */
  tasks: ContentTypeTask[] = [];

  /**
   * @param systemName the system name, e.g. 'Person'
   * @param label the localized text relating to the content type
   * @param note a note to show after the label
   */
  constructor(public systemName: string, public label: string, public note: string) {
    this.children = [];
  }
  children: ContentTypeNode[];

  addTask(task: ContentTypeTask): this {
    this.tasks.push(task);
    return this;
  }
}

/**
 * Represents a task to perform on a given content type
 */
export class ContentTypeTask {
  /**
   * @param id unique id of the task
   * @param primary whether the task is primary, and should be shown in place of the node label
   * @param label the label of the task
   * @param icon the icon to show before the label
   * @param onClick callback for when the task is clicked
   */
  constructor(public id: string, public primary: boolean, public label: string, public icon: IconName, public onClick: () => any) {}
}

export interface SelectRelatedContentTypeProps {
  /**
   * The hierarchy of nodes to show
   */
  nodes?: ContentTypeNode[];
}

const componentName = 'SelectRelatedContentType';

export class SelectRelatedContentType extends React.Component<SelectRelatedContentTypeProps, {}> {
  renderNode(node: ContentTypeNode, child?: boolean) {
    return (
      <div key={node.systemName} data-key={`SelectRelatedContentType_node-${node.systemName}`}>
        <div className={styles['node-label']}>
          {child && <span key="line" className={styles['line-angle']} />}
          <Icon name={getIconNameFromAlias(node.systemName)} size={16} />
          {this.renderPrimaryTaskAndLabel(node)}
        </div>
        {this.renderNestedTasks(node)}
        {this.renderChildNodes(node, child)}
      </div>
    );
  }

  renderPrimaryTaskAndLabel(node: ContentTypeNode) {
    const primaryTask = node.tasks.find((t) => t.primary);
    const task = primaryTask && this.renderTask(primaryTask);
    const line = primaryTask && <span className={styles['label-task-line']} />;
    return (
      <div className={styles['label-primary-task']}>
        <label>
          {node.label}
          <span className={styles['note']}>{node.note}</span>
        </label>
        {line}
        {task}
      </div>
    );
  }

  renderNestedTasks(node: ContentTypeNode) {
    const tasks = node.tasks.filter((task) => !task.primary).map((task) => this.renderTask(task));
    if (tasks.length) {
      return <div className={styles['node-nested-tasks']}>{tasks}</div>;
    }
    return null;
  }

  renderTask(task: ContentTypeTask) {
    const line = task.primary ? null : <span key="line" className={styles['line-angle']} />;
    return (
      <div key={task.id} data-key={`SelectRelatedContentType_task-${task.id}`} className={styles['task']}>
        {line}
        <CommandButton type="button" key="command" size="small" icon={task.icon} label={task.label} onClick={task.onClick} />
      </div>
    );
  }

  renderChildNodes(parent: ContentTypeNode, indent: boolean) {
    if (parent.children.length) {
      const classNames = [styles['node-children']];
      if (indent) {
        classNames.push(styles['node-children-indent']);
      }
      return <div className={classNames.join(' ')}>{parent.children.map((child) => this.renderNode(child, true))}</div>;
    }
    return null;
  }

  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.nodes.map((node) => this.renderNode(node))}
      </div>
    );
  }
}

SelectRelatedContentType['displayName'] = componentName;
