import * as React from 'react';
import { ContextType } from 'react';
import styles from './radio-button.module.scss';
import { AriaRadioInterface } from '../AccessibilityProps';
import { Radio, RadioProps } from '../radio/radio';
import { Text, TextSizes } from '../text/text';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import StylingContext from '../../style-context/styling-context-provider';
import { getDataAttributes } from '../../util/attribute-util';
import { LangUtil } from '../../client-shared';

export interface RadioButtonProps extends RadioProps {
  /**
   * Optional DOM id to assign to the radio input, e.g. to associate a field element with it
   */
  id?: string;

  /**
   * The label to show next to the radio button
   */
  label: string;

  aria?: AriaRadioInterface;
}

const radioButtonTextSize = new Map<RadioButtonProps['size'], TextSizes>([
  ['large', 'font16'],
  ['small', 'font14'],
]);

const radioButtonBrandTextSize = new Map<RadioButtonProps['size'], TextSizes>([
  ['small', 'font16'],
  ['medium', 'font20'],
  ['large', 'font24'],
]);

const componentName = 'RadioButton';

export class RadioButton extends React.Component<RadioButtonProps> {
  static defaultProps: Partial<RadioButtonProps> = {
    size: 'medium',
    name: null,
  };
  context: ContextType<typeof StylingContext>;
  private uuid = LangUtil.randomUuid();
  private radio: Radio;

  setRef = (reference: Radio) => {
    this.radio = reference;
  };

  render() {
    const { styling } = this.context;
    const radioId = IdEncoderUtil.encodeId('radio_', this.props.id || this.uuid);
    const labelId = IdEncoderUtil.encodeId('label_', this.props.id || this.uuid);

    const classNames = [styles['component'], styles[styling], styles[this.props.size]];
    this.props.isDisabled && classNames.push(styles['disabled']);

    const textSize =
      styling === 'brand' ? radioButtonBrandTextSize.get(this.props.size) : radioButtonTextSize.get(this.props.size) ?? 'font16';

    return (
      <div className={classNames.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        <Radio
          size={this.props.size}
          id={radioId}
          isDisabled={this.props.isDisabled}
          isSelected={this.props.isSelected}
          onSelectedChanged={this.props.onSelectedChanged}
          name={this.props.name}
          ref={this.setRef}
          aria={this.props.aria}
          {...getDataAttributes(this.props, '-radio')}
        />
        <label htmlFor={radioId} className={styles['label']} id={labelId} {...getDataAttributes(this.props, '-label')}>
          <Text size={textSize} maxLines={1}>
            {this.props.label}
          </Text>
        </label>
      </div>
    );
  }

  focus() {
    this.radio.focus();
  }
}

RadioButton['displayName'] = componentName;
RadioButton.contextType = StylingContext;
