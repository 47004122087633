/*HTML Attributes*/
export const ARIA_ACTIVEDESCENDANT = 'aria-activedescendant';
export const ARIA_MULTISELECTABLE = 'aria-multiselectable';
export const ARIA_EXPANDED = 'aria-expanded';
export const ARIA_SELECTED = 'aria-selected';
export const ARIA_HASPOPUP = 'aria-haspopup';
export const ARIA_OWNS = 'aria-owns';
export const ARIA_ROWINDEX = 'aria-rowindex';
export const ARIA_ROWSPAN = 'aria-rowspan';
export const ARIA_ROWCOUNT = 'aria-rowcount';
export const ARIA_COLINDEX = 'aria-colindex';
export const ARIA_COLSPAN = 'aria-colspan';
export const ARIA_COLCOUNT = 'aria-colcount';
export const ROLE = 'role';
export const ARIA_SORT = 'aria-sort';

/*Attribute values*/
export const GROUP = 'group';
export const COMBOBOX = 'combobox';
export const TREEITEM = 'treeitem';
export const TREE = 'tree';
export const TABLIST = 'tablist';
export const GRID = 'grid';
export const GRIDCELL = 'gridcell';
export const ROW = 'row';
export const COLUMNHEADER = 'columnheader';
export const MENU = 'menu';
export const MENUITEM = 'menuitem';
export const DIALOG = 'dialog';
export const CHECKBOX = 'checkbox';
export const RADIO = 'radio';
export const PRESENTATION = 'presentation';
