/**
 *  A part in a dash-separated styleName.
 *  Either as a string or a keyed string that is included depending on the corresponding boolean value.
 */
type StyleNamePart = string | { [key: string]: boolean };

export const LangUtil = {
  /**
   * Creates a styleName given a number of style name parts. Useful in combination with @CSSModules and styleName on TSX elements.
   * Each part is separated with dashes, e.g. 'stylename1-stylename2'
   * @param styleNameParts a number of style name parts used to generate the full style name
   * @return the generated style name
   */
  createStyleName(...styleNameParts: StyleNamePart[]): string {
    const parts = [];
    if (styleNameParts) {
      styleNameParts.forEach((part) => {
        if (typeof part === 'string') {
          parts.push(part);
        } else {
          Object.keys(part).forEach((key) => {
            if (part[key]) {
              parts.push(key);
            }
          });
        }
      });
    }
    return parts.join('-');
  },

  /**
   * Combines a number of style names from createStyleName to be applied at once. Style names are separated by white space per the CSS multi class convention, e.g. 'cls1 clas2';
   * @param styleNames the style names to combine
   * @return the generated style name
   */
  createMultiStyleNames(...styleNames: Array<StyleNamePart[]>) {
    return styleNames.map((s) => LangUtil.createStyleName(...s)).join(' ');
  },

  /**
   * Gets whether the specified value is considered as defined.
   * - Values null and undefined return false
   * - Numbers return true, expect NaN which returns false
   * - All booleans return true
   * - Arrays return true when non-empty
   * - For all other cases, e.g. objects, functions, dates, etc. boolean coercion using !! is applied.
   * @param value the value to check
   * @return {boolean} whether the value is defined.
   */
  isDefined(value) {
    if (value === undefined || value === null) {
      return false;
    }
    if (typeof value === 'string') {
      return !!(value as string).trim();
    }
    if (typeof value === 'number') {
      // expect NaN, all valid numbers including 0 are considered defined
      return !Number.isNaN(value);
    }
    if (typeof value === 'boolean') {
      // all booleans considered defined
      return true;
    }
    //mobx observableArray will be caught in this, as it is an array with extra features
    if (Array.isArray(value)) {
      // non-empty array is defined
      return value.length > 0;
    }

    // boolean coercion for all others
    return !!value;
  },

  first<T>(list: T[]): T {
    if (this.isDefined(list)) {
      return list[0];
    }
    return null;
  },

  last<T>(list: T[]): T {
    if (this.isDefined(list)) {
      return list[list.length - 1];
    }
    return null;
  },

  firstMap<T, R>(items: T[], map: (item: T) => R): R {
    if (this.isDefined(items)) {
      return map(items[0]);
    }
    return null;
  },

  forceCast<T>(obj): T {
    return obj as T;
  },

  getParameterByName(name: string, url?: string): string {
    if (!url) {
      url = typeof window === 'object' ? window.location.href : '';
    }
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)', 'i');
    const results = regex.exec(url);
    if (!results) {
      return null;
    }
    if (!results[2]) {
      return '';
    }
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },

  randomUuid(): string {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  },

  /**
   * Creates a map based on a set of items and mapping functions for keys and values
   */
  createMap<I, V>(items: I[], keys: (item: I) => string, values: (item: I) => V): { [key: string]: V } {
    const result: { [key: string]: V } = {};
    items.forEach((item) => (result[keys(item)] = values(item)));
    return result;
  },

  printReactChildren(children) {
    const seenValues = [];
    console.log(
      JSON.stringify(
        children,
        (key: string, value) => {
          if (key === '_owner') {
            return null;
          }
          if (key === '_mountOrder') {
            return null;
          }
          if (key === '_rootNodeID') {
            return null;
          }
          if (key === '_domID') {
            return null;
          }
          if (key === 'webpackPropertiesBundle') {
            return null;
          }
          if (typeof value === 'object') {
            if (seenValues.indexOf(value) !== -1) {
              return null;
            } else {
              seenValues.push(value);
            }
          }
          return value;
        },
        2
      )
    );
    return children;
  },
};
