/**
 * Based on Braid Design System.
 * See https://github.com/seek-oss/braid-design-system/blob/master/LICENSE for legal terms
 *
 * Local additions: Added className prop.
 */

import * as React from 'react';
import { createContext, ReactElement } from 'react';
import { Box } from '../box/box';
import type { ColumnProps } from '../column/column';
import { useNegativeMarginLeft } from '../../hooks/use-negative-margin/use-negative-margin';
import { ResponsiveSpace, Space } from '../../vanilla-extract/atoms/atoms';
import { normalizeResponsiveValue } from '../../vanilla-extract/atoms/sprinkles.css';
import { CollapsibleAlignmentProps, resolveCollapsibleAlignmentProps } from '../../util/collapsible-alignment-props';
import { getDataAttributes } from '../../util/attribute-util';

type CollapsibleAlignmentChildProps = ReturnType<typeof resolveCollapsibleAlignmentProps>['collapsibleAlignmentChildProps'];

interface ColumnsContextValue {
  collapseMobile: boolean;
  collapseTablet: boolean;
  mobileSpace: Space;
  tabletSpace: Space;
  desktopSpace: Space;
  collapsibleAlignmentChildProps: CollapsibleAlignmentChildProps | null;
}

export const ColumnsContext = createContext<ColumnsContextValue>({
  collapseMobile: false,
  collapseTablet: false,
  mobileSpace: 'none',
  tabletSpace: 'none',
  desktopSpace: 'none',
  collapsibleAlignmentChildProps: null,
});

export interface ColumnsProps extends CollapsibleAlignmentProps {
  /**
   * The space between the columns
   */
  space: ResponsiveSpace;

  /**
   * The child columns to render in the column layout
   */
  children: Array<ReactElement<ColumnProps> | null> | ReactElement<ColumnProps> | null;

  /**
   * Optional class name. Should only be used as a last resort since it may clash with the CSS
   * that columns uses internally, e.g. `display`.
   *
   * See the `atomicClasses` function to reuse existing available classes such as background colors etc.
   */
  className?: string;
}

const componentName = 'Columns';

export const Columns = ({ children, collapseBelow, reverse = false, space = 'none', align, alignY, className, ...props }: ColumnsProps) => {
  const normalizedSpace = normalizeResponsiveValue(space);
  const { mobile: mobileSpace = 'none', tablet: tabletSpace = mobileSpace, desktop: desktopSpace = tabletSpace } = normalizedSpace;

  const { collapsibleAlignmentProps, collapsibleAlignmentChildProps, collapseMobile, collapseTablet, orderChildren } =
    resolveCollapsibleAlignmentProps({
      collapseBelow,
      align,
      alignY,
      reverse,
    });

  const negativeMarginLeft = useNegativeMarginLeft({
    mobile: collapseMobile ? 'none' : mobileSpace,
    tablet: collapseTablet ? 'none' : tabletSpace,
    desktop: desktopSpace,
  });

  let fullClassName = negativeMarginLeft;
  if (className) {
    fullClassName = `${fullClassName} ${className}`;
  }

  return (
    <Box {...collapsibleAlignmentProps} className={fullClassName} data-component={componentName} {...getDataAttributes(props)}>
      <ColumnsContext.Provider
        value={{
          collapseMobile,
          collapseTablet,
          mobileSpace,
          tabletSpace,
          desktopSpace,
          collapsibleAlignmentChildProps,
        }}
      >
        {orderChildren(children)}
      </ColumnsContext.Provider>
    </Box>
  );
};

Columns['displayName'] = componentName;
