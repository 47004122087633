import * as React from 'react';
import { LegacyRef } from 'react';
import styles from './table.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface TrProps {
  children?: React.ReactNode;
  onClick?: () => void;
  assignRef?: LegacyRef<HTMLTableRowElement>;

  /**
   * True will change the styling so that there is no hover style on the row.
   * Default is false.
   */
  disableHover?: boolean;
}

const componentName = 'Tr';

export class Tr extends React.Component<TrProps, any> {
  render() {
    const classes = [styles['tr']];
    const { assignRef, ...props } = this.props;
    this.props.onClick && classes.push(styles['clickable']);
    this.props.disableHover && classes.push(styles['disable-hover']);

    return (
      <tr
        ref={assignRef}
        {...props}
        className={classes.join(' ')}
        data-component={componentName}
        onClick={props.onClick}
        {...getDataAttributes(this.props)}
      >
        {props.children}
      </tr>
    );
  }
}
Tr['displayName'] = componentName;
