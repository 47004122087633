import * as React from 'react';
import styles from './tab.module.scss';
import { Button } from '../button/button';
import { S_M_L_Size } from '../../components/t-shirt-sizes';
import { Orientation } from './tabs';
import { IconName } from '../../components/icon/icon-SVGs';

export interface TabProps {
  label?: string;
  iconLeftName?: IconName;
  iconRightName?: IconName;
  iconOnlyName?: IconName;
  iconOnly?: boolean;
  disabled?: boolean;
  id: string;
  children?: React.ReactNode | React.ReactNode[];
}

export enum MoveDirection {
  Prev = -1,
  Next = 1,
  Start = 999999,
  End = -999999,
}

export interface TabPrivateProps {
  selected: boolean;
  orientation: Orientation;
  onSelected: () => void;
  onMoveSelection: (direction: MoveDirection) => void;
  size?: S_M_L_Size;
}

const componentName = 'Tab';

export function Tab({ id, label, iconLeftName, iconRightName, iconOnly, iconOnlyName, disabled, ...privateProps }: TabProps) {
  const { selected, orientation, onSelected, onMoveSelection: onSelectNew, size } = privateProps as any as TabPrivateProps;
  const didMount = React.useRef(false);

  const classNames = [styles[orientation], styles[size]];
  const ref = React.useRef<HTMLButtonElement>(null);

  selected && classNames.push(styles.selected);
  disabled && classNames.push(styles.disabled);

  React.useEffect(() => {
    // This effect gives the tab focus when it is selected.
    // However, we don't want to do it when the component has just been mounted.
    // If we did, then the first selected tab would grab focus.
    if (didMount.current && selected && !disabled) {
      ref?.current?.focus();
    }
    didMount.current = true;
  }, [selected, ref]);

  const handleKeyboard = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowUp':
        event.preventDefault();
        onSelectNew(MoveDirection.Prev);
        break;
      case 'ArrowRight':
      case 'ArrowDown':
        event.preventDefault();
        onSelectNew(MoveDirection.Next);
        break;
      case 'Home':
        event.preventDefault();
        onSelectNew(MoveDirection.Start);
        break;
      case 'End':
        event.preventDefault();
        onSelectNew(MoveDirection.End);
        break;
    }
  };

  return (
    <span data-testid={`${id}`} className={classNames.join(' ')} data-component={componentName}>
      <Button
        variant="tertiary"
        iconLeftName={iconLeftName}
        iconRightName={iconRightName}
        iconOnly={iconOnly}
        iconOnlyName={iconOnlyName}
        disabled={disabled}
        text={label}
        ref={ref as any}
        role="tab"
        size={size}
        tabIndex={selected ? 0 : -1}
        aria-selected={selected}
        onKeyDown={(e) => handleKeyboard(e)}
        onClick={() => onSelected()}
        aria-label={iconOnlyName}
      />
    </span>
  );
}
Tab.displayName = componentName;
