import * as React from 'react';
import styles from './table.module.scss';
import { AriaRoletypeInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';

export interface ThProps {
  children?: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  rowSpan?: number;
  colSpan?: number;
  clearBorder?: boolean;

  /**
   * Aria properties available on any role
   */
  aria?: AriaRoletypeInterface;
}

const componentName = 'Th';

export class Th extends React.Component<ThProps, any> {
  render() {
    const classNames = [styles['th']];

    if (this.props.clearBorder) {
      classNames.push(styles['clear-border']);
    }

    return (
      <th
        className={classNames.join(' ')}
        data-component={componentName}
        style={{ width: this.props.width, minWidth: this.props.minWidth, maxWidth: this.props.maxWidth }}
        {...this.props.aria}
        rowSpan={this.props.rowSpan}
        colSpan={this.props.colSpan}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </th>
    );
  }
}
Th['displayName'] = componentName;
