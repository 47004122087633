import * as React from 'react';
import { IconName, icons } from './icon-SVGs';
import './icon.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export type IconColor =
  | 'default'
  | 'inverse'
  | 'secondary'
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'interaction'
  | 'brand'
  | 'interaction-contrast-1'
  | 'interaction-disabled';

export interface IconProps {
  /**
   * The name of the icon to show, e.g. "navigate-down" or "Person"
   */
  name: IconName;

  /**
   * The size of the icon, e.g. 16, 32, etc.
   * If size is not added then it will default to 32, and if null is supplied then the icon will expand to fit the space available.
   */
  size?: number;

  /**
   * color of icon - matches the '$color-icon-' tokens
   * $color-icon-primary is selected by default
   */
  fill?: IconColor;
}

const componentName = 'Icon';

/**
 * Icon component that shows a named svg shape, e.g. a Person icon.
 * Note that system names can be passed directly as name since a lowercase conversion takes place.
 */
export class Icon extends React.Component<IconProps, {}> {
  render() {
    const size = this.props.size === undefined ? 32 : this.props.size;
    const iconName = this.props.name;
    const IconSVG = icons[iconName];
    if (IconSVG) {
      return (
        <IconSVG
          size={size}
          name={iconName}
          fill={this.props.fill ? this.props.fill : 'default'}
          data-component={componentName}
          {...getDataAttributes(this.props)}
        />
      );
    }
    return <svg role={'presentation'} width={size} height={size} data-component={componentName} {...getDataAttributes(this.props)} />;
  }
}

Icon['displayName'] = componentName;
