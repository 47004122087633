import * as React from 'react';
import { Box } from '../../../components/box/box';
import { Icon } from '../../../components/icon/icon';
import { Label } from '../../label/label';
import { Stack } from '../../../components/stack/stack';
import { Inline } from '../../../components/inline/inline';
import { IconName } from '../../../components/icon/icon-SVGs';
import { getDataAttributes } from '../../../util/attribute-util';
import styles from './form-elements-wrapper.module.scss';
import { S_M_L_XL_Size } from '../../../components/t-shirt-sizes';

const componentName = 'FormElementWrapper';

export interface FormElementWrapperProps {
  /**
   * Defines htmlFor property label component. The htmlFor attribute specifies which form element a label is bound to
   */
  dataId: string;
  /**
   * (Optional): Defines the height and font size of the element
   */
  size?: S_M_L_XL_Size;
  /**
   *  Defines component which should be rendered
   */
  children?: React.ReactElement;
  /**
   * (Optional): Description of what data is expected to be provided in the component.
   */
  label?: string;
  /**
   * (Optional): Text provided at the bottom of the field to provide additional information about the state of the textarea field
   * whether it is valid.
   */
  infoText?: string;
  /**
   * (Optional): Text provided at the bottom of the field to provide details about potential errors as a result of changes
   * to the textarea field.
   */
  errorText?: string;
  /**
   * (Optional): Text provided at the bottom of the field to provide a positive verification message regarding changes
   * to the textarea field.
   */
  successText?: string;
  /**
   * (Optional): Defines text message which is placed next to label.
   */
  optionalText?: string;
  /**
   * (Optional): Defines width of helper text.
   */
  width?: string;
  /**
   * (Optional): Value provided to show amount of characters in counter.
   */
  value?: string;
  /**
   * (Optional): Defines if we need to display counter.
   */
  maxCharacters?: number;
  /**
   * (Optional): Defines if we need to display border on the left on error state.
   */
  withErrorBorderLeft?: boolean;
}

export const FormElementWrapper = ({
  size,
  label,
  value,
  width,
  dataId,
  infoText,
  children,
  errorText,
  successText,
  optionalText,
  maxCharacters,
  withErrorBorderLeft,
  ...props
}: FormElementWrapperProps): JSX.Element => {
  const messageStyles = [styles['message-1'], styles['message-2'], styles['message-3']];

  const messages = [
    {
      type: 'info',
      text: infoText,
    },
    {
      type: 'error',
      text: errorText,
      icon: 'alert-outline-square' as IconName,
    },
    {
      type: 'success',
      text: successText,
      icon: 'confirmation-outline-circle' as IconName,
    },
  ]
    .filter(({ text }) => typeof text === 'string' && !!text)
    .filter(({ type }, _, arr) => type !== 'success' || !arr.find(({ type }) => type === 'error'));

  const getIconSize = (size) => {
    switch (size) {
      case 'small':
        return 14;
      case 'medium':
        return 14;
      case 'large':
        return 16;
      case 'xlarge':
        return 16;
      default:
        return 14;
    }
  };

  return (
    <div data-testid={componentName} data-component={componentName} {...getDataAttributes(props)}>
      <Stack spacing="4dp">
        <Inline>
          {label && <Label htmlFor={dataId} size={['small', 'medium'].includes(size) ? 'small' : 'medium'} text={label} wrap={false} />}
          {optionalText && (
            <Label size={['small', 'medium'].includes(size) ? 'small' : 'medium'} text={optionalText} className={styles['optional-text']} />
          )}
        </Inline>
        <Box className={styles[withErrorBorderLeft && errorText ? 'withErrorBorderLeft' : '']}>{children}</Box>
        <div className={[styles['helper-text'], styles[size]].join(' ')} style={{ width: '100%' }}>
          {maxCharacters > 0 && (
            <span className={styles['counter']}>
              {value?.toString()?.length || 0}/{maxCharacters}
            </span>
          )}
          {messages.map(({ type, text, icon }, i) => (
            <span aria-label={text} className={`${messageStyles[i]} ${styles[type]}`} key={`textarea-label-${i}`}>
              {icon && <Icon name={icon} size={getIconSize(size)} />}
              {text}
            </span>
          ))}
        </div>
      </Stack>
    </div>
  );
};

FormElementWrapper.displayName = componentName;
