/**
 * Based on Braid Design System.
 * See https://github.com/seek-oss/braid-design-system/blob/master/LICENSE for legal terms
 */
import { mapResponsiveValue, OptionalResponsiveValue } from '../vanilla-extract/atoms/sprinkles.css';

export type Align = 'left' | 'center' | 'right' | 'stretch';
export type AlignY = 'top' | 'center' | 'bottom';

const alignToFlexAlignLookup = {
  left: 'flexStart',
  center: 'center',
  right: 'flexEnd',
  stretch: 'spaceBetween',
} as const;

export const alignToFlexAlign = (align: OptionalResponsiveValue<Align> | undefined) =>
  align ? mapResponsiveValue(align, (value) => alignToFlexAlignLookup[value]) : undefined;

const alignYToFlexAlignLookup = {
  top: 'flexStart',
  center: 'center',
  bottom: 'flexEnd',
} as const;

export const alignYToFlexAlign = (alignY: OptionalResponsiveValue<AlignY> | undefined) =>
  alignY ? mapResponsiveValue(alignY, (value) => alignYToFlexAlignLookup[value]) : undefined;
