import { isSSR } from '../../util/ssr';

export interface Rect {
  width: number;
  height: number;
  left?: number;
  top?: number;
}

export class HTMLMeasurer {
  static container: HTMLElement;

  // static defaultDivStyles: {
  //     [key: string]: string;
  // } = {};

  static commonElementTypes = ['DIV', 'SPAN'];

  static defaultMap: {
    [key: string]: {
      [key: string]: string;
    };
  } = {};

  static stripStyles = ['opacity', 'visibility', 'z-index'];

  static dataSetName = 'dummy';

  /**
   * Utility to compute the dimensions of a given HTMLElement if a different styling had been applied.
   * Useful for calculating actual height of an element with a max-height or height property.
   *
   * @param elem
   * @param stripAdditionalStyles
   * @param overrideStyles
   * @param leafSelectors list of selectors which causes the function to stop evaluating children and instead replace the leafs with empty dummy elements with the targets dimensions and margins
   */
  static computeDimensions(
    elem: HTMLElement,
    stripAdditionalStyles: string[],
    overrideStyles: { key: string; value: string }[],
    leafSelectors: string[] = ['svg']
  ): Rect {
    if (isSSR) {
      return { height: 0, width: 0 };
    }
    if (Object.getOwnPropertyNames(HTMLMeasurer.defaultMap).length === 0) {
      HTMLMeasurer.commonElementTypes.forEach((type) => {
        HTMLMeasurer.defaultMap[type] = {};
        const cleanElement = document.createElement(type);
        window.document.body.appendChild(cleanElement);
        const computed = window.getComputedStyle(cleanElement);
        for (let x = 0; x < computed.length; x++) {
          HTMLMeasurer.defaultMap[type][computed[x]] = computed[computed[x]];
        }
        window.document.body.removeChild(cleanElement);
      });
    }

    const clone = elem.cloneNode(true) as HTMLElement;

    //performance optimization. If leafSelectors have been provided, replace them with dummy nodes with appropriate dimensions and margins
    leafSelectors.forEach((selector) => {
      const newLeafs = clone.querySelectorAll(selector);

      for (let i = 0; i < newLeafs.length; i++) {
        const leaf = newLeafs.item(i);
        const leafStyle = window.getComputedStyle(leaf);
        const leafRect = leaf.getBoundingClientRect();
        const parent = leaf.parentNode;
        const dummy = document.createElement('div');
        dummy.dataset[HTMLMeasurer.dataSetName] = 'dummy';
        dummy.style.setProperty('max-height', leafRect.height + 'px');
        dummy.style.setProperty('min-height', leafRect.height + 'px');
        dummy.style.setProperty('max-width', leafRect.width + 'px');
        dummy.style.setProperty('min-width', leafRect.width + 'px');
        dummy.style.setProperty('margin', leafStyle.margin);
        dummy.style.setProperty('flex', leafStyle.flex);
        parent.replaceChild(dummy, leaf);
      }
    });

    //strip all IDs
    clone.setAttribute('id', Math.random().toString());
    const nodes = clone.querySelectorAll('[id]');
    for (let i = 0; i < nodes.length; i++) {
      (nodes[i] as HTMLElement).removeAttribute('id');
    }

    //strip styles that might make this element visible
    HTMLMeasurer.stripStyles.forEach((s) => clone.style.removeProperty(s));

    //strip styles specific to this measurement
    stripAdditionalStyles.forEach((s) => clone.style.removeProperty(s));

    HTMLMeasurer.cloneStyles(elem, clone, stripAdditionalStyles);

    overrideStyles && overrideStyles.forEach((s) => clone.style.setProperty(s.key, s.value));

    if (HTMLMeasurer.container === undefined) {
      HTMLMeasurer.createContainer(document.body);
    }

    HTMLMeasurer.container.appendChild(clone);

    const rect = clone.getBoundingClientRect();

    HTMLMeasurer.container.removeChild(clone);

    return {
      width: rect.width,
      height: rect.height,
    };
  }

  /**
   *
   * @param text - The text to be measured. Note. This expects the text to be on a single line.
   * @param fontSize - The text size in pixels
   * @param fontFamily - The text font family e.g. "Nexus Sans", Arial, sans-serif
   * @returns a width in pixels. Note this method is slow, and should not be used with performance in mind.
   */
  public static measureText(text: string, fontSize: number, fontFamily: string): number {
    if (isSSR) {
      /* When the document is not available, this indicates that it is running on the server not the client */
      console.log('measureText not supported in SSR');
      return 0;
    }
    let div = document.createElement('div');
    div.style.fontSize = `${fontSize}px`;
    div.style.fontFamily = fontFamily;
    div.style.position = 'absolute';

    const ensureElementIsHidden = () => {
      div.style.setProperty('z-index', '-1');
      div.style.setProperty('left', '-1000');
      div.style.setProperty('top', '-1000');
    };

    ensureElementIsHidden();

    document.body.appendChild(div);

    div.textContent = text;
    const result = div.clientWidth;

    document.body.removeChild(div);
    div = null;

    return result;
  }

  private static cloneStyles(base: HTMLElement, clone: HTMLElement, stripAdditionalStyles?: string[]) {
    if (base.nodeType !== 1) {
      throw 'cannot clone styles on something that is not an element';
    }

    const baseStyles = window.getComputedStyle(base);

    //strip classes from clone
    //we can't simply use clone.className as this will break if the element is an SVG, but classList works
    if (clone.classList) {
      // IE11 classList is undefined on SVG elements
      for (let i = 0; i < clone.classList.length; i++) {
        clone.classList.remove(clone.classList.item(i));
      }
    }

    //apply remaining styles to clone, unless they are on our naughty list
    for (let i = 0; i < baseStyles.length; i++) {
      const style = baseStyles[i];
      if (typeof baseStyles[style] !== 'string' && typeof baseStyles[style] !== 'number') {
        continue;
      }
      if (HTMLMeasurer.stripStyles.some((s) => s === style)) {
        clone.style.removeProperty(style);
        continue;
      }
      //our UI mostly consists of the same element type so lets ignore default styles on a few of them. There is no reason to override a default value
      if (
        this.commonElementTypes.some((t) => t === clone.tagName) &&
        // eslint-disable-next-line no-prototype-builtins
        HTMLMeasurer.defaultMap[clone.tagName].hasOwnProperty(style) &&
        HTMLMeasurer.defaultMap[clone.tagName][style] === baseStyles[style]
      ) {
        continue;
      }
      if (stripAdditionalStyles !== undefined && stripAdditionalStyles.some((s) => s === style)) {
        clone.style.removeProperty(style);
        continue;
      }

      clone.style.setProperty(style, baseStyles[style]);
    }

    //now do it again!
    if (base.hasChildNodes()) {
      for (let i = 0; i < base.childNodes.length; i++) {
        if (base.childNodes[i].nodeType !== 1) {
          continue;
        }
        if ((clone.childNodes[i] as HTMLElement).dataset[HTMLMeasurer.dataSetName]) {
          continue;
        }
        HTMLMeasurer.cloneStyles(base.childNodes[i] as HTMLElement, clone.childNodes[i] as HTMLElement);
      }
    }
  }

  private static createContainer(mountPoint: HTMLElement) {
    HTMLMeasurer.container = document.createElement('div');
    //HTMLMeasurer.container.style.setProperty('zIndex', '-1');
    //HTMLMeasurer.container.style.setProperty('visibility', 'hidden');
    HTMLMeasurer.container.style.setProperty('position', 'absolute');
    HTMLMeasurer.container.style.setProperty('pointerEvents', 'none');

    mountPoint.appendChild(HTMLMeasurer.container);
  }
}
