import { TreeNode } from './tree-node';

export class LabelSubLabelNode<V> implements TreeNode<LabelSubLabelNode<V>> {
  public label: string;
  public value: V;
  public subLabel: string;

  constructor(id: string, label: string, value: V, isOpen: boolean, subLabel = '', children: LabelSubLabelNode<any>[] = []) {
    this.id = id.toString();
    this.isOpen = isOpen;
    this.children = children;
    this.label = label;
    this.value = value;
    this.subLabel = subLabel;
  }

  id: string;
  isOpen: boolean;
  children: LabelSubLabelNode<V>[];

  setOpen(isOpen: boolean) {
    this.isOpen = isOpen;
  }

  addChild(child: LabelSubLabelNode<any>) {
    this.children.push(child);
  }
}
