import * as React from 'react';
import { useState } from 'react';
import styles from './chip.module.scss';
import { Icon } from '../icon/icon';
import { Inline } from '../inline/inline';
import { Label } from '../label/label';
import { getDataAttributes } from '../../util/attribute-util';
import { AriaRoletypeInterface } from '../AccessibilityProps';

export interface ChipProps {
  /**
   * The main label
   */
  label: string;
  /**
   * (Optional): Number that comes after the label if higher than 999 will be changed to 999+.
   */
  countLabel?: number;
  /**
   * (Optional): OnClick callback for the chip itself
   */
  chipOnClick?: () => void;
  /**
   * (Optional): Boolean value to check if the chip is selected or not
   */
  selected?: boolean;
  /**
   * (Optional): OnClick callback for the X icon
   */
  cancelOnClick?: () => void;
  /**
   * (Optional): Aria for the chip itself
   */
  chipAria?: AriaRoletypeInterface;
  /**
   * (Optional): Aria for the X icon button
   */
  cancelButtonAria?: AriaRoletypeInterface;
  /**
   * (Optional): To disable the chip
   */
  disabled?: boolean;
  /**
   * (Optional): Id used for the chip
   */
  chipId?: string;
  /**
   * (Optional): Id used for the cancel button
   */
  cancelButtonId?: string;
  /**
   * (Optional): Can be provided for custom focus methods by relying on this when disableTabStopIs prop is provided, when the focusedId matches chipId or cancelButtonId the appropriate button will have a data focus attribute on it
   */
  focusedId?: string;
  /**
   * (Optional): Disables tab stop, can be used to allow for custom keyboard movement.
   */
  disableTabStop?: boolean;
}

const componentName = 'Chip';

export const Chip: React.FunctionComponent<ChipProps> = (props: ChipProps) => {
  const classes: string[] = [styles['chip-component']];
  props.disabled && classes.push(styles['disabled']);

  return (
    <div data-component={componentName} {...getDataAttributes(props)} className={classes.join(' ')}>
      <InnerChip
        innerId={props.chipId}
        aria={props.chipAria}
        focusedId={props.focusedId}
        selected={props.selected}
        onClickChip={!props.disabled ? props.chipOnClick : null}
        label={props.label}
        secondaryLabel={props.countLabel}
        disabled={props.disabled}
        disableTabStop={props.disableTabStop}
      >
        <ChipCancel
          focusedId={props.focusedId}
          cancelId={props.cancelButtonId}
          aria={props.cancelButtonAria}
          disableTabStop={props.disableTabStop}
          cancelOnClick={!props.disabled ? props.cancelOnClick : null}
          disabled={props.disabled}
        />
      </InnerChip>
    </div>
  );
};

interface InnerChipProps {
  innerId?: string;
  aria?: AriaRoletypeInterface;
  label: string;
  secondaryLabel?: number;
  onClickChip?: () => void;
  selected?: boolean;
  disabled?: boolean;
  focusedId?: string;
  disableTabStop?: boolean;
  children: React.ReactNode;
}

const InnerChip = (props: InnerChipProps) => {
  const attributes: React.HTMLAttributes<HTMLElement> = {};
  const [focus, setFocus] = useState(false);

  const selected: boolean = props.selected ? props.selected : false;
  const chipClasses = [styles['chip']];
  const buttonClasses = [styles['chip-main-button']];
  const countString: string = props.secondaryLabel <= 999 ? props.secondaryLabel.toString() : '999+';
  const focused: boolean = focus || (props.focusedId && props.focusedId === props.innerId);

  focused && chipClasses.push(styles['focused']);
  focused && (attributes['data-focus'] = true);
  props.disabled && chipClasses.push(styles['disabled']);
  props.disabled && buttonClasses.push(styles['disabled']);
  selected ? chipClasses.push(styles['chip-selected']) : chipClasses.push(styles['chip-unselected']);

  return (
    <div {...attributes} className={chipClasses.join(' ')}>
      <Inline>
        <button
          id={props.innerId}
          data-id={props.innerId}
          data-hasfocus={focused}
          className={buttonClasses.join(' ')}
          disabled={props.disabled}
          tabIndex={props.disableTabStop && -1}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          onClick={props.onClickChip}
          {...props.aria}
          aria-label={getAriaLabel(props.aria, props.label)}
        >
          <Inline>
            <Label className={styles['chip-label']} type={'x-small'} text={props.label} />
            {(!!props.secondaryLabel || props.secondaryLabel === 0) && (
              <Label className={styles['chip-count']} type={'x-small'} text={countString} />
            )}
          </Inline>
        </button>
        {props.children}
      </Inline>
    </div>
  );
};

interface ChipCancelProps {
  cancelId?: string;
  cancelOnClick?: () => void;
  aria?: AriaRoletypeInterface;
  disabled?: boolean;
  focusedId?: string;
  disableTabStop?: boolean;
}

const ChipCancel = (props: ChipCancelProps) => {
  const attributes: React.HTMLAttributes<HTMLElement> = {};
  const [hoverCancel, setHoverCancel] = useState(false);
  const [focus, setFocus] = useState(false);
  const classes = [styles['chip-button']];

  const focused = focus || (props.focusedId && props.cancelId === props.focusedId);
  props.disabled && classes.push(styles['disabled']);
  focused && (attributes['data-focus'] = true);

  return (
    <button
      id={props.cancelId}
      onMouseLeave={() => setHoverCancel(false)}
      onMouseOver={() => setHoverCancel(true)}
      data-hasfocus={focused}
      data-id={props.cancelId}
      tabIndex={props.disableTabStop && -1}
      disabled={props.disabled}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
      className={classes.join(' ')}
      onClick={props.cancelOnClick}
      {...props.aria}
      aria-label={getAriaLabel(props.aria, 'cancel')}
    >
      <div {...attributes} className={focused ? styles['focused-cancel'] : styles['cancel']}>
        <Icon fill={props.disabled ? 'interaction-disabled' : hoverCancel ? 'default' : 'interaction'} name={'delete'} size={12} />
      </div>
    </button>
  );
};

function getAriaLabel(aria: AriaRoletypeInterface | undefined, fallback: string): string {
  const ariaLabel = aria ? aria['aria-label'] : undefined;
  return ariaLabel ?? fallback;
}

Chip['displayName'] = componentName;
