import * as React from 'react';
import { Stack } from '../stack/stack';
export { AreaBody } from './area-body';
export { AreaFooter } from './area-footer';
export { AreaHeader } from './area-header';
import { getDataAttributes } from '../../util/attribute-util';

export interface AreaProps {
  children: React.ReactElement | React.ReactElement[];
}

const componentName = 'Area';

export const Area = ({ children, ...props }: AreaProps) => {
  const header = findChild(children, 'AreaHeader');
  const body = findChild(children, 'AreaBody');
  const footer = findChild(children, 'AreaFooter');

  return (
    <Stack spacing={'8dp'} data-component={componentName} {...getDataAttributes(props)}>
      {header}
      {body}
      {footer}
    </Stack>
  );
};

function findChild(children: React.ReactNode, displayName: string): React.ReactNode {
  return React.Children.toArray(children).find((x) => ((x as any)?.type?.displayName ?? '') === displayName);
}

Area.displayName = componentName;
