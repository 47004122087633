import * as aria from './aria-attributes';
import { AriaGridEventHandler } from './aria-grid/aria-grid-event-handler';
import { delegateKeyboardEvent, getFirstParentWithAttribute, getNext, getPrevious } from './accessibility-utils';
import { KeyCodes } from '../client-shared';

export class AccessibilityEventHandlers {
  public static grid = AriaGridEventHandler;

  public static treeSingleSelectFactory(
    selectionHandler: (element: HTMLElement, select: boolean) => any,
    expansionHandler: (element: HTMLElement, expand: boolean) => any,
    focusHandler: (element: HTMLElement) => any,
    useComboboxPopupNavigation: boolean
  ): EventListener {
    return (evt: WindowEventMap['keydown']) => {
      try {
        const container = evt.currentTarget as HTMLElement;

        const multiAttr = container.getAttribute(aria.ARIA_MULTISELECTABLE);

        const isMultiSelect = !!multiAttr && multiAttr.toLowerCase() === 'true';
        let activeDescendantId = null;

        /*
                            The Tree navigation relies on the aria-activedescendant paradigm,
                            so we need to dig out the active element via the aria-activedescendant attribute on the container, or some parent (if we are in a combobox).
                         */
        if (!useComboboxPopupNavigation) {
          activeDescendantId = container.getAttribute(aria.ARIA_ACTIVEDESCENDANT);
        } else {
          //if the container is owned, then use the owner, else traverse the DOM
          let comboboxContainer = document.body.querySelector(`[${aria.ROLE}=${aria.COMBOBOX}][${aria.ARIA_OWNS}~=${container.id}]`);

          if (!comboboxContainer) {
            comboboxContainer = getFirstParentWithAttribute(
              container,
              aria.ARIA_ACTIVEDESCENDANT,
              (el) => el.hasAttribute(aria.ROLE) && el.getAttribute(aria.ROLE) !== aria.COMBOBOX
            );
          }

          activeDescendantId = comboboxContainer.getAttribute(aria.ARIA_ACTIVEDESCENDANT);
        }
        if (!activeDescendantId) {
          if (useComboboxPopupNavigation) {
            console.error(
              'Tree event handler',
              `Cannot set Activedescendant on combobox tree.
                    Could not locate any element with the selector [${aria.ROLE}=${aria.COMBOBOX}][${aria.ARIA_OWNS}~=${container.id}]
                    nor any parent element of `,
              container
            );
          } else {
            console.error('Tree event handler', 'No activedescendant attribute on container', container);
          }
          return;
        }

        const elem: HTMLElement = container.querySelector(`#${activeDescendantId}`);

        const parentTreeItem: HTMLElement = getFirstParentWithAttribute(elem, aria.ROLE, (el) => el === container, aria.TREEITEM);

        const hasParentTreeItem = !!parentTreeItem;

        const childGroup = elem.querySelector(`*[ROLE="${aria.GROUP}"]`);
        const isExpandable = elem.hasAttribute(aria.ARIA_EXPANDED);
        const isExpanded = elem.getAttribute(aria.ARIA_EXPANDED) === 'true';
        const isSelected = elem.getAttribute(aria.ARIA_SELECTED) === 'true';

        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_SPACE: {
            if (!isMultiSelect) {
              //space not part of the spec for single select.
              break;
            }
            //Select if we are using multiSelect
            selectionHandler && selectionHandler(elem, !isSelected);

            evt.preventDefault();
            evt.stopPropagation();
            break;
          }

          case KeyCodes.DOM_VK_RETURN:
          case KeyCodes.DOM_VK_ENTER: {
            /*
                                        activates a node, i.e., performs its default action.
                                        For parent nodes, one possible default action is to open or close the node.
                                        In single-select trees where selection does not follow focus (see note below), the default action is typically to select the focused node.
                                    */
            selectionHandler && selectionHandler(elem, !isSelected);

            evt.preventDefault();
            evt.stopPropagation();
            break;
          }

          case KeyCodes.DOM_VK_LEFT: {
            evt.preventDefault();
            /*
                                        When focus is on an open node, closes the node.
                                        When focus is on a child node that is also either an end node or a closed node, moves focus to its parent node.
                                        When focus is on a root node that is also either an end node or a closed node, does nothing.
                                    */

            if (!!childGroup && isExpanded) {
              expansionHandler && expansionHandler(elem, false);
            } else if (hasParentTreeItem) {
              focusHandler && focusHandler(parentTreeItem);
              if (useComboboxPopupNavigation) {
                selectionHandler && selectionHandler(parentTreeItem, !isSelected);
              }
            } else {
              //do nothing
            }
            break;
          }

          case KeyCodes.DOM_VK_RIGHT: {
            evt.preventDefault();
            /*
                                        When focus is on a closed node, opens the node; focus does not move.
                                        When focus is on a open node, moves focus to the first child node.
                                        When focus is on an end node, does nothing.
                                    */

            if (!childGroup && isExpandable && !isExpanded) {
              expansionHandler && expansionHandler(elem, true);
            } else if (childGroup) {
              let newSelection = childGroup.querySelector(`*[role="${aria.TREEITEM}"]`) as HTMLElement;

              if (!newSelection) {
                console.error(
                  'Tree event handler',
                  'Cannot navigate into child group. No element found within ',
                  childGroup,
                  ` with [role="${aria.TREEITEM}"]`
                );
                return;
              }

              if (newSelection.getAttribute('aria-disabled') === 'true') {
                newSelection = getNext(newSelection, (el) => [aria.TREE].some((r) => r === el.getAttribute(aria.ROLE)));
              }

              focusHandler && focusHandler(newSelection);

              /*if Combobox then select on navigation*/
              useComboboxPopupNavigation && selectionHandler(newSelection, true);
            } else {
              //do nothing
            }

            break;
          }

          case KeyCodes.DOM_VK_UP: {
            evt.preventDefault();
            const prev = getPrevious(elem, (el) => [aria.TREE].some((r) => r === el.getAttribute(aria.ROLE)));

            if (prev) {
              focusHandler && focusHandler(prev);
              if (useComboboxPopupNavigation) {
                selectionHandler && selectionHandler(prev, true);
              }
            } else if (useComboboxPopupNavigation) {
              const items = container.querySelectorAll(`*[role=${aria.TREEITEM}]`);
              const last = items.item(items.length - 1) as HTMLElement;
              focusHandler && focusHandler(last);
              selectionHandler && selectionHandler(last, true);
            }
            break;
          }

          case KeyCodes.DOM_VK_DOWN: {
            evt.preventDefault();
            //Moves focus to the next node that is focusable without opening or closing a node.
            if (!!childGroup && isExpanded) {
              // go through children
              let next = childGroup.querySelector(`*[role="${aria.TREEITEM}"]`) as HTMLElement;

              if (!next) {
                console.error(
                  'Tree event handler',
                  'Cannot navigate into child group. No element found within ',
                  childGroup,
                  ` with [role="${aria.TREEITEM}"]`
                );
                return;
              }

              if (next.getAttribute('aria-disabled') === 'true') {
                next = getNext(next, (el) => [aria.TREE].some((r) => r === el.getAttribute(aria.ROLE)));
              }

              focusHandler && focusHandler(next);

              if (useComboboxPopupNavigation) {
                selectionHandler && selectionHandler(next, true);
              }
            } else {
              //go through siblings
              const sib = getNext(elem, (el) => [aria.TREE].some((r) => r === el.getAttribute(aria.ROLE)));

              if (sib) {
                focusHandler && focusHandler(sib);
                if (useComboboxPopupNavigation) {
                  selectionHandler && selectionHandler(sib, true);
                }
              } else {
                if (useComboboxPopupNavigation) {
                  const first = container.querySelector(`*[role="${aria.TREEITEM}"]`) as HTMLElement;

                  if (!first) {
                    console.error('Tree event handler', 'Cannot Locate any elements within the container', container);
                    return;
                  }

                  focusHandler && focusHandler(first);
                  selectionHandler && selectionHandler(first, true);
                }
              }
            }

            break;
          }

          case KeyCodes.DOM_VK_HOME: {
            //Moves focus to the first node in the tree without opening or closing a node.
            break;
          }

          case KeyCodes.DOM_VK_END: {
            //Moves focus to the last node in the tree that is focusable without opening a node.
            break;
          }

          /*
                              todo:
                              Type-ahead is recommended for all trees, especially for trees with more than 7 root nodes:
                              Type a character: focus moves to the next node with a name that starts with the typed character.
                              Type multiple characters in rapid succession: focus moves to the next node with a name that starts with the string of characters typed.
                              * (Optional): Expands all siblings that are at the same level as the current node.
                              */
        }
      } catch (e) {
        console.error('Tree event handler', e);
      }
    };
  }

  public static comboboxFactory(popupOpener: (isOpen: boolean) => any, selectCurrentValueHandler: () => any): EventListener {
    return (evt: WindowEventMap['keydown']): void => {
      try {
        const container = evt.currentTarget as HTMLElement;
        if (container.getAttribute(aria.ROLE) !== aria.COMBOBOX) {
          console.error('combobox event handler', 'Combobox event handler not registered to Combobox element', container);
        }

        const isPopupOpen = container.getAttribute(aria.ARIA_EXPANDED) === 'true';
        const popupRole = container.getAttribute(aria.ARIA_HASPOPUP);

        //the popup may either be a child or an owned element
        const childPopup = container.querySelector(`*[role="${popupRole}"]`) as HTMLElement;
        const popupId = container.getAttribute(aria.ARIA_OWNS);
        const popup = childPopup || (document.body.querySelector(`#${popupId}`) as HTMLElement);

        if (isPopupOpen && !popup) {
          console.error('Combobox event handler', `Combobox has property ${aria.ARIA_EXPANDED}, but popup cannot be located.`);

          console.error('Combobox event handler', `Cannot locate popup via *[role="${popupRole}"]`);
          console.error('Combobox event handler', `Cannot locate popup via #${popupId}`);
          return;
        }

        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_ESCAPE: {
            /*
                                        Dismisses the popup if it is visible. Optionally, clears the textbox.
                                    */
            if (isPopupOpen) {
              popupOpener(false);
            }
            break;
          }

          case KeyCodes.DOM_VK_RETURN:
          case KeyCodes.DOM_VK_ENTER: {
            /*
                                        If an autocomplete suggestion is automatically selected, accepts the suggestion either by placing the input cursor at the end of the accepted value in the textbox
                                        or by performing a default action on the value. For example, in a messaging application, the default action may be to add the accepted value to a list of message
                                        recipients and then clear the textbox so the user can add another recipient.
                                      * */
            selectCurrentValueHandler();
            // if(this.isOpen && this.items && this.items.length === 1) {
            //     // enter selects if only one item is shown
            //     this.handleItemSelected(this.items[0]);
            // } else {
            //     this.isOpen = !this.isOpen;
            //     this.focusInput();
            // }
            break;
          }

          case KeyCodes.DOM_VK_DOWN: {
            /*
                                        If the popup is available, moves focus into the popup:
                                        If the autocomplete behavior automatically selected a suggestion before Down Arrow was pressed, focus is placed on the suggestion following the automatically selected suggestion.
                                        Otherwise, places focus on the first focusable element in the popup.
                                    */
            if (!isPopupOpen) {
              popupOpener(true);
            } else {
              //propagate the event to the popup
              delegateKeyboardEvent(popup, evt);
            }
            break;
          }

          case KeyCodes.DOM_VK_UP: {
            /*
                                        If the popup is available, places focus on the last focusable element in the popup.
                                    * */
            if (isPopupOpen) {
              //propagate the event to the popup
              delegateKeyboardEvent(popup, evt);
            }
            break;
          }

          case KeyCodes.DOM_VK_TAB:
            break;
          case KeyCodes.DOM_VK_HOME:
          case KeyCodes.DOM_VK_END:
          case KeyCodes.DOM_VK_RIGHT:
          case KeyCodes.DOM_VK_LEFT: {
            if (isPopupOpen) {
              delegateKeyboardEvent(popup, evt);
            }
          }
        }

        /*
                            todo:
                            Alt + Down Arrow (Optional): If the popup is available but not displayed, displays the popup without moving focus.
                            Alt + Up Arrow (Optional): If the popup is displayed:
                            If the popup contains focus, returns focus to the textbox.
                            Closes the popup
                        * */
      } catch (e) {
        console.error('combobox event handler', e);
      }
    };
  }

  public static tablistFactory(
    selectionHandler: (element: HTMLElement) => any,
    focusHandler: (element: HTMLElement) => any,
    contextMenuHandler: (element: HTMLElement) => any
  ): EventListener {
    return (evt: WindowEventMap['keydown']): void => {
      try {
        const container = evt.currentTarget as HTMLElement;

        const activeElementId = container.getAttribute(aria.ARIA_ACTIVEDESCENDANT);

        if (activeElementId === null) {
          console.error('Tab list event handler', 'Cannot find activedescendant attribute');
          return;
        }

        const activeTab = container.querySelector(`#${activeElementId}`) as HTMLElement;

        if (activeTab === null) {
          console.error('Tab list event handler', `Cannot find the activedescendant with the id #${activeElementId}`);
          return;
        }

        const previousTab = getPrevious(activeTab as HTMLElement, (elem) => elem.getAttribute(aria.ROLE) === aria.TABLIST);

        const nextTab = getNext(activeTab as HTMLElement, (elem) => elem.getAttribute(aria.ROLE) === aria.TABLIST);

        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_LEFT: {
            /*
             * Left Arrow: moves focus to the previous tab. If focus is on the first tab, moves focus to the last tab. Optionally, activates the newly focused tab (See note below).
             * */

            if (!previousTab) {
              return;
            }
            focusHandler && focusHandler(previousTab);
            break;
          }
          case KeyCodes.DOM_VK_RIGHT: {
            /*
             * Right Arrow: Moves focus to the next tab. If focus is on the last tab element, moves focus to the first tab. Optionally, activates the newly focused tab (See note below).
             * */
            if (!nextTab) {
              return;
            }
            focusHandler && focusHandler(nextTab);

            break;
          }
          case KeyCodes.DOM_VK_SPACE:
          case KeyCodes.DOM_VK_RETURN:
          case KeyCodes.DOM_VK_ENTER: {
            /*
             * Space or Enter: Activates the tab if it was not activated automatically on focus.
             * */
            selectionHandler && selectionHandler(activeTab);
            evt.preventDefault();
            break;
          }
          case KeyCodes.DOM_VK_F10:
            if (evt.shiftKey) {
              contextMenuHandler && contextMenuHandler(activeTab);
              evt.preventDefault();
            }
            break;
        }
      } catch (e) {
        console.error('Tab list event listener', e);
      }
    };
  }

  public static linkEventFactory(clickHandler: () => any): EventListener {
    return (evt: WindowEventMap['keydown']): void => {
      try {
        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_RETURN:
          case KeyCodes.DOM_VK_ENTER: {
            clickHandler && clickHandler();
          }
        }
      } catch (e) {
        console.error('Link event handler', e);
      }
    };
  }

  public static dialogFactory(closeHandler: () => any): EventListener {
    return (evt: WindowEventMap['keydown']): void => {
      try {
        const focusElement = evt.target as Element;
        const dialog = evt.currentTarget as Element;

        if (dialog.getAttribute(aria.ROLE) !== aria.DIALOG) {
          console.error('Dialog event handler', `Event handler not registered to element with ${aria.DIALOG} role`);
        }

        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_ESCAPE: {
            closeHandler && closeHandler();
            break;
          }
          case KeyCodes.DOM_VK_TAB: {
            if (dialog === focusElement) {
              console.error('Dialog event handler', 'Dialog is focusable. This should not be possible.');
              return;
            }

            const focusableElements = dialog.querySelectorAll(
              "a:not([tabindex='-1']), input:not([tabindex='-1']), *[tabindex='0'], button:not([tabindex='-1']), select:not([tabindex='-1']), textarea:not([tabindex='-1'])"
            );

            if (focusableElements.length === 0) {
              //no element in focus. How did we get a keyboard event?
              console.error('could not find any focusable element within the dialog', dialog);
              return;
            } else if (!evt.shiftKey && focusableElements[focusableElements.length - 1] === focusElement) {
              //if last element is in focus
              (focusableElements[0] as HTMLElement).focus();
              evt.preventDefault();
            } else if (evt.shiftKey && focusableElements[0] === focusElement) {
              //if first element is in focus and shift is pressed
              (focusableElements[focusableElements.length - 1] as HTMLElement).focus();
              evt.preventDefault();
            }
            break;
          }
        }
      } catch (e) {
        console.error('Dialog event handler', e);
      }
    };
  }

  public static MenuFactory(
    selectionHandler: (element: HTMLElement) => any,
    focusHandler: (element: HTMLElement) => any,
    closeHandler: () => any,
    searchSelectionHandler: (prefix: string) => any
  ): EventListener {
    return (evt: WindowEventMap['keydown']): void => {
      try {
        evt.stopImmediatePropagation();
        const container = evt.currentTarget as HTMLElement;
        let activeDescendantId = null;

        if (container.hasAttribute(aria.ARIA_ACTIVEDESCENDANT)) {
          activeDescendantId = container.getAttribute(aria.ARIA_ACTIVEDESCENDANT);
        } else {
          const actualContainer = getFirstParentWithAttribute(
            container,
            aria.ARIA_ACTIVEDESCENDANT,
            (el) => el.hasAttribute(aria.ROLE) && el.getAttribute(aria.ROLE) !== aria.MENU
          );
          if (!actualContainer) {
            console.error(
              'Menu event listener',
              'No activedescendant on the container ',
              container,
              ' nor is there any parent with the activedescendant attribute.'
            );
            return;
          }
          activeDescendantId = actualContainer.getAttribute(aria.ARIA_ACTIVEDESCENDANT);
        }

        const elem: HTMLElement = container.querySelector(`#${activeDescendantId}`);

        if (!elem) {
          console.error(
            'menu event listener',
            `Could not locate element with id ${activeDescendantId} specified by the activedescendant attribute`
          );
          return;
        }

        const next: HTMLElement = getNext(elem, (el) => el.getAttribute(aria.ROLE) === aria.MENU);
        const previous: HTMLElement = getPrevious(elem, (el) => el.getAttribute(aria.ROLE) === aria.MENU);

        switch (evt.keyCode) {
          case KeyCodes.DOM_VK_UP: {
            /*
                                    When focus is in a menu, moves focus to the previous item, optionally wrapping from the first to the last.
                                    (Optional): When focus is on a menuitem in a menubar, opens its submenu and places focus on the last item in the submenu.
                                    * */

            if (previous) {
              focusHandler && focusHandler(previous);
            } else {
              //wrap around
              const items = container.querySelectorAll(`[role="${aria.MENUITEM}"]:not([aria-disabled=true])`);

              if (items.length === 0) {
                console.error('Menu event handler', `Could not locate any items with [role="${aria.MENUITEM}"] `);
                return;
              }

              focusHandler && focusHandler(items[items.length - 1] as HTMLElement);
            }
            break;
          }
          case KeyCodes.DOM_VK_DOWN: {
            /*
                                    When focus is on a menuitem in a menubar, opens its submenu and places focus on the first item in the submenu.
                                    When focus is in a menu, moves focus to the next item, optionally wrapping from the last to the first.
                                    * */

            if (next) {
              focusHandler && focusHandler(next);
            } else {
              //wrap around
              const first = container.querySelector(`[role="${aria.MENUITEM}"]:not([aria-disabled=true])`);
              if (!first) {
                console.error('Menu event handler', `Could not locate any items with [role="${aria.MENUITEM}"] `);
                return;
              }
              focusHandler && focusHandler(first as HTMLElement);
            }

            break;
          }
          case KeyCodes.DOM_VK_LEFT: {
            /*
                                    When focus is in a menubar, moves focus to the previous item, optionally wrapping from the first to the last.
                                    When focus is in a submenu of an item in a menu, closes the submenu and returns focus to the parent menuitem.
                                    When focus is in a submenu of an item in a menubar, performs the following 3 actions:
                                    Closes the submenu.
                                    Moves focus to the previous menuitem in the menubar.
                                    Either: (Recommended) opens the submenu of that menuitem without moving focus into the submenu, or opens the submenu of that
                                            menuitem and places focus on the first item in the submenu.
                                    * */

            break;
          }
          case KeyCodes.DOM_VK_RIGHT: {
            /*
                                    When focus is in a menubar, moves focus to the next item, optionally wrapping from the last to the first.
                                    When focus is in a menu and on a menuitem that has a submenu, opens the submenu and places focus on its first item.
                                    When focus is in a menu and on an item that does not have a submenu, performs the following 3 actions:
                                    Closes the submenu and any parent menus.
                                    Moves focus to the next menuitem in the menubar.
                                    Either: (Recommended) opens the submenu of that menuitem without moving focus into the submenu, or
                                            opens the submenu of that menuitem and places focus on the first item in the submenu.
                                    Note that if the menubar were not present, e.g., the menus were opened from a menubutton, Right Arrow would not do anything when focus is on an item that does not have a submenu.
                                    * */

            break;
          }
          case KeyCodes.DOM_VK_RETURN:
          case KeyCodes.DOM_VK_ENTER: {
            /*
                                    When focus is on a menuitem that has a submenu, opens the submenu and places focus on its first item.
                                    Otherwise, activates the item and closes the menu.
                                    * */

            selectionHandler && selectionHandler(elem);

            break;
          }
          case KeyCodes.DOM_VK_SPACE: {
            /*
                                    (Optional): When focus is on a menuitemcheckbox, changes the state without closing the menu.
                                    (Optional): When focus is on a menuitemradio that is not checked, without closing the menu, checks the focused menuitemradio and unchecks any other checked menuitemradio element in the same group.
                                    (Optional): When focus is on a menuitem that has a submenu, opens the submenu and places focus on its first item.
                                    (Optional): When focus is on a menuitem that does not have a submenu, activates the menuitem and closes the menu.
                                    * */

            selectionHandler && selectionHandler(elem);

            break;
          }
          case KeyCodes.DOM_VK_HOME: {
            /* If arrow key wrapping is not supported, moves focus to the first item in the current menu or menubar. */
            break;
          }
          case KeyCodes.DOM_VK_END: {
            /* If arrow key wrapping is not supported, moves focus to the last item in the current menu or menubar. */
            break;
          }
          case KeyCodes.DOM_VK_ESCAPE: {
            /* Close the menu that contains focus and return focus to the element or context, e.g., menu button or parent menuitem, from which the menu was opened. */
            closeHandler && closeHandler();
            break;
          }
          case KeyCodes.DOM_VK_TAB: {
            /*Moves focus to the next element in the tab sequence, and if the item that had focus is not in a menubar, closes its menu and all open parent menu containers.*/

            /* Shift + Tab: Moves focus to the previous element in the tab sequence, and if the item that had focus is not in a menubar, closes its menu and all open parent menu containers. */
            closeHandler && closeHandler();
            break;
          }
          default: {
            /* Any key that corresponds to a printable character (Optional): Move focus to the next menu item in the current menu whose label begins with that printable character. */
            searchSelectionHandler && searchSelectionHandler(evt.key);
            break;
          }
        }

        evt.preventDefault();
      } catch (e) {
        console.error('Menu event handler', e);
      }
    };
  }
}
