// A radix-ui implementation of the Tooltip component
import * as React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import styles from './tool-tip.module.scss';
import { Arrow } from './tool-tip-arrow';
import { Text } from '../text/text';
import { getAllButDataAttributes, getDataAttributes } from '../../util/attribute-util';
const componentName = 'ToolTip';

export interface ToolTipProps {
  /**
   * A unique id for the tooltip.
   */
  id?: string;

  /**
   * The tooltip content to display.
   */
  content: string;

  /**
   * Optional; to render the tooltip as a div or a span. div is default and will render as a block element. span will render as an inline element.
   */
  as?: 'span' | 'div';

  /**.
   * When true, the tooltip will be shown immediately.
   */
  forceShow?: boolean;

  /**
   * Whether or not to wrap or truncate text when out of space
   */
  wrapText?: boolean;

  /** Target element */
  children?: React.ReactNode;
}

export function ToolTip({ children, content, forceShow = undefined, as = 'div', wrapText, ...props }: ToolTipProps) {
  // Wrap the trigger in the selected element
  const trigger = React.createElement(as, {}, children);
  const portalRootClass = getPaletteClassName({ name: getDefaultPalette().name });
  // When forceShow is set to false, the component will not render. Resetting to undefined.
  !forceShow && (forceShow = undefined);

  return (
    <TooltipPrimitive.Root open={forceShow} defaultOpen={false} delayDuration={100}>
      <TooltipPrimitive.Trigger asChild>{trigger}</TooltipPrimitive.Trigger>

      <TooltipPrimitive.Content
        className={[portalRootClass, styles.content].join(' ')}
        side="bottom"
        align="start"
        sideOffset={10}
        {...getAllButDataAttributes(props)}
      >
        <TooltipPrimitive.Arrow width={10} height={10} asChild className={styles.arrow}>
          <Arrow />
        </TooltipPrimitive.Arrow>

        <div className="inner" data-component={componentName} {...getDataAttributes(props)}>
          <div
            style={{
              overflow: wrapText ? 'visible' : 'hidden',
              textOverflow: wrapText ? 'auto' : 'ellipsis',
              overflowWrap: 'break-word',
              whiteSpace: wrapText ? 'pre-wrap' : 'pre',
              lineHeight: wrapText ? 'initial' : 1.6, // Hanging chars get clipped, might be an issue with Text
              minHeight: '10px', // To make sure that the text does not get cut off in the tooltip
              paddingTop: '2px', // To make sure that the text does not get cut off in the tooltip
              paddingBottom: '2px', // To make sure that the text does not get cut off in the tooltip
            }}
          >
            <Text size="font12">{content}</Text>
          </div>
        </div>
      </TooltipPrimitive.Content>
    </TooltipPrimitive.Root>
  );
}
ToolTip.displayName = componentName;
