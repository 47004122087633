import * as React from 'react';
import { ContentListBroadFilter } from './content-list-broad-filter';
import { ContentListNarrowFilter } from './content-list-narrow-filter';
import { AriaButtonInterface, AriaTextboxInterface } from '../AccessibilityProps';
import { SelectItem } from '../../models/select-item';
import { SearchFieldProperties } from '../search-field/search-field';
import { getDataAttributes } from '../../util/attribute-util';

export interface ContentListFilterProps extends SearchFieldProperties {
  /*
      Show or hide the search bar defaults to true.
       */
  displaySearchBar?: boolean;
  saveFilterButtonAria?: AriaButtonInterface;
  saveFilterInputAria?: AriaTextboxInterface;
  cancelButtonAria?: AriaButtonInterface;
  chipAria?: AriaButtonInterface;
  searchAria?: AriaTextboxInterface;
  option: 'narrow' | 'broad';
  /*
          Filter list options are created by using these objects. Make sure to use unique values for selectItem.value number or string as they are used for identifying.
          Children is A React.ReactNode and the provided children will be rendered upon selection of that item.
          Add selected values as false, if you want a selected one from the beginning use presetFilters props with the selected value on item you want.
       */
  contentListFilterItems: ContentListFilterItem[];
  /*
          Width of the search field when using broad option
       */
  searchWidth?: string;
  /*
          Will receive the used chips list everytime it has any changes(selecting, adding/removing new chips)
       */
  onFiltersChanged?: (chips: ContentListFilterItem[]) => void;
  /*
          Receive data when save filter input is updated.
       */
  onFiltersSave?: (value: FilterSave) => void;
  popUpLabel: string;
  saveButtonLabel?: string;
  /*
          List of ContentListFilterItems that would already be displayed as added/selected filters. Use only single selectedItem.selected = true as it uses the first true found.
       */
  presetFilters?: ContentListFilterItem[];
  filterInputPlaceholder?: string;
}

export interface ContentListFilterItem {
  children: React.ReactNode;
  SelectItem: SelectItem;
  count?: number;
  selected: boolean;
  /*
      Disabled state for items, disabled items don't show up for the list
       */
  disabled?: boolean;
}

interface FilterSave {
  filterName: string;
  action: 'Saved' | 'Aborted';
}

const componentName = 'ContentListFilter';

export const ContentListFilter = (props: ContentListFilterProps, {}) => {
  return props.option === 'narrow' ? (
    <ContentListNarrowFilter data-component={componentName} {...props} />
  ) : (
    <ContentListBroadFilter data-component={componentName} {...props} />
  );
};

ContentListFilter['displayName'] = componentName;
