import * as React from 'react';
import styles from './loading-skeleton-bar.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { S_M_L_Size } from '../t-shirt-sizes';

export interface LoadingSkeletonBarProps {
  /**
   * Controls if the component is going to infinitely transition between a dark
   * and a light color;
   */
  isAnimating?: boolean;
  /**
   * The width percentage of the container that the SkeletonBar is going to occupy;
   */
  width?: number;
  /**
   * The color that we are going to use as default if we are not animating;
   */
  shade?: 'light' | 'dark';
  /**
   * The vertical size of the container; It automatically adjust the spacing;
   */
  size?: S_M_L_Size;
}

const componentName = 'LoadingSkeletonBar';

export const LoadingSkeletonBar: React.FC<LoadingSkeletonBarProps> = (props) => {
  const style = {
    width: `${props.width}%`,
  };

  const classNames = [styles['component'], styles[props.size]];

  props.isAnimating && classNames.push(styles['animate']);
  props.shade && classNames.push(styles[props.shade]);

  return (
    <span className={classNames.join(' ')} style={style} data-component={componentName} {...getDataAttributes(props)}>
      &zwnj;
    </span>
  );
};

LoadingSkeletonBar.defaultProps = {
  isAnimating: true,
  shade: 'light',
  width: 100,
  size: 'small',
};

LoadingSkeletonBar['displayName'] = componentName;
