import * as React from 'react';
import { ContextType } from 'react';
import styles from './toggle.module.scss';
import { Switch, SwitchProps } from './switch';
import { AriaCheckboxInterface } from '../AccessibilityProps';
import StylingContext from '../../style-context/styling-context-provider';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'Toggle';

export interface ToggleProps extends SwitchProps {
  label: string;
  aria?: AriaCheckboxInterface;
}

export class Toggle extends React.PureComponent<ToggleProps> {
  static defaultProps: ToggleProps = {
    label: null,
    size: 'small',
  };
  context: ContextType<typeof StylingContext>;
  private switchId = LangUtil.randomUuid();

  render() {
    const { styling } = this.context;
    let size = this.props.size;

    //Because classic does not have a medium size, we manual set it to large
    if (styling === 'classic' && this.props.size === 'medium') {
      size = 'large';
    }

    const classNames = [styles['component'], styles[size], styles[styling]];

    this.props.isDisabled && classNames.push(styles['disabled']);

    return (
      <div data-component={componentName} {...getDataAttributes(this.props)} className={classNames.join(' ')}>
        <Switch
          id={this.switchId}
          size={size}
          aria={this.props.aria}
          isDisabled={this.props.isDisabled}
          isSelected={this.props.isSelected}
          onSelectedChanged={this.props.onSelectedChanged}
        />
        <label htmlFor={this.switchId} className={styles['label']}>
          {this.props.label}
        </label>
      </div>
    );
  }
}

Toggle['displayName'] = componentName;
Toggle.contextType = StylingContext;
