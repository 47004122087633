import * as React from 'react';
import { Transition } from '../transition/transition';
import { Icon } from '../icon/icon';
import styles from './accordion.module.scss';
import { AriaTabInterface, AriaTreeItemInterface } from '../AccessibilityProps';
import { useStylingContext } from '../../style-context/styling-context-provider';
import { IFocusable } from '../IFocusable';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'Accordion';

export type ToggleButtonPosition = 'toggleButtonPositionCenter' | 'toggleButtonPositionTop';

export interface AccordionProps {
  /**
   * Optional id of this accordion which identifies it when the onToggle callback is invoked
   */
  id?: string;

  /**
   * Whether the accordion is currently open
   */
  isOpen: boolean;

  /**
   * The heading of the accordion, expressed as a string or a custom element element such as
   */
  heading: string | React.ReactElement<any>;

  /**
   * Whether clicking on the header should toggle the accordion.
   * Defaults to true.
   */
  toggleOnHeadingClick?: boolean;

  /**
   * Callback when the user toggles the accordion
   * @param id the id of this accordion if passed in props.id
   * @param isOpen whether the user has requested to open or close the accordion
   */
  onToggle: (id: string, isOpen: boolean) => void;

  /**
   * Whether to animate. Defaults to true;
   */
  animate?: boolean;

  /**
   *  Show the focus state for the arrow. Only use this if you are handeling focus elsewhere, such as aria-activeDescendant
   */
  hasFocus?: boolean;

  className?: string;

  role?: 'treeitem' | 'tab';

  aria?: AriaTreeItemInterface | AriaTabInterface;

  toggleButtonPosition?: ToggleButtonPosition;

  children?: React.ReactNode;
}

/**
 * An accordion that shows a collapsible and expandable child
 */
export const Accordion = React.forwardRef<IFocusable, AccordionProps>(
  (
    {
      id,
      isOpen = false,
      heading = null,
      toggleOnHeadingClick = true,
      onToggle = null,
      animate = true,
      hasFocus,
      className,
      role,
      aria,
      toggleButtonPosition = 'toggleButtonPositionCenter' as ToggleButtonPosition,
      children,
      ...props
    },
    focusableRef
  ) => {
    const { styling } = useStylingContext();
    const focusElementRef = React.useRef<HTMLElement>();

    React.useImperativeHandle(focusableRef, () => ({
      focus: () => focusElementRef.current.focus(),
      element: focusElementRef.current,
    }));

    const fallbackId = React.useMemo(() => LangUtil.randomUuid(), []);

    const classNames = [className, styles['component'], styles[styling], styles[isOpen ? 'open' : 'closed']].join(' ');

    const treeItemAria: AriaTreeItemInterface = {
      'aria-expanded': isOpen ? 'true' : 'false',
      'aria-selected': 'false',
    };

    const tabAria: AriaTabInterface = {
      /*'aria-controls':{this.getId('content')}*/
    };

    let finalAria = {};

    if (role === 'treeitem') {
      finalAria = treeItemAria;
    } else if (role === 'tab') {
      finalAria = tabAria;
    }
    finalAria = { ...finalAria, ...aria };

    // don't use buttons in trees. TreeItems should have no tab indices
    const ArrowWrapper: any = role === 'treeitem' ? 'div' : 'button';

    // in case the arrow is a button, we have to set the type to not be a 'submit' of the form
    const arrowProps = ArrowWrapper === 'button' ? { type: 'button' } : {};

    const buttonAriaLabel = aria ? aria['aria-label'] ?? 'toggle' : 'toggle';

    const headerStyles = [styles['header']];

    toggleButtonPosition && headerStyles.push(styles[toggleButtonPosition]);

    toggleOnHeadingClick && headerStyles.push(styles['header-hover']);

    const arrowClasses = [styles['toggle-button']];
    hasFocus && arrowClasses.push(styles['focus']);

    const renderContent = () => {
      if (isOpen) {
        const finalRole = role === 'tab' ? { role: 'tabpanel' } : {};

        return (
          <div id={getId('content')} {...finalRole}>
            {children}
          </div>
        );
      }

      return null;
    };

    const handleHeaderToggle = (e: React.SyntheticEvent<any>) => {
      if (toggleOnHeadingClick) {
        handleToggle(e);
      }
    };

    const handleToggle = (e: React.SyntheticEvent<any>) => {
      if (onToggle) {
        onToggle(id, !isOpen);
        // don't propagate to the header
        e.stopPropagation();
      }
    };

    const getId = (suffix?: string) => {
      const finalId = id ?? fallbackId;
      return finalId + (suffix ? '-' + suffix : '');
    };

    return (
      <div data-component={componentName} {...getDataAttributes(props)} id={getId()} className={classNames} role={role} {...finalAria}>
        <div className={styles['heading']}>
          <div className={headerStyles.join(' ')} onClick={handleHeaderToggle}>
            <ArrowWrapper
              aria-label={buttonAriaLabel}
              className={arrowClasses.join(' ')}
              onClick={handleToggle}
              ref={focusElementRef}
              {...arrowProps}
            >
              <Icon key="icon" name={isOpen ? 'navigate-down' : 'navigate-right'} />
            </ArrowWrapper>
            <span className={styles['header-text']}>{heading}</span>
          </div>
        </div>
        <div className={styles['content']}>
          <Transition transition="expand" animate={animate} markAsPresentation>
            {renderContent()}
          </Transition>
        </div>
      </div>
    );
  }
);

Accordion['displayName'] = componentName;
