import * as React from 'react';
import { ButtonBaseProps, ButtonIconProps, ButtonLabelProps, ButtonSizeProps } from '../button-base/button-interfaces';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import styles from './content-placeholder.module.scss';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';
import { Inline } from '../inline/inline';
import { getDataAttributes } from '../../util/attribute-util';

export interface ContentPlaceholderBaseProps extends ButtonBaseProps, ButtonLabelProps, ButtonSizeProps, ButtonIconProps {
  onClick: (e?: React.MouseEvent<any>) => void;
  stretch?: boolean;
  iconOnly?: boolean;
}

export type ContentPlaceholderProps = ContentPlaceholderBaseProps;

const componentName = 'ContentPlaceholder';

const Content = React.forwardRef<any, ContentPlaceholderProps & ButtonFocusHandlerChildProps>((props, ref) => {
  const classes = [styles['component']];
  props.size && classes.push(styles[props.size]);
  props.iconOnly && classes.push(styles['icon-only']);
  props.stretch && classes.push(styles['stretch']);
  const attributes: React.HTMLAttributes<HTMLElement> = { id: props.id };

  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const content: React.ReactNode = (
    <React.Fragment>
      <Inline horizontalAlignment={'center'}>
        <Icon size={16} name={props.icon} />
        <Text size={'font16'} maxLines={1}>
          {props.label}
        </Text>
      </Inline>
    </React.Fragment>
  );

  const buttonAttributes: React.HTMLAttributes<HTMLButtonElement> = { ...attributes };
  return (
    <button
      ref={ref}
      disabled={props.disabled}
      {...buttonAttributes}
      onClick={props.onClick}
      className={classes.join(' ')}
      title={props.label}
      {...props.aria}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      {content}
    </button>
  );
});

export class ContentPlaceholder extends React.Component<ContentPlaceholderProps, any> {
  static defaultProps: Partial<ContentPlaceholderProps> = {
    size: 'small',
    stretch: false,
    iconOnly: false,
  };

  render() {
    return (
      <ButtonFocusHandler
        initialFocus={this.props.hasFocus}
        render={({ ref, hasHover, hasFocus }) => <Content ref={ref} {...this.props} hasFocus={hasFocus} hasHover={hasHover} />}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

ContentPlaceholder['displayName'] = componentName;
