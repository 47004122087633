import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Transition } from '../transition/transition';
import styles from './error-bar.module.scss';
import { getPaletteClassName } from '../../util/theme-util';
import { IconButton } from '../icon-button/icon-button';
import { getDataAttributes } from '../../util/attribute-util';

export interface ErrorBarProps {
  /**
   * The error, if any, to render.
   */
  error: Error | null;

  /**
   * The id of DOM element that the React Portal is rendered into
   */
  domId: string;

  /**
   * Optional callback for when user clicks the "hide" button
   */
  onHide?: () => any;

  /**
   * Optional button that can retry the action that resulted in the error
   */
  retryButton?: React.ReactElement<any>;

  /** Screen reader friendly label for the show details button */
  showDetailsLabel: string;

  /** Screen reader friendly label for the hide button */
  hideLabel: string;
}

const componentName = 'ErrorBar';

/**
 * Renders a red error bar on top of the current view.
 */
export class ErrorBar extends React.Component<ErrorBarProps, { hidden: boolean; showDetails: boolean }> {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false,
      showDetails: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.hidden === false) {
      return;
    }
    // Resurface a dismissed error, if the error changes
    if (this.props.error !== prevProps.error) {
      this.setState({
        hidden: false,
      });
    }
  }

  renderBar() {
    if (this.state.hidden || !this.props.error) {
      return <Transition />;
    }
    const detailsIcon = this.state.showDetails ? 'navigate-up' : 'navigate-down';
    return (
      <Transition>
        <div className={styles['component'] + ' ' + getPaletteClassName({ name: 'palette-light' })}>
          <div className={styles['bar-items']}>
            <div className={styles['text']} title={this.props.error.message}>
              {this.props.error.message}
            </div>
            {this.props.retryButton}
            <IconButton
              type="button"
              aria={{ 'aria-label': this.props.showDetailsLabel }}
              icon={detailsIcon}
              onClick={this.toggleDetails}
            />
            <IconButton type="button" aria={{ 'aria-label': this.props.hideLabel }} icon="delete" onClick={this.hide} />
          </div>
          {this.renderDetails()}
        </div>
      </Transition>
    );
  }

  renderDetails() {
    if (this.state.showDetails) {
      return <div className={styles['details']}>{this.props.error.stack + ''}</div>;
    }
    return null;
  }

  toggleDetails = () => {
    this.setState((prev) => ({
      showDetails: !prev.showDetails,
    }));
  };

  hide = () => {
    this.setState({
      hidden: true,
      showDetails: false,
    });
    if (this.props.onHide) {
      this.props.onHide();
    }
  };

  render() {
    let element = document.getElementById(this.props.domId);

    if (!element) {
      element = document.createElement('div');
      element.setAttribute('id', this.props.domId);
      document.body.appendChild(element);
    }

    element.setAttribute('data-component', componentName);
    const additionalAttributes = { ...getDataAttributes(this.props) };
    Object.keys(additionalAttributes).map((attribute) => {
      element.setAttribute(attribute, additionalAttributes[attribute]);
    });

    return ReactDOM.createPortal(this.renderBar(), element);
  }
}

ErrorBar['displayName'] = componentName;
