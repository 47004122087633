import * as React from 'react';
import { Stack, StackChildren } from '../stack/stack';
import { getDataAttributes } from '../../util/attribute-util';

export type FormStackProps = StackChildren;

const componentName = 'FormStack';
export class FormStack extends React.Component<FormStackProps, any> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Stack spacing="16dp" data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </Stack>
    );
  }
}

FormStack['displayName'] = componentName;
