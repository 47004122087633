import * as React from 'react';
import styles from './field-set.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface FieldSetProps {
  title: string;
  disabled?: boolean;
  children?: React.ReactNode;
}

const componentName = 'FieldSet';

export class FieldSet extends React.Component<FieldSetProps, {}> {
  constructor(properties) {
    super(properties);
  }

  render() {
    let attr = {};
    if (this.props.disabled) {
      attr = { disabled: this.props.disabled };
    }

    const className = [styles['content']];
    this.props.disabled && className.push(styles['disabled']);

    return (
      <fieldset className={styles['component']} {...attr} data-component={componentName} {...getDataAttributes(this.props)}>
        <legend key="header" className={styles['legend']}>
          {this.props.title}
        </legend>
        <div className={className.join(' ')}>{this.props.children}</div>
      </fieldset>
    );
  }
}

FieldSet['displayName'] = componentName;
