import * as React from 'react';
import styles from './label.module.scss';
import { Icon, IconProps } from '../../components/icon/icon';
import { S_M_L_Size } from 'graphene-core/components/t-shirt-sizes';

const componentName = 'Label';

export interface LabelInternalProps {
  /**
   * Label to be displayed
   */
  text: string;
  /**
   * (Optional): Class name prefix for external styling.
   */
  className?: string;
  /**
   * (Optional): To display an icon to the left of the text
   */
  iconLeft?: Omit<IconProps, 'size'>;
  /**
   * (Optional): To display an icon to the right of the text
   */
  iconRight?: Omit<IconProps, 'size'>;
  /**
   * (Optional): To determine which fontFamily that should be used. If true, then use Gulliver (serif) font and false to use Nexus(sans-serif)
   * Defaults to false
   */
  serif?: boolean;
  /**
   * (Optional): If the label should be able to wrap to the next line or not. If false, the label will have an ellipsis at the end if the text is too long
   * Defaults to true
   */
  wrap?: boolean;

  /**
   * (Optional): An Id that can be used to tie the label and a form control togther. This is used for focus handling and accessibility considerations.
   */
  htmlFor?: string;

  size?: S_M_L_Size;
}

type LabelNativeProps = Omit<React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>, 'style' | 'children'>;

export interface LabelProps extends LabelInternalProps, LabelNativeProps {}

export const Label = (props: LabelProps): JSX.Element => {
  const { size = 'medium', text, className, iconLeft, iconRight, serif, wrap, ...nativeProps } = props;

  const theClassName = `${className} ${styles.component} ${styles[size]} ${serif ? styles.serif : styles.sans}`;

  return (
    <label htmlFor={props.htmlFor} data-component={componentName} {...nativeProps} className={theClassName}>
      {iconLeft ? (
        <div className={[styles['icon-left'], styles[size]].join(' ')}>
          <Icon {...iconLeft} />
        </div>
      ) : undefined}
      <span className={!wrap ? styles['ellipsis'] : undefined}>{text}</span>
      {iconRight ? (
        <div className={[styles['icon-right'], styles[size]].join(' ')}>
          <Icon {...iconRight} />
        </div>
      ) : undefined}
    </label>
  );
};

Label.defaultProps = {
  className: 'text',
  wrap: true,
};

Label.displayName = componentName;
