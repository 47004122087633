import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { S_M_L_Size } from '../../components/t-shirt-sizes';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import styles from './popup.module.scss';

const componentName = 'Popup';

/**
 * This Component 'pops up' it's children in a container.
 * The popup action will occur when a button click is performed on the trigger element.
 */

export interface PopupProps {
  /**
   * (Optional): The distance between the trigger and content area.
   */
  gapOffset?: number;
  /**
   * (Optional): Class name for external styling.
   */
  className?: string;

  children: React.ReactNode;
  /**
   * The HTML / Graphene trigger element for the Popup.
   */
  trigger: React.ReactNode;
  /**
   * (Optional): Trigger the popup manually
   */
  isOpen?: boolean;
  /**
   * (Optional): The preferred alignment of the content area when visible.
   */
  align?: 'start' | 'center' | 'end';
  /**
   * (Optional): The preferred side of the content area when visible.
   */
  side?: 'top' | 'right' | 'bottom' | 'left';

  onOpenChange?: (open: boolean) => void;

  size?: S_M_L_Size;
}

export const Popup = (props: PopupProps): JSX.Element => {
  const { gapOffset, children, className, trigger, isOpen, align, side } = props;

  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={props?.onOpenChange}>
      <DropdownMenu.Trigger asChild>{trigger}</DropdownMenu.Trigger>
      <DropdownMenu.Content
        side={side}
        align={align}
        className={[getPaletteClassName({ name: getDefaultPalette().name }), className, styles.component].join(', ')}
        sideOffset={gapOffset}
      >
        {children}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

Popup.defaultProps = {
  align: 'start',
  size: 'medium',
};

Popup.displayName = componentName;
