import * as React from 'react';
import {
  DragDropContext,
  DragDropContextProps,
  DraggableLocation,
  DragStart,
  DragUpdate,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';

import { getDataAttributes } from '../../util/attribute-util';

export interface DraggableContextProps extends DragDropContextProps {
  /**
   *
   * @param startPosition
   * Default message: "You have lifted an item in position ${startPosition}. Use the arrow keys to move, space bar to drop, and escape to cancel."
   */
  dragStartAnnouncement: (startPosition: number) => string;
  /**
   *
   * @param startPosition
   * @param endPosition
   * Default message: "You have moved the item from position ${startPosition} to position ${endPosition}" - Think about including of ${listLength} in your messaging.
   */
  dragUpdateAnnouncement: (startPosition: number, endPosition: number) => string;
  /**
   *
   * @param startPosition
   * @param startList
   * @param endPosition
   * @param endList
   * Default message "You have moved the item from position ${startPosition} in list ${source.droppableId} to list ${destination.droppableId} in position ${endPosition}"
   */
  dragUpdateDifferentListAnnouncement: (startPosition: number, startList: string, endPosition: number, endList: string) => string;
  /**
   * Default message: "You are currently not dragging over a droppable area".
   */
  dragUpdateAnnouncementFallback: () => string;
  /**
   *
   * @param startPosition
   * @param endPosition
   * Default message: "You have dropped the item. It has moved from position ${startPosition} to ${endPosition}"
   */
  dragEndAnnouncement: (startPosition: number, endPosition: number) => string;
  /**
   *
   * @param startPosition
   * @param startList
   * @param endPosition
   * @param endList
   * Default message: "You have dropped the item. It has moved from position ${startPosition} in list ${result.source.droppableId} to position ${endPosition} in list ${result.destination.droppableId}"
   */
  dragEndDifferentListAnnouncement: (startPosition: number, startList: string, endPosition: number, endList: string) => string;
  /**
   *
   * @param startPosition
   * Default message: "Movement cancelled. The item has returned to its starting position of ${startPosition}"
   */
  dragEndCancelAnnouncement: (startPosition: number) => string;
  /**
   *
   * @param startPosition
   * Default message: "The item has been dropped while not over a droppable location. The item has returned to its starting position of ${startPosition}"
   */
  dragEndAnnouncementFallback: (startPosition: number) => string;
  children?: React.ReactNode;
}

const componentName = 'DraggableContext';

export class DraggableContext extends React.Component<DraggableContextProps, {}> {
  private static positionValues(source: DraggableLocation, destination?: DraggableLocation) {
    const position = (index) => index + 1;
    const startPosition = position(source.index);
    const endPosition = destination ? position(destination.index) : null;
    return { startPosition, endPosition };
  }

  private onBeforeDragStart = (beforeStart: DragStart) => {
    this.props.onBeforeDragStart && this.props.onBeforeDragStart(beforeStart);
  };

  private onDragStart = (start: DragStart, provided: ResponderProvided) => {
    if (this.props.dragStartAnnouncement) {
      const { startPosition } = DraggableContext.positionValues(start.source);
      provided.announce(this.props.dragStartAnnouncement(startPosition));
    }
    this.props.onDragStart && this.props.onDragStart(start, provided);
  };

  private onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (this.props.dragEndAnnouncement) {
      const { source, destination } = result;

      if (!destination) {
        return;
      }

      const { startPosition, endPosition } = DraggableContext.positionValues(source, destination);

      if (result.reason === 'CANCEL') {
        provided.announce(this.props.dragEndCancelAnnouncement(startPosition));
      } else {
        if (source.droppableId === destination.droppableId) {
          provided.announce(
            endPosition ? this.props.dragEndAnnouncement(startPosition, endPosition) : this.props.dragEndAnnouncementFallback(startPosition)
          );
        } else {
          provided.announce(
            endPosition
              ? this.props.dragEndDifferentListAnnouncement(startPosition, source.droppableId, endPosition, destination.droppableId)
              : this.props.dragUpdateAnnouncementFallback()
          );
        }
      }
    }
    this.props.onDragEnd && this.props.onDragEnd(result, provided);
  };

  private onDragUpdate = (update: DragUpdate, provided: ResponderProvided) => {
    if (this.props.dragUpdateAnnouncement) {
      const { source, destination } = update;

      if (!destination) {
        return;
      }

      const { startPosition, endPosition } = DraggableContext.positionValues(source, destination);

      if (source.droppableId === destination.droppableId) {
        provided.announce(
          endPosition ? this.props.dragUpdateAnnouncement(startPosition, endPosition) : this.props.dragUpdateAnnouncementFallback()
        );
      } else {
        provided.announce(
          endPosition
            ? this.props.dragUpdateDifferentListAnnouncement(startPosition, source.droppableId, endPosition, destination.droppableId)
            : this.props.dragUpdateAnnouncementFallback()
        );
      }
    }
    this.props.onDragUpdate && this.props.onDragUpdate(update, provided);
  };

  render() {
    return (
      <DragDropContext
        onBeforeDragStart={this.onBeforeDragStart}
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}
        onDragUpdate={this.onDragUpdate}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </DragDropContext>
    );
  }
}

DraggableContext['displayName'] = componentName;
