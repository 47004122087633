import * as React from 'react';
import { useState } from 'react';
import { Inline } from '../inline/inline';
import { AriaButtonInterface } from '../AccessibilityProps';
import { ContentListFilterState } from './use-content-list-filter';
import { Chip } from '../chip/chip';
import { KeyCodes } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export interface FilterChipContainerProps {
  state: ContentListFilterState;
  cancelButtonAria: AriaButtonInterface;
  chipAria?: AriaButtonInterface;
  children?: JSX.Element[];
}

const componentName = 'FilterChipContainer';

export const FilterChipContainer = (props: FilterChipContainerProps, {}) => {
  const [hasFocus, setHasFocus] = useState(false);

  React.useEffect(() => {
    !hasFocus && props.state.setSelectIndex(-1);
  }, [hasFocus]);

  const chipOnClick = (index: number) => {
    const itemArray = [...props.state.items];
    const value = itemArray[index].selected;
    itemArray.map((i) => (i.selected = false));
    const targetedItem = itemArray[index];
    targetedItem.selected = !value;
    itemArray[index] = targetedItem;
    props.state.setSelectedItem({ ...targetedItem });
    props.state.setItems([...itemArray]);
  };

  const cancelOnClick = (index: number) => {
    const itemArray = [...props.state.items];
    const targetedItem = itemArray[index];
    itemArray.splice(index, 1);
    const selectItemArray = [...props.state.popUpItems, props.state.items[index].SelectItem];
    props.state.setItems([...itemArray]);
    props.state.setPopUpItems([...selectItemArray]);
    if (targetedItem.SelectItem.value === props.state.selectedItem.SelectItem.value) {
      targetedItem.selected = false;
      props.state.setSelectedItem({ ...targetedItem });
    }
  };

  function navigationKeyDown(e) {
    const length = props.state.items.length * 2;
    switch (e.keyCode) {
      case KeyCodes.DOM_VK_TAB: {
        props.state.setSelectIndex(0);
        break;
      }
      case KeyCodes.DOM_VK_LEFT: {
        props.state.setSelectIndex((prev) => (prev === 0 ? length - 1 : prev - 1));
        break;
      }
      case KeyCodes.DOM_VK_RIGHT: {
        props.state.setSelectIndex((prev) => (prev === length - 1 ? 0 : prev + 1));
        break;
      }
      case KeyCodes.DOM_VK_SPACE: {
        if (hasFocus) {
          e.preventDefault();
        }
        if (length > 0 && hasFocus) {
          if (props.state.selectIndex % 2 === 0) {
            chipOnClick(props.state.selectIndex / 2);
          } else {
            cancelOnClick(props.state.selectIndex / 2 - 0.5);
            props.state.setSelectIndex(0);
          }
        }
      }
    }
  }

  return (
    <div
      onKeyDown={navigationKeyDown}
      style={{ width: '100%' }}
      aria-orientation={'horizontal'}
      aria-multiselectable={'false'}
      aria-activedescendant={hasFocus ? props.state.getFocusedId() : ''} //ID of the selected filterChip
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      <Inline wrap={true} spacing={'8dp'}>
        {props.children[0]}
        {props.state.items.map((item, index) => {
          if (item.disabled) {
            return;
          }
          const buttonId = props.state.getIdFor(index * 2);
          const cancelId = props.state.getIdFor(index * 2 + 1);
          let tabIndex: number;
          if (index > 0) {
            tabIndex = -1;
          } else if (index === 0) {
            tabIndex = 0;
          }

          return (
            <div
              style={{ outline: 'none' }}
              tabIndex={tabIndex}
              key={index}
              data-key={`FilterChipContainer-${index}`}
              onFocus={() => {
                props.state.setSelectIndex(0);
                setHasFocus(true);
              }}
              onBlur={() => setHasFocus(false)}
            >
              <Chip
                cancelButtonId={cancelId}
                chipId={buttonId}
                data-key={`FilterChip-${index}`}
                focusedId={hasFocus ? props.state.getFocusedId() : ''}
                label={item.SelectItem.label}
                disabled={item.disabled} // CHANGE
                cancelButtonAria={props.cancelButtonAria}
                chipAria={props.chipAria}
                selected={item.selected}
                chipOnClick={() => chipOnClick(index)}
                countLabel={item.count}
                cancelOnClick={() => cancelOnClick(index)}
                disableTabStop={true}
              />
            </div>
          );
        })}
        <div style={{ flex: '1 1 auto' }}></div>
        {props.state.items.length > 0 && props.children[1]}
      </Inline>
    </div>
  );
};

FilterChipContainer.displayName = componentName;
