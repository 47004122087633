import * as React from 'react';
import * as styles from './text.css';
import { fontColor, text } from '../../vanilla-extract/hooks/typography/typography.css';
import { className as capsizeClassName } from '../../vanilla-extract/hooks/typography/capsize/prebuilt';
import { themeVars } from '../../vanilla-extract/themes/theme.css';
import { getDataAttributes } from '../../util/attribute-util';

export type TextSizes = keyof typeof text;

export type TextColor = keyof typeof themeVars.textColor;
const componentName = 'Text';

export interface TextProps {
  /**
   * The font size for the text
   */
  size: TextSizes;

  /**
   * Optional number of wrapped lines to show before ending an overflow with ellipsis.
   * By default, the text will wrap, using as many lines as needed.
   *
   * See the line clamp documentation at https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-line-clamp
   */
  maxLines?: number;

  /**
   * Optional text color to apply. Leaving this as unspecified means that the text inherits its color.
   */
  color?: TextColor;

  /***
   * Optional serif. Defaults to false. This will use Nexus (sans-serif)
   * If true, then will use the Gulliver (serif) font
   */
  serif?: boolean;

  /***
   * Optional hypernation. Defaults to false. Long words will be split over two lines with a hyphens inserted where appropriate.
   */
  hyphenation?: boolean;
  children?: React.ReactNode;
}

export const Text: React.FunctionComponent<TextProps> = function Text({
  children,
  size = 'font16',
  color,
  maxLines,
  serif = false,
  hyphenation = false,
  ...props
}) {
  let className = [styles.resetBase, serif ? styles.serif : styles.sans, text[size].trimmed, capsizeClassName].join(' ');

  if (hyphenation) {
    className += ' ' + styles.hyphenation;
  }
  if (color) {
    className += ' ' + fontColor[color];
  }

  if (maxLines) {
    // we need a wrapper element that is full-height to support text ellipsis or line clamping
    // since `overflow: hidden` is required and would otherwise cut of the descenders.
    if (maxLines === 1) {
      children = <span className={styles.ellipsis}>{children}</span>;
    } else {
      const style: any = { WebkitLineClamp: maxLines.toString() };
      children = (
        <span style={style} data-component={componentName} className={styles.lineClamp}>
          {children}
        </span>
      );
    }
  }
  return (
    <span className={className} data-component={componentName} {...getDataAttributes(props)}>
      {children}
    </span>
  );
};

Text['displayName'] = componentName;
