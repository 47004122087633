import * as React from 'react';
import { FilterAdd } from './filter-add';
import { FilterChipContainer } from './filter-chip-container';
import { FilterSave } from './filter-save';
import { FilterSearch } from './filter-search';
import { ContentListFilterProps } from './content-list-filter';
import { Area, AreaBody, AreaFooter, AreaHeader } from '../area/area';
import { ContentListFilterState, useContentListFilter } from './use-content-list-filter';
import { Transition } from '../transition/transition';
import styles from './content-list-filter.module.scss';
import { Stack } from '../stack/stack';
import { Label } from '../label/label';
import { Inline } from '../inline/inline';
import { getDataAttributes } from '../../util/attribute-util';

export type ContentListNarrowFilterProps = ContentListFilterProps;

const componentName = 'ContentListNarrowFilter';

export const ContentListNarrowFilter = (props: ContentListNarrowFilterProps, {}) => {
  const state: ContentListFilterState = useContentListFilter(props);
  const { displaySearchBar = true } = props;

  return (
    <Area data-component={componentName} {...getDataAttributes(props)}>
      <AreaHeader>
        <Inline wrap={true}>
          <FilterChipContainer state={state} chipAria={props.chipAria} cancelButtonAria={props.cancelButtonAria}>
            <FilterAdd state={state} label={props.popUpLabel} />
            <FilterSave
              filterInputPlaceholder={props.filterInputPlaceholder}
              state={state}
              saveFilterButtonAria={props.saveFilterButtonAria}
              saveFilterInputAria={props.saveFilterInputAria}
              saveButtonLabel={props.saveButtonLabel}
              onFiltersSave={props.onFiltersSave}
            />
          </FilterChipContainer>
          <div style={{ flex: '1 1 auto' }} />
        </Inline>
      </AreaHeader>
      <AreaBody>
        <Transition transition={'expand'} animate={true} markAsPresentation={true}>
          {state.selectedItem && state.selectedItem.selected && state.items && (
            <div className={styles['option-hr']}>
              <Stack spacing={'4dp'}>
                <Label style={{ marginBottom: '8px' }} type={'small'} text={state.selectedItem.SelectItem.label} />
                {state.items.find((item) => item.selected === true)?.children}
              </Stack>
            </div>
          )}
        </Transition>
      </AreaBody>
      <AreaFooter>{displaySearchBar && <FilterSearch {...props} />}</AreaFooter>
    </Area>
  );
};

ContentListNarrowFilter['displayName'] = componentName;
