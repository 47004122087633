import * as React from 'react';
import styles from './HTML-content.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface HTMLContentProps {
  html: string;
  className?: string;
}

const componentName = 'HTMLContent';

export class HTMLContent extends React.Component<HTMLContentProps, {}> {
  render() {
    const classes = [styles['component']];

    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div
        className={classes.join(' ')}
        dangerouslySetInnerHTML={{ __html: this.props.html }}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      ></div>
    );
  }
}

HTMLContent['displayName'] = componentName;
