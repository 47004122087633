import * as React from 'react';
import { Link, LinkInternalProps } from '../link/link';

const componentName = 'PopupListTrigger';

type RefType = React.LegacyRef<HTMLAnchorElement>;

export interface PopupListTriggerProps extends Omit<LinkInternalProps, 'iconRightName'> {
  isOpen: boolean;
}

export const PopupListTrigger = React.forwardRef(({ isOpen, ...props }: PopupListTriggerProps, ref: RefType): JSX.Element => {
  return (
    <Link
      variant="default"
      href="#"
      {...props}
      data-component={componentName}
      iconRightName={isOpen ? 'navigate-down' : 'navigate-up'}
      role="button"
      ref={ref as any}
    />
  );
});

PopupListTrigger.displayName = componentName;
