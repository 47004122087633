import * as React from 'react';
import styles from './message-block.module.scss';

import { Icon } from '../icon/icon';
import { IconName } from '../icon/icon-SVGs';
import { getDataAttributes } from '../../util/attribute-util';

export type MessageBlockType = 'info' | 'warning' | 'error';

export interface MessageBlockProps {
  /**
   * To indicate what type the message block should be
   */
  type: MessageBlockType;
  /**
   * Optional: To provide an alternative icon to the messageblock. If no one is provided, the type will be converted to an icon
   */
  customIcon?: IconName;
  children: any;
}

const componentName = 'MessageBlock';

export class MessageBlock extends React.Component<MessageBlockProps, {}> {
  constructor(properties: MessageBlockProps) {
    super(properties);
  }

  get iconName() {
    switch (this.props.type) {
      case 'info':
        return 'information';
      case 'warning':
        return 'alert';
      case 'error':
        return 'caution';
      default:
        this.isExhaustive(this.props.type);
    }
  }

  isExhaustive(name: never): never {
    throw `invalid icon name "${name}"`;
  }

  render() {
    const classes = [styles['component']];

    this.props.type && classes.push(styles[this.props.type]);

    return (
      <div className={classes.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        <div className={styles['flap']}>
          <Icon name={this.props.customIcon ? this.props.customIcon : this.iconName} size={20} />
        </div>
        <div className={styles['content']}>{this.props.children}</div>
      </div>
    );
  }
}

MessageBlock['displayName'] = componentName;
