import * as React from 'react';
import { ReactElement } from 'react';
import { Icon } from '../icon/icon';
import styles from './tab-panel.module.scss';
import { InternalTabProps, TabProps } from './tab/tab';
import { AccessibilityEventHandlers } from '../../accessibility/accessibility-event-handlers';
import { AriaTabInterface, AriaTablistInterface } from '../AccessibilityProps';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { CommandButton, CommandButtonProps } from '../command-button/command-button';
import { PopupLink } from '../popup-link/popup-link';
import { SelectItem } from '../../models/select-item';
import { IconButton } from '../icon-button/icon-button';
import { IconName } from '../icon/icon-SVGs';
import { getDataAttributes } from '../../util/attribute-util';
import { ToolTip } from '../tool-tip/tool-tip';
import { LangUtil } from '../../client-shared';
import { windowSafe } from '../../util/ssr';

export interface TabPanelProps {
  /**
   * The style of tabs.
   * 'tabs': Traditional tabs placed above the content. Suitable for tools etc. Default value.
   * 'sheets': Tabs placed below the content, similar to a sheet in a spread sheet. Suitable for a set of related views.
   */
  tabStyle?: 'tabs' | 'sheets';

  /**
   * The size of tabs.
   * 'small': tab label will be using the small text size. Default value.
   * 'medium': tab label will be using the medium text size.
   */
  tabSize?: 'small' | 'medium';

  /**
   * Tabs separation strategy.
   * 'padding': Text padding is used, this styling results in the underline being wider than the tab label text. Default value.
   * 'margin': Margin is used between tabs, this styling results in underline to have exact same width as the tab label text.
   */
  tabsSeparation?: 'padding' | 'margin';

  /**
   * Id of the currently selected tab. If no id is specified, the first tab is selected by default when the component is mounted.
   */
  selectedTabId?: string;

  /**
   * Optional callback for when a tab has been selected. Example use case is to load data for the tab.
   * @param tabId the id of the tab that was selected
   */
  onTabSelected?: (tabId: string) => any;

  /**
   * Whether to grow the height of tab contents vertically to fill the available space in a parent with "display: flex". Defaults to false.
   */
  growContent?: boolean;

  /**
   * The display to use for content. Defaults to block. Use flex to allow children of the content to continue a flex layout, e.g. to divide the content area into columns.
   */
  contentDisplay?: 'block' | 'flex';

  /**
   * Optional class name to add to the content of the tab panel, e.g. to add borders
   */
  contentClassName?: string;

  /**
   * Whether to grow tab bar items to horizontally fill the available space. Defaults to false.
   */
  growBarItems?: boolean;

  /**
   * Optional. When set an add button is created and configured at the end of the tabPanel.
   */
  addButtonAttributes?: CommandButtonProps | {};

  /**
   * Whether to lazy load tabs by mounting their components only when they've been selected. Defaults to true.
   */
  lazyLoadTabs?: boolean;

  /**
   * Optional whether to render the tab bar. Defaults to true.
   */
  renderTabBar?: boolean;

  /**
   * Label describing the tabPanel for screen readers
   */
  tabListAriaLabel: string;

  /**
   * Optional whether to tabs is a condensed mode. The title is only shown for a active tab
   */
  showOnlyIcons?: boolean;

  /**
   * Optional. If there needs to be a border all the way out to the sides of the site, there will be added a padding to the tab-list
   */
  sideBorder?: '0' | '4dp' | '8dp' | '16dp' | '24dp' | '32dp';

  /**
   * Optional. If the tab panel content is not supposed to have a grey background, set this one to true and it will be transparent
   */
  backgroundColor?: 'background-page-contrast-1' | 'background-page-contrast-2' | 'background-transparent';

  /**
   * Optional. If supplied then the TabPanel will act as an expandPanel.
   */
  expandPanel?: IExpandPanelOptions;

  /**
   * Optional. Control group area on the right side of the tab list
   */
  tabBarControlGroup?: React.ReactNode;

  children: React.ReactNode;
}

export interface IExpandPanelOptions {
  /**
   * Optional callback. Invoked when the panel is expanded.
   */
  onOpen?: (tabId: string) => void;

  /**
   * Optional callback. Invoked when the panel is collapsed.
   */
  onClose?: (tabId: string) => void;

  /**
   * Optional. Takes a height value that accepts css units.
   */
  fixedHeight?: string;

  /**
   * Mandatory. Label describing the button for opening the panel to screen readers.
   */
  expandButtonOpenAriaLabel: string;

  /**
   * Mandatory. Label describing the button for closing the panel to screen readers.
   */
  expandButtonCloseAriaLabel: string;

  /**
   * Optional. default is false(collapsed). If true the panel will be initially open.
   */
  preExpanded?: boolean;

  /**
   * Optional. default is true. When true, the down icon will be used to open the panel. False is for the up icon.
   */
  downIconToOpen?: boolean;
}

const componentName = 'TabPanel';

/**
 * Please note:<br/>
 * The TabPanel and Tab components need to be restructured to have a proper parent/child relationship.<br/>
 * Currently, the TabPanel fetches the Tab components to generate the TabPanel bar items.<br/>
 * This makes it tricky to add data-features that are unique for the TabPanel bar items and the Tab content areas.<br/>
 * We would like to be able to use the getDataAttributes function for that.<br/>
 * See https://elsevier.atlassian.net/browse/GPHN-62.<br/><br/>
 *
 * A tab panel shows a number of tabbed pages ({@link Tab}), and the contents of the currently selected tab.
 * Tabs are lazy loaded once they're selected, but remain mounted to preserve their state when another tab is selected. This allows a user to switch between tabs without loosing UI state.
 * See {@link SearchFieldProperties#tabStyle} for supported tab styles.
 */

export class TabPanel extends React.Component<TabPanelProps, {}> {
  static defaultProps: Partial<TabPanelProps> = {
    tabStyle: 'tabs',
    tabSize: 'small',
    tabsSeparation: 'padding',
    contentDisplay: 'block',
    lazyLoadTabs: true,
    renderTabBar: true,
    showOnlyIcons: false,
    backgroundColor: 'background-page-contrast-2',
  };
  tabListElement: HTMLElement;
  tabBarElement: HTMLElement;
  tabScrollerElement: HTMLElement;

  private debounceResizeTimeout = null;
  private currentTabPosition = 0;
  private nextTabPosition = 0;
  private resizeObserver: ResizeObserver = null;

  uuid = LangUtil.randomUuid();

  tabPanelScope = LangUtil.randomUuid();

  state = {
    panelExpanded: this.props.expandPanel?.preExpanded ?? true,
    selectedTabId: this.props.selectedTabId,
    activeDescendant: this.props.selectedTabId,
    hasFocus: false,
    updateId: '',
    showTabScroller: false,
    translateX: 0,
  };

  loadedTabIds = new Map<string, boolean>();
  contextMenuMap = new Map<string, PopupLink>();

  // ---- rendering ----
  navigationKeyDown = AccessibilityEventHandlers.tablistFactory(
    (elm) => {
      elm.click();
    },
    (elm) => {
      this.setState({
        activeDescendant: IdEncoderUtil.decodeId(elm.id),
      });

      this.scrollElementIntoView(elm);
    },
    (elm) => {
      if (!this.contextMenuMap.has(elm.id)) {
        return;
      }
      this.contextMenuMap.get(elm.id).open();
    }
  );

  render() {
    const aria: AriaTablistInterface = {
      'aria-activedescendant': IdEncoderUtil.encodeId(this.uuid, this.state.activeDescendant),
      'aria-orientation': 'horizontal',
      'aria-multiselectable': 'false',
      'aria-label': this.props.tabListAriaLabel,
    };

    const tabBarClasses = [styles['tab-bar']];
    if (this.props.sideBorder) {
      const sideBorderStyle = 'tab-side-padding-' + this.props.sideBorder;
      tabBarClasses.push(styles[sideBorderStyle]);
    }

    const tabs = this.props.renderTabBar ? (
      <div className={styles['tab-area']}>
        {this.renderTabScroller()}
        <div ref={(c) => (this.tabBarElement = c)} className={tabBarClasses.join(' ')} key="tab-bar" {...getDataAttributes(this.props)}>
          <div
            ref={(c) => (this.tabListElement = c)}
            onBlur={() => this.setState({ hasFocus: false })}
            onFocus={() => this.setState({ hasFocus: true })}
            className={styles['tab-list']}
            data-instance-id={this.uuid}
            role={'tablist'}
            {...aria}
            tabIndex={0}
            style={{ transform: `translateX(${this.state.translateX}px)` }}
          >
            {this.renderTabBarItems()}
            {this.renderAddButton()}
          </div>
        </div>
        {this.renderTabBarControlGroup()}
      </div>
    ) : null;

    const style = this.props.contentDisplay === 'flex' ? { display: 'flex', flex: '1' } : {};
    const contentClasses = [styles['content'], this.props.contentClassName];

    contentClasses.push(styles[this.props.backgroundColor ?? 'background-page-contrast-2']);
    if (LangUtil.isDefined(this.props?.expandPanel?.fixedHeight) && this.state.panelExpanded) {
      style['height'] = this.props.expandPanel.fixedHeight;
    }

    this.props.contentDisplay && contentClasses.push(styles['flex-children']);

    const content = this.state.panelExpanded && (
      <div className={contentClasses.join(' ')} key="content" style={style} data-instance-id={this.uuid}>
        {this.renderSelectedTab()}
      </div>
    );

    const body = this.props.tabStyle === 'tabs' ? [tabs, content] : [content, tabs];

    const styleName = LangUtil.createMultiStyleNames(
      [this.props.tabStyle],
      [{ 'flex-content': this.props.growContent }],
      [{ 'flex-bar-items': this.props.growBarItems }],
      [{ 'items-margin': this.props.tabsSeparation === 'margin' }],
      [{ medium: this.props.tabSize === 'medium' }]
    );
    const className = ['component', ...styleName.split(' ')]
      .filter((s) => !!s)
      .map((s) => styles[s])
      .join(' ');

    return (
      <div
        className={className}
        data-panel-expanded={this.state.panelExpanded}
        data-component={componentName}
        data-instance-id={this.uuid}
        {...getDataAttributes(this.props)}
      >
        {body}
      </div>
    );
  }

  renderTabBarItems = () => {
    return React.Children.map(this.props.children, (tab: React.ReactElement<TabProps>) => {
      if (!tab) {
        return;
      }

      const { id, icon, label, summary, contextualMenuItems, onContextItemClicked, showIndicator } = tab.props;
      const selectedTabId = this.props.selectedTabId || this.state.selectedTabId;
      const isSelected = id === selectedTabId;
      const classes = [styles['tab']];
      let showLabel = label && label.length > 0;
      if (isSelected) {
        classes.push(styles['selected']);
      }
      if (this.props.showOnlyIcons) {
        if (showLabel && icon) {
          showLabel = isSelected;
        }
        classes.push(styles['icon-only']);
      }
      const ariaExpanded = this.state.panelExpanded ? { 'aria-controls': IdEncoderUtil.encodeId(this.tabPanelScope, id) } : {};

      const aria: AriaTabInterface = {
        'aria-selected': isSelected,
        'aria-label': label,
      };

      const toolBarItem = (
        <div
          key={id}
          data-key={`TabPanel_toolBarItem-${id}`}
          id={IdEncoderUtil.encodeId(this.uuid, id)}
          data-id={id}
          onClick={() => this.handleSelectTab(id)}
          className={classes.join(' ')}
          role={'tab'}
          title={this.props.showOnlyIcons ? '' : label}
          data-hasfocus={this.state.activeDescendant === id ? 'true' : 'false'}
          data-feature={tab.props.dataFeatureLink}
          {...ariaExpanded}
          {...aria}
        >
          {icon && <Icon name={icon} size={16} />}
          {showLabel && (
            <span className={styles['label']}>
              {label}
              {showIndicator && <span className={styles['indication-span']} />}
            </span>
          )}
          {summary && <span className={styles['summary']}>{summary}</span>}
          {contextualMenuItems && contextualMenuItems.length > 0 && (
            <PopupLink
              ref={(c) => this.contextMenuMap.set(IdEncoderUtil.encodeId(this.uuid, id), c)}
              icon={'ellipsis'}
              items={contextualMenuItems}
              onItemsSelected={(selected, newItems, removedItems, isMouseEvent) =>
                this.handleClickContextItem(selected, newItems, removedItems, isMouseEvent, onContextItemClicked)
              }
              showDropDownArrow={false}
              alignMenu={'left'}
            />
          )}
        </div>
      );

      if (this.props.showOnlyIcons) {
        return isSelected ? (
          toolBarItem
        ) : (
          <ToolTip id={id} data-feature={'tab-tooltip'} content={label} forceShow={this.state.activeDescendant === id || undefined}>
            {toolBarItem}
          </ToolTip>
        );
      } else {
        return toolBarItem;
      }
    });
  };

  /**
   * Renders the selected tab, and any previously selected tabs as mounted but hidden.
   */
  renderSelectedTab() {
    const mountedTabs = [];
    const selectedTabId = this.props.selectedTabId || this.state.selectedTabId;
    React.Children.forEach(this.props.children, (tabComponent: React.ReactElement<TabProps & InternalTabProps>) => {
      if (tabComponent && tabComponent.props.id === selectedTabId) {
        const visibleTabComponent = React.cloneElement(tabComponent, {
          key: tabComponent.props.id,
          domId: IdEncoderUtil.encodeId(this.tabPanelScope, tabComponent.props.id),
          aria: { ...tabComponent.props.aria, 'aria-labelledby': IdEncoderUtil.encodeId(this.uuid, tabComponent.props.id) },
        } as Partial<TabProps>);
        mountedTabs.push(visibleTabComponent);
      } else if (tabComponent && this.shouldMountTab(tabComponent.props.id)) {
        // tab isn't selected, but it was selected previously
        // so render it to prevent unmount from clearing UI state
        const hiddenTabProps = { hidden: true, key: tabComponent.props.id, aria: { 'aria-expanded': true } } as InternalTabProps;
        const hiddenTabComponent = React.cloneElement(tabComponent, hiddenTabProps);
        mountedTabs.push(hiddenTabComponent);
      }
    });
    return mountedTabs;
  }

  renderClosePanelButton() {
    return LangUtil.isDefined(this.props.expandPanel) ? (
      <IconButton
        type={'button'}
        aria={{
          'aria-label': this.state.panelExpanded
            ? this.props.expandPanel.expandButtonCloseAriaLabel
            : this.props.expandPanel.expandButtonOpenAriaLabel,
        }}
        icon={this.getExpandPanelIcon()}
        onClick={() => this.setState({ panelExpanded: !this.state.panelExpanded })}
      />
    ) : null;
  }

  // ---- life cycle ----
  componentDidUpdate(prevProps) {
    const { selectedTabId } = this.props;

    this.loadedTabIds.set(selectedTabId, true);

    if (prevProps.selectedTabId !== selectedTabId && this.state.selectedTabId !== selectedTabId) {
      this.props.onTabSelected?.(selectedTabId);
      if (selectedTabId) {
        this.setState({ activeDescendant: selectedTabId });
      } else {
        this.setState({
          activeDescendant: (React.Children.toArray(this.props.children)[0] as ReactElement<TabProps>).props.id,
        });
      }
    }
  }

  componentDidMount(): void {
    if (LangUtil.isDefined(this.tabListElement)) {
      this.tabListElement.addEventListener('keydown', this.navigationKeyDown);
      this.tabListElement.addEventListener('focusin', this.handleTabListFocusIn);
    }
    this.initialize();

    if (LangUtil.isDefined(this.tabBarElement)) {
      this.tabBarElement.addEventListener('scroll', this.handleTabBarScroll);
    }

    windowSafe().addEventListener('resize', this.handleResize);

    if ('ResizeObserver' in window) {
      // if the size of the tablist changes, check if we need to show the tab scroller
      // is not supported in older browsers like IE
      this.resizeObserver = new ResizeObserver(this.updateTabScrollerVisibility);
      this.resizeObserver.observe(this.tabListElement);
    }

    this.updateTabScrollerVisibility();
    this.updateTabScroller();
  }

  componentWillUnmount(): void {
    if (LangUtil.isDefined(this.tabListElement)) {
      this.tabListElement.removeEventListener('keydown', this.navigationKeyDown);
      this.tabListElement.removeEventListener('focusin', this.handleTabListFocusIn);
    }

    if (LangUtil.isDefined(this.tabBarElement)) {
      this.tabBarElement.removeEventListener('scroll', this.handleTabBarScroll);
    }

    this.resizeObserver?.disconnect();

    windowSafe().removeEventListener('resize', this.handleResize);
  }

  // ---- event handlers ----
  handleSelectTab = (id) => {
    let panelExpanded = this.state.panelExpanded;

    if (LangUtil.isDefined(this.props.expandPanel)) {
      if (this.state.selectedTabId === id) {
        panelExpanded = !panelExpanded;
      } else {
        panelExpanded = true;
      }
      if (this.state.panelExpanded && this.props.expandPanel?.onOpen) {
        this.props.expandPanel?.onOpen(this.state.selectedTabId);
      } else if (this.props.expandPanel?.onClose) {
        this.props.expandPanel?.onClose(this.state.selectedTabId);
      }
    }

    this.loadedTabIds.set(id, true);
    this.props.onTabSelected?.(id);

    this.setState({
      panelExpanded,
      activeDescendant: id,
      selectedTabId: id,
    });
  };

  handleClickContextItem = (
    selected: SelectItem[],
    newItems: SelectItem[],
    removedItems: SelectItem[],
    isMouseEvent?: boolean,
    onContextItemClicked?: (item: SelectItem) => void
  ) => {
    if (newItems?.length > 0) {
      const item = newItems[0];
      requestAnimationFrame(() => onContextItemClicked && onContextItemClicked(item));
    }
  };

  private initialize() {
    let stateChanges = {};
    let selectedTabId = this.props.selectedTabId;
    if (!selectedTabId) {
      // select the first tab by default if none has been specified using props
      React.Children.forEach(this.props.children, (tab: React.ReactElement<TabProps>) => {
        if (!selectedTabId) {
          selectedTabId = tab.props.id;
        }
      });
    }
    if (selectedTabId) {
      // tabs are lazy loaded after they've been selected for the first time
      this.loadedTabIds.set(selectedTabId, true);
      stateChanges = { ...stateChanges, updateId: LangUtil.randomUuid() };
    }
    this.setState({ ...stateChanges, selectedTabId, activeDescendant: selectedTabId });
  }

  private renderAddButton = (): JSX.Element | null => {
    if (this.props.addButtonAttributes) {
      const buttonProps: CommandButtonProps = {
        icon: 'plus',
        size: 'small',
        id: 'add-tab-button',
        ...(this.props.addButtonAttributes as CommandButtonProps),
      };

      return (
        <div className={styles['add-tab']}>
          <CommandButton {...buttonProps} />
        </div>
      );
    }
    return null;
  };

  private getExpandPanelIcon(): IconName {
    const downIconToOpen = this.props?.expandPanel?.downIconToOpen ?? true;
    const openIcon = downIconToOpen ? 'navigate-down' : 'navigate-up';
    const closeIcon = downIconToOpen ? 'navigate-up' : 'navigate-down';
    return this.state.panelExpanded ? closeIcon : openIcon;
  }

  private shouldMountTab(tabId: string): boolean {
    if (this.props.lazyLoadTabs === false) {
      return true;
    }
    return this.loadedTabIds.get(tabId);
  }

  private renderTabBarControlGroup() {
    if (!this.props.tabBarControlGroup && !this.props.expandPanel) {
      return null;
    }

    return (
      <div className={styles['tab-bar-control-group']}>
        {this.renderCustomControls()}
        {this.renderClosePanelButton()}
      </div>
    );
  }

  renderCustomControls() {
    if (this.props.tabBarControlGroup) {
      return <div className={styles['custom-controls']}>{this.props.tabBarControlGroup}</div>;
    }

    return null;
  }

  /* Tab Scroller */

  private renderTabScroller() {
    if (!this.state.showTabScroller) {
      return null;
    }

    return (
      <div ref={(e) => (this.tabScrollerElement = e)} className={styles['tab-scroller']}>
        <IconButton type="button" size="large" icon="navigate-left" onClick={(e) => this.handleScrollButtonClick(-1)} />
        <IconButton type="button" size="large" icon="navigate-right" onClick={(e) => this.handleScrollButtonClick(1)} />
      </div>
    );
  }

  get shouldShowTabScroller(): boolean {
    if (!this.tabListElement || !this.tabBarElement) {
      return false;
    }

    const widthAdjustedForScroller = this.tabListElement.offsetWidth - this.tabScrollerWidth;

    return widthAdjustedForScroller > this.tabBarElement.offsetWidth;
  }

  get tabScrollerWidth(): number {
    return this.tabScrollerElement ? this.tabScrollerElement.offsetWidth : 0;
  }

  getTab = (tabPosition: number): HTMLElement => {
    return this.tabListElement?.children[tabPosition] as HTMLElement;
  };

  handleResize = (): void => {
    this.debounceResize(this.updateAfterResize, 100);
  };

  handleTabBarScroll = (): void => {
    // prevent scrolling in the tab bar by resetting if we're not at 0, 0
    if (this.tabBarElement.scrollLeft !== 0) {
      this.tabBarElement.scroll(0, 0);

      // since the tab bar is overflowing scrolling should only be caused by scrollIntoView by a focusable element
      // use our custom scroll instead of the native functionality
      const tabLevelElement = this.findTabLevelElement(document.activeElement);
      if (tabLevelElement) {
        this.scrollElementIntoView(tabLevelElement);
      }
    }
  };

  handleTabListFocusIn = (e: FocusEvent) => {
    // only adjust the tab view on keyboard navigation
    if (document.querySelector('html').getAttribute('data-whatinput') !== 'keyboard') {
      return;
    }

    let targetElement = null;
    if (document.activeElement.id === 'add-tab-button') {
      targetElement = document.activeElement;
    } else {
      targetElement = this.tabListElement.querySelector(`#${IdEncoderUtil.encodeId(this.uuid, this.state.activeDescendant)}`);
    }
    // scroll to target element
    if (targetElement) {
      this.scrollElementIntoView(this.findTabLevelElement(targetElement));
    }
  };

  updateAfterResize = () => {
    this.updateTabScrollerVisibility();
    this.fillAvailableTabSpace();
  };

  updateTabScrollerVisibility = () => {
    const shouldShowTabScroller = this.shouldShowTabScroller;

    if (shouldShowTabScroller !== this.state.showTabScroller) {
      // if we're removing the tab scroller, we can reset tab scroll
      if (!shouldShowTabScroller) {
        this.nextTabPosition = 0;
      }

      // we can always reset translation here
      // if we hide the tab scroller, we should no longer be scrolled
      // if we show it then it should already be at 0 and setting it to 0 shouldn't matter
      this.setState({
        showTabScroller: shouldShowTabScroller,
        translateX: 0,
      });
    }
  };

  /**
   * This function checks for available space in the tab list and adjusts the tab position to fill it.
   * This happens when the user has scrolled to a tab and resizes the window. We check for available space on the right
   * and if we find any, we check if we can move the tab position one or more times to the left.
   */
  fillAvailableTabSpace = () => {
    if (this.currentTabPosition === 0) {
      return; // can't scroll further left
    }

    // check the width of the tab bar against the translated position of the tab list.
    const widthOfAvailableTabSpace = this.tabBarElement.offsetWidth - (this.tabListElement.offsetWidth + this.state.translateX);

    // if the available space is a positive number, then we check if there's room for one or more tabs
    // to be scrolled into view
    if (widthOfAvailableTabSpace > 0) {
      for (let i = this.currentTabPosition - 1; i >= 0; i--) {
        const tabElement = this.getTab(i);

        if (!tabElement) {
          console.log('no tab element found');
          return;
        }

        const remainingWidthWithTabInView = tabElement.getBoundingClientRect().left + widthOfAvailableTabSpace - this.tabScrollerWidth;
        if (remainingWidthWithTabInView >= 0) {
          this.nextTabPosition = i;
        } else {
          break;
        }
      }
    }

    this.updateTabScroller();
  };

  scrollElementIntoView = (element: HTMLElement) => {
    let newTabPosition = null;
    if (element.getBoundingClientRect().left < this.tabBarElement.getBoundingClientRect().left) {
      // we only have tabs to the left so this is simpler than going right
      newTabPosition = Array.from(this.tabListElement.children).indexOf(element);

      if (newTabPosition >= 0) {
        this.nextTabPosition = newTabPosition;
        this.updateTabScroller();
      } else {
        console.log('could not find tab at position: ' + newTabPosition);
      }
    } else if (element.getBoundingClientRect().right > this.tabBarElement.getBoundingClientRect().right) {
      // determine how much we need to scroll then search for a tab position that will yield at least
      // that much space
      const distanceToScroll = element.getBoundingClientRect().right - this.tabBarElement.getBoundingClientRect().right;

      // include current tab position in the search because we might be already be moving into view
      for (let i = this.currentTabPosition; i < this.tabListElement.children.length; i++) {
        const tabElement = this.getTab(i);

        if (!tabElement) {
          console.log('no tab element found');
          return;
        }

        const tabBarLeftEdge = this.tabBarElement.getBoundingClientRect().left + this.tabScrollerWidth;
        const distanceCovered = tabElement.getBoundingClientRect().left - tabBarLeftEdge;
        if (distanceCovered >= distanceToScroll) {
          this.nextTabPosition = i;
          break;
        }
      }
      this.updateTabScroller();
    }
  };

  findTabLevelElement(element: Element) {
    if (!LangUtil.isDefined(element)) {
      return;
    }

    // if the parent element is the tab list then the element we're looking at is at the tab level
    if (element.parentElement.getAttribute('role') === 'tablist') {
      return element;
    } else if (element === document.body) {
      // abort if we haven't found tab list by the time we hit the body element
      return;
    }

    return this.findTabLevelElement(element.parentElement);
  }

  debounceResize(func, delay) {
    if (this.debounceResizeTimeout) {
      clearTimeout(this.debounceResizeTimeout);
    }
    this.debounceResizeTimeout = setTimeout(func, delay);
  }

  updateTabScroller(focusCurrentTab = false) {
    if (!this.tabListElement || !this.tabBarElement) {
      return;
    }

    // update tab scroller visibility
    const shouldShowTabScroller = this.shouldShowTabScroller;

    // if tab position has changed, update the translation
    if (shouldShowTabScroller && this.currentTabPosition !== this.nextTabPosition) {
      const tabElement = this.getTab(this.nextTabPosition);

      if (!tabElement) {
        console.log('no tab element found');
        return;
      }

      const newTranslateX = this.calculateNewTranslation(tabElement);
      this.setState({
        translateX: newTranslateX,
        activeDescendant: focusCurrentTab ? IdEncoderUtil.decodeId(tabElement.id) : this.state.activeDescendant,
      });

      this.currentTabPosition = this.nextTabPosition;
    }
  }

  calculateNewTranslation(tab: HTMLElement) {
    // current offset/translation of the tab list and adjusted for the position of the tab scroller
    const tabScrolleroffset = this.tabListElement.getBoundingClientRect().left - this.tabScrollerElement.getBoundingClientRect().right;

    // width from left edge of tab to the right edge of the tab scroller.
    // not using width because that won't work if we're moving several tabs at a time.
    const distanceToLeftAlignTab = tab.getBoundingClientRect().left - this.tabScrollerElement.getBoundingClientRect().right;

    return tabScrolleroffset - distanceToLeftAlignTab;
  }

  handleScrollButtonClick = (tabChange: number) => {
    const newTabPosition = this.currentTabPosition + tabChange;

    // don't scroll past first position
    if (tabChange < 0 && newTabPosition < 0) {
      return;
    }

    if (
      tabChange > 0 &&
      // don't scroll past the last position
      (newTabPosition >= this.tabListElement.children.length ||
        // don't scroll left if the last tab is visible; width + negative offset must be greater than container width
        this.tabListElement.offsetWidth + this.state.translateX < this.tabBarElement.offsetWidth)
    ) {
      return;
    }

    this.nextTabPosition = newTabPosition;

    // set focus current tab to true if we're scrolling using keyboard navigation
    this.updateTabScroller(document.querySelector('html').getAttribute('data-whatinput') === 'keyboard');
  };
}

TabPanel['displayName'] = componentName;
