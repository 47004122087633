import { isSSR } from '../../util/ssr';

/* eslint-disable no-prototype-builtins */
export class PixelConverterUtil {
  public static basePixelSize = 16;

  private static get dpInPx() {
    return PixelConverterUtil.basePixelSize / 16;
  }

  public static Observe(elem: Element) {
    PixelConverterUtil.updatePixelSize();
    this.observer.observe(elem, {
      childList: true,
    });
  }

  /**
   * Convert a devicePixel value to a pixel value
   * @param pixelCount
   * @returns {number}
   */
  static dpToPx(devicePixels: number): number {
    return devicePixels * PixelConverterUtil.dpInPx;
  }

  /**
   * Convert a pixel value to a device pixel value
   * @param pixels
   * @returns {number}
   */
  static pxToDp(pixels: number): number {
    return pixels / PixelConverterUtil.dpInPx;
  }

  private static updatePixelSize = () => {
    if (isSSR) {
      return;
    }
    PixelConverterUtil.basePixelSize = parseInt(window.getComputedStyle(document.querySelector('html')).fontSize.replace(/\D/g, ''), 10);
  };

  private static observer =
    typeof window === 'object' && window.hasOwnProperty('MutationObserver')
      ? new MutationObserver(() => PixelConverterUtil.updatePixelSize())
      : {
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          observe: function (a, b) {},
        };
}
