import * as React from 'react';
import * as styles from './snackbar.module.scss';
import * as ReactDOM from 'react-dom';
import { Transition } from '../transition/transition';
import { getPaletteClassName } from '../../util/theme-util';
import { CommandButton } from '../command-button/command-button';
import { getDataAttributes } from '../../util/attribute-util';
import { Timeouts } from '../../accessibility/timeouts';
import { ContextType } from 'react';
import StylingContext from '../../style-context/styling-context-provider';

export interface SnackbarProps {
  size?: 'medium' | 'large';

  /**
   * Define the position that the component will be placed in;
   */
  position?: 'bottomLeft' | 'bottomCenter' | 'bottomRight';
  /**
   * The message to show in the snackbar
   */
  message: string;

  /**
   * An optional action to show after the message, allowing the user to react to the message
   */
  action?: {
    actionText: string;
    onClick: (e?: React.MouseEvent<any>) => void;
  };

  /**
   * Optional data-attribute to add e.g. for testing purposes
   */
  [key: string]: any;
}

export const NEXT_MESSAGE_DELAY_MS = 750;

export function showSnackbarMessage(message: SnackbarProps) {
  state.addMessage(message);
}

export function hideSnackbarMessage() {
  !state.currentMessage && state.scheduleRemoval(state.currentMessage, 1);
}

export class MessageState {
  messages: SnackbarProps[] = [];
  waitForHideCompleted = false;
  messageKey = 0;
  private removeTimer = null;
  private showNextTimer = null;

  get currentMessage() {
    return !this.waitForHideCompleted && this.messages.length ? this.messages[0] : null;
  }

  addMessage(message: SnackbarProps) {
    this.messages.push(message);
    this.messageKey++;
    if (this.messages.length === 1) {
      this.notify();
    }
  }

  onCurrentMessageRendered() {
    this.scheduleRemoval(this.currentMessage);
  }

  resume() {
    if (this.currentMessage) {
      this.scheduleRemoval(this.currentMessage);
    }
  }

  pause() {
    if (!this.waitForHideCompleted) {
      clearTimeout(this.removeTimer);
      clearTimeout(this.showNextTimer);
      this.removeTimer = null;
      this.showNextTimer = null;
    }
  }

  scheduleRemoval(message: SnackbarProps, delay: number = Timeouts.popupTimeoutMs) {
    clearTimeout(this.removeTimer);
    clearTimeout(this.showNextTimer);
    this.showNextTimer = null;
    this.removeTimer = setTimeout(() => {
      this.removeTimer = null;
      this.messageKey++;
      this.messages = this.messages.filter((m) => m !== message);
      this.waitForHideCompleted = true;
      this.notify();
      this.showNextTimer = setTimeout(() => {
        this.waitForHideCompleted = false;
        this.notify();
      }, NEXT_MESSAGE_DELAY_MS);
    }, delay);
  }

  notify() {
    this.subscriber();
  }

  subscribe(subscriber: () => void) {
    this.subscriber = subscriber;
  }

  unsubscribe() {
    this.subscriber = null;
  }

  subscriber: () => void = () => {};
}

export const state = new MessageState();

const domId = '__snackbar__';
const componentName = 'Snackbar';

export class Snackbar extends React.Component<{}, {}> {
  /**
   * Shows a momentary message that slides in and disappears after a few seconds.
   * In addition to the message, a link can be shown to allow the user to react to the message, e.g. to undo a deletion.
   *
   */

  context: ContextType<typeof StylingContext>;

  currentMessage: SnackbarProps = null;

  componentDidMount(): void {
    state.subscribe(() => this.forceUpdate());
  }

  componentWillUnmount(): void {
    state.unsubscribe();
  }

  renderComponent(message: SnackbarProps, trackMouseEnterLeave = true) {
    let className = styles['component'] + ' ' + getPaletteClassName({ name: 'palette-dark' });
    if (message?.hasOwnProperty('size')) {
      className += ` ${styles[message.size]}`;
    }

    return (
      <div
        className={className}
        key={state.messageKey}
        data-key={`Snackbar-${state.messageKey}`}
        onMouseEnter={trackMouseEnterLeave ? this.onMouseEnter : undefined}
        onMouseLeave={trackMouseEnterLeave ? this.onMouseLeave : null}
      >
        <div className={styles['text']} title={message.message} {...getDataAttributes(message)}>
          {message.message}
        </div>
        {this.renderAction(message)}
      </div>
    );
  }

  renderAction(message: SnackbarProps) {
    const currentAction = message && message.action;
    /**
     * Screen readers will read form elements even if they are inside of an aria-hidden region.
     * We use ButtonTemplate to suppress screen readers here as you realistically can't keyboard navigate to the action button anyway due to tab flow and time limitations.
     */
    if (currentAction) {
      const size = (message.hasOwnProperty('size') && message.size) === 'large' ? 'medium' : 'small';
      return (
        <div className={styles['action']} aria-hidden={true}>
          <CommandButton type="cosmetic" size={size} label={currentAction.actionText} onClick={(e) => this.handleActionClick(e, message)} />
        </div>
      );
    }
  }

  handleActionClick = (e: React.MouseEvent<any>, message: SnackbarProps) => {
    message.action.onClick(e);
    state.scheduleRemoval(message, 1); // immediate removal
  };

  onMouseEnter = () => {
    state.pause();
  };

  onMouseLeave = () => {
    state.resume();
  };

  renderBar() {
    const { currentMessage } = state;
    let component = null;
    if (currentMessage) {
      component = this.renderComponent(state.currentMessage);
      state.onCurrentMessageRendered();
      this.currentMessage = currentMessage;
    }
    let className = styles['container'];
    if (currentMessage?.hasOwnProperty('size')) {
      className += ` ${styles[currentMessage.size]}`;
    }

    /* Note: here we use the memoized this.currentMessage instead of currentMessage */
    if (this.currentMessage?.hasOwnProperty('position')) {
      className += ` ${styles[this.currentMessage.position]}`;
    } else {
      if (this.context.styling === 'classic') {
        className += ` ${styles['bottomCenter']}`;
      }
    }

    return (
      <div className={className} aria-live="polite" aria-atomic={true}>
        <Transition tag="div" transition="drop-in-out-reverse" markAsPresentation={true}>
          {component}
        </Transition>
      </div>
    );
  }

  render() {
    let element = document.getElementById(domId);
    if (!element) {
      element = document.createElement('div');
      element.setAttribute('id', domId);
      element.setAttribute('class', styles['portal']);
      document.body.appendChild(element);
    }
    element.setAttribute('data-component', componentName);
    const additionalAttributes = { ...getDataAttributes(this.props) };
    Object.keys(additionalAttributes).map((attribute) => {
      element.setAttribute(attribute, additionalAttributes[attribute]);
    });

    return ReactDOM.createPortal(this.renderBar(), element);
  }
}

Snackbar['displayName'] = componentName;
Snackbar.contextType = StylingContext;
