import * as React from 'react';
import styles from './tree-selection-selected-bar.module.scss';
import { SelectItem } from '../../models/select-item';
import { Dropdown } from '../dropdown/dropdown';
import { CommandButton } from '../command-button/command-button';
import { getDataAttributes } from '../../util/attribute-util';

export interface TreeSelectionSelectedBarProperties {
  onRemoveSelected?: () => any;
  onRemoveAll?: () => any;
  removeSelectedEnabled?: boolean;
  removeSelectedLabel?: string;
  removeAllLabel?: string;
  removeAllEnabled?: boolean;
  /** enables you to populate a dropdown through which the user can select how the items should be interpreted (use value added, exclude values added)
   *
   */
  selectionTypes?: SelectItem[];
  selectedSelectionType?: SelectItem;
  onSelectionTypeChanged?: (type: SelectItem) => any;
}

const componentName = 'TreeSelectionSelectedBar';

export class TreeSelectionSelectedBar extends React.Component<TreeSelectionSelectedBarProperties, {}> {
  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.selectionTypes && (
          <Dropdown
            onItemSelected={this.props.onSelectionTypeChanged}
            selectedItem={this.props.selectedSelectionType}
            items={this.props.selectionTypes}
          />
        )}
        <CommandButton
          type="button"
          label={this.props.removeSelectedLabel}
          icon="trash"
          size="small"
          disabled={!this.props.removeSelectedEnabled}
          onClick={() => {
            this.props.onRemoveSelected && this.props.onRemoveSelected();
          }}
        />
        <CommandButton
          type="button"
          label={this.props.removeAllLabel}
          icon="trash"
          size="small"
          disabled={!this.props.removeAllEnabled}
          onClick={() => {
            this.props.onRemoveAll && this.props.onRemoveAll();
          }}
        />
      </div>
    );
  }
}

TreeSelectionSelectedBar['displayName'] = componentName;
