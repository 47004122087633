import classnames from 'classnames';
import * as styles from './use-negative-margin-left.css';
import { RequiredResponsiveValue } from '../../vanilla-extract/atoms/sprinkles.css';
import { resolveResponsiveProp } from '../../util/resolve-responsive-prop';

type NegativeMarginLeft = Extract<
  Extract<keyof typeof styles.mobile, keyof typeof styles.tablet>,
  Extract<keyof typeof styles.mobile, keyof typeof styles.desktop>
>;

export const useNegativeMarginLeft = (space: RequiredResponsiveValue<any>) =>
  classnames(resolveResponsiveProp(space, styles.mobile, styles.tablet, styles.desktop));
