import * as React from 'react';
import { ContextType } from 'react';
import styles from './pagination.module.scss';
import { Label } from '../label/label';
import { NumberInput } from '../number-input/number-input';
import { CommandButtonProps, SelectableCommandButton } from '../command-button/command-button';
import { IconButton, IconButtonProps } from '../icon-button/icon-button';
import { Inline } from '../inline/inline';
import { Dropdown } from '../dropdown/dropdown';
import { SelectItem, SelectItemBuilder } from '../../models/select-item';
import { Box } from '../box/box';
import StylingContext from '../../style-context/styling-context-provider';
import { getDataAttributes } from '../../util/attribute-util';

export type PaginationType = 'normal' | 'compact' | 'dense';
export type PaginationPosition = 'left' | 'center' | 'right';

export interface PaginationProps {
  /**
   * The total amount of rows that exist through all pages;
   */
  pageItemCount: number;
  /**
   * The index + 1 signifying the page;
   * We add (+1) for better user perception;
   */
  currentPage: number;
  /**
   * The amount of rows we want to render per page;
   */
  pageSize: number;
  /**
   * Optionally render a dropdown that controls the amount of
   * items per page;
   *
   * Options: 5, 10, 20, 50 items;
   *
   * @default false
   */
  showRowsPerPageDropdown?: boolean;
  /**
   * Optionally render the rows range that are currently in view;
   *
   * e.g Viewing rows 21 - 40
   */
  showPagerItems: boolean;
  /**
   * Optionally render an input field of type number that can manually
   * change the current page index;
   */
  canSkipToPage: boolean;
  /**
   * If showPagerItems is enabled then the below functions need to be implemented;
   * The functions control the text that is being rendered.
   */
  pagerItemLabelPlural?: (min: number, max: number) => string;
  pagerItemLabel?: (min: number, max: number) => string;

  rowsPerPageLabel?: string;
  previousLabel: string;
  toStartLabel: string;
  toEndLabel: string;
  nextLabel: string;
  /**
   * Optionally render a spread of number that a user can click to change
   * the currentPage;
   *
   * @default false
   */
  showSpreadItems?: boolean;
  /**
   * If showSpreadItems is enabled then we can control the amount of
   * pages that can be seen.
   *
   * @default 5
   */
  spreadSize?: number;

  pagerSkipToPageAmountLabel: (pageAmount: number) => string;
  pagerSkipToPageLabel: string;
  pagerLabel: (currentPage: number, pageAmount: number) => string;

  position?: PaginationPosition;
  type?: PaginationType;

  onRowsPerPageChanged?: (rowsPerPage: SelectItem) => any;
  onPageChange: (newPage: number) => any;

  /**
   * (Optional): Used to hide the go to first page button
   * defaults to false
   */
  hideGoToFirstPage?: boolean;
  /**
   * (Optional): Used to hide the go to last page button
   * defaults to false
   */
  hideGoToLastPage?: boolean;

  children: React.ReactNode;
}

const componentName = 'Pagination';

export class Pagination extends React.PureComponent<PaginationProps> {
  static defaultProps: Partial<PaginationProps> = {
    showRowsPerPageDropdown: false,
    onRowsPerPageChanged: null,
    pagerItemLabelPlural: null,
    rowsPerPageLabel: null,
    showSpreadItems: false,
    pagerItemLabel: null,
    spreadSize: 5,
    position: 'center',
    type: 'normal',
    hideGoToFirstPage: false,
    hideGoToLastPage: false,
  };
  rowsPerPageDropdownItems = [
    new SelectItemBuilder('5', 5).build(),
    new SelectItemBuilder('10', 10).build(),
    new SelectItemBuilder('20', 20).build(),
    new SelectItemBuilder('50', 50).build(),
  ];
  context: ContextType<typeof StylingContext>;

  get updatePagerStart(): number {
    return this.props.currentPage * this.props.pageSize + 1;
  }

  get updatePagerEnd(): number {
    return Math.min(this.props.currentPage * this.props.pageSize + this.props.pageSize, this.props.pageItemCount);
  }

  get currentPageCount(): number {
    return this.props.currentPage === this.pageAmount ? this.pageAmount : this.props.currentPage + 1;
  }

  get pageAmount(): number {
    return Math.ceil(this.props.pageItemCount / this.props.pageSize);
  }

  get inputWidthCalc(): number {
    let width = 40;

    if (this.currentPageCount.toString().length <= 2) {
      return width;
    }

    const length = this.currentPageCount.toString().length <= 10 ? this.currentPageCount.toString().length : 10;

    for (let i = 0; i < length; ++i) {
      width += 10;
    }

    return width - 20;
  }

  getPageSizeSelectItem = (pageSize = this.props.pageSize): SelectItem =>
    this.rowsPerPageDropdownItems.find((x) => x.value === pageSize) ?? this.rowsPerPageDropdownItems[2];

  handleRowNumberChange = (newValue: SelectItem) => {
    this.props.onRowsPerPageChanged(newValue);
  };

  handleFirstPage = () => {
    this.handlePageChange(0);
  };

  handlePrevPage = () => {
    this.handlePageChange(this.props.currentPage - 1);
  };

  handleNextPage = () => {
    this.handlePageChange(this.props.currentPage + 1);
  };

  handleLastPage = () => {
    this.handlePageChange(this.pageAmount - 1);
  };

  handlePageChange = (page: number) => {
    this.props.onPageChange(page);
  };

  renderRowCountDropdown = (): React.ReactElement => {
    if (!this.props.showRowsPerPageDropdown) {
      return null;
    }

    return (
      <div className={styles['rows-per-page']}>
        <Inline wrap={false}>
          <Label text={this.props.rowsPerPageLabel} type="small" maxLines={1} />
          <Dropdown
            onItemSelected={this.handleRowNumberChange}
            selectedItem={this.getPageSizeSelectItem()}
            items={this.rowsPerPageDropdownItems}
          />
        </Inline>
      </div>
    );
  };

  renderButtonType = (type: 'start' | 'left' | 'right' | 'end'): React.ReactElement => {
    const buttonClassName = [styles[`navigate-${type}`]];

    let props: CommandButtonProps = {
      onClick: null,
      label: '',
      size: 'small',
      type: 'button',
    };

    let typeVisibility = false;

    switch (type) {
      case 'start':
        typeVisibility = this.currentPageCount <= 1;
        typeVisibility && buttonClassName.push(styles['hidden']);
        props = {
          ...props,
          navigationType: 'to-start',
          disabled: typeVisibility,
          onClick: this.handleFirstPage,
          label: this.props.toStartLabel,
          aria: {
            'aria-hidden': typeVisibility,
          },
        };
        break;

      case 'left':
        typeVisibility = this.currentPageCount <= 1;
        typeVisibility && buttonClassName.push(styles['hidden']);
        props = {
          ...props,
          navigationType: 'navigate-left',
          disabled: typeVisibility,
          onClick: this.handlePrevPage,
          label: this.props.previousLabel,
          aria: {
            'aria-hidden': typeVisibility,
          },
        };
        break;

      case 'right':
        typeVisibility = this.currentPageCount === this.pageAmount;
        typeVisibility && buttonClassName.push(styles['hidden']);
        props = {
          ...props,
          navigationType: 'navigate-right',
          disabled: typeVisibility,
          onClick: this.handleNextPage,
          label: this.props.nextLabel,
          aria: {
            'aria-hidden': typeVisibility,
          },
        };
        break;

      case 'end':
      default:
        typeVisibility = this.currentPageCount === this.pageAmount;
        typeVisibility && buttonClassName.push(styles['hidden']);
        props = {
          ...props,
          navigationType: 'to-end',
          disabled: typeVisibility,
          onClick: this.handleLastPage,
          label: this.props.toEndLabel,
          aria: {
            'aria-hidden': typeVisibility,
          },
        };
        break;
    }

    let button: React.ReactElement = <SelectableCommandButton {...props} />;

    if (this.props.type !== 'normal') {
      const { navigationType, label, aria, ...rest } = props;

      const iconAria: IconButtonProps['aria'] = {
        ...aria,
        'aria-label': label,
      };

      button = <IconButton {...rest} icon={navigationType} aria={iconAria} />;
    }

    return <div className={buttonClassName.join(' ')}>{button}</div>;
  };

  renderPager = (): React.ReactElement => {
    let content: React.ReactElement = null;

    if (this.props.canSkipToPage) {
      const pagerSkipToPageAmountString = this.props.pagerSkipToPageAmountLabel(this.pageAmount);

      content = (
        <span className={styles['skip-to-page']}>
          <Label text={this.props.pagerSkipToPageLabel} type="small" maxLines={1} />
          <NumberInput
            min={1}
            max={this.pageAmount}
            value={this.currentPageCount}
            maxWidth={this.inputWidthCalc}
            onChange={(newValue: number) => this.handlePageChange(newValue - 1)}
            aria={{ 'aria-label': this.props.pagerSkipToPageLabel }}
          />
          <Label text={pagerSkipToPageAmountString} type="small" maxLines={1} />
        </span>
      );
    } else if (this.props.showSpreadItems) {
      const totalItems = [];
      /**
       * We need to make sure that the first & last two numbers
       * do not shift the range of values;
       */
      let start = this.currentPageCount < 3 ? 1 : this.currentPageCount - 2;

      if (this.currentPageCount + 2 >= this.pageAmount) {
        start = this.pageAmount - 4;

        if (start <= 0) {
          start = 1;
        }
      }
      /**
       * Only render the page numbers that are relevant;
       */
      for (let i = start, count = 0; i <= this.pageAmount && count < this.props.spreadSize; i++, count++) {
        const commandProps: CommandButtonProps = {
          size: 'small',
          type: 'button',
          label: i.toString(),
          onClick: () => this.handlePageChange(i - 1),
        };

        totalItems.push(<SelectableCommandButton key={i} {...commandProps} selected={i === this.currentPageCount} />);
      }

      content = <div className={styles['skip-to-page']}>{totalItems}</div>;
    } else {
      const pagerString = this.props.pagerLabel(this.currentPageCount, this.pageAmount);

      content = <Label text={pagerString} type="small" maxLines={1} />;
    }

    return <div className={styles['pager']}>{content}</div>;
  };

  renderPagerItems = (): React.ReactElement => {
    if (!this.props.showPagerItems) {
      return null;
    }

    const showPagerItemsString =
      this.props.pageItemCount < 2
        ? this.props.pagerItemLabel(this.updatePagerStart, this.updatePagerEnd)
        : this.props.pagerItemLabelPlural(this.updatePagerStart, this.updatePagerEnd);

    return (
      <div className={styles['pager-info']}>
        <Label text={showPagerItemsString} type="small" maxLines={1} />
      </div>
    );
  };

  render() {
    const { styling } = this.context;
    const componentClassNames = [styles['component'], styles[this.props.type], styles[styling]].join(' ');
    const contentClassNames = [styles['content'], styles[this.props.position]];
    this.props.type === 'dense' && contentClassNames.push(styles['dense-styles']);

    return (
      <div className={componentClassNames} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.renderRowCountDropdown()}
        <Box className={contentClassNames.join(' ')} paddingX={'16dp'}>
          {!this.props.hideGoToFirstPage && this.renderButtonType('start')}
          {this.renderButtonType('left')}
          {this.renderPager()}
          {this.renderButtonType('right')}
          {!this.props.hideGoToLastPage && this.renderButtonType('end')}
        </Box>
        {this.renderPagerItems()}
      </div>
    );
  }
}

Pagination['displayName'] = componentName;
Pagination.contextType = StylingContext;
