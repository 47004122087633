import * as React from 'react';
import styles from './split-view.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface SplitViewProps {
  leftContent: React.ReactElement<any> | string;
  rightContent: React.ReactElement<any> | string;
}

const componentName = 'SplitView';

export class SplitView extends React.Component<SplitViewProps, {}> {
  constructor(properties: SplitViewProps) {
    super(properties);
  }

  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        <div className={styles['leftMostPanel']}>{this.props.leftContent}</div>
        <div className={styles['rightMostPanel']}>{this.props.rightContent}</div>
      </div>
    );
  }
}

SplitView['displayName'] = componentName;
