/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/ban-types */
import { StoreClass } from './store-context';
export const __createStores__: string = '__createStores__';
export const __props__: string = '__props__';

export interface StoreToCreate {
  storeClass: StoreClass<any>;
  idFunction?: (props) => string;
}

/**
 * Decorator for creating a store that is available as a property in a component.
 * The store is made available for all descendant components
 * @param id optional arrow function which produces an id for the store based on the current component props
 */
export function createStore<P>(id?: (props: P) => string) {
  return (target: Object, key: string | symbol, baseDescriptor?: PropertyDescriptor) => {
    let storesToCreate = target[__createStores__];
    if (storesToCreate === undefined) {
      storesToCreate = {};
      target[__createStores__] = storesToCreate;
    }
    const storeClass = (Reflect as any).getMetadata('design:type', target, key);
    storesToCreate[key] = {
      storeClass: storeClass,
      idFunction: id,
    } as StoreToCreate;
    defineStoreProperty(target, key, id);
  };
}

/**
 * Decorator for autowiring a store into a component, optionally with a specific id.
 * The store is expected to have been created in a parent component (see @createStore and StoreContextCreator component
 * @param id optional arrow function which produces an id for the store based on the current component props
 */
export function getStore<P>(id?: (props: P) => string) {
  return (target: Object, key: string | symbol, baseDescriptor?: PropertyDescriptor) => {
    defineStoreProperty(target, key, id);
  };
}

/**
 * Creates a property on a target component that returns the requested store
 * @param target target component instance
 * @param key the property name
 * @param id optional arrow function that returns the id to assign to the store
 */
function defineStoreProperty<P>(target, key, id?: (props: P) => string) {
  const storeClass = (Reflect as any).getMetadata('design:type', target, key);
  Object.defineProperty(target, key, {
    enumerable: true,
    get() {
      // 'this' represents the property owner instance inside the getter, e.g. a component instance
      const storeId = id ? id(this.props) : undefined;
      const store = this.context.storeContext.get(storeClass, storeId, false);
      return store;
    },
  });
}
