import * as React from 'react';
import { ReactElement, ReactNode } from 'react';
import styles from './display-table-cell.module.scss';
import { AriaGridcellInterface } from '../AccessibilityProps';
import { ARIA_COLINDEX, ARIA_COLSPAN, ARIA_ROWINDEX, ARIA_ROWSPAN, COLUMNHEADER, GRIDCELL } from '../../accessibility/aria-attributes';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { DisplayCellArcheTypes } from '../display-table/display-table';

export type DisplayCellTypes = 'cell' | 'header' | 'splitheader' | 'footer';

export type DisplayTableCellRender = (
  classNames: string[],
  attributes: React.HTMLAttributes<HTMLDivElement>,
  children: React.ReactNode
) => ReactNode;

/**
 *
 * column and row should be input 0-index, but will be converted because of the CSS Grid implementation
 * column (https://developer.mozilla.org/en-US/docs/Web/CSS/grid-column) and row (https://developer.mozilla.org/en-US/docs/Web/CSS/grid-row)
 *
 */
export interface CellCoordinates {
  colStart: number;
  colEnd?: number;
  rowStart: number;
  rowEnd?: number;
}

export interface DisplayTableCellProps extends CellCoordinates {
  id: string;
  domId?: (id: string) => string;
  isSelected?: boolean;
  elemTitleAttr?: string;
  children: React.ReactNode;
  aria?: AriaGridcellInterface;
  cellType?: DisplayCellTypes;
  /**
   * archeType corresponds to the "Schema.ReportFunctionPresentationArchetype"
   * controls alignment of cell content
   *
   * TODO: this is used for calculating the mim-width and is the wrong way to go.
   * See https://elsevier.atlassian.net/browse/GPHN-63
   * Also Arche type is a Pure concept and has no place in Graphene.
   */
  archeType?: DisplayCellArcheTypes;
  /**
   * applies styling to remove border in first column
   */
  isFirst?: boolean;
  /**
   * applies styling to remove border in last column
   */
  isLast?: boolean;
  /**
   * applies border styling
   */
  hasBorder?: boolean;

  cellRender?: DisplayTableCellRender;

  /**
   * Optional prop that applies a different styling to the table. Default is classic.
   */
  styling?: 'classic' | 'list';
}

const componentName = 'DisplayTableCell';
export class DisplayTableCell extends React.Component<DisplayTableCellProps, any> {
  static defaultProps = {
    hasBorder: true,
    style: 'classic',
    cellRender: defaultCellRender,
    domId: (id) => {
      return IdEncoderUtil.encodeId('DisplayTableCell', id);
    },
  };

  get aria() {
    return {
      ...this.props.aria,
      [ARIA_ROWINDEX]: this.setRowIndex(),
      [ARIA_COLINDEX]: this.setColIndex(),
      ...(this.props.colEnd &&
        this.props.colStart !== this.props.colEnd && { [ARIA_COLSPAN]: this.props.colEnd - this.props.colStart + 1 }),
      ...(this.props.rowEnd &&
        this.props.rowStart !== this.props.rowEnd && { [ARIA_ROWSPAN]: this.props.rowEnd - this.props.rowStart + 1 }),
    };
  }

  get componentStyles(): React.CSSProperties {
    return {
      gridColumnStart: this.setColIndex(),
      gridColumnEnd: this.props.colEnd ? this.props.colEnd + 2 : this.props.colStart + 2,
      gridRowStart: this.setRowIndex(),
      gridRowEnd: this.props.rowEnd ? this.props.rowEnd + 2 : this.props.rowStart + 2,
    };
  }

  get attributes() {
    return {
      ...this.aria,
      id: this.props.domId(this.props.id),
      tabIndex: this.props.isSelected ? 0 : -1,
      role: this.props.cellType === 'header' ? COLUMNHEADER : GRIDCELL,
      style: this.componentStyles,
      title: this.props.elemTitleAttr,
    };
  }

  setColIndex() {
    return this.props.colStart + 1;
  }

  setRowIndex() {
    return this.props.rowStart + 1;
  }

  render() {
    const componentClassNames = [styles['component'], styles[this.props.cellType]];

    if (this.props.styling === 'list') {
      componentClassNames.push(styles['list']);
    } else {
      componentClassNames.push(styles['classic']);
    }

    if (this.props.archeType) {
      componentClassNames.push(styles[this.props.archeType.toLowerCase()]);
    }

    if (this.props.isFirst) {
      componentClassNames.push(styles['isFirst']);
    }
    if (this.props.isLast) {
      componentClassNames.push(styles['isLast']);
    }
    if (this.props.hasBorder) {
      componentClassNames.push(styles['hasBorder']);
    }

    return this.props.cellRender(componentClassNames, this.attributes, this.props.children);
  }
}

function defaultCellRender(
  classNames: string[],
  attributes: React.HTMLAttributes<HTMLDivElement>,
  //children: ReactElement<any> | string | { [x: string]: any }
  children: React.ReactNode
) {
  return (
    <div {...attributes} className={classNames.join(' ')} data-component={componentName}>
      {children}
    </div>
  );
}

DisplayTableCell['displayName'] = componentName;
