import * as React from 'react';
import styles from './table.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface TableProps {
  children?: React.ReactNode;
  width?: string;
  styling?: 'classic' | 'list' | 'brand';
}

const componentName = 'Table';
export class Table extends React.Component<TableProps, any> {
  static defaultProps: Partial<TableProps> = {
    styling: 'brand',
  };

  render() {
    const classes = [styles['table']];
    switch (this.props.styling) {
      case 'classic': {
        classes.push(styles['classic']);
        break;
      }
      case 'list': {
        classes.push(styles['list']);
        break;
      }
      case 'brand': {
        classes.push(styles['brand']);
        break;
      }
    }

    return (
      <table
        className={classes.join(' ')}
        data-component={componentName}
        style={{ width: this.props.width }}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </table>
    );
  }
}

Table['displayName'] = componentName;
