import * as React from 'react';
import { StoreContext, StoreClass, StoreContextPropertyName, createReactStoreContext } from './store-context';
import { DefaultStoreContext } from './default-store-context';

export interface StoreContextCreatorProps {
  storeClass: StoreClass<any>;
  storeId?: string;
  children: React.ReactNode;
}

export interface StoreContextCreatorState {
  storeContext: StoreContext;
}

/**
 * Makes a store available on the context of child components that need to access a shared store.
 * The use case is when two or more deeply nested components need to get or modify a shared store.
 */
export class StoreContextCreator extends React.Component<StoreContextCreatorProps, StoreContextCreatorState> {
  constructor(props, context: any) {
    super(props, context);
    this.state = {
      storeContext: new NestedStoreContext(context.storeContext as StoreContext, this.props.storeClass, this.props.storeId),
    };
  }

  static contextTypes = createReactStoreContext();

  static childContextTypes = createReactStoreContext();

  getChildContext(): any {
    return { [StoreContextPropertyName]: this.state.storeContext };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return React.Children.only(this.props.children);
  }
}

export class NestedStoreContext extends DefaultStoreContext {
  parentStoreContext: StoreContext;

  constructor(parentStoreContext: StoreContext, storeClass?: StoreClass<any>, storeId?: string) {
    super();
    this.parentStoreContext = parentStoreContext;
    if (storeClass) {
      super.create(storeClass, storeId);
    }
  }

  contains<T>(storeClass: StoreClass<T>, id?: string): boolean {
    return super.contains(storeClass, id) || this.parentStoreContext.contains(storeClass, id);
  }

  get<T>(storeClass: StoreClass<T>, id?: string): T {
    // only return if this context has the store directly
    if (super.contains(storeClass, id)) {
      return super.get(storeClass, id);
    }
    // otherwise walk up the parent chain
    return this.parentStoreContext.get(storeClass, id);
  }
}
