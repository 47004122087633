import { StartDay } from './date-picker';
import moment from 'moment';
import * as React from 'react';
import { DatePickerLogic } from './date-picker-logic';
import { DatePickerDays } from './date-picker-days';

export interface DatePickerDatesProps {
  focusedCell?: moment.Moment;
  startDay: StartDay;
  startDate: moment.Moment;
  selectedDate?: moment.Moment;
  hasFocus: boolean;
  dayClick: (date: moment.Moment, autoClose: boolean) => void;
}

const componentName = 'DatePickerDates';

export function DatePickerDates(props: React.PropsWithChildren<DatePickerDatesProps>) {
  const days = DatePickerLogic.getPageFor(props.startDay, props.startDate);
  return (
    <>
      <DatePickerDays
        dates={days.previousMonthDates}
        isPrevOrNextDates={true}
        hasFocus={false}
        dayClick={(date) => props.dayClick(date, false)}
      />
      <DatePickerDays
        dates={days.currentMonthDates}
        focusedCell={props.focusedCell}
        selectedDate={props.selectedDate}
        isPrevOrNextDates={false}
        hasFocus={props.hasFocus}
        dayClick={(date) => props.dayClick(date, true)}
      />
      <DatePickerDays
        dates={days.nextMonthDates}
        isPrevOrNextDates={true}
        hasFocus={false}
        dayClick={(date) => props.dayClick(date, false)}
      />
    </>
  );
}

DatePickerDates['displayName'] = componentName;
