import * as React from 'react';
import styles from './spacer.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export type Spacing = 'contentOfPage' | 'component' | 'element' | 'halfElement' | 'none';

export interface SpacerProps {
  spacing: Spacing;
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const componentName = 'Spacer';

export class Spacer extends React.Component<SpacerProps, {}> {
  render() {
    const classes = [styles['spacer'], styles[this.props.spacing]];
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div className={classes.join(' ')} style={this.props.style} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </div>
    );
  }
}

Spacer['displayName'] = componentName;
