import * as React from 'react';
import { TabPrivateProps } from './tab';
import { getDataAttributes } from '../../util/attribute-util';
import styles from './tabs.module.scss';
import { S_M_L_Size } from '../../components/t-shirt-sizes';
import { useId } from '../../hooks/use-id';

export type Orientation = 'horizontalTop' | 'horizontalBottom' | 'verticalLeft' | 'verticalRight';

export interface TabsProps {
  id?: string;
  orientation?: Orientation;
  leftRender?: React.ReactElement;
  rightRender?: React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  onSelected?: (id: string, index: number) => void;
  size?: S_M_L_Size;
}

const componentName = 'Tabs';

export function Tabs({
  id,
  orientation = 'horizontalTop',
  leftRender,
  rightRender,
  size = 'medium',
  onSelected,
  children,
  ...otherProps
}: TabsProps) {
  const elementId = useId(id);
  const [selected, setSelected] = React.useState<number>(0);
  const tabArray = React.Children.toArray(children)
    .filter((x) => ((x as any)?.type?.displayName ?? '') === 'Tab')
    .map((x) => x as React.ReactElement);
  const ariaOrintation = orientation === 'horizontalBottom' || orientation === 'horizontalTop' ? 'horizontal' : 'vertical';

  return (
    <div
      id={elementId}
      data-testid={componentName}
      className={`${styles.container} ${styles[orientation]}`}
      data-component={componentName}
      {...getDataAttributes(otherProps)}
    >
      <div className={`${styles.tabsAndExtra} ${styles[orientation]}`}>
        {leftRender}
        <div role="tablist" aria-orientation={ariaOrintation} className={`${styles.tabList} ${styles[orientation]}`}>
          {tabArray.map((tab: React.ReactElement, tabIndex: number) => {
            const clonedProps: TabPrivateProps = {
              ...tab.props,
              selected: selected === tabIndex,
              onSelected: () => {
                onSelected?.(tab.props.id, tabIndex);
                setSelected(tabIndex);
              },
              size,
              onMoveSelection: (dir) => {
                const index = rotate(selected + dir, tabArray.length - 1);
                setSelected(index);
                if (!tabArray[index].props.disabled) {
                  onSelected?.(tabArray[index].props.id, index);
                }
              },
              orientation: orientation,
              key: tab.props.id,
            };

            return React.cloneElement(tab, clonedProps);
          })}
        </div>
        {rightRender}
      </div>
      <div className={`${styles.divider} ${styles[orientation]}`} />
      <div role="tabpanel" className={`${styles.tabPanel} ${styles[size]}`}>
        {!tabArray[selected].props.disabled ? tabArray[selected]?.props.children : null}
      </div>
    </div>
  );
}
Tabs.displayName = componentName;

function rotate(newIndex: number, maxIndex: number): number {
  if (newIndex > maxIndex) {
    return 0;
  }
  if (newIndex < 0) {
    return maxIndex;
  }
  return newIndex;
}
