import * as React from 'react';
import { ErrorBoundaryContent, ErrorBoundaryContentLabelProps } from './error-boundary-content/error-boundary-content';
import { serializeError } from 'serialize-error';
import { getDataAttributes } from '../../util/attribute-util';

export interface ErrorBoundaryProps extends ErrorBoundaryContentLabelProps {
  children: any;

  /**
   * callback when an error is catched
   */
  onError?: (error: any, info: React.ErrorInfo, userText: string) => any;
  /**
   * Suppress the render function and instead return an empty span on errors.
   */
  doNotRenderOnError?: boolean;
}

const componentName = 'ErrorBoundary';
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, {}> {
  static metaData: { [customField: string]: string } = {
    userAgent: typeof navigator === 'object' ? navigator.userAgent : 'server',
  };

  state = {
    error: null,
    info: null,
  };

  get errorText() {
    const output = ['---- copy/paste this into the JIRA ticket ----'];

    if (['string', 'number'].some((t) => t === typeof this.state.error)) {
      output.push(this.state.error.toString());
    } else {
      output.push(...this.getObjectAsKeyValueStrings({ Error: this.state.error }));
    }

    if (this.state.info) {
      output.push(...this.getObjectAsKeyValueStrings(this.state.info));
    }

    //some data is temporal, so we'll resolve it at render time
    output.push(...this.getObjectAsKeyValueStrings({ Location: typeof window === 'object' ? window.location.href : 'Server' }));

    output.push(...this.getObjectAsKeyValueStrings(ErrorBoundary.metaData));

    output.push('---- copy/paste this into the JIRA ticket ----');
    return output.join('\n\n');
  }

  getAsString(o: any) {
    let plainObject = o;
    if (o instanceof Error) {
      // JSON.stringify of Errors simply returns '{}', so we need to handle it explicitly here

      // From the document of JSON.stringify(),
      //  - For all the other Object instances (including Map, Set, WeakMap and WeakSet), only their enumerable properties will be serialized.
      //  - and Error object doesn't have its enumerable properties, that's why it prints an empty object.

      // Serialize an error into a plain object
      plainObject = serializeError(o);
    }

    return JSON.stringify(plainObject, null, 2)
      .replace(/(\\)?\\n/g, ($0, $1) => ($1 ? $0 : '\n')) // equivalent to /(?<!\\)\\n/g (replace \n, but not \\n) for pre-2018 ECMAScript
      .replace(/\\t/g, '  '); // tabs replaced with spaces
  }

  getObjectAsKeyValueStrings(o: object) {
    return Object.getOwnPropertyNames(o).map((n) => `${n}: ${this.getAsString(o[n])}`);
  }

  render() {
    if (this.state.error) {
      if (this.props.doNotRenderOnError) {
        return <span />;
      }
      return (
        <ErrorBoundaryContent
          onDismiss={this.reset}
          showDismiss={true}
          errorBoundaryHeader={this.props.errorBoundaryHeader}
          content={this.errorText}
          errorBoundaryAccordionHeader={this.props.errorBoundaryAccordionHeader}
          errorBoundaryCloseLabel={this.props.errorBoundaryCloseLabel}
          errorBoundaryCopyLabel={this.props.errorBoundaryCopyLabel}
          data-component={componentName}
          {...getDataAttributes(this.props)}
        />
      );
    }
    return this.props.children || <span />;
  }

  componentDidCatch(error: { message: string; stack: string } | any, info) {
    this.setState(
      {
        error,
        info,
      },
      () => {
        this.props.onError && this.props.onError(error, info, this.errorText);
      }
    );
  }

  reset = () => {
    this.setState({
      error: null,
      info: null,
    });
  };
}

ErrorBoundary['displayName'] = componentName;
