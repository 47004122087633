import { IconName } from '../icon/icon-SVGs';
import { AriaRoletypeInterface } from '../AccessibilityProps';

export const __ButtonInterfaces__ = '';

export type NavType = Extract<IconName, 'to-start' | 'navigate-left' | 'navigate-right' | 'to-end'>;

export interface ButtonNavigationProps {
  /**
   * Type used for navigational buttons.
   */
  navigationType?: NavType;
}

export interface ButtonIconProps {
  /**
   * Optional icon
   */
  icon?: IconName;
}

export interface ButtonSizeProps {
  /**
   * The size of the button.
   * Defaults to 'large' for primary and secondary buttons, 'small' for command buttons;
   */
  size?: 'large' | 'medium' | 'small';
}

export interface ButtonLabelProps {
  /**
   * The label to show in the button
   */
  label: string;
}

export interface ButtonButtonTypeProps {
  /**
   * Determines if this Component renders as an HTML Button, defaults to 'button' if not provided
   */
  type?: 'button';
  /**
   * Callback for when the button is clicked
   * @param e the event which triggered the callback
   */
  onClick: (e?: React.MouseEvent<any>) => void;
}

export interface ButtonAnchorTypeProps {
  /**
   * Determines if this Component renders as an HTML Anchor
   */
  type?: 'anchor';
  href: string;
  target?: HTMLAnchorElement['target'];
  /**
   * Callback for when the button is clicked
   * @param e the event which triggered the callback
   */
  onClick?: (e?: React.MouseEvent<any>) => void;
}

/**
 * Type used when you want the look of a button but without it being announced as a button. Use with care and only when you have handled screen reader compatibility explicitly
 */
export interface ButtonCosmeticTypeProps {
  type?: 'cosmetic';
  /**
   * Callback for when the button is clicked
   * @param e the event which triggered the callback
   */
  onClick?: (e?: React.MouseEvent<any>) => void;
  role?: 'treeitem' | string;
}

export type ButtonTypeProps = ButtonButtonTypeProps | ButtonAnchorTypeProps | ButtonCosmeticTypeProps;

export interface ButtonBaseProps {
  id?: string;

  /**
   * Whether to disable the button
   */
  disabled?: boolean;

  /**
   * Aria properties available on any role
   */
  aria?: AriaRoletypeInterface;

  hasFocus?: boolean;
}
