import * as React from 'react';
import { getPaletteClassName } from '../../util/theme-util';
import styles from './loading-progress-bar.module.scss';
import NProgress from 'nprogress';

const progressParentId = '__LoadingProgressBar__';

NProgress.configure({
  parent: '#' + progressParentId,
});

/**
 * React wrapper for interacting with NProgress
 */
export class LoadingProgressBar extends React.Component<{}, {}> {
  private invocations = 0;

  render() {
    // NProgress adds itself to the DOM
    return null;
  }

  start(error?: Error) {
    this.ensureParentCreated();
    if (error) {
      // error occurred, so mark progress as done
      this.done();
      console.error(error);
      return;
    }
    if (this.invocations === 0) {
      NProgress.start();
      document.body.dataset['loading'] = 'true';
    }
    this.invocations++;
  }

  done() {
    this.ensureParentCreated();
    if (this.invocations > 0) {
      // re-loading the current route doesn't invoke start
      this.invocations--;
    }
    if (this.invocations === 0) {
      NProgress.done();
      delete document.body.dataset['loading'];
    }
  }

  isLoading(): boolean {
    return document.body.dataset['loading'] === 'true';
  }

  ensureParentCreated() {
    let progressParent = document.getElementById(progressParentId);
    if (!progressParent) {
      progressParent = document.createElement('div');
      progressParent.setAttribute('id', progressParentId);
      const allClassNames = [styles.component].concat(getPaletteClassName({ name: 'palette-light' }).split(' '));
      for (const className of allClassNames.filter((x) => x.trim() !== '')) {
        try {
          progressParent.classList.add(className);
        } catch (e) {
          console.error(`Problem adding class (${className}) to progressParent`, e);
        }
      }
      document.body.appendChild(progressParent);
    }
  }

  componentWillUnmount() {
    NProgress.done();
    const progressParent = document.getElementById(progressParentId);
    if (progressParent) {
      progressParent.parentNode.removeChild(progressParent);
    }
  }
}
