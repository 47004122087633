import * as React from 'react';
import styles from './column-panel.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export type ColumnPanelLayout = 'page-layout' | 'none';

export interface ColumnPanelProps {
  /**
   * (Optional): layout type. Defaults to 'page-layout'.
   */
  layout?: ColumnPanelLayout;

  /**
   * (Optional): Determines if this component renders as an ordered or unordered list.
   */
  isOrderedList?: boolean;

  /**
   * (Optional): Should the items wrap or not
   */
  shouldRowsWrap?: boolean;

  children?: React.ReactNode;
}

const componentName = 'ColumnPanel';

export class ColumnPanel extends React.Component<ColumnPanelProps, {}> {
  static defaultProps: ColumnPanelProps = {
    layout: 'page-layout',
    shouldRowsWrap: true,
  };

  constructor(properties: ColumnPanelProps) {
    super(properties);
  }

  render() {
    const Tag = this.props.isOrderedList ? 'ol' : 'ul';
    const className = [styles[this.props.layout], styles[this.props.shouldRowsWrap ? 'row-wrap' : 'no-wrap'], styles['component']].join(
      ' '
    );
    return (
      <Tag className={className} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </Tag>
    );
  }
}

ColumnPanel['displayName'] = componentName;
