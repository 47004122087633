import * as React from 'react';
import * as styles from './inline-text-link.css';
import { getDataAttributes } from '../../util/attribute-util';
import { KeyCodes } from '../../client-shared';

export type InlineTextLinkProps = Omit<React.AnchorHTMLAttributes<any>, 'className' | 'style'>;

const componentName = 'InlineTextLink';

export class InlineTextLink extends React.Component<InlineTextLinkProps, {}> {
  anchorElement: HTMLAnchorElement;

  render() {
    const { children, ...props } = this.props;
    return (
      <a
        data-component={componentName}
        ref={(a) => (this.anchorElement = a)}
        {...getDataAttributes(this.props)}
        {...props}
        className={styles.component}
        tabIndex={0}
        onKeyDown={this.handleKeyDown}
      >
        {children}
      </a>
    );
  }

  handleKeyDown = (e: React.KeyboardEvent<any>) => {
    switch (e.keyCode) {
      case KeyCodes.DOM_VK_ENTER:
      case KeyCodes.DOM_VK_RETURN:
      case KeyCodes.DOM_VK_SPACE:
        e.stopPropagation();
        e.preventDefault();
        this.anchorElement.click();
        break;
    }
  };
}

InlineTextLink['displayName'] = componentName;
