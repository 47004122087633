import * as React from 'react';
import { ContextType } from 'react';
import styles from './check.module.scss';
import { AriaCheckboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import StylingContext from '../../style-context/styling-context-provider';
import { S_M_L_Size } from '../t-shirt-sizes';

export interface CheckBaseProps {
  size?: S_M_L_Size;
  aria?: AriaCheckboxInterface;
  tabIndex?: number;
}

export interface CheckProps extends CheckBaseProps {
  isSelected?: boolean;
  isDisabled?: boolean;
  onSelectedChanged?: (value: boolean) => void;
}

const componentName = 'Check';
export class Check extends React.PureComponent<CheckProps & { id: string }> {
  static defaultProps: CheckProps = {
    size: 'small',
    aria: {},
    tabIndex: 0,
    /* TODO :: isSelected prop interferes with the styleguide implementation.
     * I'm suspecting mutation since the component does not re-render.
     * It could also be that the onSelectedChanged prop is not implemented correctly.
     */
    // isSelected: false,
    isDisabled: false,
  };
  context: ContextType<typeof StylingContext>;
  private input: HTMLInputElement;

  render() {
    const { styling } = this.context;

    const classNames = [styles['component'], styles[styling]].join(' ');

    return (
      <div className={classNames} data-component={componentName} {...getDataAttributes(this.props)}>
        <input
          id={this.props.id}
          ref={(c) => (this.input = c)}
          type="checkbox"
          checked={this.props.isSelected}
          disabled={this.props.isDisabled}
          onChange={this.onChange}
          tabIndex={this.props.tabIndex}
          className={styles[this.props.size]}
          {...this.props.aria}
          {...getDataAttributes(this.props)}
        />
        {this.renderThemeIcon()}
      </div>
    );
  }

  renderThemeIcon = () => {
    const { styling } = this.context;
    const classNames = [styles['checkbox'], styles[this.props.size]].join(' ');

    return styling === 'brand' ? (
      <div className={classNames} aria-hidden />
    ) : (
      <svg className={classNames} viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g className={styles['check-mark']}>
          <polygon
            transform="translate(6.218236, 7.026067) rotate(-13.000000) translate(-6.218236, -7.026067) "
            points="4.96694166 11.0521338 0.765925646 6.98281398 2.07510558 5.74560582 4.79545493 8.43437013 10.2748987 3 11.6705455 4.39564681"
          />
        </g>
      </svg>
    );
  };

  onChange = (e: React.SyntheticEvent) => {
    const { onSelectedChanged } = this.props;

    if (onSelectedChanged) {
      onSelectedChanged((e.target as HTMLInputElement).checked);
    }
  };

  focus() {
    this.input && this.input.focus();
  }
}

Check.contextType = StylingContext;
Check['displayName'] = componentName;
