import * as React from 'react';
import styles from './checkbox.module.scss';
import { Label } from '../label/label';
import { Icon } from '../../components/icon/icon';
import { IconName } from '../../components/icon/icon-SVGs';
import { getDataAttributes } from '../../util/attribute-util';
import { LangUtil } from '../../client-shared';
import { S_M_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'Checkbox';

export type TCheckboxState = 'checked' | 'unchecked' | 'indeterminate';

export interface CheckboxInternalProps {
  /**
   * Control and set `indeterminate` checkbox state.
   * Instead of a `checkmark`, this is a `dash`, indicating some child checkboxes are checked.
   * This is not an HTML attribute. See [MDN](https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/checkbox#indeterminate_state_checkboxes) for reference
   */
  indeterminate?: boolean;
  /**
   * Checkbox UI sizing, default = 'medium'
   * * small: 16x16px, font 14px
   * * medium: 20x20px, font 16px
   * * large: 24x24px, font 20px
   */
  size?: S_M_L_Size;
  /**
   * Checkbox label (native HTML label)
   */
  label: string;
  /**
   * Display an icon in front of the label
   */
  iconName?: IconName;
  /**
   * For form validation. Sets the checkbox color to 'red'
   */
  hasError?: boolean;
}

type CheckboxNativeProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'style' | 'children' | 'type' | 'size' | 'ref'
>;

type RefType = React.MutableRefObject<HTMLInputElement>;

export interface CheckboxProps extends CheckboxInternalProps, CheckboxNativeProps {}

/**
 * @TODO: export function as utility function from `button.tsx`
 */
const getIconSize = (size: string) => {
  switch (size) {
    case 'small':
      return 16;
    case 'medium':
    default:
      return 20;
    case 'large':
      return 24;
  }
};

export const Checkbox = React.forwardRef((props: CheckboxProps, ref: RefType): JSX.Element => {
  const { value, checked, indeterminate, size = 'medium', label, iconName, onChange, hasError, ...nativeProps } = props;

  const localRef = React.useRef(null);
  const uniqueId = React.useMemo(() => nativeProps.id ?? LangUtil.randomUuid(), [nativeProps.id]);

  /**
   * For handling `indeterminate` state, this checkbox needs a ref.
   * If the user does not pass a ref, we need to still use a ref, the localRef.
   */
  const activeRef = ref ? ref : localRef;

  React.useEffect(() => {
    if (activeRef?.current) {
      if (indeterminate) {
        activeRef.current.checked = false;
        activeRef.current.indeterminate = true;
      } else if (checked) {
        activeRef.current.checked = true;
        activeRef.current.indeterminate = false;
      } else if (!checked) {
        activeRef.current.checked = false;
        activeRef.current.indeterminate = false;
      }
    }
  }, [indeterminate]);

  // override default styling and icon when the checkbox is 'indeterminate'
  const indeterminateClassName = indeterminate ? styles.indeterminate : '';
  const icon = indeterminate ? 'minus' : 'checkmark';

  const errorClassName = hasError ? styles.invalid : '';
  const checkboxClassNames = `${errorClassName} ${indeterminateClassName}`;

  return (
    <div className={`${styles.component} ${styles[size]}`} data-component={componentName} {...getDataAttributes(props)}>
      <input
        ref={activeRef}
        id={uniqueId}
        name={label}
        type={'checkbox'}
        onChange={onChange}
        checked={checked}
        {...nativeProps}
        className={checkboxClassNames}
      />
      {/* with .wrapper, focus ring contains both checkbox and label */}
      <div className={styles.wrapper}>
        <div aria-hidden className={styles.checkbox}>
          <Icon name={icon} size={getIconSize(size)} />
        </div>
        <Label htmlFor={uniqueId} text={label} size={size} wrap={true} iconLeft={iconName ? { name: iconName } : undefined}></Label>
      </div>
    </div>
  );
});

Checkbox.displayName = componentName;
