import * as React from 'react';
import { ButtonBaseProps, ButtonIconProps, ButtonTypeProps } from '../button-base/button-interfaces';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import styles from './floating-button.module.scss';
import { Icon } from '../icon/icon';
import { AriaRoletypeInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';

export interface FloatingButtonBaseProps extends ButtonBaseProps, Required<ButtonIconProps> {
  /**
   * nontextual interface elements must have a screen reader friendly alternative representation
   */
  aria: AriaRoletypeInterface & Required<Pick<AriaRoletypeInterface, 'aria-label'>>;
}

export type FloatingButtonProps = ButtonTypeProps & FloatingButtonBaseProps;

const componentName = 'FloatingButton';

const Content = React.forwardRef<any, FloatingButtonProps & ButtonFocusHandlerChildProps>((props, ref) => {
  const classes = [styles['component']];
  const attributes: React.HTMLAttributes<HTMLElement> = { id: props.id };

  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const content: React.ReactNode = <React.Fragment>{props.icon && <Icon size={16} name={props.icon} />}</React.Fragment>;

  switch (props.type) {
    case 'button': {
      const buttonAttributes: React.HTMLAttributes<HTMLButtonElement> = { ...attributes };
      props.disabled && (buttonAttributes['disabled'] = true);
      return (
        <button
          ref={ref}
          data-component={componentName}
          type="button"
          {...buttonAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.aria['aria-label']}
          {...props.aria}
          {...getDataAttributes(props)}
        >
          {content}
        </button>
      );
    }
    case 'anchor': {
      const anchorAttributes: React.HTMLAttributes<HTMLAnchorElement> = { ...attributes };
      props.disabled && (anchorAttributes['data-disabled'] = true);
      return (
        <a
          ref={ref}
          data-component={componentName}
          target={props.target}
          {...anchorAttributes}
          className={classes.join(' ')}
          title={props.aria['aria-label']}
          {...props.aria}
          {...(props.disabled ? {} : { href: props.href })}
        >
          {content}
        </a>
      );
    }
    case 'cosmetic': {
      const divAttributes: React.HTMLAttributes<HTMLDivElement> = { ...attributes };
      props.disabled && (divAttributes['disabled'] = true);
      const cosmeticAria = { ...props.aria };
      delete cosmeticAria['aria-label'];
      return (
        <div
          ref={ref}
          data-component={componentName}
          {...divAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.aria['aria-label']}
          {...cosmeticAria}
        >
          {content}
        </div>
      );
    }
    default: {
      const exhaustiveCheck: never = props;
      console.log(exhaustiveCheck);
    }
  }
});

export class FloatingButton extends React.Component<FloatingButtonProps, any> {
  static defaultProps: Partial<FloatingButtonProps> = {
    aria: { 'aria-label': '' },
    type: 'button',
  };

  render() {
    return (
      <ButtonFocusHandler
        initialFocus={this.props.hasFocus}
        render={({ ref, hasHover, hasFocus }) => <Content ref={ref} {...this.props} hasFocus={hasFocus} hasHover={hasHover} />}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

FloatingButton['displayName'] = componentName;
