import * as React from 'react';
import styles from './radio-button-group.module.scss';
import { Box } from '../../components/box/box';
import { getDataAttributes } from '../../util/attribute-util';
import { RadioButton, RadioButtonProps } from '../../components-base/radio-button/radio-button';
import { FormElementWrapper, FormElementWrapperProps } from '../../components-base/HOCs/form-elements-wrapper/form-elements-wrapper';
import { S_M_L_XL_Size, S_M_L_Size } from '../../components/t-shirt-sizes';
import { IdEncoderUtil } from '../../util/id-encoder-util';

const componentName = 'RadioButtonGroup';

export interface RadioButtonGroupProps
  extends FormElementWrapperProps,
    Omit<FormElementWrapperProps, 'children' | 'maxCharacters' | 'value'> {
  id?: string;
  /**
   * (Optional): Function which send to client updated list of options
   */
  onChange?: (props: RadioButtonProps[]) => void;
  /**
   * List of checkbox options that need to be render.
   */
  options: RadioButtonProps[];
}

const getSize = (size: S_M_L_XL_Size): { style: S_M_L_XL_Size; radiobuttonSize: S_M_L_Size } => {
  switch (size) {
    case 'small':
      return { style: 'small', radiobuttonSize: 'small' };
    case 'large':
      return { style: 'large', radiobuttonSize: 'medium' };
    case 'xlarge':
      return { style: 'xlarge', radiobuttonSize: 'large' };
    case 'medium':
    default:
      return { style: 'medium', radiobuttonSize: 'medium' };
  }
};

export const RadioButtonGroup = (props: RadioButtonGroupProps): JSX.Element => {
  const { id, size = 'medium', label, options, onChange, infoText, errorText, successText, optionalText } = props;
  const { style, radiobuttonSize } = getSize(size);

  const handleChange = (e) => {
    const id = IdEncoderUtil.decodeId(e.target.id);
    const newArr = options.map((item) => {
      const newItem: RadioButtonProps = { ...item };
      newItem.checked = id === newItem.id && !item.disabled;
      return newItem;
    });
    onChange(newArr);
  };

  const getTabIndex = (rbProps: RadioButtonProps, index: number): number => {
    const noneSelected = !props.options.some((x) => x.checked);
    if (noneSelected && index === 0) return 0;
    return rbProps.checked ? 0 : -1;
  };

  return (
    <div className={`${styles.component} ${styles[style]}`} data-component={componentName} {...getDataAttributes(props)}>
      <FormElementWrapper
        size={style}
        label={label}
        dataId={id}
        infoText={infoText}
        errorText={errorText}
        successText={successText}
        optionalText={optionalText}
        withErrorBorderLeft
      >
        <Box className={styles['radiobutton-group-wrapper']}>
          <fieldset className={styles['radiobutton-group-fieldset']}>
            <legend>{label}</legend>
            {options.map((item, index) => (
              <RadioButton
                id={item.id}
                key={`group-radiobutton-group-${item?.id || index}`}
                size={radiobuttonSize}
                text={item.text}
                checked={item.checked}
                tabIndex={getTabIndex(item, index)}
                disabled={item.disabled}
                onChange={handleChange}
              />
            ))}
          </fieldset>
        </Box>
      </FormElementWrapper>
    </div>
  );
};

RadioButtonGroup.displayName = componentName;
