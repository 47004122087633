import * as React from 'react';
import styles from './text-area.module.scss';
import { AriaTextboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import { FormFieldValueProps } from '../../models/form-field-value-props';
import { KeyCodes, PixelConverterUtil } from '../../client-shared';
import { windowSafe } from '../../util/ssr';

export enum Theme {
  Transparent,
  Default,
}

export interface TextAreaBaseProps {
  /**
   * Optional DOM id to assign to the textarea element, e.g. to enable a field to focus the textarea
   */
  id?: string;

  /**
   * optional role property
   */
  role?: string;

  /**
   * Optional callback for when the textarea blur event has occurred.
   */
  onBlur?: () => any;

  /**
   * Optional callback for when the textarea focus event has occurred.
   */
  onFocus?: () => any;

  /**
   * Optional callback for when
   * @param keyCode
   */
  onNavigationKey?: (keyCode: number) => any;

  /**
   * Optionally sets the textarea autoFocus attribute
   */
  autoFocus?: boolean;

  /**
   * Optional delay to wait before firing onChange in response to textarea
   */
  delay?: number;

  /**
   * Optional placeholder to show in the textarea when its empty
   */
  placeholder?: string;

  /**
   * Optional max-width of the textarea
   */
  maxWidth?: number;

  /**
   * Optional max-length of the textarea
   */
  maxLength?: number;

  /**
   * Optional theme which controls the appearance of the textarea.
   * Defaults to the 'Default' theme.
   */
  theme?: Theme;

  /**
   * Optional custom styles to apply to the textarea
   */
  style?: React.CSSProperties;

  /**
   * Optional DOM id of a button that should be clicked when the user presses enter in the textarea
   */
  submitButtonId?: string;

  /**
   * Ignore data entry
   */
  readOnly?: boolean;

  aria?: AriaTextboxInterface;

  /**
   * Optional if you want to turn off autoComplete or help the browser understand what to autoComplete, e.g. autoComplete="email"
   */
  autoComplete?: string;
}

export interface TextAreaProps extends TextAreaBaseProps, FormFieldValueProps<string> {
  /**
   * Callback for when the text has changed
   * @param newValue
   */
  onChange?: (newValue: string) => void;
}

const navigationKeys = [
  KeyCodes.DOM_VK_UP,
  KeyCodes.DOM_VK_DOWN,
  KeyCodes.DOM_VK_ESCAPE,
  KeyCodes.DOM_VK_RETURN,
  KeyCodes.DOM_VK_ENTER,
  KeyCodes.DOM_VK_TAB,
];

const componentName = 'TextArea';

export class TextArea extends React.Component<TextAreaProps, {}> {
  timeout = 0;
  state = {
    value: this.props.value ?? '',
  };

  input: any;

  constructor(properties) {
    super(properties);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.value !== undefined) {
      this.setState({
        value: newProps.value,
      });
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeout);
  }

  public focus(): void {
    this.input?.focus();
  }

  select() {
    this.input.select();
  }

  setRef = (c: HTMLTextAreaElement) => {
    this.input = c;
  };

  handleOnChange = (e: React.SyntheticEvent<any>) => {
    if (this.props.readOnly) {
      return;
    }
    const newValue = (e.target as HTMLTextAreaElement).value;
    this.setState(
      {
        value: newValue,
      },
      () => {
        clearTimeout(this.timeout);
        if (this.props.delay) {
          this.timeout = windowSafe().setTimeout(() => this.props.onChange?.(newValue), this.props.delay);
        } else {
          this.props.onChange?.(newValue);
        }
      }
    );
  };

  handleOnKeyDown = (e: React.KeyboardEvent<any>) => {
    if (this.props.readOnly) {
      return;
    }
    const keyCode = e.keyCode;
    if (this.props.onNavigationKey && navigationKeys.some((k) => k === keyCode)) {
      this.props.onNavigationKey(keyCode);
      if (keyCode !== KeyCodes.DOM_VK_TAB) {
        e.preventDefault();
      }
    }
    if (this.props.submitButtonId) {
      if (keyCode === KeyCodes.DOM_VK_ENTER || keyCode === KeyCodes.DOM_VK_RETURN) {
        const submitButton = document.getElementById(this.props.submitButtonId);
        if (submitButton) {
          submitButton.click();
        }
      }
    }
  };

  render() {
    const classes = [styles['textarea']];
    classes.push(this.props.theme !== undefined ? styles[Theme[this.props.theme]] : styles['Default']);

    if (this.props.disabled) {
      classes.push(styles['disabled']);
    } else if (this.props.error) {
      classes.push(styles['error']);
    }

    let inlineStyle = {};
    if (this.props.maxWidth) {
      inlineStyle['maxWidth'] = PixelConverterUtil.pxToDp(this.props.maxWidth) + 'px';
    }
    inlineStyle = Object.assign({}, inlineStyle, this.props.style);

    let setMaxLength = {};
    if (this.props.maxLength) {
      setMaxLength = { maxLength: this.props.maxLength };
    }

    const disabled = this.props.disabled ? { disabled: true } : null;

    let role = {};

    if (this.props.role) {
      role = { role: this.props.role };
    }

    return (
      <textarea
        id={this.props.id}
        {...role}
        value={this.state.value}
        placeholder={this.props.placeholder}
        {...disabled}
        ref={this.setRef}
        className={classes.join(' ')}
        style={inlineStyle}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autoComplete}
        onChange={this.handleOnChange}
        onKeyDown={this.handleOnKeyDown}
        {...setMaxLength}
        onFocus={(event) => {
          if (!this.props.onFocus) {
            // Set selection to the end of the text-field when it is selected/focused
            // only if focus isn't otherwise overridden.
            const target = event.target as HTMLTextAreaElement;
            target.selectionStart = target.value.length;
          }
          this.props.onFocus && this.props.onFocus();
        }}
        onBlur={() => {
          this.props.onBlur && this.props.onBlur();
        }}
        {...this.props.aria}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

TextArea['displayName'] = componentName;
