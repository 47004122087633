import * as React from 'react';
import { Highchart, HighchartDataSeries, HighchartTitlesProps } from '../highchart/highchart';
import { getDataAttributes } from '../../util/attribute-util';

export interface PieChartDataPoint {
  label: string;
  value: number;
}

export interface PieChartProps extends HighchartTitlesProps {
  id: string;
  name: string;
  dataPoints: PieChartDataPoint[];
  onClick?: (name: string) => any;
  useLegend?: boolean;
  useToolTip?: boolean;
}

const componentName = 'PieChart';

export class PieChart extends React.Component<PieChartProps, {}> {
  private highchartRef: React.RefObject<Highchart> = React.createRef();

  get dataSeries(): HighchartDataSeries {
    return {
      id: this.props.id,
      name: this.props.name,
      type: 'pie',
      data: this.props.dataPoints.map((p) => {
        return { name: p.label, y: p.value };
      }),
    };
  }

  render() {
    return (
      <Highchart
        type={'pie'}
        ref={this.highchartRef}
        dataSeries={[this.dataSeries]}
        onClick={this.props.onClick}
        useLegend={this.props.useLegend}
        title={this.props.title}
        legendTitle={this.props.legendTitle}
        useToolTip={this.props.useToolTip}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  public reflow(): void {
    this.highchartRef.current.reflow();
  }
}

PieChart['displayName'] = componentName;
