import * as React from 'react';
import styles from './draggable-list.module.scss';
import { Droppable, DroppableProps, DroppableProvided } from 'react-beautiful-dnd';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { getDataAttributes } from '../../util/attribute-util';

export interface DraggableListProps extends Omit<DroppableProps, 'children'> {
  children?: any;
}

const componentName = 'DraggableList';

export class DraggableList extends React.Component<DraggableListProps, any> {
  encodedId = IdEncoderUtil.encodeId('draggableList', this.props.droppableId);

  render() {
    return (
      <Droppable
        key={this.encodedId}
        type={this.props.type}
        direction={this.props.direction}
        droppableId={this.props.droppableId}
        isDropDisabled={this.props.isDropDisabled}
        isCombineEnabled={this.props.isCombineEnabled}
        ignoreContainerClipping={this.props.ignoreContainerClipping}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {(provided: DroppableProvided) => (
          <div className={styles['component']} id={this.encodedId} ref={provided.innerRef} {...provided.droppableProps}>
            {this.props.children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

DraggableList['displayName'] = componentName;
