import moment from 'moment';
import { StartDay } from './date-picker';

export class DatePickerLogic {
  /**
   * This will return all the dates to show on the calendar for
   * a given date.
   *
   * e.g. for 15/08/2020, the calender page should look like this
   * 27 28 29 30 31  1  2
   *  3  4  5  6  7  8  9
   * ...
   * 31  1  2  3  4  5  6
   *
   * We would return:
   * previousMonthDates: [27,28,29,30,1,2]
   * currentMonthDates: [1...31]
   * nextMonthDates: [1,2,3,4,5,6]
   */
  public static getPageFor(startDay: StartDay, date: moment.Moment): ICalendarPage {
    const numberOfDaysInPage = 6 * 7;
    const startOfMonth = date.clone().startOf('month').startOf('day');

    const startOfNextMonth = startOfMonth.clone().add(1, 'month');
    const numOfPreviousMonthDates = this.countDaysBackUntil(startOfMonth, 7, (date) => date.format('dddd').localeCompare(startDay) === 0);
    const startOfPreviousDates = startOfMonth.clone().subtract(numOfPreviousMonthDates, 'days');

    const previousMonthDates: moment.Moment[] = DatePickerLogic.getNextDates(startOfPreviousDates, numOfPreviousMonthDates);
    const currentMonthDates: moment.Moment[] = DatePickerLogic.getNextDates(startOfMonth, startOfMonth.daysInMonth());
    const nextMonthDates: moment.Moment[] = DatePickerLogic.getNextDates(
      startOfNextMonth,
      numberOfDaysInPage - previousMonthDates.length - currentMonthDates.length
    );

    return { previousMonthDates, currentMonthDates, nextMonthDates };
  }

  public static getNextDates(start: moment.Moment, days: number): moment.Moment[] {
    let current = start.clone().startOf('day');
    let result: moment.Moment[] = [];
    for (let i = 0; i < days; i++) {
      result = result.concat(current);
      current = current.clone().add(1, 'day');
    }
    return result;
  }

  public static countDaysBackUntil(startDate: moment.Moment, maxSearch, predicate: (date: moment.Moment) => boolean): number {
    let date = startDate.clone();
    for (let index = 0; index < maxSearch; index++) {
      if (predicate(date)) {
        return index;
      }
      date = date.clone().subtract(1, 'day');
    }
    return -1; // Nothing matched within maxSearch
  }

  /**
   * Given a date and a keycode, then move the date.
   * @param date
   * @param navigation
   *
   * See https://doc.pure.elsevier.com/pages/viewpage.action?pageId=86198999&preview=/86198999/86198979/Modified%20date%20input.png for
   * details on the navigation.
   */
  public static navigateCursor(date: moment.Moment, navigation: Navigation): moment.Moment {
    const cleanDate = date.clone().startOf('day');
    const moveLeft = (unit: Unit) => cleanDate.clone().subtract(1, unit);
    const moveRight = (unit: Unit) => cleanDate.clone().add(1, unit);
    const moveFirstDay = () => cleanDate.clone().startOf('month');
    const moveLastDay = () => cleanDate.clone().endOf('month');

    switch (navigation) {
      case 'nextDay':
        return moveRight('day');
      case 'prevDay':
        return moveLeft('day');

      case 'nextWeek':
        return moveRight('week');
      case 'prevWeek':
        return moveLeft('week');

      case 'nextMonth':
        return moveRight('month').clone().isoWeekday(cleanDate.isoWeekday());
      case 'prevMonth':
        return moveLeft('month').clone().isoWeekday(cleanDate.isoWeekday());

      case 'nextYear':
        return moveRight('year').clone().isoWeekday(cleanDate.isoWeekday());
      case 'prevYear':
        return moveLeft('year').clone().isoWeekday(cleanDate.isoWeekday());

      case 'firstDay':
        return moveFirstDay();
      case 'lastDay':
        return moveLastDay();
    }

    return date;
  }
}

export type Navigation =
  | 'nextDay'
  | 'prevDay'
  | 'nextWeek'
  | 'prevWeek'
  | 'nextMonth'
  | 'prevMonth'
  | 'nextYear'
  | 'prevYear'
  | 'firstDay'
  | 'lastDay';
type Unit = 'day' | 'week' | 'month' | 'year';

export interface ICalendarPage {
  previousMonthDates: moment.Moment[];
  currentMonthDates: moment.Moment[];
  nextMonthDates: moment.Moment[];
}
