import { SelectItem } from '../../models/select-item';
import { PopupLink } from '../popup-link/popup-link';
import { ContentListFilterState } from './use-content-list-filter';
import React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

export interface FilterAddProps {
  state: ContentListFilterState;
  label: string;
}
const componentName = 'FilterAdd';

export const FilterAdd = (props: FilterAddProps, {}) => {
  const onItemSelected = (selectedArray: SelectItem[]) => {
    const [selectedItem] = selectedArray;

    const array = [...props.state.popUpItems];
    const selectItem: SelectItem = props.state.popUpItems.find((item) => selectedItem.value === item.value);
    array.splice(array.indexOf(selectItem), 1); // Remove the selected one from the choice list.
    props.state.setPopUpItems([...array]);
    const itemArray = [...props.state.items];
    const contListFilterItem = props.state.contentListFilterMap.get(selectedItem.value);
    itemArray.map((i) => (i.selected = false));
    itemArray.unshift(contListFilterItem);
    contListFilterItem.selected = true;
    if (props.state.items.includes(contListFilterItem)) {
      return;
    }
    props.state.setSelectedItem({ ...contListFilterItem });
    props.state.setItems([...itemArray]);
  };

  return (
    props.state.popUpItems.length > 0 && (
      <PopupLink
        onItemsSelected={onItemSelected}
        items={props.state.popUpItems}
        label={props.label}
        icon={'plus'}
        showDropDownArrow={true}
        data-component={componentName}
        {...getDataAttributes(props)}
      />
    )
  );
};

FilterAdd.displayName = componentName;
