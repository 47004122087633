import * as React from 'react';
import { FocusEventHandler } from 'react';
import styles from './display-table.module.scss';
import { DisplayTableCellProps } from '../display-table-cell/display-table-cell';
import { COLUMNHEADER, GRID, GRIDCELL, ROLE, ROW } from '../../accessibility/aria-attributes';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { LangUtil } from '../../client-shared';
import { AccessibilityEventHandlers } from '../../accessibility/accessibility-event-handlers';
import { getDataAttributes } from '../../util/attribute-util';

export type DisplayCellArcheTypes = 'NUMBER' | 'STRING' | 'YEAR' | 'ACCORDION';
export type DisplayCellWidthValues = '1/10' | '2/10' | '3/10' | '4/10' | '5/10' | '6/10' | '7/10' | '8/10' | '9/10' | '1'; // 1

export interface DisplayTableProps {
  colCount: number;
  rowCount: number;

  /**
   * This is used to determine what kind of column is being rendered e.g number or string , so width can be calculated
   * See columnWidths getter
   *
   * Type corresponds to the "Schema.ReportFunctionPresentationArchetype"
   */
  colTypes?: DisplayCellArcheTypes[];
  colWidths?: DisplayCellWidthValues[];
  initialFocusCellId?: string;
  children?: React.ReactNode;
}
const componentName = 'DisplayTable';

export class DisplayTable extends React.Component<DisplayTableProps, { selectedCellId: string }> {
  element: HTMLDivElement;
  randomUuid: string = btoa(LangUtil.randomUuid());
  state = {
    selectedCellId: null,
  };
  keyDownHandler = AccessibilityEventHandlers.grid;

  constructor(props, context) {
    super(props, context);
  }

  get columnWidths() {
    const actualColWidths = this.props.colWidths
      ? this.props.colWidths.map((x) => this.mapFractionToFr(x))
      : this.props.colTypes
      ? this.props.colTypes.map((x) => this.mapColTypeToFr(x))
      : [`repeat(${this.props.colCount}, 1fr)`];
    return actualColWidths.join(' ');
  }

  get componentStyles(): React.CSSProperties {
    return {
      gridTemplateColumns: this.columnWidths,
      gridTemplateRows: 'auto',
    };
  }

  componentDidMount(): void {
    this.element.addEventListener('keydown', this.keyDownHandler);
  }

  componentWillUnmount(): void {
    this.element.removeEventListener('keydown', this.keyDownHandler);
  }

  renderCells = () => {
    return React.Children.map(this.props.children, (c: React.ReactElement<DisplayTableCellProps>) => {
      if (c.props.id === (this.state.selectedCellId || this.props.initialFocusCellId)) {
        return React.cloneElement(c, { isSelected: true, domId: (id) => IdEncoderUtil.encodeId(this.randomUuid, id) });
      } else {
        return React.cloneElement(c, { domId: (id) => IdEncoderUtil.encodeId(this.randomUuid, id) });
      }
    });
  };

  renderRows = () => {
    const rowArray: string[][] = [];

    React.Children.forEach(this.props.children, (c: React.ReactElement<DisplayTableCellProps>) => {
      if (rowArray.length <= c.props.rowStart) {
        for (let i = rowArray.length; i <= c.props.rowStart; ++i) {
          rowArray.push([]);
        }
      }
      rowArray[c.props.rowStart].push(IdEncoderUtil.encodeId(this.randomUuid, c.props.id));
    });

    return rowArray.map((r, i) => (
      <span key={i} data-key={`DisplayTable-${i}`} id={'row' + this.randomUuid + i} role={ROW} aria-owns={r.join(' ')} />
    ));
  };

  focusHandler: FocusEventHandler<HTMLElement> = (e) => {
    const attrValue = e.target.getAttribute(ROLE);
    const target = e.target;
    if ([GRIDCELL, COLUMNHEADER].some((v) => v === attrValue)) {
      this.setState(
        {
          selectedCellId: IdEncoderUtil.decodeId(e.target.id),
        },
        () => {
          target?.focus();
        }
      );
    }
  };

  private mapFractionToFr(x: DisplayCellWidthValues): string {
    if (x === '1') {
      return '1fr';
    }
    const num = x.split('/')[0];
    return `.${num}fr`;
  }

  private mapColTypeToFr(ct: DisplayCellArcheTypes): string {
    switch (ct) {
      case 'NUMBER':
        return `0.1fr`;
      case 'STRING':
        return `1fr`;
      case 'YEAR':
        return `1fr`;
      case 'ACCORDION':
        return `1.2fr`;
      default:
        return '1fr';
    }
  }

  render() {
    return (
      <div
        role={GRID}
        aria-colcount={this.props.colCount}
        aria-rowcount={this.props.rowCount}
        ref={(c) => (this.element = c)}
        onFocus={this.focusHandler}
        className={styles['component']}
        style={this.componentStyles}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.renderRows()}
        {this.renderCells()}
      </div>
    );
  }
}

DisplayTable['displayName'] = componentName;
