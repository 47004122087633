import * as React from 'react';
import { MenuItemLabel } from './types';
import { Text } from '../text/text';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'DisplayLabel';

export const DisplayLabel = ({ item, ...props }: { item: MenuItemLabel }) => (
  <span style={{ display: 'flex', alignItems: 'center' }} data-key={item.id} data-component={componentName} {...getDataAttributes(props)}>
    <span>
      <Text size="font12">{item.label}</Text>
    </span>
  </span>
);

DisplayLabel['displayName'] = componentName;
