import * as React from 'react';
import styles from './error-boundary-content.module.scss';
import { Header } from '../../header/header';
import { Paragraph } from '../../paragraph/paragraph';
import { MessageBlock } from '../../message-block/message-block';
import { IdEncoderUtil } from '../../../util/id-encoder-util';
import { CommandButton } from '../../command-button/command-button';
import { PrimaryButton } from '../../primary-button/primary-button';
import { Accordion } from '../../accordion/accordion';
import { LangUtil } from '../../../client-shared';
import { getDataAttributes } from '../../../util/attribute-util';

export interface ErrorBoundaryContentLabelProps {
  errorBoundaryHeader: string;
  errorBoundaryAccordionHeader: string;
  errorBoundaryCopyLabel: string;
  errorBoundaryCloseLabel: string;
}

export interface ErrorBoundaryContentProps extends ErrorBoundaryContentLabelProps {
  content: string;
  showDismiss: boolean;
  onDismiss: () => any;
}

const componentName = 'ErrorBoundaryContent';

export class ErrorBoundaryContent extends React.Component<ErrorBoundaryContentProps, { showDetails: boolean }> {
  state = {
    showDetails: false,
  };

  headerId: string = IdEncoderUtil.encodeId('', LangUtil.randomUuid());
  accordionId: string = IdEncoderUtil.encodeId('', LangUtil.randomUuid());

  render() {
    return (
      <section
        className={styles['component']}
        aria-labelledby={this.headerId}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <MessageBlock type="error">
          <div className={styles['content']}>
            <Header id={this.headerId} headerLevel={2} headerType={'section'}>
              {this.props.errorBoundaryHeader}
            </Header>
            <Accordion
              id={this.accordionId}
              isOpen={this.state.showDetails}
              heading={this.props.errorBoundaryAccordionHeader}
              onToggle={() => {
                this.setState((s) => ({
                  showDetails: !s.showDetails,
                }));
              }}
            >
              <Paragraph type="font14" style={{ whiteSpace: 'pre-wrap', overflow: 'auto' }}>
                {this.props.content}
              </Paragraph>
            </Accordion>
            <div className={styles['buttons']}>
              <CommandButton
                type="button"
                size="small"
                label={this.props.errorBoundaryCopyLabel}
                onClick={() => {
                  const listener = (e: ClipboardEvent) => {
                    e.clipboardData.setData('text/plain', this.props.content);
                    e.preventDefault();
                    document.removeEventListener('copy', listener);
                  };
                  document.addEventListener('copy', listener);
                  document.execCommand('copy');
                }}
              />
              {this.props.showDismiss ? (
                <PrimaryButton type="button" label={this.props.errorBoundaryCloseLabel} size="small" onClick={this.props.onDismiss} />
              ) : null}
            </div>
          </div>
        </MessageBlock>
      </section>
    );
  }
}

ErrorBoundaryContent['displayName'] = componentName;
