import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as styles from './popup-menu.css';
import { MenuEvent, MenuItemAny } from './types';
import { PopupItem } from './popup-item';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import { getDataAttributes } from '../../util/attribute-util';

export interface PopupMenuProps {
  children: React.ReactNode;
  items: MenuItemAny[];
  isOpen?: boolean;
  defaultOpen?: boolean;
  handleChildrenAsGroup?: boolean;
  asChild?: boolean;
  onSelect: (item: MenuItemAny, e: MenuEvent) => void;
  onClose: () => void;
  id: string;
}

const componentName = 'PopupMenu';

export function PopupMenu({ isOpen, items, children, defaultOpen, onClose, onSelect, asChild, id, ...props }: PopupMenuProps) {
  // Based off of: https://www.radix-ui.com/docs/primitives/components/dropdown-menu
  // Menu Items can be: Separators, Items

  const handleSelect = (item: MenuItemAny, e: MenuEvent) => {
    if (onSelect) {
      onSelect(item, e);
    }
  };

  const handleOnOpenChange = (open: boolean) => {
    if (!open && onClose) {
      onClose();
    }
  };

  return (
    <DropdownMenu.Root defaultOpen={defaultOpen} open={isOpen} onOpenChange={handleOnOpenChange}>
      <DropdownMenu.Trigger asChild={asChild}>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Content
        align={'start'}
        id={id}
        className={[getPaletteClassName({ name: getDefaultPalette().name }), styles.content].join(' ')}
      >
        {items.map((item) => (
          <PopupItem key={item.id} item={item} onSelect={handleSelect} data-component={componentName} {...getDataAttributes(props)} />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

PopupMenu['displayName'] = componentName;
