import classnames from 'classnames';
import { RequiredResponsiveValue } from '../../vanilla-extract/atoms/sprinkles.css';
import * as styles from './use-negative-margin-top.css';
import { resolveResponsiveProp } from '../../util/resolve-responsive-prop';

type NegativeMarginTop = Extract<
  Extract<keyof typeof styles.mobile, keyof typeof styles.tablet>,
  Extract<keyof typeof styles.mobile, keyof typeof styles.desktop>
>;

export const useNegativeMarginTop = (space: RequiredResponsiveValue<any>) => {
  const negativeMarginTop = resolveResponsiveProp(space, styles.mobile, styles.tablet, styles.desktop);
  return classnames(styles.base, negativeMarginTop);
};
