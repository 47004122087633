import * as React from 'react';
import styles from './highlighting-block.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface HighlightingBlockProps {
  id?: string;
  children?: React.ReactNode;
}

const componentNane = 'HighlightingBlock';

export class HighlightingBlock extends React.Component<HighlightingBlockProps, {}> {
  render() {
    return (
      <div id={this.props.id} className={styles['highlighting-block']} data-component={componentNane} {...getDataAttributes(this.props)}>
        <div className={styles['highlighting-block-container']}>{this.props.children}</div>
      </div>
    );
  }
}

HighlightingBlock['displayName'] = componentNane;
