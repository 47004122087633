import { getClassFunctionName } from '../client-shared/util/browser';
import { windowSafe } from './ssr';

/**
 * Utility functions for use by various components.
 */
export class ComponentUtil {
  private static loggedDeprecations = {};

  /**
   * Logs a deprecation console warning once per page-load and only while the dev server is running
   * @param componentClass the class of the component that is deprecated
   */
  static logDeprecationMessage(componentClass) {
    if (componentClass && module && module['hot']) {
      const componentName = getClassFunctionName(componentClass);
      if (!Object.prototype.hasOwnProperty.call(ComponentUtil.loggedDeprecations, componentName)) {
        ComponentUtil.loggedDeprecations[componentName] = true;
        console.warn(componentName + ' is deprecated');
      }
    }
  }

  /**
   * @param config {
   *     cancellationHandle: the handle returned from the previous call to debounce or null for the first time.
   *     action - the action to call when the debounceTime elapses
   *     debounceTime - time to wait before calling the action. Repeat calls will reset the wait time.
   * }
   */
  static debounce(config: DebounceConfig): number | null {
    windowSafe().clearTimeout(config.cancellationHandle);
    return config.debounceTimeMs > 0 ? windowSafe().setTimeout(() => config.action(), config.debounceTimeMs) : config.action();
  }
}

export interface DebounceConfig {
  cancellationHandle: number | null;
  action: () => null;
  debounceTimeMs: number;
}
