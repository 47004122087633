import * as React from 'react';
import Select, { components } from 'react-select';
import styles from './dropdown.module.scss';
import { Icon } from '../icon/icon';
import { flatSelect, SelectItem } from '../../models/select-item';
import { getDataAttributes } from '../../util/attribute-util';
import { AriaComboboxInterface } from '../AccessibilityProps';
import { Inline } from '../inline/inline';
import { getPaletteClassFromElement } from '../../util/theme-util';
import { LangUtil, HTMLMeasurer } from '../../client-shared';
import { isSSR } from '../../util/ssr';

const componentName = 'Dropdown';

type InternalAria = 'aria-owns' | 'aria-activedescendant' | 'aria-expanded' | 'aria-haspopup';
type Width = 'fitToContent' | 'fullWidth';

export interface DropdownBaseProps {
  /**
   * (Optional): DOM id to assign to the input in the drop down, e.g. to associate a field element with it
   */
  id?: string;

  /**
   * A list containing select items. the label, icon combination on each select item will be rendered as a single row in the drop down
   */
  items: SelectItem[];

  /**
   * (Optional): placeholder text for when no value has been selected
   */
  placeholder?: string;

  /**
   * (Optional): Text for when search has no results
   */
  noOptionsMessage?: string;

  aria?: Omit<AriaComboboxInterface, InternalAria>;

  /**
   * (Optional): Defines whether to fit to content or use full width.
   */
  width?: Width;

  /**
   * (Optional): if you want to turn off autoComplete or help the browser understand what to autoComplete, e.g. autoComplete="email"
   */
  autoComplete?: string;
}

const measure = (text) => HTMLMeasurer.measureText(text ?? '', 14, '"Nexus Sans", Arial, sans-serif');

export interface DropdownProps extends DropdownBaseProps {
  /**
   * callback returning the selectItem which has been selected via mouse or keyboard navigation
   * if filter is empty then return null if user presses enter
   * @param selectItem
   */
  onItemSelected: (selectItem: SelectItem | undefined) => void;

  /**
   * (Optional): The currently selected item, or null if no item is selected
   */
  selectedItem?: SelectItem;

  /**
   * (Optional): Whether the drop down should be highlighted as an error, e.g. when a selection is required and none has been made.
   * Defaults to false.
   */
  error?: boolean;
}

export class Dropdown extends React.Component<DropdownProps, any> {
  static defaultProps = {
    selectedItem: null,
    width: 'fitToContent',
    error: false,
    placeholder: 'Select item...',
  };

  private portalId = 'dropdown-' + LangUtil.randomUuid();
  private select = React.createRef<Select>();

  get options() {
    return this.props.items.map((option) => {
      if (option.children.length > 0) {
        return {
          ...option,
          options: option.children.map((v) => ({ ...v, isDisabled: !v.isSelectable })),
        };
      }
      return {
        ...option,
        isDisabled: !option.isSelectable,
      };
    });
  }

  DropdownIndicator = (props: { selectProps: { menuIsOpen: true } } & any) => {
    const {
      children = <Icon name={props.selectProps.menuIsOpen ? 'navigate-up' : 'navigate-down'} size={16} />,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        key={'indicator'}
        className={styles['clear-indicator']}
        {...restInnerProps}
        ref={ref}
        aria-label={props.selectProps.menuIsOpen ? 'Close' : 'Open'}
      >
        {children}
      </div>
    );
  };

  GroupHeading = ({ children, ...props }) => {
    const item = this.props.items.find((v) => v.label === children);
    return (
      <components.GroupHeading {...props}>
        <Inline spacing="4dp">
          {item.icon && <Icon name={item.icon} size={16} fill={'default'} />}
          {children}
        </Inline>
      </components.GroupHeading>
    );
  };

  Option = ({ children, ...props }) => (
    <div data-component={'DropdownOption'} data-key={`dropdown-option-${props.data.id}`}>
      <components.Option {...props}>
        <Inline spacing="4dp">
          {props.data.icon && <Icon name={props.data.icon} size={16} fill={props.isDisabled ? 'interaction-disabled' : 'interaction'} />}
          {children}
        </Inline>
      </components.Option>
    </div>
  );

  Input = (props) => {
    return <components.Input {...props} aria-invalid={this.props.error} />;
  };

  findAndApplyPaletteNameToPortal = (div: HTMLDivElement) => {
    if (div) {
      const paletteName = getPaletteClassFromElement(div);
      this.getPortalElement()?.setAttribute('class', paletteName);
    }
  };

  render() {
    const props: Partial<{ value: SelectItem; id: string }> = {};
    if (this.props.selectedItem) {
      props.value = this.props.selectedItem;
    }

    if (this.props.id) {
      props.id = this.props.id;
    }

    return (
      <div
        data-component={componentName}
        {...getDataAttributes(this.props)}
        {...this.props.aria}
        style={{
          width: this.props.width === 'fitToContent' ? this.optionWidth() + 'px' : '100%',
        }}
        ref={this.findAndApplyPaletteNameToPortal}
      >
        <Select
          ref={this.select}
          options={this.options}
          aria-label={'Searchable list'}
          className={[styles['component'], this.props.error && styles['error']].join(' ')}
          classNamePrefix={'react-select'}
          isMulti={false}
          components={{
            GroupHeading: this.GroupHeading,
            DropdownIndicator: this.DropdownIndicator,
            Option: this.Option,
          }}
          menuPortalTarget={this.getPortalElement()}
          autoComplete={this.props.autoComplete}
          tabSelectsValue={false}
          menuPlacement={'auto'}
          placeholder={this.props.placeholder}
          closeMenuOnSelect={true}
          closeMenuOnScroll={true}
          openMenuOnFocus={true}
          onChange={this.onChangeHandler}
          noOptionsMessage={() => this.props.noOptionsMessage}
          {...props}
        />
      </div>
    );
  }

  public focus() {
    this.select?.current.focus();
  }

  onChangeHandler = (value) => {
    this.props.onItemSelected(value);
  };

  private optionWidth(): number {
    const longest5SelectItems = flatSelect(this.props.items ?? [])
      .sort((a, b) => (b.label?.length ?? 0) - (a.label?.length ?? 0))
      .slice(0, 5);

    const optionPadding = 6;
    const openButton = 29;
    const max = longest5SelectItems.reduce((acc, cur) => {
      const iconWidth = cur.icon ? 16 : 0;
      return Math.max(acc, measure(cur.label) + openButton + iconWidth + optionPadding);
    }, 0);
    return Math.max(max, measure(this.props.placeholder || '') + openButton + optionPadding);
  }

  componentWillUnmount(): void {
    const portalElement = this.getPortalElement(false);
    if (portalElement) {
      portalElement.parentNode.removeChild(portalElement);
    }
  }

  private getPortalElement(createIfNeeded = true): HTMLElement {
    if (isSSR) {
      return null;
    }
    let portalElement = document.getElementById(this.portalId);
    if (!portalElement && createIfNeeded) {
      portalElement = document.createElement('div');
      portalElement.setAttribute('id', this.portalId);
      document.body.appendChild(portalElement);
    }
    return portalElement;
  }
}

Dropdown['displayName'] = componentName;
