import * as React from 'react';
import { useContext } from 'react';
import { getDataAttributes } from '../../util/attribute-util';
import { MediaContext } from '../../media-context/media-context';
import { getDefaultPalette } from '../../util/theme-util';
import { Icon } from '../../components/icon/icon';

import styles from './avatar.module.scss';
import { S_M_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'Avatar';

export interface AvatarInternalProps {
  /**
   * (Optional) Defines the height, width and font size of the avatar.
   */
  size?: S_M_L_Size;
  /**
   * (Optional) Defines the type of avatar.
   */
  variant?: 'initials' | 'photo';
  /**
   * (Optional) Defines initials for the avatar.
   */
  initials?: string;
  /**
   * (Optional) Defines the image url for the avatar.
   */
  image?: string;
  /**
   * (Optional) Defines the image alt property for the avatar.
   */
  imageAlt?: string;
}

type ButtonNativeProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'style' | 'children' | 'ref'
>;

export interface AvatarProps extends AvatarInternalProps, ButtonNativeProps {}
type RefType = React.LegacyRef<HTMLButtonElement>;

export interface AvatarProps extends AvatarInternalProps, ButtonNativeProps {}

export const Avatar = React.forwardRef(
  ({ size, variant, onClick, initials, image, imageAlt, ...nativeProps }: AvatarProps, ref: RefType): JSX.Element => {
    const context = useContext(MediaContext);
    const wrapperClassName = [styles[size], `${getDefaultPalette().name}-theme`, styles['avatar-wrapper']];

    context && context.screen === 'Mobile' && wrapperClassName.push(styles['mobile-styling-container']);

    const getIconSize = (size: string) => {
      switch (size) {
        case 'small':
          return 14;
        case 'medium':
          return 17;
        case 'large':
          return 26;
        default:
          return 14;
      }
    };

    const getAvatarBody = () => {
      if (variant === 'initials' && !!initials) {
        return initials.slice(0, 2).toUpperCase();
      }

      if (variant === 'photo' && image) {
        return <img src={image} alt={imageAlt} />;
      }

      return <Icon name="person_gizmo" size={getIconSize(size)} />;
    };

    return (
      <button
        className={wrapperClassName.join(' ')}
        ref={ref}
        aria-label={nativeProps['aria-label'] ?? componentName}
        data-component={componentName}
        {...getDataAttributes(nativeProps)}
        {...nativeProps}
      >
        {getAvatarBody()}
      </button>
    );
  }
);

Avatar.defaultProps = {
  size: 'medium',
};

Avatar.displayName = componentName;
