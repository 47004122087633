/***
 * Returns a subset of the input object containing only data-prefixed keys.
 * @param object the object to filter
 * @param postfix the value to add to the end of each value. Useful when supporting a heirarchy of dataattributes.
 */
export function getDataAttributes(object: Record<any, any>, postfix = '') {
  return Object.keys(object)
    .filter((key) => key.startsWith('data-'))
    .reduce((result: Record<any, any>, key: string) => {
      result[key] = object[key] + postfix;

      return result;
    }, {});
}

export function getAllButDataAttributes(object: Record<any, any>) {
  return Object.keys(object)
    .filter((key) => !key.startsWith('data-'))
    .reduce((result: Record<any, any>, key: string) => {
      result[key] = object[key];

      return result;
    }, {});
}
