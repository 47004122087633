import * as React from 'react';
import { SelectItem } from '../../models/select-item';
import { Icon } from '../icon/icon';
import styles from './popup-link.module.scss';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { IconName } from '../icon/icon-SVGs';
import { DecorativeSelectionIndicator } from '../selection-list/selection-list';
import { getDataAttributes } from '../../util/attribute-util';
import { PopupMenuAlignment, PopupMenuBridge } from '../popup-menu/popup-menu-bridge';
import { LangUtil } from '../../client-shared';
import { AriaRoletypeInterface } from '../AccessibilityProps';

export interface PopupLinkProps {
  /**
   * Optional name of the icon to show before the label
   */
  icon?: IconName;

  /**
   * Optional label to show after the icon
   */
  label?: string;

  /**
   * Whether to show a drop down arrow after the icon and label. Defaults to true.
   */
  showDropDownArrow?: boolean;

  /**
   * The select items to show in the popup menu
   */
  items?: Array<SelectItem>;

  /**
   * Setting selectedItems means the list is being controlled from outside, and fed into SelectionList.
   * Since the selection state is controlled outside of SelectionList, this also makes it possible to have
   * items which can be selected, and other items which can just be clicked, but does not show a selection state.
   *
   * If not set, SelectionList holds its own internal selectedItems state.
   */
  selectedItems?: SelectItem[];

  /**
   * Callback for when selectedItems have changed
   * @deprecated Unless you are depending on changes, use onSelect instead
   */
  onItemsSelected?: (selected: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[], isMouseEvent?: boolean) => any;

  /**
   * If enabled, a checkbox or checkIcon is shown as a visual indicator of the selected state of each item.
   * The checkbox option is purely decorative and does not appear in the accessibility tree.
   * Note: If you want to only have this applied to some of the items, you can set showSelectionIndicator on a specific SelectItem
   */
  decorativeSelectionIndicator?: DecorativeSelectionIndicator;

  /**
   * Which edge of the opener that the popup menu should align with.
   * Defaults to left.
   */
  alignMenu?: PopupMenuAlignment;

  /**
   * If enabled, only one item can be selected at one time
   */
  onlyAllowSingleSelectedItem?: boolean;

  disabled?: boolean;

  /**
   * onSelect. A simpler event that always returns the item you selected.
   */
  onSelect?: (item: SelectItem) => void;

  /**
   * Aria properties available on any role
   */
  aria?: AriaRoletypeInterface;
}

/**
 * Icon that can show a popup menu with actions.
 */

const componentName = 'PopupLink';

export class PopupLink extends React.Component<PopupLinkProps, { isOpen: boolean }> {
  static defaultProps: PopupLinkProps = {
    showDropDownArrow: true,
    items: undefined,
    onItemsSelected: undefined,
    alignMenu: 'left',
  };
  private id = IdEncoderUtil.encodeId('', LangUtil.randomUuid());

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  render() {
    const className = this.props.label ? [styles['component'], styles['has-label']] : [styles['component']];

    const attributes = {};

    this.state.isOpen && (attributes['aria-expanded'] = true);
    this.props.disabled && (attributes['disabled'] = true);

    const content = [
      this.props.icon && <Icon key={'iconMain'} size={16} name={this.props.icon} />,
      this.props.label,
      this.props.showDropDownArrow && <Icon key={'iconDropdown'} name="navigate-down" size={16} />,
    ].filter((x) => x !== null);

    const addAriaControlsWhenOpen = this.state.isOpen ? { 'aria-controls': this.id } : {};

    return (
      <PopupMenuBridge
        id={this.id}
        items={this.props.items}
        isOpen={this.state.isOpen}
        onClose={this.handleClose}
        onItemsSelected={this.handleItemSelected}
        onSelect={this.handleSingleSelection}
        decorativeSelectionIndicator={this.props.decorativeSelectionIndicator}
        alignMenu={this.props.alignMenu}
        selectedItems={this.props.selectedItems}
        onlyAllowSingleSelectedItem={this.props.onlyAllowSingleSelectedItem}
        data-component={componentName}
      >
        <button
          type="button"
          className={className.join(' ')}
          title={this.props.label}
          onClick={this.handleClick}
          {...addAriaControlsWhenOpen}
          {...this.props.aria}
          {...attributes}
          {...getDataAttributes(this.props)}
        >
          <span>{content}</span>
        </button>
      </PopupMenuBridge>
    );
  }

  public open(): void {
    this.setState({ isOpen: true });
  }

  public close(): void {
    this.setState({ isOpen: false });
  }

  /* Event Handlers */
  private handleClick = () => {
    this.open();
  };

  private handleClose = () => {
    this.close();
  };

  private handleItemSelected = (selected: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[], isMouseEvent?: boolean) => {
    // we close the popup first, which enables the popup menu to restore focus to the element that opened it
    this.handleClose();
    // once closed, we can invoke the item handler, ensuring that any focus restore in dialogs etc. works as expected
    this.props.onItemsSelected?.(selected, newItems, removedItems, isMouseEvent);
  };

  private handleSingleSelection = (item: SelectItem) => {
    this.props.onSelect?.(item);
  };
}

PopupLink['displayName'] = componentName;
