import * as aria from '../aria-attributes';
import { getFirstParentWithAttribute } from '../accessibility-utils';
import { KeyCodes } from '../../client-shared';

/**
 * get the next cell in the column
 * @param grid
 * @param cell
 * @param colIndex
 * @param rowIndex
 * @param colCount
 * @param rowCount
 */
function getDownCell(grid: HTMLElement, cell: HTMLElement, colIndex: number, rowIndex: number, colCount: number, rowCount: number) {
  let nextCell: HTMLElement = null;

  //navigate using aria colindex and rowIndex. We use this for sparse or spanning cells
  if (colIndex > 0 && rowIndex > 0) {
    if (rowIndex === rowCount) {
      return null;
    }

    //if the adjacent cell does not exist, then see of there is a cell spanning that cell else see if the next row has a cell
    for (let j = rowIndex + 1; j <= rowCount; j++) {
      for (let i = colIndex; i >= 1; i--) {
        nextCell = grid.querySelector(`[${aria.ARIA_COLINDEX}="${i}"][${aria.ARIA_ROWINDEX}="${j}"]`);
        if (nextCell && i === colIndex) {
          return nextCell;
        }
        if (nextCell && (getColSpan(nextCell) === 0 || getColSpan(nextCell) > colIndex - i)) {
          return nextCell;
        }
      }
    }
    return null;
  }

  //we have no colIndex or rowIndex to use as navigational points. We assume that this is a dense table without colSpans
  const cells = getColumnCellsWithoutIndex(grid, cell);

  const currentZeroBasedRowIndex = cells.indexOf(cell);

  if (currentZeroBasedRowIndex === cells.length - 1) {
    return null;
  }

  const next = cells[currentZeroBasedRowIndex + 1];

  return next;
}

/**
 * Get the previous cell in the column.
 * @param grid
 * @param cell
 * @param colIndex
 * @param rowIndex
 */
function getUpCell(grid: HTMLElement, cell: HTMLElement, colIndex: number, rowIndex: number) {
  let nextCell: HTMLElement = null;

  //navigate using aria colindex and rowIndex. We use this for sparse or spanning cells
  if (colIndex > 0 && rowIndex > 0) {
    if (rowIndex === 1) {
      return null;
    }
    //if the adjacent cell does not exist, then see of there is a cell spanning that cell else see if the previous row has a cell
    for (let j = rowIndex - 1; j >= 1; j--) {
      for (let i = colIndex; i >= 1; i--) {
        nextCell = grid.querySelector(`[${aria.ARIA_COLINDEX}="${i}"][${aria.ARIA_ROWINDEX}="${j}"]`);
        if (nextCell && i === colIndex) {
          return nextCell;
        }
        if (nextCell && (getColSpan(nextCell) === 0 || getColSpan(nextCell) > colIndex - i)) {
          return nextCell;
        }
      }
    }
    return null;
  }

  //we have no colIndex or rowIndex to use as navigational points. We assume that this is a dense table without colSpans
  const cells = getColumnCellsWithoutIndex(grid, cell);

  const currentZeroBasedRowIndex = cells.indexOf(cell);

  if (currentZeroBasedRowIndex === 0) {
    return null;
  }

  const next = cells[currentZeroBasedRowIndex - 1];

  return next;
}

/**
 * Get previous cell in the row
 * @param grid
 * @param cell
 * @param colIndex
 * @param rowIndex
 */
function getLeftCell(grid: HTMLElement, cell: HTMLElement, colIndex: number, rowIndex: number): HTMLElement | null {
  let nextCell: HTMLElement = null;

  //navigate using aria colindex and rowIndex. We use this for sparse or spanning cells
  if (colIndex > 0 && rowIndex > 0) {
    if (colIndex === 1) {
      return null;
    }
    //if the adjacent cell does not exist, then see of there is a cell spanning that cell else see if the previous row has a cell
    for (let i = colIndex - 1; i >= 1; i--) {
      for (let j = rowIndex; j >= 1; j--) {
        nextCell = grid.querySelector(`[${aria.ARIA_COLINDEX}="${i}"][${aria.ARIA_ROWINDEX}="${j}"]`);
        if (nextCell && j === rowIndex) {
          return nextCell;
        }
        if (nextCell && (getRowSpan(nextCell) === 0 || getRowSpan(nextCell) > rowIndex - j)) {
          return nextCell;
        }
      }
    }

    return null;
  }

  //we have no colIndex or rowIndex to use as navigational points. We assume that this is a dense table without rowSpans
  const cells = getRowCellsWithoutIndex(grid, getRow(grid, cell));

  const currentZeroBasedColIndex = cells.indexOf(cell);

  if (currentZeroBasedColIndex === 0) {
    return null;
  }

  const next = cells[currentZeroBasedColIndex - 1];

  return next;
}

/**
 * Get the next cell in the row.
 * @param grid
 * @param cell
 * @param colIndex
 * @param rowIndex
 * @param colCount
 * @param rowCount
 */
function getRightCell(
  grid: HTMLElement,
  cell: HTMLElement,
  colIndex: number,
  rowIndex: number,
  colCount: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  rowCount: number
): HTMLElement | null {
  let nextCell: HTMLElement = null;

  //navigate using aria colindex and rowIndex. We use this for sparse or spanning cells
  if (colIndex > 0 && rowIndex > 0) {
    if (colIndex === colCount) {
      return null;
    }
    //if the adjacent cell does not exist, then see of there is a cell spanning that cell else see if the next row has a cell
    for (let i = colIndex + 1; i <= colCount; i++) {
      for (let j = rowIndex; j >= 1; j--) {
        nextCell = grid.querySelector(`[${aria.ARIA_COLINDEX}="${i}"][${aria.ARIA_ROWINDEX}="${j}"]`);
        if (nextCell && j === rowIndex) {
          return nextCell;
        }
        if (nextCell && (getRowSpan(nextCell) === 0 || getRowSpan(nextCell) > rowIndex - j)) {
          return nextCell;
        }
      }
    }

    return null;
  }

  //we have no colIndex or rowIndex to use as navigational points. We assume that this is a dense table without rowSpans
  const cells = getRowCellsWithoutIndex(grid, getRow(grid, cell));

  const currentZeroBasedColIndex = cells.indexOf(cell);

  if (currentZeroBasedColIndex === cells.length - 1) {
    return null;
  }

  const next = cells[currentZeroBasedColIndex + 1];

  return next;
}

/**
 * Get the aria-rowspan attribute of the cell
 * @param cell
 */
function getRowSpan(cell: HTMLElement) {
  const rowSpan = cell.getAttribute(aria.ARIA_ROWSPAN);
  return rowSpan === null ? null : parseInt(rowSpan, 10);
}

/**
 * Get the aria-colspan attribute of the cell
 * @param cell
 */
function getColSpan(cell: HTMLElement) {
  const colSpan = cell.getAttribute(aria.ARIA_COLSPAN);
  return colSpan === null ? null : parseInt(colSpan, 10);
}

/**
 * Provides an list of child elements of a given row, as well as aria-owns elements. Does not handle cells with rowspan.
 * @param grid
 * @param row
 */
function getRowCellsWithoutIndex(grid: HTMLElement, row: HTMLElement) {
  const seenIds = new Set();
  /*
   * If an element has both aria-owns and DOM children then the order of the child elements with respect to the parent/child relationship
   * is the DOM children first, then the elements referenced in aria-owns. If the author intends that the DOM children are not first,
   * then list the DOM children in aria-owns in the desired order. Authors SHOULD NOT use aria-owns as a replacement for the DOM hierarchy.
   * If the relationship is represented in the DOM, do not use aria-owns. Authors MUST ensure that an element's ID is not specified in more
   * than one other element's aria-owns attribute at any time. In other words, an element can have only one explicit owner.
   * */

  const cells: HTMLElement[] = [];
  //look at the owns attribute or the child collection
  const ownsString = row.getAttribute(aria.ARIA_OWNS);
  const owns = (ownsString || '').split(' ');

  for (let i = 0; i < row.childElementCount; i++) {
    const elem = row.children.item(i) as HTMLElement;
    cells.push(elem);
    seenIds.add(elem.id);
  }

  const ownsCells = owns.map((id) => grid.querySelector(`*[id ="${id}"]`) as HTMLElement);

  //remove any cell which is both in the child collection and the aria-owns list, before inserting it.
  ownsCells.forEach((c) => {
    if (seenIds.has(c.id)) {
      cells.splice(cells.indexOf(c), 1);
    }
    cells.push(c);
  });

  return cells;
}

/**
 * Provides an list of child elements of a given column. Does not handle cells with colspan, rowspan or sparse tables.
 * @param grid
 * @param cell
 */
function getColumnCellsWithoutIndex(grid: HTMLElement, cell: HTMLElement) {
  const rows = grid.querySelectorAll(`[${aria.ROLE}="${aria.ROW}"`);

  const cells: HTMLElement[] = [];
  const colIndex = getRowCellsWithoutIndex(grid, getRow(grid, cell)).indexOf(cell);

  for (let i = 0; i < rows.length; i++) {
    cells.push(getRowCellsWithoutIndex(grid, rows.item(i) as HTMLElement)[colIndex]);
  }

  return cells;
}

/**
 * Handles the event if the navigation is in or out of a cell
 * @param evt
 * @param cell
 */
function handleCellInOutNavigation(evt: WindowEventMap['keydown'], cell: HTMLElement) {
  switch (evt.keyCode) {
    case KeyCodes.DOM_VK_ESCAPE: {
      cell.focus();
      break;
    }
    case KeyCodes.DOM_VK_RETURN:
    case KeyCodes.DOM_VK_ENTER: {
      if (evt.target !== cell) {
        break;
      }
      /*
       * */
      const firstFocus = cell.querySelector("a, input, *[tabindex='0'], button") as HTMLElement;
      if (!firstFocus) {
        console.log('Grid event handler', 'no focusable element within the selected cell');
        return;
      }
      firstFocus.focus();
      break;
    }
    case KeyCodes.DOM_VK_TAB: {
      const focusableElements = cell.querySelectorAll("a, input, *[tabindex='0'], button");
      if (evt.target === cell || focusableElements.length === 0) {
        return;
      } else if (!evt.shiftKey && focusableElements[focusableElements.length - 1] === evt.target) {
        (focusableElements[0] as HTMLElement).focus();
        evt.preventDefault();
      } else if (evt.shiftKey && focusableElements[0] === evt.target) {
        (focusableElements[focusableElements.length - 1] as HTMLElement).focus();
        evt.preventDefault();
      }

      break;
    }
  }
}

/**
 * get the aria-colindex attribute of the cell
 * @param cell
 */
function getColIndex(cell: HTMLElement) {
  const colIndex = parseInt(cell.getAttribute('aria-colindex'), 10);
  if (colIndex === undefined) {
    console.error('Grid event handler', 'cannot get aria-colindex from ', cell);
    return null;
  }
  return colIndex;
}

/**
 * get the aria-rowindex attribute of the cell
 * @param cell
 */
function getRowIndex(cell: HTMLElement) {
  const rowIndex = parseInt(cell.getAttribute('aria-rowIndex'), 10);
  if (rowIndex === undefined) {
    console.error('Grid event handler', 'Cannot get aria-rowindex from cell', cell);
    return null;
  }
  return rowIndex;
}

/**
 * get the aria-rowcount attribute of the grid
 * @param gridElement
 */
function getRowCount(gridElement: HTMLElement) {
  //look for a rowcount attribute. if the grid is virtualized or sparse then a rowcount must be present
  const rowCountAttr = gridElement.getAttribute(aria.ARIA_ROWCOUNT);
  if (rowCountAttr) {
    return parseInt(rowCountAttr, 10);
  }

  //if there is no rowCount, count the number of rows
  return gridElement.querySelectorAll(`[${aria.ROLE}="${aria.ROW}"]`).length;
}

/**
 * Get the row containing the given cell
 * @param gridElement
 * @param cell
 */
function getRow(gridElement: HTMLElement, cell: HTMLElement) {
  //get the row which has the target id in its "owns" list
  const row: HTMLElement = gridElement.querySelector(`*[role="row"][aria-owns~="${cell.id}"]`);

  if (!row) {
    console.error('Grid event handler', `Cannot locate a row element within the grid element with the cell ${cell} as its child.`);
    return;
  }
  return row;
}

/**
 * Get the ancestor element with the grid role
 * @param child
 */
function getGrid(child: HTMLElement) {
  const gridElement = getFirstParentWithAttribute(child, aria.ROLE, (el) => !el, aria.GRID);

  if (!gridElement) {
    console.error('Grid event handler', 'Cannot find grid element as parent to cell element', child);
    return;
  }

  return gridElement;
}

/**
 * Get the cell element closest to the given element
 * @param focusElement
 */
function getCell(focusElement: HTMLElement) {
  let cell: HTMLElement;
  //Get the cell. Its role can either be a column header or a grid cell
  if ([aria.COLUMNHEADER, aria.GRIDCELL].some((r) => r === focusElement.getAttribute(aria.ROLE))) {
    cell = focusElement;
  } else {
    cell = getFirstParentWithAttribute(focusElement, aria.ROLE, (el) => !el, aria.GRIDCELL);
    if (!cell) {
      cell = getFirstParentWithAttribute(focusElement, aria.ROLE, (el) => !el, aria.COLUMNHEADER);
    }
  }

  if (!cell) {
    console.error('Grid event handler', `Cannot find a cell role on the focused element, nor its parents.`, focusElement);
    throw ''; //todo
  }
  return cell;
}

/**
 * Get the column count of the Grid, either via aria-colcount or by counting the first row
 * @param gridElement
 */
function getColCount(gridElement: HTMLElement): number {
  //look for a colcount attribute. If the grid is virtualized or sparse then a colcount must be present
  const colAttr = gridElement.getAttribute(aria.ARIA_COLCOUNT);
  if (colAttr) {
    return parseInt(colAttr, 10);
  }

  // If there is no col count then calculate it.
  // If there is no colcount then we assume that we are dealing with a dense column table and can simply use the first row.
  const firstRow = gridElement.querySelector(`[${aria.ROLE}="${aria.ROW}"]`) as HTMLElement;
  return getRowCellsWithoutIndex(gridElement, firstRow).length;
}

export const AriaGridEventHandler = (evt: WindowEventMap['keydown']): void => {
  try {
    const focusElement = evt.target as HTMLElement;
    const cell: HTMLElement = getCell(focusElement);
    const grid: HTMLElement = getGrid(cell);
    const colCount = getColCount(grid);
    const rowCount = getRowCount(grid);
    const colIndex = getColIndex(cell);
    const rowIndex = getRowIndex(cell);

    //handle navigation into, out of and within cells.
    handleCellInOutNavigation(evt, cell);

    //The following actions can only be performed if the cell is in focus
    if (focusElement !== cell) {
      return;
    }

    switch (evt.keyCode) {
      case KeyCodes.DOM_VK_LEFT: {
        evt.preventDefault();
        //Left Arrow: Moves focus one cell to the left. If focus is on the left-most cell in the row, focus does not move.
        const next = getLeftCell(grid, cell, colIndex, rowIndex);
        next && next.focus();

        break;
      }
      case KeyCodes.DOM_VK_RIGHT: {
        //Right Arrow: Moves focus one cell to the right. If focus is on the right-most cell in the row, focus does not move.
        evt.preventDefault();
        const next = getRightCell(grid, cell, colIndex, rowIndex, colCount, rowCount);
        next && next.focus();

        break;
      }
      case KeyCodes.DOM_VK_DOWN: {
        //Down Arrow: Moves focus one cell down. If focus is on the bottom cell in the column, focus does not move.
        evt.preventDefault();
        const next = getDownCell(grid, cell, colIndex, rowIndex, colCount, rowCount);
        next && next.focus();
        break;
      }
      case KeyCodes.DOM_VK_UP: {
        evt.preventDefault();
        //Up Arrow: Moves focus one cell Up. If focus is on the top cell in the column, focus does not move.
        const next = getUpCell(grid, cell, colIndex, rowIndex);
        next && next.focus();
        break;
      }
      case KeyCodes.DOM_VK_PAGE_DOWN: {
        /*
                            Page Down: Moves focus down an author-determined number of rows,
                            typically scrolling so the bottom row in the currently visible set of rows becomes one of the first visible rows.
                            If focus is in the last row of the grid, focus does not move.
                        */
        break;
      }
      case KeyCodes.DOM_VK_PAGE_UP: {
        /*
                            Page Up: Moves focus up an author-determined number of rows, typically scrolling so the top row in the currently visible set of rows
                            becomes one of the last visible rows. If focus is in the first row of the grid, focus does not move.
                        */
        break;
      }
      case KeyCodes.DOM_VK_HOME: {
        /*
                            Home: moves focus to the first cell in the row that contains focus.
                            Control + Home: moves focus to the first cell in the first row.
                        */
        break;
      }
      case KeyCodes.DOM_VK_END: {
        /*
                            End: moves focus to the last cell in the row that contains focus.
                            Control + End: moves focus to the last cell in the last row.
                        */
        break;
      }
    }
  } catch (e) {
    console.error('Grid event handler', e);
  }
};
