import * as React from 'react';
import { Inline } from '../inline/inline';
import { DayToggle } from './day-toggle';
import moment from 'moment';
import { getDataAttributes } from '../../util/attribute-util';
import { KeyCodes, LangUtil } from '../../client-shared';

export type StartDay = 'Mon' | 'Sun';
export type Weekday = 'Mon' | 'Tue' | 'Wed' | 'Thu' | 'Fri' | 'Sat' | 'Sun';

export interface DayPickerProps {
  /**
   * selectedDays: undefined is none selected.
   */
  selectedDays?: Weekday[];

  /**
   * startDay: default to Monday. Weeks in picker will start on this day.
   */
  startDay?: StartDay;

  /**
   * onSelectionChanged: When days are selected, this event will be called with the selected days.
   */
  onSelectionChanged?: (selected: Weekday[]) => void;

  /**
   * isSelected: whether a day is selected or not.
   */
  isSelected?: boolean;
}

const componentName = 'DayPicker';

export class DayPicker extends React.Component<DayPickerProps, { selectedDays: Weekday[]; hasFocus: boolean; focusIndex: number }> {
  static defaultProps = {
    startDay: 'Mon' as StartDay,
  };

  state = {
    selectedDays: this.props.selectedDays ?? [],
    hasFocus: false,
    focusIndex: 0,
  };

  private weekDays: Weekday[] = [0, 1, 2, 3, 4, 5, 6]
    .map((i) =>
      moment()
        .day(i + (this.props.startDay === 'Mon' ? 1 : 0))
        .format('ddd')
    )
    .map((x) => x as Weekday);

  handleKeyDown = (e: { keyCode: number }) => {
    if (e.keyCode === KeyCodes.DOM_VK_LEFT && this.state.focusIndex > 0) {
      this.setState((prev) => ({ focusIndex: prev.focusIndex - 1 }));
    } else if (e.keyCode === KeyCodes.DOM_VK_RIGHT && this.state.focusIndex < this.weekDays.length - 1) {
      this.setState((prev) => ({ focusIndex: prev.focusIndex + 1 }));
    }

    if (e.keyCode === KeyCodes.DOM_VK_RETURN || e.keyCode === KeyCodes.DOM_VK_SPACE) {
      const focusedDay = this.weekDays[this.state.focusIndex];
      this.toggle(focusedDay);
    }
  };

  toggle = (day: Weekday) => {
    const selectedDay = this.state.selectedDays.find((x) => x === day);
    this.weekdayClickedHandler(day, !LangUtil.isDefined(selectedDay));
  };

  weekdayClickedHandler = (day: Weekday, selected: boolean) => {
    const item = this.weekDays.find((itm) => itm === day);
    const newSelectedDays = selected ? this.state.selectedDays.concat(item) : this.state.selectedDays.filter((itm) => itm !== item);

    this.setState({
      selectedDays: newSelectedDays,
      focusIndex: this.weekDays.indexOf(day),
    });
    this.props.onSelectionChanged?.(newSelectedDays);
  };

  render() {
    return (
      <div
        tabIndex={0}
        onFocus={() => this.setState({ hasFocus: true })}
        onBlur={() => this.setState({ hasFocus: false })}
        onKeyDown={(e) => {
          if (e.keyCode === KeyCodes.DOM_VK_SPACE) {
            e.preventDefault();
          }
          this.handleKeyDown(e);
        }}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <Inline wrap={false} spacing={'8dp'}>
          {this.weekDays.map((day, i) => (
            <DayToggle
              key={day}
              data-key={`DayPicker-${day}`}
              weekday={day}
              aria={{ 'aria-label': day }}
              isSelected={this.state.selectedDays.some((itm) => itm === day)}
              isKeyboardFocused={this.state.focusIndex === i && this.state.hasFocus}
              onDaySelected={(id, isSelected) => this.weekdayClickedHandler(day, isSelected)}
            />
          ))}
        </Inline>
      </div>
    );
  }
}

DayPicker['displayName'] = componentName;
