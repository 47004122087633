import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from './selection-list.module.scss';
import { flatSelect, SelectItem } from '../../models/select-item';
import { Icon } from '../icon/icon';
import { AriaTreeInterface } from '../AccessibilityProps';
import { AccessibilityEventHandlers } from '../../accessibility/accessibility-event-handlers';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { ModelUtil } from '../../util/Model-util';
import { Header } from '../header/header';
import { getDataAttributes } from '../../util/attribute-util';
import { Accordion } from '../accordion/accordion';
import { Check } from '../check/check';
import { LangUtil, PixelConverterUtil } from '../../client-shared';
import { ChildNodeFunctions } from '../../models/child-node';

export interface ItemTemplate<T extends SelectItem> {
  hasSelectionFunctionality?: boolean;
  templateType: string;
  template: React.ElementType<{ item: T }>;
}

export type DecorativeSelectionIndicator = 'checkbox' | 'checkIcon';

export interface SelectionListProps<T extends SelectItem> {
  id: string;

  // items
  items: T[];
  itemTemplates?: ItemTemplate<T>[];

  // styling
  className?: string;
  buttonClassName?: string;
  style?: React.CSSProperties;

  /**
   * Label for selectedChildrenCount sub label. Will be placed after the count e.g. if label is "selected", it will be "2 selected"
   * If no label is given here, but showSelectedCounter is set to true, it will simply show the count
   */
  selectedChildrenCountLabel?: string;

  // selection
  /**
   * Setting selectedItems means the list is being controlled from outside, and fed into SelectionList.
   * Since the selection state is controlled outside of SelectionList, this also makes it possible to have
   * items which can be selected, and other items which can just be clicked, but does not show a selection state.
   *
   * If not set, SelectionList holds its own internal selectedItems state.
   */
  selectedItems?: T[];
  highlightedItem?: T;
  onItemsSelected?: (selected: T[], newItems: T[], removedItems: T[], isMouseEvent?: boolean) => any;
  onClick?: (item: T) => void;
  onItemHighlighted?: (item: T) => any;

  onNegativeIndexRequested?: () => void;
  onKeyboardInput?: (keyCode: number) => void;

  // drag and drop
  dragDisabled?: boolean;

  // settings

  /**
   * If enabled, only one item can be selected at one time
   */
  onlyAllowSingleSelectedItem?: boolean;

  /**
   * If enabled, a checkbox or checkIcon is shown as a visual indicator of the selected state of each item.
   * The checkbox option is purely decorative and does not appear in the accessibility tree.
   * Note: If you want to only have this applied to some of the items, you can set showSelectionIndicator on a specific SelectItem
   */
  decorativeSelectionIndicator?: DecorativeSelectionIndicator;

  /**
   * If true, all parent elements will be shown as open.
   */
  showElementsAsOpen?: boolean;

  /**
   * Shows a border to the left of the item, appearing when hovering, on focus, or when selected
   */
  useBorderIndicator?: boolean;

  /**
   * If true, shows a counter of how many children is selected beneath a parent element, e.g. "2 selected".
   * The counter is shown behind the name of the element.
   */
  showSelectedCounter?: boolean;

  subLabelAlignment?: 'left' | 'right';

  /**
   * Useful for scenarios where you have applied filters or similar outside of this component.
   * E.g. if you have made a search, and only show part of the items, the selected item count might be more meaningful if you count the appearing items + the items filtered out
   * @param selectedItems
   * @param nodeId
   */
  getExternalSelectedCounter?: (nodeId: string, selectedItems: T[]) => number;

  /**
   * Useful for scenarios where an item's type can get overridden after e.g. filtering or similar. This makes it possible to reach outside of SelectionList and
   * return to it the initial item, with its original type intact.
   * @param nodeId    the id of the node/item to find
   */
  getInitialItem?: (nodeId: string) => any;

  /**
   *  Changes navigation model to use selection on focus as well as disables the aria-activedescendant attribute. Requires the component to be mounted inside of a combobox
   */
  useComboboxPopupNavigation?: boolean;
  aria?: AriaTreeInterface;
}

const componentName = 'SelectionList';

export class SelectionList<T extends SelectItem> extends React.Component<
  SelectionListProps<T>,
  {
    selectedItems: T[];
    activeDescendant: string;
    isFocused: boolean;
    toggledAccordionNodes: Set<string | number | boolean>;
    showElementsAsOpen: boolean;
  }
> {
  static defaultProps = {
    itemTemplates: [],
    useBorderIndicator: true,
    subLabelAlignment: 'left',
  };

  // keeps track of toggled accordions
  private static textWidths: { [text: string]: number } = {};
  private static _measureElement: HTMLElement = null;
  private selectionList: HTMLElement;

  constructor(properties) {
    super(properties);
    const f = new Set<string | number | boolean>();
    flatSelect(this.props.items)
      .filter((v) => v.isOpen === true)
      .forEach((v) => f.add(v.value));
    this.state = {
      selectedItems: this.props.selectedItems ? this.props.selectedItems : [],
      toggledAccordionNodes: new Set<string | number | boolean>(f),
      activeDescendant: '',
      isFocused: false,
      showElementsAsOpen: this.props.showElementsAsOpen,
    };
  }

  private get flatItems() {
    return ModelUtil.treeNodeAsFlatArray(this.props.items);
  }

  /*
   *
   * RENDER METHODS
   *
   */

  /**
   * Returns id's of top nodes that has children
   */
  private get topParentsWithChildrenIds(): string[] {
    return this.props.items
      .map((item) => {
        return item.children?.length > 0 ? item.id : null;
      })
      .filter((item) => item !== null);
  }

  static getMeasureElement() {
    let elem = SelectionList._measureElement;
    if (!elem) {
      elem = document.createElement('div');
      elem.style.setProperty('position', 'absolute');
      elem.style.setProperty('visibility', 'hidden');
      elem.setAttribute('class', styles['item']);
      elem.style.setProperty('width', 'initial');
      document.body.appendChild(elem);
      SelectionList._measureElement = elem;
    }
    return elem;
  }

  static measureText(text: string, measureElem: HTMLElement) {
    let width = SelectionList.textWidths[text];
    if (width) {
      return width;
    }
    measureElem.innerHTML = '';
    measureElem.appendChild(document.createTextNode(text));
    width = measureElem.getBoundingClientRect().width;
    SelectionList.textWidths[text] = width;
    return width;
  }

  static removeMeasureElement() {
    if (SelectionList._measureElement && SelectionList._measureElement.parentElement) {
      SelectionList._measureElement.parentElement.removeChild(SelectionList._measureElement);
      SelectionList._measureElement = null;
    }
  }

  /*
   *
   * ACTIONS
   *
   */

  /**
   * Measures the text width of the labels in the specified items. Also, for items with icons, the width of the icon plus spacing is included.
   */
  static measureMaxItemWidth(items: SelectItem[]): number {
    if (LangUtil.isDefined(items)) {
      const iconWithSpacing = PixelConverterUtil.pxToDp(24);
      const measureElem = SelectionList.getMeasureElement();
      let maxWidth = 0;
      items.forEach((item) => {
        if (typeof item.label === 'string') {
          let childWidth = 0;

          let itemWidth = SelectionList.measureText(item.label, measureElem);

          if (item.icon) {
            itemWidth += iconWithSpacing;
          }

          if (item.children.length > 0) {
            //nesting costs some spacing. For now lets assume that it is approximately the same as an icon
            childWidth = iconWithSpacing + SelectionList.measureMaxItemWidth(item.children);
          }

          maxWidth = Math.max(maxWidth, itemWidth, childWidth);
        }
      });
      SelectionList.removeMeasureElement();
      return maxWidth + 2; // add space for a text caret
    }
    return null;
  }

  render() {
    const classes = [styles['component'], this.props.className, styles['list']];

    const aria: AriaTreeInterface = {
      'aria-orientation': 'vertical',
      'aria-multiselectable': !this.props.onlyAllowSingleSelectedItem,
      'aria-required': 'true',
    };

    const addAriaControlsWhenOpen =
      !this.props.useComboboxPopupNavigation && this.state.isFocused
        ? { 'aria-activedescendant': IdEncoderUtil.encodeId(this.props.id, this.state.activeDescendant) }
        : {};

    return (
      <div
        id={this.props.id}
        role="tree"
        className={classes.join(' ')}
        ref={(c) => this.getElement(c)}
        {...(!this.props.useComboboxPopupNavigation && { tabIndex: 0 })}
        {...this.props.aria}
        {...aria}
        {...addAriaControlsWhenOpen}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.items.map((item: T, index: number) => this.renderNode(item, index))}
      </div>
    );
  }

  componentDidMount() {
    this.selectionList.addEventListener('keydown', this.keyPressHandler, true);
    this.props.onItemHighlighted && this.props.onItemHighlighted(this.flatItems.find((itm) => itm.id === this.state.activeDescendant));
  }

  componentWillUnmount() {
    this.selectionList.removeEventListener('keydown', this.keyPressHandler, true);
  }

  /*
   *
   * LIFECYCLE METHODS
   *
   */

  componentWillUpdate(newProps) {
    let state = { ...this.state };
    let stateUpdated = false;
    if (newProps.selectedItems && this.state.selectedItems && !this.state.selectedItems.every((v) => newProps.selectedItems.includes(v))) {
      state = { ...state, selectedItems: newProps.selectedItems };
      stateUpdated = true;
    }
    /**
     * The showElementsAsOpen is also held in local state. When the prop value
     * changes, then we want to reflect that immediatly in state.
     * The state value for showElementsAsOpen is changed when the user closed the accordion manually.
     */
    if (this.props.showElementsAsOpen !== newProps.showElementsAsOpen) {
      state = { ...state, showElementsAsOpen: newProps.showElementsAsOpen };
      stateUpdated = true;
    }
    // making sure to reset toggledAccordionNodes if new props have new isOpen states for any node
    if (this.state.toggledAccordionNodes.size > 0) {
      // checking for new items added or items removed
      const newPropItemIds = newProps.items.map((item) => item.id);
      const currentPropItemIds = this.props.items.map((item) => item.id);

      const changeIncludesNewItems = newPropItemIds.some((nId) => !currentPropItemIds.find((cId) => nId === cId));
      const changeIncludesRemovedItems = currentPropItemIds.some((nId) => !newPropItemIds.find((cId) => nId === cId));

      // comparing isOpen states for new vs current props
      const initialOpenStates = new Map<string, boolean>();
      this.props.items.forEach((n) => {
        ChildNodeFunctions.asFlat(n).forEach((nf: SelectItem) => {
          initialOpenStates.set(nf.id, nf.isOpen);
        });
      });

      let changeIncludesNodesToggled = false;

      for (let i = 0; i < newProps.items.length; i++) {
        const n = newProps.items[i];
        for (let j = 0; j < ChildNodeFunctions.asFlat(n).length; j++) {
          const nf = ChildNodeFunctions.asFlat(n)[j] as SelectItem;
          if (initialOpenStates.get(nf.id) !== nf.isOpen) {
            changeIncludesNodesToggled = true;
            j = ChildNodeFunctions.asFlat(n).length;
            i = newProps.items.length;
          }
        }
      }

      if (changeIncludesNewItems || changeIncludesRemovedItems || changeIncludesNodesToggled) {
        state = { ...state, toggledAccordionNodes: new Set<string>() };
        stateUpdated = true;
      }
    }
    if (stateUpdated) {
      this.setState(state);
    }
  }

  public focus(): void {
    this.selectionList.focus();
  }

  private isNodeOpen = (node: T) => {
    if (this.props.showElementsAsOpen !== undefined && this.props.showElementsAsOpen !== null) {
      return this.props.showElementsAsOpen;
    }
    if (this.state.toggledAccordionNodes.has(node.id)) {
      return true;
    }

    return node.isOpen;
  };

  private renderNode = (node: T, index: number) => {
    const nodeHasChildren = node.children && node.children.length > 0;

    let content = this.instantiateItem(node, nodeHasChildren);

    if (!content) {
      console.error('SelectionList: No itemTemplate available for type ' + typeof node);
      content = <div data-feature={node.dataFeature} />;
    }
    if (nodeHasChildren) {
      const accordionItemHighlighting = [styles['accordion-styling']];
      if (this.state.activeDescendant === node.id) {
        accordionItemHighlighting.push(styles['accordion-styling-focused']);
        if (this.props.useBorderIndicator) {
          accordionItemHighlighting.push(styles['accordion-styling-focused-border']);
        }
      }

      return (
        <div key={node.id} data-key={`SelectionList-${node.id}`} className={styles['accordion']}>
          <Accordion
            id={IdEncoderUtil.encodeId(this.props.id, node.id)}
            toggleOnHeadingClick={true}
            heading={content}
            className={accordionItemHighlighting.join(' ')}
            animate={false}
            isOpen={this.state.showElementsAsOpen || this.state.toggledAccordionNodes.has(node.id)}
            onToggle={(id: string, state: boolean) => this.toggleAccordion(node, state)}
            role={'treeitem'}
            aria={{
              'aria-selected': this.state.selectedItems.some((itm) => itm.id === node.id),
              'aria-disabled': !node.isSelectable,
            }}
          >
            <div role="group" data-feature={node.dataFeature}>
              {node.children.map((childNode: T, index: number) => this.renderNode(childNode, index))}
            </div>
          </Accordion>
        </div>
      );
    } else if (node.isSeparator) {
      return <div key={node.id + index} className={styles['item-separator']} role={'separator treeitem'} data-feature={node.dataFeature} />;
    } else if (node.isGroupHeading) {
      const headerStyles = [styles['item-group-header']];
      if (this.props.decorativeSelectionIndicator) {
        headerStyles.push(styles['item-group-header-indent']);
      }
      return (
        <div key={node.id + index} className={headerStyles.join(' ')} role={'treeitem'} data-feature={node.dataFeature}>
          <Header headerType={'list'} headerLevel={4} maxLines={1}>
            {node.label}
          </Header>
        </div>
      );
    } else {
      return content;
    }
  };

  /*
   *
   * UTIL
   *
   */

  /**
   * Renders the item with a template, or a default if no template is set.
   * @node            The item to render
   * @nodeHasChildren Whether the node is a parent or not
   */
  private instantiateItem(node: T, nodeHasChildren = false) {
    let content: React.ReactNode;

    let temp: ItemTemplate<T>;

    if (this.props.getInitialItem) {
      // get the initial item that still has the item's original type (as this might have been overridden by filtering functions etc.)
      const initialItem = this.props.getInitialItem(node.id);
      temp = this.props.itemTemplates.find((t) => initialItem.selectItemType === t.templateType);
    } else {
      temp = this.props.itemTemplates.find((t) => node.templateItemType === t.templateType);
    }

    // this makes it so you have to opt out of being selectable in a template
    let canBeSelected = true;

    if (temp) {
      // make use of template instead of default rendering of the node
      const Comp: any = temp.template;
      content = <Comp item={node} />;

      // whether this node has the option to be selected (thus, does it have states for selection or not, and optionally a checkbox)
      canBeSelected = temp.hasSelectionFunctionality;
    } else {
      content = this.renderDefaultSelectItem(node);
    }

    // whether this node is able to be selected (enabled) or not (disabled)
    const isEnabled = node.isSelectable;

    // whether this node is selected or not
    const selectedItems = this.props.selectedItems ? this.props.selectedItems : this.state.selectedItems;
    let isSelected: boolean = selectedItems.some((itm) => itm.id === node.id);
    if (typeof node.value === 'boolean') {
      isSelected = node.value;
    }

    const buttonClasses = [this.props.buttonClassName, styles['item']];

    // whether this is a parent, making it non-selectable
    if (nodeHasChildren) {
      buttonClasses.push(styles['accordion-header-item']);
      canBeSelected = false;
    }

    // disabled state
    if (!isEnabled) {
      buttonClasses.push(styles['disabled']);
      buttonClasses.push('disabled'); //global style can be targeted in templates
    }

    // selected state
    if (isSelected) {
      buttonClasses.push(styles['selected']);
      buttonClasses.push('selected'); //global style can be targeted in templates
      if (this.props.useBorderIndicator) {
        buttonClasses.push(styles['selected-border']);
      }
    }

    // focused state
    if (node.id === this.state.activeDescendant && isEnabled) {
      buttonClasses.push(styles['focus']);
      if (this.props.useBorderIndicator) {
        buttonClasses.push(styles['focus-border']);
      }
    }

    // selection indicator
    let selectionIndicator;
    let selectionIndicatorStyle = styles['visual-selection-indicator'];
    if (this.props.decorativeSelectionIndicator) {
      const decorativeStyle = this.props.decorativeSelectionIndicator ? this.props.decorativeSelectionIndicator : 'checkbox';
      if (decorativeStyle === 'checkbox') {
        // Checkbox is for decorative purposes only. Therefore aria-hidden and tabIndex are set as follows
        selectionIndicator = (
          <span className={styles['decorative-check']}>
            <Check id={'check_' + node.id} isDisabled={!isEnabled} isSelected={isSelected} aria={{ 'aria-hidden': true }} tabIndex={-1} />
          </span>
        );
      } else {
        buttonClasses.push(styles['visual-selection-indicator-item-padding']);
        selectionIndicatorStyle = styles['visual-selection-indicator-checkIcon'];
        selectionIndicator = isSelected ? <Icon name={'check'} size={16} /> : <div className={styles['indentation-spacing']} />;
      }
    }

    // sub label text
    let subLabel: string = node.subLabel ?? undefined;

    // sub label as selected children count
    if (!subLabel && nodeHasChildren && this.props.showSelectedCounter) {
      let selectedChildrenCount: number;
      if (this.props.getExternalSelectedCounter !== undefined) {
        selectedChildrenCount = this.props.getExternalSelectedCounter(node.id, this.state.selectedItems);
      } else {
        const flatChildren = ModelUtil.treeNodeAsFlatArray(node.children).filter((item) => selectedItems.some((itm) => itm.id === item.id));
        selectedChildrenCount = flatChildren.length;
      }
      subLabel = selectedChildrenCount > 0 ? `${selectedChildrenCount} ${this.props.selectedChildrenCountLabel || ''}` : undefined;
    }

    const subLabelClasses = [styles['sub-label']];
    !isEnabled && subLabelClasses.push(styles['sub-label-disabled']);
    if (this.props.subLabelAlignment) {
      const subLabelAlignmentStyle = 'sub-label-' + this.props.subLabelAlignment;
      subLabelClasses.push(styles[subLabelAlignmentStyle]);
    }

    return (
      <div
        id={IdEncoderUtil.encodeId(this.props.id, node.id)}
        key={'button_' + node.id}
        data-key={'button_' + node.id}
        {...(!nodeHasChildren && { role: 'treeitem' })}
        className={buttonClasses.join(' ')}
        onClick={() => {
          this.toggleItem(node, canBeSelected, true);
          this.props.onClick?.(node);
        }}
        onMouseEnter={() => this.onItemHoverEvent(node.id)}
        onMouseLeave={() => this.onItemHoverEvent()}
        aria-selected={selectedItems.some((itm) => itm.id === node.id)}
        aria-disabled={!node.isSelectable}
        data-feature={node.dataFeature}
      >
        {/* decorative checkbox */}
        {this.props.decorativeSelectionIndicator && canBeSelected && !nodeHasChildren && (
          <div className={selectionIndicatorStyle}>{selectionIndicator}</div>
        )}

        {/* item content */}
        {content}

        {/* sub label */}
        {subLabel && (
          <div className={subLabelClasses.join(' ')} title={subLabel}>
            {subLabel}
          </div>
        )}
      </div>
    );
  }

  private renderDefaultSelectItem = (item: T) => {
    return (
      <div key={item.id} data-key={`SelectionList_default-${item.id}`} className={styles['default-item']}>
        {item.icon && (
          <span className={styles['default-item-icon']}>
            <Icon name={item.icon} size={16} fill={'interaction'} />
          </span>
        )}
        <span className={styles['item-label']}>{item.label}</span>
      </div>
    );
  };

  private getElement(c) {
    this.selectionList = ReactDOM.findDOMNode(c) as HTMLElement;

    // if selection list element is available set the activeDescendant to the first enabled item
    // unless activeDescendant is already set to something
    if (this.selectionList !== null && this.props.items && this.props.items.length > 0) {
      const setActiveDescendant = () => {
        const currentActiveDescendant = this.flatItems.find((item) => item.id === this.state.activeDescendant);
        if (currentActiveDescendant === undefined || !currentActiveDescendant.isSelectable) {
          const firstEnabledItem = this.props.items.find((item) => item.isSelectable);
          this.setState((s) => ({
            ...s,
            activeDescendant: firstEnabledItem?.id,
          }));
        }
      };

      if (this.state.isFocused) {
        setActiveDescendant();
      }

      this.selectionList.onfocus = () => {
        this.setState(
          (s) => ({
            ...s,
            isFocused: true,
          }),
          () => {
            setActiveDescendant();
          }
        );
      };

      this.selectionList.onblur = () => {
        this.setState((s) => ({
          ...s,
          isFocused: false,
          activeDescendant: null,
        }));
      };
    }
  }

  private onItemHoverEvent = (id?: string) => {
    if (id) {
      const node = this.flatItems.find((item) => item.id === id);
      if (node.isSelectable) {
        this.setState((s) => ({
          ...s,
          activeDescendant: id,
        }));
      }
    } else if (!this.state.isFocused) {
      this.setState((s) => ({
        ...s,
        activeDescendant: null,
      }));
    }
  };

  private toggleAccordion = (node: T, state: boolean) => {
    this.setState((s) => {
      const newVal = new Set(s.toggledAccordionNodes);
      if (state) {
        newVal.add(node.id);
      } else {
        newVal.delete(node.id);
      }
      return {
        ...s,
        showElementsAsOpen: false,
        toggledAccordionNodes: new Set(newVal),
      };
    });
  };

  private toggleItem = (item: T, canBeSelected: boolean, isMouse: boolean) => {
    if (!item.isSelectable || !canBeSelected || this.topParentsWithChildrenIds.indexOf(item.id) !== -1) {
      return;
    }

    if (this.props.onlyAllowSingleSelectedItem) {
      this.setState(
        (s) => ({
          ...s,
          selectedItems: [item],
        }),
        () => {
          this.props.onItemsSelected && this.props.onItemsSelected(this.state.selectedItems, [item], [], isMouse);
        }
      );
    } else {
      const existingItem = this.state.selectedItems.find((itm) => itm.id === item.id);
      if (!existingItem) {
        this.setState(
          (s) => {
            const newVal = s.selectedItems.slice();
            newVal.push(item);
            return {
              ...s,
              selectedItems: newVal,
            };
          },
          () => {
            this.props.onItemsSelected && this.props.onItemsSelected(this.state.selectedItems, [item], [], isMouse);
          }
        );
      } else {
        this.setState(
          (s) => ({
            ...s,
            selectedItems: s.selectedItems.filter((v) => v.id !== existingItem.id),
          }),
          () => {
            this.props.onItemsSelected && this.props.onItemsSelected(this.state.selectedItems, [], [existingItem], isMouse);
          }
        );
      }
    }
  };

  // Event handlers

  private scrollElementIntoViewWhenNecessary = (elem: HTMLElement, item: T) => {
    const scrollbarElement = this.findScrollbar(elem);
    const scrollbarPresent = scrollbarElement !== null;

    if (scrollbarPresent) {
      const elementClientRect = elem.getBoundingClientRect();
      const openChildrenCount = this.countOpenChildren(item);

      // When a SelectionList item (elem) has children, then its height also includes the height of the children.
      // This will can cause the item to scroll, because of it children. By dividing it by the number of children + 1
      // we end up with a single items height. Then it only scrolls when it is required.
      const singleHeight = elementClientRect.bottom / (openChildrenCount + 1);

      if (singleHeight > scrollbarElement.clientHeight) {
        elem.scrollIntoView();
      }
      if (elementClientRect.top < scrollbarElement.scrollTop) {
        elem.scrollIntoView();
      }
    }
  };

  private countOpenChildren(itm: T): number {
    return this.isNodeOpen(itm)
      ? itm.children.length + itm.children.map((i) => this.countOpenChildren(i as T)).reduce((p, c) => p + c, 0)
      : 0;
  }

  private childFocus = (elem: HTMLElement) => {
    this.setState(
      (s) => ({
        ...s,
        activeDescendant: IdEncoderUtil.decodeId(elem.id),
      }),
      () => {
        const item = this.flatItems.find((itm) => itm.id === this.state.activeDescendant);
        this.scrollElementIntoViewWhenNecessary(elem, item);
        this.props.onItemHighlighted && this.props.onItemHighlighted(item);
      }
    );
  };

  private findScrollbar(node: HTMLElement | null): HTMLElement | null {
    return node === null || node.scrollHeight > node.clientHeight ? node : this.findScrollbar(node.parentNode as HTMLElement);
  }

  private selectionHandler = (elem, state) => {
    const decodedId = IdEncoderUtil.decodeId(elem.id);
    if (state === this.state.selectedItems.some((itm) => itm.id === decodedId)) {
      return;
    }

    let canBeSelected = true;
    const node = this.flatItems.find((item) => item.id === decodedId);

    if (this.props.itemTemplates?.length > 0) {
      let temp: ItemTemplate<T>;

      if (this.props.getInitialItem) {
        // get the initial item that still has the correct type (as this might have been overridden by filtering functions etc.)
        const initialItem = this.props.getInitialItem(decodedId);
        temp = this.props.itemTemplates.find((t) => initialItem.selectItemType === t.templateType);
      } else {
        temp = this.props.itemTemplates.find((t) => node.templateItemType === t.templateType);
      }

      if (temp) {
        canBeSelected = temp.hasSelectionFunctionality;
      }
    } else if (node?.children?.length > 0) {
      canBeSelected = false;
    }

    this.toggleItem(
      this.flatItems.find((itm) => itm.id === decodedId),
      canBeSelected,
      false
    );
  };

  private expansionHandler = (elem) => {
    const decodedId = IdEncoderUtil.decodeId(elem.id);
    this.props.items.forEach((n) => {
      ChildNodeFunctions.asFlat(n).forEach((nf: T) => {
        if (nf.id === decodedId) {
          const isNodeOpen = this.isNodeOpen(nf);
          this.toggleAccordion(nf, !isNodeOpen);
        }
      });
    });
  };

  private keyPressHandler = AccessibilityEventHandlers.treeSingleSelectFactory(
    this.selectionHandler,
    this.expansionHandler,
    this.childFocus,
    this.props.useComboboxPopupNavigation
  );
}

SelectionList['displayName'] = componentName;
