import * as React from 'react';
import { Box } from '../box/box';
import { getDataAttributes } from '../../util/attribute-util';

export interface FocusTrapProps {
  /**
   * Callback function to be called when the <FocusTrap /> will unmount;
   *
   * Useful when you need to return focus.
   */
  unmountCallback?: () => void;

  children?: any;
}

const componentName = 'FocusTrap';
export class FocusTrap extends React.PureComponent<FocusTrapProps> {
  ref = React.createRef<HTMLDivElement>();

  get focusableElements() {
    const focusableElementSelector = [
      'a',
      'button:not([disabled])',
      'input:not([readonly])',
      'select',
      'textarea',
      '[tabindex]:not([tabindex^="-"])',
    ].join(',');
    const elements = this.ref.current?.querySelectorAll(focusableElementSelector);

    return Array.from(elements);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyboardEvents);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyboardEvents);

    this.props.unmountCallback && this.props.unmountCallback();
  }

  handleKeyboardEvents = (event: KeyboardEvent) => {
    const pressingShift = event.shiftKey;
    const pressingTab = event.key === 'Tab';

    if (!pressingTab) {
      return;
    }

    const elements = this.focusableElements;

    if (elements.length === 0) {
      return;
    }

    // Since we are preventing default action, we need to manually handle tab, shift+tab focus;
    event.preventDefault();

    const firstElement = elements[0];
    const lastElement = elements[elements.length - 1];
    let desirableElement = document.activeElement;

    const focusedElementIndex = elements.indexOf(desirableElement);

    // If first time focusing or at the end of the focusable elements list and Shift, focus the first element;
    if (focusedElementIndex === -1 || (pressingTab && !pressingShift)) {
      desirableElement = document.activeElement === lastElement ? firstElement : elements[focusedElementIndex + 1];
    }
    // If at the begining of the focusable list and Shift+Tab, focus the last element;
    if (pressingShift && pressingTab) {
      desirableElement = document.activeElement === firstElement ? lastElement : elements[focusedElementIndex - 1];
    }

    (desirableElement as HTMLElement)?.focus();
  };

  render() {
    return (
      <Box ref={this.ref} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </Box>
    );
  }
}

FocusTrap['displayName'] = componentName;
