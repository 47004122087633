import * as React from 'react';
import styles from './column-item.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface ColumnItemProps {
  itemMinWidth?: number;
  children?: React.ReactNode;
}

const componentName = 'ColumnItem';

export class ColumnItem extends React.Component<ColumnItemProps, {}> {
  render() {
    return (
      <li
        style={{ flexBasis: this.props.itemMinWidth + 'px' }}
        className={styles['component']}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </li>
    );
  }
}

ColumnItem['displayName'] = componentName;
