import * as React from 'react';
import styles from './widget-container.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { Inline } from '../inline/inline';
import { TextField } from '../text-field/text-field';
import { HTMLMeasurer, KeyCodes } from '../../client-shared';

export interface WidgetContainerProps {
  options: React.ReactElement<any>[];
  children?: any | any[];
  isSelected?: boolean;
  className?: string;
  onClicked?: () => any;
  hasBorders?: boolean;
  title?: string;
  onTitleChanged?: (newTitle: string) => any;
  readOnly?: boolean;
  placeholder?: string;
}

interface WidgetContainerState {
  isEditingTitle: boolean;
}

const componentName = 'WidgetContainer';

export class WidgetContainer extends React.Component<WidgetContainerProps, WidgetContainerState> {
  static defaultProps: Partial<WidgetContainerProps> = {
    hasBorders: true,
  };
  optionsElement: HTMLDivElement;
  placeholderElement: HTMLDivElement;
  tempTitle: string = undefined;

  constructor(properties: WidgetContainerProps) {
    super(properties);

    this.state = {
      isEditingTitle: false,
    };
  }

  /**
   * When title is not supplied. e.g. null on the prop then it has no title.
   * See also showTitlePlaceholder();
   */
  private get showTitle() {
    return this.props.title !== undefined && this.props.title !== null;
  }

  /*
   * When the title is provided as '', then a placeholder is shown.
   * When the title is null, then no title is shown.
   */
  private get showTitlePlaceholder(): boolean {
    if (!this.showTitle) {
      return false;
    }
    return this.props.title.trim().length === 0;
  }

  renderEditableTitle() {
    const title = this.getTitle();
    const titleHeader = this.showTitlePlaceholder ? this.renderTitlePlaceholder() : title;

    return (
      <div className={[styles['title'], styles['editable']].join(' ')} title={this.props.title} onClick={this.titleClickHandler}>
        {!this.state.isEditingTitle ? (
          titleHeader
        ) : (
          <TextField
            onChange={(val) => {
              this.tempTitle = val;
            }}
            value={title}
            onBlur={this.blurHandler}
            onNavigationKey={this.handleNavigationKey}
            autoFocus={true}
            delay={null}
            style={{ width: '400px' }}
          />
        )}
      </div>
    );
  }

  renderReadOnlyTitle(): React.ReactNode {
    if (!this.showTitle) {
      return null;
    }

    return this.showTitlePlaceholder ? (
      this.renderTitlePlaceholder()
    ) : (
      <div className={styles['title']} title={this.props.title}>
        {this.props.title}
      </div>
    );
  }

  renderTitlePlaceholder(): React.ReactNode {
    const placeholderClassName = [styles['title'], styles['title-placeholder']].join(' ');
    const textValue = this.props.placeholder ?? 'Untitled';
    return (
      <div className={placeholderClassName} title={textValue}>
        {textValue}
      </div>
    );
  }

  render() {
    const classes = [styles['component']];

    if (this.props.isSelected) {
      classes.push(styles['isSelected']);
    }
    if (this.props.hasBorders) {
      classes.push(styles['has-borders']);
    }
    if (this.props.className) {
      classes.push(this.props.className);
    }

    return (
      <div className={classes.join(' ')} data-component={componentName} onClick={this.clickedHandler} {...getDataAttributes(this.props)}>
        <div className={styles['header']}>
          <div className={styles['header_placeholder']} ref={this.setPlaceholderRef} />
          {this.props.readOnly ? this.renderReadOnlyTitle() : this.renderEditableTitle()}
          <div className={styles['options']} ref={this.setOptionsRef}>
            <Inline spacing={'8dp'}>{this.props.options}</Inline>
          </div>
        </div>
        <div className={styles['content']}>{this.props.children}</div>
      </div>
    );
  }

  updatePlaceholder() {
    const optionsRect = HTMLMeasurer.computeDimensions(this.optionsElement, [], []);
    this.placeholderElement.style.setProperty('width', optionsRect.width + 'px');
    this.placeholderElement.style.setProperty('height', optionsRect.height + 'px');
  }

  componentDidMount() {
    this.updatePlaceholder();
  }

  componentDidUpdate() {
    this.updatePlaceholder();
  }

  clickedHandler = () => {
    this.props.onClicked && this.props.onClicked();
  };

  setPlaceholderRef = (elem: HTMLDivElement) => {
    this.placeholderElement = elem;
  };

  setOptionsRef = (elem: HTMLDivElement) => {
    this.optionsElement = elem;
  };

  titleClickHandler = () => {
    this.setState({ isEditingTitle: true });
  };

  blurHandler = () => {
    if (this.tempTitle !== undefined) {
      this.props.onTitleChanged && this.props.onTitleChanged(this.tempTitle.trim());
    }

    this.tempTitle = undefined;
    this.setState({ isEditingTitle: false });
  };

  handleNavigationKey = (keyCode) => {
    switch (keyCode) {
      case KeyCodes.DOM_VK_RETURN:
      case KeyCodes.DOM_VK_ESCAPE:
        this.blurHandler();
        break;
    }
  };

  private getTitle(): string | null {
    if (!this.showTitle) {
      return null;
    }
    return this.props.title.trim();
  }
}

WidgetContainer['displayName'] = componentName;
