import * as React from 'react';
import styles from './reorderable-list.module.scss';
import { SelectItem } from '../../models/select-item';
import { IconButton } from '../icon-button/icon-button';
import { getDataAttributes } from '../../util/attribute-util';

export interface ReorderableListProps {
  items: SelectItem[];
  onReorder: (newOrdering: SelectItem[]) => any;
  upLabel: string;
  downLabel: string;
}

const componentName = 'ReorderableList';

export class ReorderableList extends React.Component<ReorderableListProps, {}> {
  constructor(properties: ReorderableListProps) {
    super(properties);
  }

  moveUp = (item) => {
    const copy = this.props.items.slice(0);
    const index = copy.indexOf(item);
    copy.splice(index, 1);
    copy.splice(index - 1, 0, item);

    this.props.onReorder(copy);
  };

  moveDown = (item) => {
    const copy = this.props.items.slice(0);
    const index = copy.indexOf(item);
    copy.splice(index, 1);
    copy.splice(index + 1, 0, item);

    this.props.onReorder(copy);
  };

  renderItem = (item: SelectItem, i: number, a: any[]) => {
    const upProps = {};
    const downProps = {};

    i === 0 && (upProps['disabled'] = true);

    i === a.length - 1 && (downProps['disabled'] = true);

    return (
      <div key={item.value as string} data-key={`ReorderableList-${item.value}`} className={styles['item']} tabIndex={0}>
        <div className={styles['label']}>{item.label}</div>
        <div key="buttons" className={styles['buttons']}>
          <IconButton
            type="button"
            key="up"
            icon="navigate-up"
            aria={{ 'aria-label': this.props.upLabel }}
            {...upProps}
            onClick={() => this.moveUp(item)}
          />
          <IconButton
            type="button"
            key="down"
            icon="navigate-down"
            aria={{ 'aria-label': this.props.downLabel }}
            {...downProps}
            onClick={() => this.moveDown(item)}
          />
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.items.map(this.renderItem)}
      </div>
    );
  }
}

ReorderableList['displayName'] = componentName;
