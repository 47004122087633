import * as React from 'react';
import { useContext } from 'react';
import { getDataAttributes } from '../../util/attribute-util';
import { MediaContext } from '../../media-context/media-context';
import { getDefaultPalette } from '../../util/theme-util';
import styles from './textarea.module.scss';
import { FormElementWrapper } from '../HOCs/form-elements-wrapper/form-elements-wrapper';
import { S_M_L_XL_Size } from '../../components/t-shirt-sizes';

const componentName = 'Textarea';

export interface TextareaInternalProps {
  /**
   * (Optional) sets the textarea fields validation state to invalid.
   */
  hasError?: boolean;
  /**
   * (Optional): Description of what data is expected to be provided in the textarea.
   */
  label?: string;
  /**
   * (Optional): Defines the height and font size of the textarea field
   */
  size?: S_M_L_XL_Size;
  /**
   * (Optional): Defines the maximum number of characters allowed to pass validation in the textarea field. Please note maxLength is allowed
   * to use, but with using maxLength user will not have possibility to write more characters than maxLenth property.
   */
  maxCharacters?: number;
  /**
   * (Optional) Text which will be shown right to the label.
   */
  optionalText?: string;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide additional information about the state of the textarea field
   * whether it is valid.
   */
  infoText?: string;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide details about potential errors as a result of changes
   * to the textarea field.
   */
  errorText?: string;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide a positive verification message regarding changes
   * to the textarea field.
   */
  successText?: string;
  /**
   * (Optional): Defines width of component.
   */
  width?: string;
}

type TextareaNativeProps = Omit<React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'size'>;

export interface TextareaProps extends TextareaInternalProps, TextareaNativeProps {}

export const Textarea = ({
  size,
  label,
  width,
  hasError,
  infoText,
  errorText,
  maxLength,
  successText,
  optionalText,
  maxCharacters,
  ...nativeProps
}: TextareaProps): JSX.Element => {
  const context = useContext(MediaContext);

  const classNames = [styles[size], `${getDefaultPalette().name}-theme`];
  const wrapperClassName = [styles.component];

  context && context.screen === 'Mobile' && wrapperClassName.push(styles['mobile-styling-container']);

  const textareaWrapperClassName = [styles['textarea-wrapper']];
  hasError && textareaWrapperClassName.push(styles['textarea-wrapper--error']);

  const getDimensions = (size) => {
    switch (size) {
      case 'small':
        return { width: '240px' };
      case 'medium':
        return { width: '280px' };
      case 'large':
        return { width: '320px' };
      case 'xlarge':
        return { width: '360px' };
    }
  };

  return (
    <div
      style={{ maxWidth: width ? width : getDimensions(size).width }}
      className={wrapperClassName.join(' ')}
      data-component={componentName}
      {...getDataAttributes(nativeProps)}
    >
      <FormElementWrapper
        label={label}
        size={size}
        value={nativeProps.value as string}
        width={getDimensions(size).width as string}
        dataId={nativeProps?.id}
        infoText={infoText}
        errorText={errorText}
        successText={successText}
        optionalText={optionalText}
        maxCharacters={maxCharacters}
      >
        <div className={textareaWrapperClassName.join(' ')}>
          <textarea className={classNames.join(' ')} onChange={nativeProps.onChange} {...nativeProps} />
        </div>
      </FormElementWrapper>
    </div>
  );
};

Textarea.defaultProps = {
  hasError: false,
  size: 'small',
};

Textarea.displayName = componentName;
