import * as React from 'react';
import { useContext } from 'react';
import styles from './button.module.scss';
import { Label } from '../label/label';
import { Icon } from '../../components/icon/icon';
import { IconName } from '../../components/icon/icon-SVGs';
import { MediaContext } from '../../media-context/media-context';
import { XS_S_M_L_Size, S_M_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'Button';

type Variant = 'primary' | 'secondary' | 'tertiary';

export interface ButtonInternalProps {
  /**
   * Variant decides the type and styling of the button
   * Variant can be 'primary' | 'secondary' | 'tertiary'
   */
  variant: Variant;
  /**
   * (Optional): Button UI sizing.<br/>
   * <b>Note:</b> xsmall can only be used with <b>iconOnly</b> mode.
   */
  size?: XS_S_M_L_Size;
  /**
   * (Optional): Button text to be displayed
   */
  text?: string;
  /**
   * (Optional): To display an icon to the left of the text
   */
  iconLeftName?: IconName;
  /**
   * (Optional): To display an icon to the right of the text
   */
  iconRightName?: IconName;
  /**
   * (Optional): Whether the button should only display an icon or not
   * Defaults to false
   */
  iconOnly?: boolean;
  /**
   * (Optional): Icon to display if iconOnly is true
   */
  iconOnlyName?: IconName;
  /**
   * (Optional): Whether the button should stretch on mobile or not
   * Defaults to false
   */
  stretchOnMobile?: boolean;
}

type ButtonNativeProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'style' | 'children' | 'ref'
>;

export interface ButtonProps extends ButtonInternalProps, ButtonNativeProps {}
type RefType = React.LegacyRef<HTMLButtonElement>;

export const Button = React.forwardRef((props: ButtonProps, ref: RefType): JSX.Element => {
  const { variant, size, text, iconLeftName, iconRightName, iconOnlyName, stretchOnMobile, ...nativeProps } = props;

  const iconOnly = props.iconOnly || size === 'xsmall'; // When size is xsmall, then the button is iconOnly. This is because the text would be too small to see.

  const context = useContext(MediaContext);

  const classNames = [styles.component, styles[variant], styles[size]];
  iconOnly && classNames.push(styles['icon-only']);

  const hasOneIcon = (!iconLeftName && iconRightName) || (iconLeftName && !iconRightName);
  hasOneIcon && text && classNames.push(styles['one-icon']);

  iconLeftName && iconRightName && text && classNames.push(styles['left-and-right-icon']);
  !iconLeftName && !iconRightName && text && classNames.push(styles['text-only']);

  stretchOnMobile && classNames.push(styles['stretch'], styles['mobile-flex-grow']);
  context && context.screen === 'Mobile' && classNames.push(styles['mobile-styling']);

  nativeProps.className && classNames.push(nativeProps.className);

  const className = classNames.join(' ');

  if (iconOnly) {
    return (
      <button data-component={componentName} aria-label={text ?? iconOnlyName} ref={ref} {...nativeProps} className={className}>
        <Icon name={iconOnlyName} size={getIconSize(size)} />
      </button>
    );
  }

  return (
    <button data-component={componentName} aria-label={text} ref={ref} {...nativeProps} className={className}>
      {text && (
        <Label
          text={text}
          size={getLabelSize(size)}
          wrap={false}
          iconLeft={iconLeftName && { name: iconLeftName }}
          iconRight={iconRightName && { name: iconRightName }}
        />
      )}
    </button>
  );
});

Button.defaultProps = {
  stretchOnMobile: false,
  variant: 'primary',
  iconOnly: false,
  size: 'medium',
};

Button.displayName = componentName;

const getIconSize = (size: XS_S_M_L_Size): number => {
  switch (size) {
    case 'xsmall':
      return 16;
    case 'small':
      return 16;
    case 'medium':
    default:
      return 20;
    case 'large':
      return 24;
  }
};

const getLabelSize = (size: XS_S_M_L_Size): S_M_L_Size => {
  switch (size) {
    case 'xsmall':
      return 'small';
    case 'small':
      return 'small';
    case 'medium':
    default:
      return 'medium';
    case 'large':
      return 'large';
  }
};
