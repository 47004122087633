import * as React from 'react';
import { useContext } from 'react';
import styles from './input.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { IconProps } from '../../components/icon/icon';
import { MediaContext } from '../../media-context/media-context';
import { getDefaultPalette } from '../../util/theme-util';
import { FormElementWrapper } from '../HOCs/form-elements-wrapper/form-elements-wrapper';
import { S_M_L_XL_Size } from 'graphene-core/components/t-shirt-sizes';

const componentName = 'Input';

type Variant = 'text' | 'password' | 'email' | 'number';

export interface InputInternalProps {
  /**
   * (Optional) InputType decides the type of the input field which effects how the text is displayed and validated.
   * InputType can be 'text' | 'password' | 'email' | 'number'
   */
  variant?: Variant;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide additional information about the state of the textarea field
   * whether it is valid.
   */
  infoText?: string;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide details about potential errors as a result of changes
   * to the textarea field.
   */
  errorText?: string;
  /**
   * (Optional): Text provided at the bottom of the textarea field to provide a positive verification message regarding changes
   * to the textarea field.
   */
  successText?: string;
  /**
   * (Optional) Text which will be shown right to the label.
   */
  optionalText?: string;
  /**
   * (Optional) sets the input fields validation state to invalid.
   */
  hasError?: boolean;
  /**
   * (Optional): Description of what data is expected to be provided in the input.
   */
  label?: string;
  /**
   * (Optional): To display an icon to the right of the text
   */
  icon?: React.ReactElement<IconProps>;
  /**
   * (Optional): Defines the height and font size of the input field
   */
  size?: S_M_L_XL_Size;
  /**
   * (Optional): Defines the maximum number of characters allowed to pass validation in the input field. Please note maxLength is allowed
   * to use, but with using maxLength user will not have possibility to write more characters than maxLenth property.
   */
  maxCharacters?: number;
  /**
   * (Optional): A function that is called when the input field icon is clicked.
   */
  onIconClick?: () => void;
}

type InputNativeProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size' | 'ref'>;

export interface InputProps extends InputInternalProps, InputNativeProps {}

type RefType = React.LegacyRef<HTMLInputElement>;

export const Input = React.forwardRef(
  (
    {
      size,
      icon,
      label,
      hasError,
      variant,
      required,
      infoText,
      errorText,
      successText,
      onIconClick,
      optionalText,
      maxCharacters,
      ...nativeProps
    }: InputProps,
    ref: RefType
  ): JSX.Element => {
    const context = useContext(MediaContext);

    const classNames = [styles[size], `${getDefaultPalette().name}-theme`];

    icon && classNames.push(styles['input-with-icon-right']);

    const wrapperClassName = [styles.component];

    context && context.screen === 'Mobile' && wrapperClassName.push(styles['mobile-styling-container']);
    let inputWrapperClassName = [styles['input-wrapper']];
    if (hasError || (maxCharacters && nativeProps.value?.toString()?.length > maxCharacters)) {
      inputWrapperClassName.push(styles['input-wrapper--error']);
    } else {
      inputWrapperClassName = [styles['input-wrapper']];
    }

    const getDimensions = (size) => {
      switch (size) {
        case 'small':
          return { icon: 16, width: '240px' };
        case 'medium':
          return { icon: 20, width: '280px' };
        case 'large':
          return { icon: 20, width: '320px' };
        case 'xlarge':
          return { icon: 20, width: '360px' };
        default:
          return { icon: 16, width: '240px' };
      }
    };

    return (
      <div
        style={{ maxWidth: nativeProps.width ? nativeProps.width : getDimensions(size).width }}
        className={wrapperClassName.join(' ')}
        data-component={componentName}
        {...getDataAttributes(nativeProps)}
      >
        <FormElementWrapper
          label={label}
          size={size}
          value={nativeProps.value as string}
          width={getDimensions(size).width as string}
          dataId={nativeProps?.id}
          optionalText={optionalText}
          infoText={infoText}
          errorText={errorText}
          successText={successText}
          maxCharacters={maxCharacters}
        >
          <div className={inputWrapperClassName.join(' ')}>
            {icon && (
              <div
                role="button"
                onClick={onIconClick}
                aria-label="right icon button"
                className={[styles['input-icon-wrapper'], styles[size]].join(' ')}
              >
                {React.cloneElement(icon, { size: getDimensions(size).icon })}
              </div>
            )}
            <input
              className={classNames.join(' ')}
              onChange={nativeProps.onChange}
              required={!optionalText || required}
              {...nativeProps}
              value={nativeProps.value || null}
              type={variant}
              ref={ref}
            />
          </div>
        </FormElementWrapper>
      </div>
    );
  }
);

Input.defaultProps = {
  variant: 'text',
  required: true,
  hasError: false,
  size: 'medium',
};

Input.displayName = componentName;
