import * as React from 'react';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';
import offlineExporting from 'highcharts/modules/offline-exporting';
import { getDataAttributes } from '../../util/attribute-util';

//This is need to fix the issue for SSR: https://github.com/highcharts/highcharts-react#highcharts-with-nextjs
if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts);
  offlineExporting(Highcharts);
}

export type HighchartsStyledProps = Exclude<HighchartsReact.Props, 'highcharts'>;

export interface HighchartsReactForwardRef {
  chart: Highcharts.Chart;
  container: React.RefObject<HTMLDivElement>;
}

const componentName = 'HighchartsStyled';

export const HighchartsStyled = React.forwardRef<HighchartsReactForwardRef, HighchartsStyledProps>((props, ref) => {
  const optionOverrides: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
      fallbackToExportServer: false,
    },
  };
  return (
    <HighchartsReact
      ref={ref}
      highcharts={Highcharts}
      {...props}
      options={{ ...props.options, ...optionOverrides }}
      data-component={componentName}
      {...getDataAttributes(props)}
    />
  );
});

HighchartsStyled['displayName'] = componentName;
