import * as React from 'react';
import styles from './toggle.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { LabelProps } from '../../components-base/label/label';
import { useId } from '../../hooks/use-id';
import { S_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'Toggle';

export interface ToggleInternalProps {
  /**
   * (Optional) : A label component to render on the left of the toggle.
   */
  labelLeft?: React.ReactElement<LabelProps>;
  /**
   * (Optional) : A label component to render on the right of the toggle.
   */
  labelRight?: React.ReactElement<LabelProps>;
  /**
   * (Optional): Defines the height, width and font size of the toggle.
   */
  size?: S_L_Size;
  /**
   *  Sets whether or not the toggle is rendered in its active state or not.
   */
  active: boolean;
}

type InputNativeProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'size' | 'ref'>;

export interface ToggleProps extends ToggleInternalProps, InputNativeProps {}

type RefType = React.LegacyRef<HTMLInputElement>;

export const Toggle = React.forwardRef(
  ({ active, size, labelLeft, labelRight, required, onClick, id, disabled, ...nativeProps }: ToggleProps, ref: RefType): JSX.Element => {
    const [focused, setFocused] = React.useState(false);

    const wrapperClassName = [styles.component];
    const checkboxClassName = [styles.checkbox, styles[size]];
    const switchClassName = [styles.switch, styles[size]];
    if (active) {
      switchClassName.push(styles.checked);
    }
    if (disabled) {
      switchClassName.push(styles.disabled);
      wrapperClassName.push(styles.disabled);
    }
    if (focused) {
      wrapperClassName.push(styles.focused);
    }
    const elementID = useId(id); // Custom hook that will allocate an Id if passed id is null.

    const onChange = nativeProps.onChange ? nativeProps.onChange : () => ({});

    return (
      <div className={wrapperClassName.join(' ')} data-component={componentName} {...getDataAttributes(nativeProps)}>
        {cloneLabel(labelLeft, size, elementID, styles.spacingRight)}

        <div className={styles.checkboxContainer}>
          <input
            id={elementID}
            role="switch"
            className={checkboxClassName.join(' ')}
            type="checkbox"
            aria-checked={active}
            checked={active}
            disabled={disabled}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            ref={ref}
            {...nativeProps}
            onChange={onChange}
          />
        </div>

        <div data-testid="switch" className={switchClassName.join(' ')}></div>
        {cloneLabel(labelRight, size, elementID, styles.spacingLeft)}
      </div>
    );
  }
);

Toggle.defaultProps = {
  required: true,
  size: 'large',
};

Toggle.displayName = componentName;

function cloneLabel(label: React.ReactElement<LabelProps>, size: S_L_Size, elementID: string, className): React.ReactElement {
  if (label) {
    return (
      <div className={className}>
        {label.props.size
          ? React.cloneElement(label, { htmlFor: elementID })
          : React.cloneElement(label, { size: size, htmlFor: elementID })}
      </div>
    );
  }
  return null;
}
