export class DispatchCustomEvent {
  // We can't reliably feature detect whether the browser has CustomEvent constructor support since Safari returns Object from 'typeof CustomEvent'
  static supportsCustomEventConstructor: boolean = null;

  static Dispatch(target: EventTarget, eventName: string, args: Record<any, any> = {}) {
    let event = null;
    if (DispatchCustomEvent.useCustomEventConstructor()) {
      //Evergreen browsers
      event = new CustomEvent(eventName, { detail: args });
    } else {
      if (typeof document.createEvent === 'function') {
        // IE11, Safari
        event = document.createEvent('CustomEvent');
        event.initCustomEvent(eventName, true, false, args);
      } else {
        return;
      }
    }
    target.dispatchEvent(event);
  }

  static useCustomEventConstructor(): boolean {
    if (DispatchCustomEvent.supportsCustomEventConstructor === null) {
      try {
        new CustomEvent('FooBarBaz');
        DispatchCustomEvent.supportsCustomEventConstructor = true;
      } catch (e) {
        DispatchCustomEvent.supportsCustomEventConstructor = false;
      }
    }
    return DispatchCustomEvent.supportsCustomEventConstructor;
  }
}
