import * as React from 'react';
import { TreeNavigation } from '../tree-navigation/tree-navigation';
import { LabelSubLabelNode } from '../../models/label-sub-label-node';
import styles from './searchable-tree.module.scss';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { getDataAttributes } from '../../util/attribute-util';
import { SearchField } from '../search-field/search-field';
import { LangUtil } from '../../client-shared';

export interface SearchableTreeProps<T> {
  /**
   * callback to handle changes in the search field. If a callback is set, the SearchableTree will disable its internal filtering algorithm.
   * @param str
   */
  searchCallback?: (str: string) => any;
  placeholderText?: string;
  selectionCallback?: (nodeId: string, val: T) => any;
  treeNodes: LabelSubLabelNode<T>[];
  selectedNode?: LabelSubLabelNode<T>;
  searchString?: string;
  searchAutoFocus?: boolean;
  onNodeOpenStateChanged?: (nodeId: string, newState: boolean) => any;
  clearSearchLabel: string;
  submitSearchLabel?: string;
}

const componentName = 'SearchableTree';

export class SearchableTree extends React.Component<SearchableTreeProps<any>, { searchString: string }> {
  treeId = IdEncoderUtil.encodeId('SearchableTree', LangUtil.randomUuid());

  state = {
    searchString: '',
  };

  constructor(props) {
    super(props);
    this.props.searchString && (this.state.searchString = this.props.searchString);
  }

  get filteredNodes() {
    if (this.props.searchCallback || this.state.searchString === '') {
      return this.props.treeNodes;
    }
    return this.props.treeNodes.map((n) => this.filter(n, this.state.searchString.toLocaleLowerCase())).filter((n) => n !== null);
  }

  componentWillReceiveProps(newProps: SearchableTreeProps<any>) {
    if (this.props.searchString !== undefined && this.props.searchString !== this.state.searchString) {
      this.setState({
        searchString: this.props.searchString,
      });
    }
  }

  searchCallback = (searchString: string) => {
    this.setState(
      {
        searchString,
      },
      () => {
        this.props.searchCallback && this.props.searchCallback(searchString);
      }
    );
  };

  /**
   * Returns a node based on how it matches a search string.
   * - If the node's own label matches the search string, it returns the node as it is, with all its children.
   * - Otherwise, if some of its children matches the search string, it returns the node with its children filtered to those matching the search string.
   * @param node The node to filter based on the search string
   * @param searchString The search string to filter the node by
   */
  filter(node: LabelSubLabelNode<any>, searchString: string) {
    const children = node.children.map((c) => this.filter(c, searchString)).filter((c) => c !== null);
    if (node.label.toLocaleLowerCase().includes(searchString)) {
      return new LabelSubLabelNode<any>(node.id, node.label, node.value, true, node.subLabel, node.children);
    } else if (children.length > 0) {
      return new LabelSubLabelNode<any>(node.id, node.label, node.value, true, node.subLabel, children);
    } else {
      return null;
    }
  }

  render() {
    const nodes = this.props.searchCallback ? this.props.treeNodes : this.filteredNodes;
    const str = this.props.searchString !== undefined ? this.props.searchString : this.state.searchString;

    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        <SearchField
          value={str}
          onSearch={this.searchCallback}
          autoSearch={true}
          autoSearchDelay={200}
          placeholder={this.props.placeholderText}
          autoFocus={this.props.searchAutoFocus}
          clearLabel={this.props.clearSearchLabel}
          searchLabel={this.props.submitSearchLabel}
          aria={{
            'aria-controls': this.treeId,
          }}
        />
        <TreeNavigation
          id={this.treeId}
          nodes={nodes}
          onValueSelected={this.props.selectionCallback}
          selectedNode={this.props.selectedNode}
          onNodeOpenStateChanged={this.props.onNodeOpenStateChanged}
        />
      </div>
    );
  }
}

SearchableTree['displayName'] = componentName;
