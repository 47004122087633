import { atoms } from './atoms';

export type AtomsProperties = Parameters<typeof atoms>[0];

type AtomCssClassName = string;

let cssMapsCreated = false;

interface AtomCssRule {
  rule: CSSStyleRule;
  owner: CSSStyleSheet | CSSGroupingRule;
}

const cssAtoms = new Map<AtomCssClassName, AtomCssRule>();

type PendingRuntimeClassRule = {
  atomClassName: AtomCssClassName;
  insertRule: (atom: AtomCssRule) => void;
};

const pendingRuntimeClasses: PendingRuntimeClassRule[] = [];

const vanillaExtractLoadedSymbol = typeof window === 'object' && window['Symbol'] ? Symbol() : '__vanilla-extract-loaded__';

function queueRuntimeClassRuleInsertion(pendingRuntimeClass: PendingRuntimeClassRule) {
  if (typeof document !== 'object') {
    return; // ssr
  }
  if (pendingRuntimeClasses.length === 0) {
    document.querySelectorAll('link[rel=stylesheet]').forEach((link) => {
      if (link[vanillaExtractLoadedSymbol]) {
        // already attached a load event for this CSS link
        return;
      }
      link[vanillaExtractLoadedSymbol] = true;
      link.addEventListener('load', () => {
        const cssAtoms = createCssAtomsMapIfNeeded(true);
        if (pendingRuntimeClasses.length) {
          const pending = [...pendingRuntimeClasses];
          pendingRuntimeClasses.length = 0;
          pending.forEach((pendingRuntimeClass) => {
            const { atomClassName, insertRule } = pendingRuntimeClass;
            const atom = cssAtoms.get(atomClassName);
            if (atom) {
              insertRule(atom);
            } else {
              queueRuntimeClassRuleInsertion(pendingRuntimeClass);
            }
          });
        }
      });
    });
  }
  pendingRuntimeClasses.push(pendingRuntimeClass);
}

function createCssAtomsMapIfNeeded(forceCreate = false): typeof cssAtoms {
  if (cssMapsCreated && !forceCreate) {
    // already initialized
    return cssAtoms;
  }

  const processStyleRule = (rule: CSSStyleRule, owner: CSSStyleSheet | CSSGroupingRule) => {
    const { selectorText } = rule;
    if (selectorText.charAt(0) === '.') {
      // only interested in atomic classes from sprinkles
      cssAtoms.set(selectorText.substr(1), {
        owner,
        rule,
      });
    }
  };

  const isClient = typeof document === 'object';
  for (let s = 0; isClient && s < document.styleSheets.length; s++) {
    const stylesheet = document.styleSheets.item(s);
    if (stylesheet[vanillaExtractLoadedSymbol]) {
      continue;
    }
    stylesheet[vanillaExtractLoadedSymbol] = true;
    for (let sr = 0; sr < stylesheet.cssRules.length; sr++) {
      const rule = stylesheet.cssRules[sr];
      const mediaRule = rule as CSSMediaRule;

      switch (rule.type) {
        case rule.STYLE_RULE:
          processStyleRule(rule as CSSStyleRule, stylesheet);
          break;
        case rule.MEDIA_RULE:
          // responsive props are nested within @media rules
          for (let mr = 0; mr < mediaRule.cssRules.length; mr++) {
            const rule = mediaRule.cssRules.item(mr);
            if (rule.type === rule.STYLE_RULE) {
              processStyleRule(rule as CSSStyleRule, mediaRule);
            }
          }
          break;
      }
    }
  }

  // done initializing
  cssMapsCreated = true;

  return cssAtoms;
}

type RuntimeCssSelector = string;
type RuntimeCssClassName = string;

const atomsToRuntimeClassNames = new Map<RuntimeCssSelector, Map<AtomCssClassName, RuntimeCssClassName>>();

let runtimeClassNameIdCounter = 0;

export function getRuntimeClassNameFromSelector(atomsProperties: AtomsProperties | undefined, postfixSelector: RuntimeCssSelector): string {
  if (atomsProperties) {
    const cssAtoms = createCssAtomsMapIfNeeded();

    const atomClassNames = atoms(atomsProperties).split(' ');
    const runtimeClassNames: string[] = [];

    let styleRuleMap = atomsToRuntimeClassNames.get(postfixSelector);
    if (!styleRuleMap) {
      styleRuleMap = new Map();
      atomsToRuntimeClassNames.set(postfixSelector, styleRuleMap);
    }

    for (const atomClassName of atomClassNames) {
      let runtimeClassName: RuntimeCssClassName = styleRuleMap.get(atomClassName);
      if (!runtimeClassName) {
        runtimeClassName = `${atomClassName}_r${runtimeClassNameIdCounter++}`;
        styleRuleMap.set(atomClassName, runtimeClassName);
        const insertRule = (atom: AtomCssRule) => {
          const { rule, owner } = atom;
          const runtimeCssText = rule.cssText.replace(atomClassName, runtimeClassName + postfixSelector);
          owner.insertRule(runtimeCssText, undefined);
        };
        const atom = cssAtoms.get(atomClassName);
        if (atom) {
          insertRule(atom);
        } else {
          queueRuntimeClassRuleInsertion({
            atomClassName,
            insertRule,
          });
        }
      }
      if (runtimeClassName) {
        runtimeClassNames.push(runtimeClassName);
      }
    }

    return runtimeClassNames.join(' ');
  }

  return '';
}
