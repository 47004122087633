import * as React from 'react';
import {
  ButtonBaseProps,
  ButtonLabelProps,
  ButtonNavigationProps,
  ButtonSizeProps,
  ButtonTypeProps,
} from '../button-base/button-interfaces';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import styles from './secondary-button.module.scss';
import { Icon } from '../icon/icon';
import { Text, TextSizes } from '../text/text';
import { getDataAttributes } from '../../util/attribute-util';
import StylingContext, { useStylingContext } from '../../style-context/styling-context-provider';

export interface SecondaryButtonBaseProps extends ButtonBaseProps, ButtonLabelProps, ButtonSizeProps, ButtonNavigationProps {}

export type SecondaryButtonProps = ButtonTypeProps & SecondaryButtonBaseProps;

const buttonSizeToTextSize = new Map<ButtonSizeProps['size'], TextSizes>([
  ['large', 'font16'],
  ['small', 'font12'],
]);

const buttonBrandSizeToTextSize = new Map<ButtonSizeProps['size'], TextSizes>([
  ['small', 'font16'],
  ['medium', 'font20'],
  ['large', 'font24'],
]);

const Content = React.forwardRef<any, SecondaryButtonProps & ButtonFocusHandlerChildProps>((props, ref) => {
  const { styling } = useStylingContext();

  const classes = [styles['component'], styles[styling]];
  props.size && classes.push(styles[props.size]);

  const attributes: React.HTMLAttributes<HTMLElement> = { id: props.id };
  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const textSize = styling === 'brand' ? buttonBrandSizeToTextSize.get(props.size) : buttonSizeToTextSize.get(props.size);

  let content: React.ReactNode = (
    <Text size={textSize} maxLines={1}>
      {props.label}
    </Text>
  );

  switch (props.navigationType) {
    case 'to-start':
    case 'navigate-left': {
      content = (
        <React.Fragment>
          <Icon size={16} name={props.navigationType} />
          {content}
        </React.Fragment>
      );
      break;
    }

    case 'to-end':
    case 'navigate-right': {
      content = (
        <React.Fragment>
          {content}
          <Icon size={16} name={props.navigationType} />
        </React.Fragment>
      );
      break;
    }
  }

  switch (props.type) {
    case 'button': {
      const buttonAttributes: React.HTMLAttributes<HTMLButtonElement> = { ...attributes };
      props.disabled && (buttonAttributes['disabled'] = true);
      return (
        <button
          ref={ref}
          {...buttonAttributes}
          type="button"
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...getDataAttributes(props)}
        >
          {content}
        </button>
      );
    }
    case 'anchor': {
      const anchorAttributes: React.HTMLAttributes<HTMLAnchorElement> = { ...attributes };
      props.disabled && (anchorAttributes['data-disabled'] = true);
      return (
        <a
          ref={ref}
          target={props.target}
          {...anchorAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...(props.disabled ? {} : { href: props.href })}
          {...getDataAttributes(props)}
        >
          {content}
        </a>
      );
    }
    case 'cosmetic': {
      const divAttributes: React.HTMLAttributes<HTMLDivElement> = { ...attributes };
      props.disabled && (divAttributes['disabled'] = true);
      return (
        <div
          ref={ref}
          role={props.role}
          {...divAttributes}
          onClick={props.onClick}
          className={classes.join(' ')}
          title={props.label}
          {...props.aria}
          {...getDataAttributes(props)}
        >
          {content}
        </div>
      );
    }
  }
});

const componentName = 'SecondaryButton';

export class SecondaryButton extends React.PureComponent<SecondaryButtonProps> {
  static defaultProps: Partial<SecondaryButtonProps> = {
    size: 'medium',
    type: 'button',
  };
  context: React.ContextType<typeof StylingContext>;

  render() {
    const { styling } = this.context;
    const props = {
      ...this.props,
    };
    /**
     * Classic styles do not contain medium sizing;
     * We need to change the this.props.size to 'large' for backwards compatibility.
     */
    if (styling === 'classic' && this.props.size === 'medium') {
      props['size'] = 'large';
    }

    return (
      <ButtonFocusHandler
        initialFocus={this.props.hasFocus}
        render={({ ref, hasFocus, hasHover }) => <Content ref={ref} {...props} hasFocus={hasFocus} hasHover={hasHover} />}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

SecondaryButton['displayName'] = componentName;
SecondaryButton.contextType = StylingContext;
