import * as React from 'react';
import { AriaTabpanelInterface } from '../../AccessibilityProps';
import { IconName } from '../../icon/icon-SVGs';
import { SelectItem } from '../../../models/select-item';
import { getDataAttributes } from '../../../util/attribute-util';

/**
 * Props used by the TabPanel to control the tab
 */
export interface InternalTabProps {
  /**
   * Internal prop for hiding tabs that are no longer selected.
   */
  hidden?: boolean;

  flex?: boolean;

  /**
   * Id used for the dom Element. This property is overwritten by the tabPanel and should not be set manually.
   */
  domId?: string;
}

export interface TabProps {
  /**
   * Unique id of the tab
   */
  id: string;

  /**
   * Optional label to show in the tab header
   */
  label?: string;

  /**
   * Optional summary to show after the label in the tab header, e.g. how many items the tab contain
   */
  summary?: string;

  /**
   * Optional icon to show first in the tab header
   */

  icon?: IconName;

  contextualMenuItems?: SelectItem[];

  /**
   * Optional callback. Invoked when a context item is clicked.
   */
  onContextItemClicked?: (item: SelectItem) => void;

  /**
   * Aria properties for aria-tabPanel
   */
  aria?: AriaTabpanelInterface;

  /**
   * Temporary solution for having data-feature attributes that are unique for the TabPanel bar items and the Tab content areas. See https://elsevier.atlassian.net/browse/GPHN-62.
   */
  dataFeatureLink?: string;

  /**
   * Temporary solution for having data-feature attributes that are unique for the TabPanel bar items and the Tab content areas. See https://elsevier.atlassian.net/browse/GPHN-62.
   */
  dataFeatureContents?: string;

  /**
   * Optional indicator that will indicate that there is something new on the tab (e.g. a new notification)
   */
  showIndicator?: boolean;

  children?: React.ReactNode;
}

const componentName = 'Tab';

export class Tab extends React.Component<TabProps & InternalTabProps, {}> {
  /**
   * Represents a single tab in a tab panel
   */

  render() {
    const internalTabProps = this.props as InternalTabProps;
    const hidden = !!internalTabProps.hidden;
    const style: React.CSSProperties =
      !hidden && internalTabProps.flex
        ? { display: 'flex', flex: '1', flexDirection: 'column', alignItems: 'stretch', overflow: 'hidden' }
        : null;

    return (
      <div
        id={this.props.domId}
        key={this.props.id}
        role={'tabpanel'}
        style={style}
        data-id={this.props.id}
        data-hidden={hidden}
        data-feature={this.props.dataFeatureContents}
        data-component={componentName}
        {...this.props.aria}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </div>
    );
  }
}

Tab['displayName'] = componentName;
