import * as React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'AreaHeader';

export const AreaHeader: React.FC<{ children: React.ReactNode }> = (props) => {
  return (
    <div data-component={componentName} {...getDataAttributes(props)}>
      {props.children}
    </div>
  );
};
AreaHeader.displayName = componentName;
