import * as React from 'react';
import styles from './tree-selection-search.module.scss';
import { TreeSelection } from '../tree-selection/tree-selection';
import { DecorativeSelectionIndicator, ItemTemplate } from '../selection-list/selection-list';
import { PrimaryButton } from '../primary-button/primary-button';
import { SelectItem, SelectItemBuilder } from '../../models/select-item';
import { getDataAttributes } from '../../util/attribute-util';
import { SearchField } from '../search-field/search-field';
import { LangUtil } from '../../client-shared';
import { LocalizedMessage } from '../../util/localized-message';
import { ChildNodeFunctions } from '../../models/child-node';

export interface TreeSelectionSearchProps {
  // styling and custom content

  className?: string;
  barContent?: React.ReactElement | string | LocalizedMessage; // content that appears just below the search bar

  /**
   * Label for selectedChildrenCount sub label. Will be placed after the count e.g. if label is "selected", it will be "2 selected"
   * If no label is given here, but showSelectedCounter is set to true, it will simply show the count
   */
  selectedChildrenCountLabel?: string;
  showSelectedCounter?: boolean;

  // items

  items: SelectItem[];
  /**
   * Specify templates for the different items in the list, making it possible to render custom items.
   */
  itemTemplates?: ItemTemplate<any>[];

  // search

  ariaSearchFieldClearLabel: string;
  searchPlaceholderLabel?: string;

  // apply changes

  /**
   * Per default, any changes made will trigger the application of those changes
   * @param items The selectedItems that will be used to apply the change
   */
  onApplyChanges: (items: SelectItem[]) => void;

  /**
   * If used, called every time a new selection has been made (but not applied). Useful for getting access to temporary changes before they are applied.
   * @param items The currently selected items in state
   */
  onSelectionChanged?: (items: SelectItem[]) => void;

  /**
   * In relation to {@link onSelectionChanged}, this makes it possible to give information to this component about temporary selection changes
   */
  hasSelectionChanged?: boolean;

  // settings

  /**
   * If true, a button will be available to the user, which will control the application of changes instead of the default trigger on change
   */
  applyWithButton?: boolean;

  /**
   * If true, will be able to select children from more than one root parent. If not, selecting from a root parent will disable all items from other root parents.
   */
  selectFromMultipleRootParents?: boolean;

  /**
   * If true, only one item can be selected at the time and therefore there will not be any checkboxes
   */
  useSingleSelection?: boolean;

  /**
   * If enabled, a checkbox or checkIcon is shown as a visual indicator of the selected state of each item.
   * The checkbox option is purely decorative and does not appear in the accessibility tree.
   * Note: If you want to only have this applied to some items, you can set showSelectionIndicator on a specific SelectItem
   */
  decorativeSelectionIndicator?: DecorativeSelectionIndicator;
}

const componentName = 'TreeSelectionSearch';

export class TreeSelectionSearch extends React.Component<
  TreeSelectionSearchProps,
  {
    searchString: string;
    selectedItems: SelectItem[];
  }
> {
  constructor(properties) {
    super(properties);
    this.state = {
      searchString: '',
      // In Pure they extends the SelectItem and adds a selected property, which we need to support
      selectedItems: this.itemsAsFlat.filter((itm) => (itm as any).selected),
    };
  }

  private get itemsAsFlat(): SelectItem[] {
    return this.props.items.reduce((p, c) => p.concat(ChildNodeFunctions.asFlat(c)), []);
  }

  private get hasSelectionChanged(): boolean {
    if (LangUtil.isDefined(this.props.hasSelectionChanged)) {
      return this.props.hasSelectionChanged;
    } else {
      const initiallySelectedItems = this.props.items
        .reduce((p, c) => p.concat(ChildNodeFunctions.asFlat(c)), [])
        .filter((itm) => itm.selected);
      const initiallySelectedItemIds = initiallySelectedItems.map((item) => item.id);

      const currentlySelectedItemIds = this.state.selectedItems.map((item) => item.id);

      let hasChanged = false;
      if (this.state.selectedItems.some((item) => initiallySelectedItemIds.indexOf(item.id) === -1)) {
        // items have been added
        hasChanged = true;
      }
      if (initiallySelectedItems.some((item) => currentlySelectedItemIds.indexOf(item.id) === -1)) {
        // items have been removed
        hasChanged = true;
      }

      return hasChanged;
    }
  }

  private get items(): SelectItem[] {
    const items = this.applySelectionStateToItems(this.props.items);
    if (this.state.searchString !== '') {
      return this.searchItemsAndOpenParents(this.state.searchString, items);
    }
    return items;
  }

  /**
   * Returns the id of the top node that has any child selected.
   * Only used for when you can only select from one root parent at a time
   */
  private get selectedTopNode(): SelectItem {
    if (this.props.selectFromMultipleRootParents) {
      return;
    }

    const selectedItemIds = this.state.selectedItems.map((item) => item.id);
    for (let i = 0; i < this.props.items.length; i++) {
      if (selectedItemIds.indexOf(this.props.items[i].id) !== -1) {
        // the top node itself has been selected
        return this.props.items[i];
      }

      const selectedChildren = this.props.items[i].children
        .reduce((p, c) => p.concat(ChildNodeFunctions.asFlat(c)), [])
        .filter((itm) => {
          return selectedItemIds.indexOf(itm.id) !== -1;
        });

      if (selectedChildren.length > 0) {
        // some of the top node's children have been selected
        return this.props.items[i];
      }
    }

    return;
  }

  /**
   * If only one root parent is selectable at a time, this will return all the currently selectable children ids
   * based on what is currently the selected top node (having any children selected)
   */
  private get selectableChildrenIds(): string[] {
    if (this.props.selectFromMultipleRootParents) {
      return;
    }

    // find selectable items, based on which top node is selected
    const selectedTopNode: SelectItem = this.selectedTopNode && this.props.items.find((item) => item.id === this.selectedTopNode.id);
    const selectedTopNodeFlatChildren: SelectItem[] = selectedTopNode
      ? selectedTopNode.children.reduce((p, c) => p.concat(ChildNodeFunctions.asFlat(c)), [])
      : [];
    const selectableChildrenIds: string[] = selectedTopNodeFlatChildren.map((item) => item.id);

    return selectableChildrenIds;
  }

  /**
   * Since we might loose the type from each object when we filter for search or for selectable state,
   * we find the original items here before we apply the changes.
   */
  handleApplyChanges = () => {
    const allItems = this.itemsAsFlat;
    const selectedItemIds = this.state.selectedItems.map((item) => item.id);
    const selectedItems = allItems.filter((item) => selectedItemIds.indexOf(item.id) !== -1);

    if (this.props.onApplyChanges) {
      this.props.onApplyChanges(selectedItems);
    } else {
      console.log('Function for applying changes to selected items missing. Use onApplyChanges from the properties.');
    }
  };

  render() {
    return (
      <TreeSelection
        className={this.props.className}
        headerContent={this.renderSearchField()}
        barContent={this.props.barContent}
        bottomContent={this.props.applyWithButton ? this.renderApplyButton() : null}
        itemTemplates={this.props.itemTemplates}
        selectedChildrenCountLabel={this.props.selectedChildrenCountLabel}
        showSelectedCounter={this.props.showSelectedCounter ?? true}
        items={this.items}
        showElementsAsOpen={this.state.searchString !== '' ? true : null}
        selectedItems={this.state.selectedItems}
        onSelectionChanged={this.handleSelectionChanged}
        getExternalSelectedCounter={this.getItemSelectedChildrenCount}
        getInitialItem={this.getItem}
        useSingleSelection={this.props.useSingleSelection}
        decorativeSelectionIndicator={this.props.decorativeSelectionIndicator ? this.props.decorativeSelectionIndicator : 'checkbox'}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  clearSelection() {
    this.updateSelection([]);
  }

  /**
   * This can be used outside TreeSelectionSearch to update its state, and keep it in sync with your outside changes
   * @param items The new items to set selectedItems to
   */
  updateSelection(items: SelectItem[]) {
    this.setState((s) => ({
      ...s,
      selectedItems: items,
    }));
  }

  private handleSelectionChanged = (allSelectedItems: SelectItem[]) => {
    this.setState((s) => ({
      ...s,
      selectedItems: allSelectedItems,
    }));
    this.props.onSelectionChanged && this.props.onSelectionChanged(allSelectedItems);

    // if apply is not a button, do this
    if (!this.props.applyWithButton) {
      this.handleApplyChanges();
    }
  };

  private renderApplyButton() {
    return (
      <div className={styles['apply-button']}>
        <PrimaryButton
          {...getDataAttributes(this.props, '-apply')}
          type={'button'}
          disabled={!this.hasSelectionChanged}
          label={'Apply'}
          size={'large'}
          onClick={() => this.handleApplyChanges()}
        />
      </div>
    );
  }

  private renderSearchField() {
    return (
      <SearchField
        {...getDataAttributes(this.props, '-search')}
        autoSearch={true}
        placeholder={this.props.searchPlaceholderLabel}
        clearLabel={this.props.ariaSearchFieldClearLabel}
        onSearch={(searchString) => {
          this.setState((s) => ({
            ...s,
            searchString,
          }));
        }}
      />
    );
  }

  private getItem = (nodeId: string): any => {
    return this.itemsAsFlat.find((item) => item.id === nodeId);
  };

  private getItemSelectedChildrenCount = (itemId: string): number => {
    const item = this.itemsAsFlat.find((itm) => itm.id === itemId);
    let count = 0;
    if (item) {
      const children = item.children.reduce((p, c) => p.concat(ChildNodeFunctions.asFlat(c)), []);
      children.forEach((childItem) => {
        if (this.state.selectedItems.some((selectedItem) => selectedItem.id === childItem.id)) {
          count++;
        }
      });
    }

    return count;
  };

  /**
   * Recursive function that returns items with select state set based on how many root parents can be selected from simultaneously.
   * Also, sets state to disabled for any item that has no enabled children.
   * @param items
   */
  private applySelectionStateToItems = (items: SelectItem[]): SelectItem[] => {
    return items.map((item) => {
      const children = this.applySelectionStateToItems(item.children);

      let isSelectable = false;

      if (this.selectedTopNode?.id === item.id) {
        isSelectable = true;
      } else if (item.isSelectable === false) {
        // item is set to not be selectable from outside the component
        isSelectable = item.isSelectable;
      } else if (this.selectableChildrenIds?.length === 0 && !this.selectedTopNode) {
        // either all items are selectable or no children has been selected from a parent yet
        isSelectable = true;
      } else if (this.selectableChildrenIds?.length > 0) {
        // can only select from one root parent at a time
        if (this.selectableChildrenIds.indexOf(item.id) !== -1) {
          isSelectable = true;
        }
      } else if (children.some((child) => child.isSelectable)) {
        // if this is a parent, isSelectable is true because it has selectable children
        isSelectable = true;
      } else if (this.props.selectFromMultipleRootParents) {
        isSelectable = true;
      }
      return new SelectItemBuilder(item.label, item.value, item.icon, children, item.isOpen, isSelectable)
        .withSubLabel(item.subLabel)
        .withDataFeature(item.dataFeature)
        .build();
    });
  };

  /**
   * Recursive filter function that returns any items that matches the search string.
   * Parents to matching items are also returned.
   * @param searchString
   * @param items
   * @param parentChain
   */
  private searchItemsAndOpenParents = (searchString: string, items: SelectItem[], parentChain: SelectItem[] = []): SelectItem[] => {
    const filteredItems = items.map((item) => {
      if (item.children.length > 0) {
        const children = this.searchItemsAndOpenParents(searchString, item.children, [...parentChain, item]);

        // if the children match, then its a match so return them
        if (children.length > 0) {
          return new SelectItemBuilder(item.label, item.value, item.icon, children, item.isOpen, item.isSelectable)
            .withSubLabel(item.subLabel)
            .build();
        }
      }

      // if the node matches the search string, return it along with all its children
      if (item.label.toLocaleLowerCase().includes(searchString.toLocaleLowerCase())) {
        // Open Parents
        parentChain.forEach((parent) => (parent.isOpen = true));
        return item;
      }
      return null;
    });

    return filteredItems.filter((itm) => itm !== null);
  };
}

TreeSelectionSearch['displayName'] = componentName;
