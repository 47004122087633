import * as React from 'react';
import styles from './metric-list.module.scss';
import { MetricItem, MetricItemProps, MetricItemSize } from './metric-item/metric-item';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export type MetricListItem = Omit<MetricItemProps, 'size' | 'className'> & { id: string };

export interface MetricListProps {
  /**
   *  An array of metric items to render.
   */
  metricItems: MetricListItem[];

  /**
   *  A description to display. Will be shown beneath the metrics.
   */
  description?: React.ReactNode;

  /**
   *  Link to details. Will be shown to the right of the metrics.
   */
  details?: React.ReactNode;

  /**
   *  The size of the metrics.
   */
  size?: MetricItemSize;

  /**
   * Optional label control, where the label can be replaced by e.g. a popup-menu
   * @param item
   */
  labelControl?: (item: MetricListItem) => React.ReactNode;
}

const componentName = 'MetricList';

export class MetricList extends React.Component<MetricListProps, { isMounted: boolean }> {
  descriptionId: string = IdEncoderUtil.encodeId('', LangUtil.randomUuid());
  private metricList;
  //Defined by UX
  private width = 300;

  constructor(props) {
    super(props);
    this.state = {
      isMounted: false,
    };
  }

  get descriptionWidth() {
    if (this.state.isMounted) {
      if (this.metricList.offsetWidth > this.width) {
        return this.metricList.offsetWidth;
      } else {
        return this.width;
      }
    }
    return null;
  }

  componentDidMount() {
    this.setState({ isMounted: true });
  }

  renderDescription() {
    if (this.props.description && this.state.isMounted) {
      return (
        <div id={this.descriptionId} style={{ width: this.descriptionWidth }} className={styles['description']}>
          {this.props.description}
        </div>
      );
    }
  }

  renderDetails() {
    if (this.props.details) {
      return <div className={styles['details']}>{this.props.details}</div>;
    }
  }

  renderItem = (metricItem: MetricListItem) => {
    return (
      <MetricItem
        key={metricItem.id}
        data-key={`MetricList_MetricItem-${metricItem.id}`}
        labelControl={this.props.labelControl}
        className={styles['metric']}
        {...metricItem}
        size={this.props.size}
      />
    );
  };

  render() {
    let containerClass = 'large';
    if (this.props.size === 'small') {
      containerClass = 'small';
    }

    const classes = [styles['component'], styles[containerClass]];

    return (
      <div
        ref={(metricList) => {
          this.metricList = metricList;
        }}
        className={classes.join(' ')}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <div className={styles['metrics-wrapper']}>
          <ol className={styles['metrics']} aria-describedby={this.descriptionId}>
            {this.props.metricItems.map(this.renderItem)}
          </ol>
          {this.renderDetails()}
        </div>
        {this.renderDescription()}
      </div>
    );
  }
}

MetricList['displayName'] = componentName;
