import * as React from 'react';
import { useRef } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { IconButton } from '../icon-button/icon-button';
import styles from './callout.module.scss';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import { CommandButton } from '../command-button/command-button';
import { ErrorBoundaryContentLabelProps } from '../error-boundary/error-boundary-content/error-boundary-content';
import { ErrorBoundary } from '../error-boundary/error-boundary';
import { defaultErrorBoundary } from '../graphene-root';
import { getDataAttributes } from '../../util/attribute-util';

type Side = 'top' | 'right' | 'bottom' | 'left';

const componentName = 'Callout';
export interface CalloutProps {
  /**
   *  The content of the callout when open
   */
  children: React.ReactNode;

  /**
   * The trigger component to open the callout
   */
  trigger: React.ReactNode;

  /**
   * Value to indicate if the callout is open or not
   */
  isOpen: boolean;

  /**
   * To indicate which side the callout should open, if there is enough space
   * If not enough space the callout will find another suitable side to choice
   * Default is 'right'
   */
  side?: Side;

  /**
   * Should the footer be hidden or not
   * Default is to hide footer
   */
  hideFooter?: boolean;

  /**
   * Label to the CommandButton in the footer
   */
  commandButtonLabel?: string;

  /**
   * Custom function to be fired when user presses CommandButton in footer if present
   */
  onCommandButtonClicked?: () => void;

  /**
   * Aria label for the close icon
   */
  closeAriaLabel: string;

  /**
   *  with either div or span to work with Radix
   * Defaults to 'div'
   */
  as?: 'div' | 'span';

  /**
   * ErrorBoundary used if an error happens in the callout.
   * Defaults to some defaults value for the error boundary
   */
  errorBoundary?: ErrorBoundaryContentLabelProps;

  /**
   * Method to cancel onClose method
   * If false, cancel event
   * If true, do nothing and let onClosed happen
   */
  onClosing?: () => boolean;

  /**
   * Method which is called when callout is closed
   */
  onClosed: () => void;

  /**
   * Hide the arrow or not
   * Defaults to false
   */
  hideArrow?: boolean;

  /**
   * Event handler called when auto-focusing on close
   * Default is to focus trigger element
   */
  onCloseAutoFocus?: (event: Event) => void;

  /**
   * if set to true will remove padding around the callout content,
   * will also remove the 'x' button as without padding there is no space for it
   */
  stripPadding?: boolean;

  /**
   * The preferred alignment against the anchor. May change when collisions occur.
   */
  align?: 'start' | 'center' | 'end';

  /**
   * An offset in pixels from the "start" or "end" alignment options.
   */
  alignOffset?: number;
}

export const Callout = ({
  isOpen,
  trigger,
  onClosed,
  onClosing,
  children,
  side = 'right',
  hideFooter = true,
  commandButtonLabel,
  onCommandButtonClicked,
  closeAriaLabel,
  as = 'div',
  hideArrow = false,
  onCloseAutoFocus,
  errorBoundary = defaultErrorBoundary,
  stripPadding = false,
  align = 'center',
  alignOffset = 0,
  ...props
}: CalloutProps) => {
  const triggerRef = useRef(null);
  const triggerElement = React.createElement(as, { ref: triggerRef }, trigger);

  const handleOnClose = () => {
    const value = onClosing?.() ?? true;
    if (!value) {
      return;
    }
    onClosed();
  };

  const defaultOnCloseAutoFocus = () => {
    ((triggerRef.current as HTMLElement)?.firstElementChild as any)?.focus();
  };

  const classNames = [getPaletteClassName({ name: getDefaultPalette().name }), styles['container']];

  stripPadding && classNames.push(styles['without-padding']);

  return (
    <Popover.Root open={isOpen} defaultOpen={false} modal={true}>
      <Popover.Trigger asChild>
        <div className={styles['trigger']}>{triggerElement}</div>
      </Popover.Trigger>
      <Popover.Content
        onCloseAutoFocus={onCloseAutoFocus != null ? onCloseAutoFocus : defaultOnCloseAutoFocus}
        sideOffset={0}
        align={align}
        alignOffset={alignOffset}
        onInteractOutside={handleOnClose}
        onEscapeKeyDown={handleOnClose}
        side={side}
        className={classNames.join(' ')}
      >
        <div data-component={componentName} {...getDataAttributes(props)}>
          <div className={styles['content']}>
            <ErrorBoundary {...errorBoundary}>{children}</ErrorBoundary>
          </div>
          {hideFooter ? null : (
            <div className={styles['footer']}>
              {commandButtonLabel ? (
                <CommandButton
                  data-testid="close-footer"
                  type="button"
                  label={commandButtonLabel}
                  onClick={() => {
                    onCommandButtonClicked?.();
                    handleOnClose();
                  }}
                />
              ) : null}
            </div>
          )}
          {hideArrow ? null : <Popover.Arrow className={styles['arrow']} data-testid={'arrow'} />}
          {!stripPadding && (
            <Popover.Close asChild>
              <div className={styles['close']}>
                <IconButton aria={{ 'aria-label': closeAriaLabel }} icon="delete" type="button" size="medium" onClick={handleOnClose} />
              </div>
            </Popover.Close>
          )}
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

Callout['displayName'] = componentName;
