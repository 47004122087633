import { useState } from 'react';
import { themeClassName } from '../vanilla-extract/themes/theme.css';

/**
 * The supported palette names in a theme.
 */
export type PaletteName = 'palette-light' | 'palette-dark';

/**
 * The palette names that are part of a theme
 */
export const PaletteNames: PaletteName[] = ['palette-light', 'palette-dark'];

/**
 * A palette is a combination of a name and whether it's high contrast
 */
export type Palette = {
  name: PaletteName;
  highContrast?: boolean;
};

export interface TokenVariables {
  '--color-interaction'?: string;
  '--color-brand'?: string;
  '--color-base'?: string;
  '--color-page-contrast-1'?: string;
  '--color-page-contrast-2'?: string;
  '--color-input-base'?: string;
  '--color-input-contrast-1'?: string;
  '--color-input-contrast-2'?: string;
  '--color-action-contrast'?: string;
  '--color-action-highlight'?: string;
  '--color-action-dimmed'?: string;
  '--color-select-contrast-1'?: string;
  '--color-select-contrast-2'?: string;
  '--color-interaction-disabled'?: string;
  '--color-interaction-placeholder'?: string;
  '--color-text-primary'?: string;
  '--color-text-secondary'?: string;
  '--color-text-header'?: string;
  '--color-text-link'?: string;
  '--color-text-inverse'?: string;
  '--color-success'?: string;
  '--color-success-dimmed'?: string;
  '--color-error'?: string;
  '--color-error-dimmed'?: string;
  '--color-warning'?: string;
  '--color-warning-dimmed'?: string;
  '--color-info'?: string;
  '--color-info-dimmed'?: string;
  '--color-highlight'?: string;
  '--color-highlight-dimmed'?: string;
}

export const PaletteVariableNamesMap = new Map<string, string>([
  ['--color-interaction', 'The primary interaction color'],
  ['--color-brand', 'The primary brand color'],

  ['--color-base', 'The color of the entire page, e.g. white for light palette'],
  ['--color-page-contrast-1', 'First level of contrast for the page, e.g. a light grey panel'],
  ['--color-page-contrast-2', 'Second level of contrast for the page, e.g. a grey panel'],
  ['--color-page-contrast-3', 'Third level of contrast on the page, e.g. for borders around other contrast levels'],

  ['--color-input-base', 'Base color of form input elements, e.g. white input fields or a tree selection'],
  ['--color-input-contrast-1', 'The border of text fields, radio buttons, check boxes etc.'],
  ['--color-input-contrast-2', 'The focus/hover border of text fields, radio buttons, check boxes etc.'],

  [
    '--color-action-contrast',
    'The contrast color of an action against the background, e,g. a light grey for a command button during hover',
  ],
  ['--color-action-highlight', 'The highlight color of an action, e,g. during hover of a button'],
  ['--color-action-dimmed', 'A dimmed down color of an action, e.g. during a pressed state of a button'],

  [
    '--color-select-contrast-1',
    'An affordance color for a select user flow, e.g. during hover/focus on a selectable tree item, or an item in a list',
  ],
  ['--color-select-contrast-2', 'Second level contrast affordance color for a select user flow, e.g.for selected tree items'],

  ['--color-interaction-disabled', 'Background of disabled form elements, e.g. a greyed out button'],
  ['--color-interaction-placeholder', 'Placeholder in form elements such as input fields'],

  ['--color-text-primary', 'Default color for text, e.g. the text in a paragraph'],
  ['--color-text-secondary', 'Color for text that should have a lower visual weight, indicating it is secondary information'],
  ['--color-text-header', 'Color of headings'],
  ['--color-text-link', 'Color of links (HTML a elements)'],
  [
    '--color-text-inverse',
    'The inverse text color, for use when the background for the text has been inverted, e.g. light to dark background',
  ],

  ['--color-success', 'The color of a success status or message, typically green'],
  ['--color-success-dimmed', 'A toned down version of a success status or message, typically a light green'],
  ['--color-error', 'The color of an error status or message, typically red'],
  ['--color-error-dimmed', 'A toned down version of an error status or message, typically a light red'],
  ['--color-warning', 'The color of a warning status or message, typically yellow'],
  ['--color-warning-dimmed', 'A toned down version of a warning status or message, typically a light yellow'],
  ['--color-info', 'The color of an informational status or message, typically blue'],
  ['--color-info-dimmed', 'A toned down version of an informational status or message, typically a light blue'],
  ['--color-highlight', 'Color used to draw attention to an element by highlighting it on the page'],
  ['--color-highlight-dimmed', 'A toned down version of the highlight color'],
]);

/**
 * The CSS variable names that represent a color palette in a theme
 */
export const PaletteVariableNames = Array.from(PaletteVariableNamesMap.keys());

/**
 * Adds an observable dependency on the palette version
 */
export function useThemePaletteVariablesVersion(): { onThemePaletteVariablesUpdated: () => void } {
  const [, setVersion] = useState(() => 0);
  return {
    onThemePaletteVariablesUpdated() {
      setVersion((version) => version + 1);
    },
  };
}

/**
 * Converts a hex string '#<6-hex-chars>' to the equivalent rgb components, e.g. '0, 0, 0' for use in a rgba CSS variable
 */
export function hexStringToRgbVariableValue(hex: string): string {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function (m, r, g, b) {
    return r + r + g + g + b + b;
  });
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? `${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}` : '';
}

/** Persists the specified palette as the default using local storage */
export function setDefaultPalette(palette: Palette) {
  localStorage.setItem('palette', JSON.stringify(palette));
}

/** Gets the default palette from local storage, falling back to a light palette */
export function getDefaultPalette(): Palette {
  const lastPalette = localStorage?.getItem('palette');
  if (lastPalette) {
    try {
      const defaultPalette: Palette = JSON.parse(lastPalette);
      if (PaletteNames.indexOf(defaultPalette.name) !== -1) {
        return defaultPalette;
      }
    } catch (_) {
      // ignored
    }
  }
  return {
    name: 'palette-light',
    highContrast: false,
  };
}

export function convertHEXtoHSLA(hex: string, opacity: number, lighten: number): string {
  const reHex = /[0-9A-Fa-f]{6}/g;

  const _lighten = lighten ? lighten : 0;
  const _hex = reHex.test(hex) ? hex : '#000000';
  const _opacity = opacity ? opacity : 1;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);

  let r: number = parseInt(result[1], 16);
  let g: number = parseInt(result[2], 16);
  let b: number = parseInt(result[3], 16);

  (r /= 255), (g /= 255), (b /= 255);
  const max: number = Math.max(r, g, b),
    min: number = Math.min(r, g, b);
  let h: number,
    s: number,
    l: number = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d: number = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100 + _lighten;
  l = Math.round(l);
  h = Math.round(360 * h);

  return `hsla(${h}, ${s}%, ${l}%, ${_opacity})`;
}

// Converts a hex (with optional opacity) to a 'solid' rgb color
export const convertHEXtoRGB = (hex: string, opacity?: number): string => {
  const reHex = /[0-9A-Fa-f]{6}/g;

  const _hex = reHex.test(hex) ? hex : '#000000';
  const _opacity = opacity ? opacity : 1;

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(_hex);

  let r: number = parseInt(result[1], 16);
  let g: number = parseInt(result[2], 16);
  let b: number = parseInt(result[3], 16);

  if (_opacity < 1) {
    r = 255 - _opacity * (255 - r);
    g = 255 - _opacity * (255 - g);
    b = 255 - _opacity * (255 - b);
  }
  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * Resolved for whether the UI is considered to be in high contrast mode.
 * The product should persist this option to ensure the experience remains consistent between sessions and page loads.
 */
export type HighContrastResolver = () => boolean | undefined;

let currentHighContrastResolver: HighContrastResolver = undefined;

/**
 * Sets the resolved for determining whether the product is in high contrast mode.
 */
export function setHighContrastResolver(resolver: HighContrastResolver) {
  currentHighContrastResolver = resolver;
}

/**
 * Gets the CSS class names that contains the CSS variable values for the specified palette.
 * If high contrast in the palette is undefined or null, the current high contrast resolver will be used.
 **/
export function getPaletteClassName(palette: Palette) {
  let highContrast = palette.highContrast;
  if (palette.highContrast === undefined || palette.highContrast === null) {
    if (currentHighContrastResolver) {
      highContrast = currentHighContrastResolver();
    }
  }
  const classNames = [
    palette.name, // legacy scss
    themeClassName,
  ];
  if (highContrast) {
    // legacy scss
    classNames.push(' palette-high-contrast');
  }
  return classNames.join(' ');
}

/**
 * Finds the closes palette class that applies to the specified element.
 * For example, given a drop down component, use the component element to get the palette class and apply it
 * to a popup menu shown via a React Portal.
 */
export function getPaletteClassFromElement(element: HTMLElement) {
  let currentElement = element;
  while (currentElement != null) {
    const paletteClasses = [];
    for (let i = 0; i < currentElement.classList.length; i++) {
      const className = currentElement.classList.item(i);
      if (className.startsWith('palette-')) {
        paletteClasses.push(className);
      }
    }
    if (paletteClasses.length > 0) {
      return paletteClasses.join(' ');
    }
    if (currentElement.parentNode instanceof HTMLElement) {
      currentElement = currentElement.parentNode;
    } else {
      break;
    }
  }
  return '';
}

/**
 * Gets the CSS rules for a theme keyed by the palette names
 */
export function getThemeCSSStyleRules(): { [paletteName: string]: CSSStyleRule } {
  const themeRules: { [paletteName: string]: CSSStyleRule } = {};
  for (let i = 0; i < document.styleSheets.length; i++) {
    const stylesheet = document.styleSheets.item(i);
    if (stylesheet instanceof CSSStyleSheet) {
      try {
        for (let r = 0; r < stylesheet.cssRules.length; r++) {
          const rule = stylesheet.cssRules.item(r);
          if (rule instanceof CSSStyleRule) {
            if (rule.selectorText.startsWith('.palette-')) {
              themeRules[rule.selectorText.substr(1)] = rule;
            }
          }
        }
      } catch (e) {
        console.warn(e);
      }
    }
  }
  return themeRules;
}
