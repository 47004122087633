import * as React from 'react';
import styles from './select-delete-label.module.scss';
import { Label } from '../label/label';
import { Icon } from '../icon/icon';
import { getDataAttributes } from '../../util/attribute-util';

export interface SelectDeleteLabelProps {
  label: string;
  onDelete: () => any;
  onLabelClicked?: () => any;
}

const componentName = 'SelectDeleteLabel';

export class SelectDeleteLabel extends React.Component<SelectDeleteLabelProps> {
  deleteHandler: React.MouseEventHandler<any> = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    this.props.onDelete();
  };

  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        <span className={styles['label']} onClick={this.props.onLabelClicked}>
          <Label text={this.props.label} type="small" />
        </span>
        <div data-testid={'delete-icon'} className={styles['deleteWrapper']} onClick={this.deleteHandler}>
          <span className={styles['icon']}>
            <Icon name="trash" />
          </span>
        </div>
      </div>
    );
  }
}

SelectDeleteLabel['displayName'] = componentName;
