import * as React from 'react';
import { Highchart, HighchartDataSeries, HighchartTitlesProps } from '../highchart/highchart';
import { getDataAttributes } from '../../util/attribute-util';

export interface LineChartProps extends HighchartTitlesProps {
  id: string;
  name: string;
  lineChartOptions?: LineChartOptions;
  dataSeries: HighchartDataSeries[];
  onClick?: (name: string) => any;
  zoomType?: 'x' | 'xy' | 'y';
  useToolTip?: boolean;
}

export interface LineChartOptions {
  highlightedLineId: string;
  highlightedLineWidth: number;
}

/**
 * @deprecated use HighchartsStyled instead
 */

const componentName = 'LineChart';

export class LineChart extends React.Component<LineChartProps, {}> {
  private highchartRef: React.RefObject<Highchart> = React.createRef();

  render() {
    return (
      <Highchart
        ref={this.highchartRef}
        type={'line'}
        title={this.props.title}
        onClick={this.props.onClick}
        zoomType={this.props.zoomType}
        xAxisTitle={this.props.xAxisTitle}
        yAxisTitle={this.props.yAxisTitle}
        useToolTip={this.props.useToolTip}
        dataSeries={this.props.dataSeries}
        lineChartOptions={this.props.lineChartOptions}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  public reflow(): void {
    this.highchartRef.current.reflow();
  }
}

LineChart['displayName'] = componentName;
