import { IconName, icons } from './icon-SVGs';

//add a map from existing icon names to themselves.
const identityMappings = Object.getOwnPropertyNames(icons).reduce((o, n) => {
  o[n] = n;
  return o;
}, {});

// To aid in adding icons to entities such as system names, we use aliases to aid in resolving the right icon
const aliases: { [key: string]: IconName } = {
  ...identityMappings,
  researchoutput: 'publication',
  writtenproduction: 'publication',
  ueoexternalorganisation: 'externalorganisation',
  award: 'funding',
  upmproject: 'project',
  era2014person: 'era_person',
  era2014researchoutput: 'era_research_output',
  era2014portfolio: 'era-portfolio',
  era2018fieldofresearch: 'era-field-of-research',
};

/**
 * Adds a mapping from the given alias to an IconName.
 * @param {string} alias the alias, e.g. a system name of a model type in Pure
 * @param {IconName} iconName the name of the icon to show
 */
export function registerIconNameAlias(alias: string, iconName: IconName) {
  aliases[alias.toLowerCase()] = iconName;
}

/**
 * Get an IconName from an alias
 */
export function getIconNameFromAlias(alias: string): IconName | undefined {
  return aliases[alias.toLowerCase()];
}
