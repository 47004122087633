import * as React from 'react';
import { createReactStoreContext, StoreContext, StoreContextPropertyName } from './store-context';
import { DefaultStoreContext } from './default-store-context';

export interface StoreContextOwner {
  storeContext?: StoreContext;
  children: React.ReactNode;
}

/**
 * Establishes a store context that is either passed in through props or a default context.
 * This context is made made available to children.
 */
export class StoreContextProvider extends React.Component<StoreContextOwner, StoreContextOwner> {
  constructor(props, context: any) {
    super(props, context);
    if (!this.props.storeContext) {
      // no explicit context, so use the default
      this.state = {
        storeContext: new DefaultStoreContext(),
        children: props.children,
      };
    }
  }

  static childContextTypes = createReactStoreContext();

  getChildContext(): any {
    return { [StoreContextPropertyName]: this.props.storeContext || this.state.storeContext };
  }

  render() {
    // eslint-disable-next-line react/prop-types
    return React.Children.only(this.props.children);
  }
}
