import * as React from 'react';
import { ReactElement } from 'react';
import styles from './template-selector.module.scss';
import { IconName } from '../icon/icon-SVGs';
import { AriaRoletypeInterface } from '../AccessibilityProps';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Label } from '../label/label';
import { Paragraph } from '../paragraph/paragraph';
import { Badge } from '../badge/badge';
import { Inline } from '../inline/inline';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import { getDataAttributes } from '../../util/attribute-util';

export interface TemplateSelectorProps {
  /**
   * 'primary' is a button styled as a primary button with a centered icon and title
   * 'secondary' is a button styled as a secondary button with a centered icon and title
   * 'content' is a button styled as a secondary button with a title and different optional content: icon, description, badge and image
   */
  type: 'primary' | 'secondary' | 'content';

  /**
   * Optional icon
   */
  icon?: IconName;

  /**
   * The title to show in the button
   */
  title: string;

  /**
   * Callback for when the button is clicked
   * @param e the event which triggered the callback
   */
  onClick: (e?: React.MouseEvent<any>) => void;

  /**
   * Aria properties available on any role
   */
  aria?: AriaRoletypeInterface;

  /**
   * Optional width of the button. Default is 210px
   */
  width?: string;

  /**
   * Optional height of the button. Min-height is 140px and default is auto
   */
  height?: string;

  /**
   * Optional description for the 'content' type
   */
  description?: string;

  /**
   * Optional badge for the 'content' type
   */
  badgeLabel?: string;

  /**
   * Optional image for the 'content' type
   */
  imgSrc?: string;

  /**
   * Alt text for the optional image on the 'content' type
   */
  imgAlt?: string;

  /**
   * Optional SVG for the 'content' type.
   * NOTE: to make the svg accessible, add '<title>Your alt text</title>' as the first (important) child of your SVG.
   * If the SVG is purely decorative, it does not need the '<title>Your alt text</title>'
   */
  svg?: ReactElement;
}

const Content = React.forwardRef<any, TemplateSelectorProps & ButtonFocusHandlerChildProps>((props, ref) => {
  const classes = [styles['component']];
  props.icon && classes.push(styles['hasIcon']);

  const attributes: React.HTMLAttributes<HTMLElement> = {};

  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const width = props.width ? props.width : '210px';
  const height = props.height ? props.height : 'auto';
  const style: React.CSSProperties = {
    width: width,
    height: height,
  };

  switch (props.type) {
    case 'primary': {
      classes.push(styles['primary']);
      return (
        <button
          className={classes.join(' ')}
          onClick={props.onClick}
          type={'button'}
          title={props.title}
          style={style}
          ref={ref}
          {...props.aria}
          {...attributes}
          {...getDataAttributes(props)}
        >
          <Stack spacing={'8dp'} horizontalAlignment={'center'} verticalAlignment={'middle'}>
            {props.icon && <Icon size={32} name={props.icon} />}
            <Label text={props.title} type={'field'} maxLines={3} />
          </Stack>
        </button>
      );
    }
    case 'secondary': {
      classes.push(styles['secondary']);
      return (
        <button
          className={classes.join(' ')}
          onClick={props.onClick}
          type={'button'}
          title={props.title}
          style={style}
          ref={ref}
          {...props.aria}
          {...attributes}
          {...getDataAttributes(props)}
        >
          <Stack spacing={'8dp'} horizontalAlignment={'center'} verticalAlignment={'middle'}>
            {props.icon && <Icon size={32} name={props.icon} />}
            <Label text={props.title} type={'field'} maxLines={3} />
          </Stack>
        </button>
      );
    }
    case 'content': {
      classes.push(styles['content']);
      return (
        <button
          className={classes.join(' ')}
          onClick={props.onClick}
          type={'button'}
          title={props.title}
          style={style}
          ref={ref}
          {...props.aria}
          {...attributes}
          {...getDataAttributes(props)}
        >
          <Stack spacing={'8dp'}>
            <Inline wrap={false} verticalAlignment={'top'}>
              {props.icon && <Icon size={32} name={props.icon} />}
              {props.badgeLabel && <Badge variant={'informationSubtile'} text={props.badgeLabel} />}
            </Inline>
            <Label text={props.title} type={'field'} maxLines={3} />
            {props.description && (
              <Paragraph color={'default'} type={'font12'}>
                {props.description}
              </Paragraph>
            )}
            {props.imgSrc && <img className={styles['template-image']} src={props.imgSrc} alt={props.imgAlt} />}
            {props.svg && <div className={styles['svg']}>{props.svg}</div>}
          </Stack>
        </button>
      );
    }
  }
});

const componentName = 'TemplateSelector';

export class TemplateSelector extends React.Component<TemplateSelectorProps, {}> {
  render() {
    return (
      <ButtonFocusHandler
        render={({ ref, hasFocus, hasHover }) => <Content ref={ref} {...this.props} hasFocus={hasFocus} hasHover={hasHover} />}
        data-component={componentName}
      />
    );
  }
}

TemplateSelector['displayName'] = componentName;
