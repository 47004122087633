export interface ChildNode<T extends ChildNode<T>> {
  children: T[];
}

export const ChildNodeFunctions = {
  asFlat<T extends ChildNode<T>>(node: ChildNode<T>): T[] {
    let flatChildren = [];

    node.children.forEach((c) => {
      flatChildren = flatChildren.concat(ChildNodeFunctions.asFlat(c));
    });

    return [node].concat(flatChildren).map((x) => x as T);
  },
  addChild<T extends ChildNode<T>>(node: ChildNode<T>, child: T) {
    node.children.push(child);
  },
};
