import * as React from 'react';
import styles from './container-box.module.scss';
import { AriaRoletypeInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import { useStylingContext } from '../../style-context/styling-context-provider';

export interface ContainerBoxProps {
  className?: string;

  children?: any;

  variant: 'light-grey-stack';

  role?: string;

  aria?: AriaRoletypeInterface;
}

const componentName = 'ContainerBox';

export function ContainerBox(props: ContainerBoxProps) {
  const { styling } = useStylingContext();
  const classes = [styles[props.variant], styles[styling]];

  props.className && classes.push(props.className);

  const role = props.role ? { role: props.role } : {};

  return (
    <div data-component={componentName} className={classes.join(' ')} {...role} {...props.aria} {...getDataAttributes(props)}>
      {props.children}
    </div>
  );
}

ContainerBox['displayName'] = componentName;
