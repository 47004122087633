import { AllHTMLAttributes, createElement, forwardRef } from 'react';
import { atoms, Atoms } from '../../vanilla-extract/atoms/atoms';
import { sprinkles } from '../../vanilla-extract/atoms/sprinkles.css';
import { getRuntimeClassNameFromSelector } from '../../vanilla-extract/atoms/runtime-atoms';
import type { ClassValue } from 'clsx';
import clsx from 'clsx';
import { getDataAttributes } from '../../util/attribute-util';

type AtomsProps = Omit<Atoms, 'reset'>;

export interface BoxProps extends AtomsProps, Omit<AllHTMLAttributes<HTMLElement>, 'width' | 'height' | 'className'> {
  /**
   * The html element that the box renders. Default to `div`
   */
  as?: keyof JSX.IntrinsicElements;

  /**
   * Properties applied when hovered and not disabled
   */
  hoverProps?: AtomsProps;

  /**
   * Properties applied when focused and not disabled
   */
  focusProps?: AtomsProps;

  /**
   * Properties applied when pressed/active and not disabled
   */
  pressedProps?: AtomsProps;

  /**
   * Properties applied when disabled
   */
  disabledProps?: AtomsProps;

  /**
   * Optional custom CSS selectors to apply, with keys being the custom CSS selectors and the values the Box properties.
   *
   * The CSS selectors are added as a postfix to the existing atomic class name.
   *
   * Typical use cases such as hover, focus, pressed, and disabled should use the dedicated props.
   *
   * Example: `selectors: {"[data-has-error=true]": {color: 'error'}}`
   */
  selectors?: { [selector: string]: AtomsProps };

  /**
   * Optional escape hatch for adding a custom class name. Should only be used as a last resort if the other props are
   * unable to apply the desired styles
   */
  className?: ClassValue;
}

const componentName = 'Box';

export const Box = forwardRef<HTMLElement, BoxProps>(
  ({ as = 'div', hoverProps, focusProps, pressedProps, disabledProps, selectors, className, ...props }, ref) => {
    const atomProps: Record<string, unknown> = {};
    const nativeProps: Record<string, unknown> = {};

    for (const key in props) {
      if (sprinkles.properties.has(key as keyof Omit<Atoms, 'reset'>)) {
        atomProps[key] = props[key as keyof typeof props];
      } else {
        nativeProps[key] = props[key as keyof typeof props];
      }
    }

    const atomicClasses = atoms({ reset: as, ...atomProps });
    const runtimeAtomicClasses = clsx(
      hoverProps && getRuntimeClassNameFromSelector(hoverProps, ':hover:not(:disabled)'),
      focusProps && getRuntimeClassNameFromSelector(focusProps, ':focus:not(:disabled)'),
      pressedProps && getRuntimeClassNameFromSelector(pressedProps, ':active:not(:disabled)'), // keyboard pressed
      pressedProps && getRuntimeClassNameFromSelector(pressedProps, ':hover:active:not(:disabled)'), // hover + pressed needs higher specificity than hover alone
      disabledProps && getRuntimeClassNameFromSelector(disabledProps, ':disabled'),
      ...(selectors ? Object.entries(selectors).map(([selector, props]) => getRuntimeClassNameFromSelector(props, selector)) : [])
    );
    const userClasses = clsx(className);

    const element = createElement(as, {
      className: `${atomicClasses}${runtimeAtomicClasses ? ` ${runtimeAtomicClasses}` : ''}${userClasses ? ` ${userClasses}` : ''}`,
      ...nativeProps,
      ref,
      'data-component': componentName,
      ...getDataAttributes(props),
    });

    return element;
  }
);

Box.displayName = componentName;
