import * as React from 'react';
import { IconName } from '../icon/icon-SVGs';
import styles from './radio-icon-button.module.scss';
import { RadioProps } from '../radio/radio';
import { Icon } from '../icon/icon';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export interface RadioIconButtonProps extends RadioProps {
  id?: string;
  icon: IconName;
  toolTipLabel?: string;
}

const componentName = 'RadioIconButton';

export class RadioIconButton extends React.Component<RadioIconButtonProps, {}> {
  input = null;

  render() {
    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        <input
          ref={(c) => (this.input = c)}
          id={this.props.id || LangUtil.randomUuid()}
          type="radio"
          title={this.props.toolTipLabel}
          name={this.props.name}
          checked={this.props.isSelected}
          disabled={this.props.isDisabled}
          onChange={(e) => {
            this.props.onSelectedChanged && this.props.onSelectedChanged((e.target as HTMLInputElement).checked);
          }}
          {...this.props.aria}
        />
        <div className={styles['radio-icon-button']}>
          <Icon size={16} name={this.props.icon} />
        </div>
      </div>
    );
  }

  focus() {
    this.input && this.input.focus();
  }
}

RadioIconButton['displayName'] = componentName;
