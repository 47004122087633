import * as React from 'react';
import styles from './widget-placeholder.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface WidgetPlaceholderProps {
  className?: string;
  children: React.ReactNode;
}

const componentName = 'WidgetPlaceholder';

export class WidgetPlaceholder extends React.Component<WidgetPlaceholderProps, {}> {
  constructor(properties: WidgetPlaceholderProps) {
    super(properties);
  }

  render() {
    return (
      <div
        className={[styles['component'], this.props.className].join(' ')}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </div>
    );
  }
}

WidgetPlaceholder['displayName'] = componentName;
