import * as React from 'react';
import { ChangeEvent } from 'react';
import styles from './data-input.module.scss';
import { AriaTextboxInterface } from '../AccessibilityProps';
import StylingContext from '../../style-context/styling-context-provider';
import { KeyCodes, PixelConverterUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'DateInput';

export enum Theme {
  Transparent,
  Default,
}

export interface DateInputProps {
  onChange: (newValue: string) => void;
  onBlur?: () => any;
  onFocus?: () => any;
  onNavigationKey?: (keyCode: number) => any;
  placeholder?: string;
  maxWidth?: number;
  theme?: Theme;
  style?: React.CSSProperties;
  value?: string;

  /**
   * min and max values should use YYYY-MM-DD pattern
   */
  minRange?: string;
  maxRange?: string;

  aria?: AriaTextboxInterface;
}

export class DateInput extends React.Component<DateInputProps, { value: string }> {
  /**
   * Styling context to handle 'brand' or 'classic' styling;
   */
  context: React.ContextType<typeof StylingContext>;
  input: any;

  private datePattern = '([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))';
  private regexMatcher = new RegExp(this.datePattern);

  constructor(properties) {
    super(properties);
    this.state = {
      value: this.props.value ?? '',
    };
  }

  public focus(): void {
    this.input.focus?.();
  }

  onKeyDown(keyCode: number) {
    const navigationKeys = [KeyCodes.DOM_VK_UP, KeyCodes.DOM_VK_DOWN, KeyCodes.DOM_VK_ESCAPE, KeyCodes.DOM_VK_ENTER, KeyCodes.DOM_VK_TAB];

    if (this.props.onNavigationKey && navigationKeys.some((k) => k === keyCode)) {
      this.props.onNavigationKey(keyCode);
    }
  }

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ value: event.target.value });
    if (this.regexMatcher.test(event.target.value)) {
      this.props.onChange(event.target.value);
    }
  };

  render() {
    const { styling } = this.context;
    const classes = [styles['input'], styles[styling]];
    classes.push(this.props.theme !== undefined ? styles[Theme[this.props.theme]] : styles['Default']);

    let inlineStyle = {};
    if (this.props.maxWidth) {
      inlineStyle['maxWidth'] = PixelConverterUtil.pxToDp(this.props.maxWidth) + 'px';
    }
    inlineStyle = { ...inlineStyle, ...this.props.style };

    return (
      <input
        data-component={componentName}
        {...this.props.aria}
        ref={(c) => (this.input = c)}
        className={classes.join(' ')}
        style={inlineStyle}
        value={this.state.value || ''}
        onChange={this.onChange}
        type={'date'}
        pattern={this.datePattern}
        min={this.props.minRange}
        max={this.props.maxRange}
        onKeyDown={(e) => this.onKeyDown(e.keyCode)}
        placeholder={this.props.placeholder}
        onFocus={() => {
          this.props.onFocus && this.props.onFocus();
        }}
        onBlur={() => {
          this.props.onBlur && this.props.onBlur();
        }}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

DateInput['displayName'] = componentName;
DateInput.contextType = StylingContext;
