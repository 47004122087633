import * as React from 'react';
import styles from './tree-selection-search-bar.module.scss';
import { CommandButton } from '../command-button/command-button';
import { Checkbox } from '../checkbox/checkbox';
import { getDataAttributes } from '../../util/attribute-util';

export interface TreeSelectionSearchBarProperties {
  includeUnderlyingSettings?: IncludeUnderlyingSettings;
  selectAllSettings?: SelectAllSettings;
  deselectAllSettings?: DeselectAllSettings;
}

export interface IncludeUnderlyingSettings {
  includeUnderlying: boolean;
  includeUnderlyingLabel: string;
  onIncludeUnderlyingChanged: (val: boolean) => any;
}

export interface SelectAllSettings {
  selectAllEnabled: boolean;
  selectAllLabel: string;
  onSelectAll: () => any;
}

export interface DeselectAllSettings {
  deselectAllEnabled: boolean;
  deselectAllLabel: string;
  onDeselectAll: () => any;
}

const componentName = 'TreeSelectionSearchBar';

export class TreeSelectionSearchBar extends React.Component<TreeSelectionSearchBarProperties, {}> {
  render() {
    const { includeUnderlyingSettings, selectAllSettings, deselectAllSettings } = this.props;

    if (!includeUnderlyingSettings && !selectAllSettings && !deselectAllSettings) {
      return null;
    }

    return (
      <div className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {selectAllSettings && (
          <CommandButton
            type="button"
            icon="cursor"
            size="small"
            label={selectAllSettings.selectAllLabel}
            disabled={!selectAllSettings.selectAllEnabled}
            onClick={() => selectAllSettings.onSelectAll()}
          />
        )}

        {deselectAllSettings && (
          <CommandButton
            type="button"
            icon="delete"
            size="small"
            label={deselectAllSettings.deselectAllLabel}
            disabled={!deselectAllSettings.deselectAllEnabled}
            onClick={() => deselectAllSettings.onDeselectAll()}
          />
        )}

        {includeUnderlyingSettings && (
          <Checkbox
            label={includeUnderlyingSettings.includeUnderlyingLabel}
            isSelected={includeUnderlyingSettings.includeUnderlying}
            onSelectedChanged={(val: boolean) => includeUnderlyingSettings.onIncludeUnderlyingChanged(val)}
          />
        )}
      </div>
    );
  }
}

TreeSelectionSearchBar['displayName'] = componentName;
