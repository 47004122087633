import { windowSafe } from '../util/ssr';

export class Timeouts {
  private static popupTimeoutLocalStorageKey = 'popup-timeout-ms';

  private static DefaultPopupHideDelayInMs = 4000;

  static get popupTimeoutMs() {
    const storedTimeout = windowSafe().localStorage.getItem(Timeouts.popupTimeoutLocalStorageKey);
    if (storedTimeout === null) {
      return Timeouts.DefaultPopupHideDelayInMs;
    }

    return Number.parseInt(storedTimeout, 10);
  }

  static setPopupTimeoutMs(ms: number) {
    windowSafe().localStorage.setItem(Timeouts.popupTimeoutLocalStorageKey, ms.toString(10));
  }
}
