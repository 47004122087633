import * as React from 'react';
import { LabelSubLabelNode } from '../../models/label-sub-label-node';
import styles from './tree-navigation.module.scss';
import { AriaTreeInterface, AriaTreeItemInterface } from '../AccessibilityProps';
import { AccessibilityEventHandlers } from '../../accessibility/accessibility-event-handlers';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { CommandButton } from '../command-button/command-button';
import { getDataAttributes } from '../../util/attribute-util';
import { Accordion } from '../accordion/accordion';
import { LangUtil } from '../../client-shared';
import { ChildNodeFunctions } from '../../models/child-node';

export interface TreeNavigationProps {
  id: string;
  nodes: LabelSubLabelNode<any>[];
  selectedNode?: LabelSubLabelNode<any>;
  onValueSelected?: (nodeId: string, value: any) => any;
  style?: React.CSSProperties;
  useLinksInsteadOfButtons?: boolean;
  onNodeOpenStateChanged?: (nodeId: string, newState: boolean) => any;
  /**
   * Makes it impossible to tab to the component and disables keyboard navigation.
   * Use this property when you want to use the treeNavigation in a context which wants to control the aria-ativedescendant such as a combobox (our Dropdown)
   */
  disableComponentNavigation?: boolean;
  isComboboxPopup?: boolean;
  aria?: AriaTreeInterface;
}

const componentName = 'TreeNavigation';

export class TreeNavigation extends React.Component<
  TreeNavigationProps,
  {
    hasFocus: boolean;
    activeDescendant: string;
  }
> {
  uuid = LangUtil.randomUuid();

  element: HTMLElement;

  state = {
    hasFocus: false,
    activeDescendant: '',
  };

  constructor(properties) {
    super(properties);
  }

  get activeDescendant(): string {
    return this.state.hasFocus ? this.state.activeDescendant : null;
  }

  set activeDescendant(value: string) {
    this.setState((s) => ({
      ...s,
      _activeDescendant: value,
    }));
  }

  componentDidMount(): void {
    this.element.addEventListener('keydown', this.keyPressHandler);
    this.element.addEventListener('focus', this.focusHandler);
    this.element.addEventListener('blur', this.blurHandler);

    if (!this.activeDescendant || !this.props.nodes.some((n) => n.id === this.activeDescendant)) {
      this.activeDescendant = this.props.nodes && this.props.nodes.length > 0 ? this.props.nodes[0].id : null;
    }
  }

  componentWillUnmount() {
    this.element.removeEventListener('keydown', this.keyPressHandler);
    this.element.removeEventListener('focus', this.focusHandler);
    this.element.removeEventListener('blur', this.blurHandler);
  }

  childFocus = (element: HTMLElement) => {
    this.activeDescendant = IdEncoderUtil.decodeId(element.id);
  };

  private focusHandler = () => {
    this.setState((s) => ({
      ...s,
      hasFocus: true,
    }));
  };

  private blurHandler = (evt: FocusEvent) => {
    if (evt.currentTarget !== this.element) {
      return;
    }
    this.setState((s) => ({
      ...s,
      hasFocus: false,
    }));
  };

  private selectionHandler = (elem) => elem.click();
  private expansionHandler = (elem) => {
    const id = IdEncoderUtil.decodeId(elem.id);
    this.props.nodes.forEach((n) => ChildNodeFunctions.asFlat(n).forEach((nf: any) => nf.id === id && (nf.isOpen = !nf.isOpen)));
  };

  keyPressHandler = AccessibilityEventHandlers.treeSingleSelectFactory(
    this.selectionHandler,
    this.expansionHandler,
    this.childFocus,
    this.props.isComboboxPopup
  );

  renderNode = (node: LabelSubLabelNode<any>) => {
    return node.children.length > 0
      ? this.renderAsAccordion(node)
      : this.props.useLinksInsteadOfButtons
      ? this.renderAsLink(node)
      : this.renderAsButton(node);
  };

  renderAsAccordion(node) {
    return (
      <Accordion
        key={node.id}
        data-key={`TreeNavigation_accordion-${node.id}`}
        id={IdEncoderUtil.encodeId(this.uuid, node.id)}
        isOpen={node.isOpen}
        role={'treeitem'}
        aria={{ 'aria-selected': 'false' }}
        heading={
          <div className={styles['label']}>
            <span>{node.label}</span>
          </div>
        }
        hasFocus={this.activeDescendant === node.id}
        onToggle={(id: string, newState: boolean) => {
          node.setOpen(newState);
          this.activeDescendant = node.id;
          this.props.onNodeOpenStateChanged && this.props.onNodeOpenStateChanged(id, newState);
        }}
      >
        <div role="group">{node.children.length > 0 ? node.children.map(this.renderNode) : undefined}</div>
      </Accordion>
    );
  }

  renderAsLink(node: LabelSubLabelNode<any>) {
    return (
      <div
        key={node.id}
        data-key={`TreeNavigation_link-${node.id}`}
        id={IdEncoderUtil.encodeId(this.uuid, node.id)}
        role="treeitem"
        aria-selected={this.props.selectedNode && node.id === this.props.selectedNode.id ? 'true' : 'false'}
        className={styles['label'] + ' ' + styles['label-with-link']}
      >
        <a href={node.value}>
          {node.label}
          <span className={styles['sub-label']}>{node.subLabel}</span>
        </a>
      </div>
    );
  }

  renderAsButton(node) {
    const classes = [styles['label-with-button']];

    if (node === this.props.selectedNode) {
      classes.push(styles['selected']);
    }

    const encodedId = IdEncoderUtil.encodeId(this.uuid, node.id);

    return (
      <div key={node.id} data-key={`TreeNavigation_button-${node.id}`} className={[styles['treeItemWrapper'], ...classes].join(' ')}>
        <CommandButton
          id={encodedId}
          key={node.id}
          role="treeitem"
          size="small"
          onClick={() => {
            this.activeDescendant = node.id;
            this.props.onValueSelected(node.id, node.value);
          }}
          type="cosmetic"
          label={node.label}
          hasFocus={this.activeDescendant === node.id}
          aria={
            {
              'aria-selected': this.props.selectedNode && node.id === this.props.selectedNode.id ? 'true' : 'false',
            } as AriaTreeItemInterface
          }
        />
        {node.subLabel && (
          <span className={styles['sub-label']} title={node.subLabel}>
            {node.subLabel}
          </span>
        )}
      </div>
    );
  }

  render() {
    const aria: AriaTreeInterface = {
      'aria-orientation': 'vertical',
      'aria-multiselectable': 'false',
      'aria-required': 'true',
    };

    const activeDescendantProps = {};

    //only use active-descendant if we have tab focus enabled. If tab focus is disabled then we have to control activedescendant from a parent component
    if (!this.props.disableComponentNavigation) {
      activeDescendantProps['aria-activedescendant'] = IdEncoderUtil.encodeId(this.uuid, this.activeDescendant);
      activeDescendantProps['tabIndex'] = this.props.disableComponentNavigation ? -1 : 0; /* for activeDecendant navigation*/
    }

    return (
      <div
        id={this.props.id}
        ref={(elem) => {
          this.element = elem;
        }}
        role="tree"
        className={styles['component']}
        style={this.props.style}
        {...{ ...this.props.aria, ...aria }}
        {...activeDescendantProps}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.nodes.map(this.renderNode)}
      </div>
    );
  }
}

TreeNavigation['displayName'] = componentName;
