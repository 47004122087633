import * as React from 'react';
import { Label } from '../label/label';
import styles from './field.module.scss';
import { Stack } from '../stack/stack';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'Field';

export interface FieldProps {
  /**
   * The label text to show for the field
   */
  label: string;

  /**
   * (Optional): Label type to apply to the label.
   * The default is a small label type.
   */
  labelType?: 'small' | 'field';

  /**
   * (Optional): The description provides useful information about the field contents
   */
  description?: string;

  /**
   * (Optional): DOM id of the interaction element that the label should focus on click
   */
  htmlFor?: string;

  /**
   * (Optional): If set to true, this will add a * to the end of the field to indicate that it is required
   */
  isRequired?: boolean;
  children?: React.ReactNode;
}

export class Field extends React.Component<FieldProps, {}> {
  static defaultProps: Partial<FieldProps> = {
    labelType: 'small',
  };

  constructor(properties) {
    super(properties);
  }

  render() {
    return (
      <div
        data-component={componentName}
        data-field={this.props.htmlFor}
        className={styles['component']}
        {...getDataAttributes(this.props)}
      >
        <Stack spacing={'8dp'}>
          <Label text={this.props.label} type={this.props.labelType} htmlFor={this.props.htmlFor} isRequired={this.props.isRequired} />
          {this.props.children}
          {this.props.description && <Label text={this.props.description} type="x-small" htmlFor={this.props.htmlFor} />}
        </Stack>
      </div>
    );
  }
}

Field['displayName'] = componentName;
