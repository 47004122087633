import * as React from 'react';
import { useContext, useMemo, useState } from 'react';

export type Styling = 'brand' | 'classic';

type State = {
  styling: Styling;
  setStyling: (styling: Styling) => void;
};
const StylingContext = React.createContext<State | null>(null);

interface Props {
  children: React.ReactChild;
  styling: Styling;
}

export const StylingContextProvider = ({ children, styling }: Props) => {
  const [internalStyling, setInternalStyling] = useState<Styling>(styling);

  useMemo(() => {
    setInternalStyling(styling);
  }, [styling]);

  return <StylingContext.Provider value={{ styling: internalStyling, setStyling: setInternalStyling }}>{children}</StylingContext.Provider>;
};
StylingContextProvider.displayName = 'StylingContextProvider';

export const useStylingContext = () => {
  const context = useContext(StylingContext);

  if (!context) {
    throw Error('"useStylingContext" can only be used inside "StylingContextProvider"');
  }
  return context;
};

export default StylingContext;
