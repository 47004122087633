import * as React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { MenuEvent, MenuItemAny } from './types';
import * as styles from './popup-menu.css';
import { DisplayItem } from './display-item';
import { DisplayLabel } from './display-label';
import { getDefaultPalette, getPaletteClassName } from '../../util/theme-util';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'PopupItem';

export const PopupItem = ({ item, onSelect, ...props }: { item: MenuItemAny; onSelect: (item: MenuItemAny, e: MenuEvent) => void }) => {
  const handleSelect = (e: MenuEvent) => {
    onSelect(item, e);
  };

  // No children
  if (!item.items || item.items.length === 0) {
    switch (item.type) {
      case 'plain':
        return (
          <DropdownMenu.Item className={styles.item} onSelect={handleSelect}>
            <DisplayItem item={item} />
          </DropdownMenu.Item>
        );
      case 'check':
        return (
          <DropdownMenu.CheckboxItem checked={item.isSelected} className={styles.item} onSelect={handleSelect}>
            <DisplayItem item={item} />
          </DropdownMenu.CheckboxItem>
        );
      case 'separator':
        return <DropdownMenu.Separator className={styles.separator} />;
      case 'label':
        return (
          <DropdownMenu.Label className={styles.label}>
            <DisplayLabel item={item} />
          </DropdownMenu.Label>
        );
    }
    console.warn(`Unhandled MenuItem type: ${item.type}`);
    return null;
  }

  // Recursive child items
  return (
    <DropdownMenu.Root>
      <DropdownMenu.TriggerItem className={styles.item}>
        <DisplayItem item={item} />
      </DropdownMenu.TriggerItem>
      <DropdownMenu.Content className={[getPaletteClassName({ name: getDefaultPalette().name }), styles.content].join(' ')}>
        {item?.items.map((subItem) => (
          <PopupItem key={subItem.id} item={subItem} onSelect={onSelect} data-component={componentName} {...getDataAttributes(props)} />
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
};

PopupItem['displayName'] = componentName;
