import * as React from 'react';
import { useMemo, useState } from 'react';
import { CommandButton } from '../command-button/command-button';
import { FieldControl } from '../field-control/field-control';
import { AriaButtonInterface, AriaTextboxInterface } from '../AccessibilityProps';
import { IconButtonProps } from '../icon-button/icon-button';
import { ContentListFilterState } from './use-content-list-filter';
import { TextField } from '../text-field/text-field';
import { KeyCodes } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export interface FilterSave {
  filterName: string;
  action: 'Saved' | 'Aborted';
}

export interface FilterSaveProps {
  state: ContentListFilterState;
  saveButtonLabel: string;
  saveFilterButtonAria: AriaButtonInterface;
  saveFilterInputAria: AriaTextboxInterface;
  onFiltersSave: (value: FilterSave) => void;
  filterInputPlaceholder?: string;
}

const componentName = 'FilterSave';

export const FilterSave = (props: FilterSaveProps, {}) => {
  const [saveFilterDisplay, setSaveFilterDisplay] = useState<boolean>(true);
  const [saveFilterInput, setSaveFilterInput] = useState<string>();

  const fieldControlItems: IconButtonProps[] = useMemo<IconButtonProps[]>(
    () => [
      { type: 'button', icon: 'delete', onClick: () => onInputAction('Aborted'), aria: { 'aria-label': 'delete' } },
      { type: 'button', icon: 'check', onClick: () => onInputAction('Saved'), aria: { 'aria-label': 'confirm' } },
    ],
    []
  );

  const onInputAction = (action: 'Saved' | 'Aborted') => {
    const filterSave: FilterSave = { filterName: saveFilterInput, action: action };
    setSaveFilterInput('');
    setSaveFilterDisplay(true);
    props.onFiltersSave(filterSave);
  };

  return saveFilterDisplay ? (
    <div style={{ paddingBottom: '2px', paddingTop: '2px' }} data-component={componentName} {...getDataAttributes(props)}>
      <CommandButton
        aria={props.saveFilterButtonAria}
        type="button"
        icon="pushpin"
        size="small"
        onClick={() => setSaveFilterDisplay(false)}
        label={props.saveButtonLabel}
      />
    </div>
  ) : (
    <FieldControl items={fieldControlItems} data-component={componentName} {...getDataAttributes(props)}>
      <TextField
        value={saveFilterInput}
        onBlur={() => setTimeout(() => setSaveFilterDisplay(true), 250)}
        onChange={(e) => setSaveFilterInput(e)}
        onNavigationKey={(e) => {
          e === KeyCodes.DOM_VK_ESCAPE && onInputAction('Aborted');
          e === KeyCodes.DOM_VK_RETURN && onInputAction('Saved');
        }}
        maxWidth={105}
        placeholder={props.filterInputPlaceholder}
        autoFocus={true}
        aria={props.saveFilterInputAria}
      />
    </FieldControl>
  );
};

FilterSave['displayName'] = componentName;
