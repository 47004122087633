/* eslint-disable no-prototype-builtins */
/* eslint-disable prefer-rest-params */
// include the IE11 Promise polyfill
import * as promise from 'promise-polyfill';

if (typeof Promise === 'undefined' && typeof window === 'object') {
  (window as any).Promise = promise;
}

export interface NamedGlobal {
  name: string;
  global: any;
}

/**
 * Gets the class name of a function that represents a JavaScript class
 */
export function getClassFunctionName(classFunction): string {
  let name = classFunction.name;
  if (!name) {
    // no name so use IE11 toString() which returns "function <name>()"
    name = classFunction.toString().replace('function ', '');
    name = name.substr(0, name.indexOf('('));
  }
  return name;
}

/**
 * Utility class for working with TypeScript in the browser, e.g. globals in window.
 */
export class Browser {
  /**
   * Adds the specified objects as globals in the browser by adding them to the window object.
   * @param globals objects with a name property under which it will be available as a global variable in the browser
   */
  static globals(...globals) {
    const namedGlobals = globals.map((global) => {
      const name = getClassFunctionName(global);
      return {
        name: name,
        global: global,
      } as NamedGlobal;
    });
    Browser.globalsWithName(...namedGlobals);
  }

  /**
   * Adds the specified objects as globals in the browser by adding them to the window object.
   * Serves as a replacement for webpack expose, since expose imports don't show up in IDEA find usages.
   * @param namedGlobals objects with a name property under which it will be available as a global variable in the browser
   */
  static globalsWithName(...namedGlobals: NamedGlobal[]) {
    if (window && namedGlobals) {
      namedGlobals.forEach((namedGlobal) => {
        if (namedGlobal.name) {
          window[namedGlobal.name] = namedGlobal.global;
        } else {
          console.warn("Can't register unnamed global:", namedGlobal);
        }
      });
    }
  }
}

// IE11 Object.assign polyfill -- https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/assign
if (typeof Object.assign !== 'function') {
  (function () {
    Object.assign = function (target) {
      'use strict';
      // We must check against these specific cases.
      if (target === undefined || target === null) {
        throw new TypeError('Cannot convert undefined or null to object');
      }

      const output = Object(target);
      for (let index = 1; index < arguments.length; index++) {
        const source = arguments[index];
        if (source !== undefined && source !== null) {
          for (const nextKey in source) {
            if (source.hasOwnProperty(nextKey)) {
              output[nextKey] = source[nextKey];
            }
          }
        }
      }
      return output;
    };
  })();
}

// IE11 String.includes polyfill -- https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/String/includes
if (!String.prototype.includes) {
  String.prototype.includes = function (search, start) {
    'use strict';
    if (typeof start !== 'number') {
      start = 0;
    }

    if (start + search.length > this.length) {
      return false;
    } else {
      return this.indexOf(search, start) !== -1;
    }
  };
}

// IE11 Number.isNaN polyfill -- https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
Number.isNaN =
  Number.isNaN ||
  function (value) {
    return typeof value === 'number' && isNaN(value);
  };

//IE11 Array.find polyfill -- https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
//please note that polyfill has been extended with writable attribute as per: https://github.com/mobxjs/mobx/issues/1028
if (!Array.prototype.find) {
  Object.defineProperty(Array.prototype, 'find', {
    writable: true, //workaround for mobx find patching https://github.com/mobxjs/mobx/issues/1028
    value: function (predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      const len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1];

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return kValue.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return kValue;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return undefined.
      return undefined;
    },
  });
}
