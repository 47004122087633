import { useWindowSize } from '../../hooks/useWindowSize';
import { breakpoints, Breakpoint } from '../../vanilla-extract/atoms/breakpoints';
import * as React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

export interface ScreenBreakpointProps {
  breakpoint: Breakpoint[];
  children?: React.ReactElement | React.ReactElement[];
}

const componentName = 'ScreenBreakpoint';

export function ScreenBreakpoint(props: ScreenBreakpointProps) {
  const [width] = useWindowSize();

  const isBPValid = (bp: Breakpoint) => {
    switch (bp) {
      case 'mobile':
        return width < breakpoints.tablet;
      case 'tablet':
        return width >= breakpoints.tablet && width < breakpoints.desktop;
      case 'desktop':
        return width >= breakpoints.desktop;
    }
    return false;
  };

  const show = props.breakpoint.some((bp) => isBPValid(bp));

  return show && props.children ? (
    <div data-component={componentName} {...getDataAttributes(props)}>
      {props.children}
    </div>
  ) : null;
}

ScreenBreakpoint.displayName = componentName;
