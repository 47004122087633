import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './inline.css';
import { atoms, Space } from '../../vanilla-extract/atoms/atoms';
import { OptionalResponsiveValue } from '../../vanilla-extract/atoms/sprinkles.css';
import { Align, alignToFlexAlign, AlignY, alignYToFlexAlign } from '../../util/align';
import { PixelConverterUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'Inline';

export interface InlineProps {
  /**
   * (Optional): The space between children both in the horizontal and vertical direction.
   * Defaults to `4dp`
   */
  spacing?: Space;

  /**
   * (Optional): The horizontal alignment of children.
   * Defaults to `left`
   */
  horizontalAlignment?: OptionalResponsiveValue<Align>;

  /**
   * (Optional): The vertical alignment of children.
   * Defaults to 'center' which centers children vertically across the row they're in.
   */
  verticalAlignment?: OptionalResponsiveValue<AlignY>;

  /**
   * The children to render in an inline layout
   */
  children: React.ReactNode;

  /**
   * (Optional): Whether to wrap children onto new lines as needed, e.g. for multiple lines of badges
   * Defaults to `false`
   */
  wrap?: boolean;
}

export const Inline = ({
  spacing = '4dp',
  horizontalAlignment = 'left',
  verticalAlignment = 'center',
  children,
  wrap = false,
  ...props
}: InlineProps) => {
  const classArray = [
    styles.component,
    styles.space, // backwards compat (see not at definition)
    atoms({ alignItems: alignYToFlexAlign(verticalAlignment) }),
    atoms({ justifyContent: alignToFlexAlign(horizontalAlignment) }),
    atoms({ flexWrap: wrap ? 'wrap' : 'nowrap' }),
  ];
  const columnGap = PixelConverterUtil.dpToPx(+spacing.split('d')[0]);
  const rowGap = PixelConverterUtil.dpToPx(4);

  const workaroundPrintIssue = () => {
    // FIX FOR https://elsevier.atlassian.net/browse/GPHN-570 it's a problem with having row-gap no non null children in the inline(null children would be <></>,  "", null, undefined)
    return <div style={{ height: '0', width: '0', marginLeft: `-${columnGap}px` }}></div>;
  };
  return (
    <div style={{ columnGap, rowGap }} className={classArray.join(' ')} data-component={componentName} {...getDataAttributes(props)}>
      {children}
      {horizontalAlignment !== 'stretch' && workaroundPrintIssue()}
    </div>
  );
};

Inline['displayName'] = componentName;
