import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';

import './transition.module.scss';

import { CSSTransitionGroup } from 'react-transition-group';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import { getDataAttributes } from '../../util/attribute-util';

export interface TransitionProps {
  transition?: 'fade' | 'cross-fade' | 'expand' | 'drop-in-out' | 'drop-in-out-reverse';
  tag?: string;
  animate?: boolean;

  /**
   * Explicitly suppress the tags native semantics by setting the html role attribute to 'presentation'.
   */
  markAsPresentation?: boolean;

  /**
   * Child node. Changes to it will trigger transitions.
   */
  children?: React.ReactNode;
}

//used for webdriver. Do not remove!
const animationStatus = {
  pendingAnimations: 0,
  isAnimating() {
    return this.pendingAnimations > 0;
  },
  onAnimationBegin(duration: number) {
    this.pendingAnimations++;
    setTimeout(() => {
      this.pendingAnimations--;
    }, duration + 1);
  },
};

//used for webdriver. Do not remove!
if (typeof window === 'object') {
  window['animationStatus'] = animationStatus;
}

export const DefaultAnimationDurationMs = 350;

const componentName = 'Transition';

export class Transition extends React.Component<TransitionProps, { isAnimating: boolean }> {
  /**
   * Adds a CSS transition when the component mounts or unmounts.
   * To control when the mounting and unmounting happens, use the key prop on a parent.
   */

  static childContextTypes: React.ValidationMap<any> = {
    getParent: PropTypes.func,
  };
  static contextTypes: React.ValidationMap<any>;
  private observer: MutationObserver;

  constructor(props) {
    super(props);
    this.state = {
      isAnimating: false,
    };
  }

  get isAnimating() {
    return this.state.isAnimating;
  }

  set isAnimating(val) {
    this.setState({ isAnimating: val });
  }

  get isInsideAnimation() {
    return (
      this.context !== undefined &&
      this.context.getParent !== undefined &&
      (this.context.getParent().isAnimating || this.context.getParent().isInsideAnimation)
    );
  }

  getChildContext = () => {
    return {
      getParent: () => {
        return this as any;
      },
    };
  };

  componentDidMount() {
    this.isAnimating = !this.isInsideAnimation; // Note: This used be inside of a componentWillMount
    if (this.props.transition === 'expand' && this.props.animate !== false) {
      // the expand transition needs to specify which max-height to transition from (a limitation in CSS transitions)

      this.observer = new MutationObserver((mutations: MutationRecord[], observer: MutationObserver) => {
        mutations.forEach((mutation) => {
          if (mutation.type !== 'attributes' || mutation.attributeName !== 'class') {
            // we're only interested in class name changes
            return;
          }
          if (mutation.target instanceof HTMLElement) {
            const element = mutation.target as HTMLElement;
            if (!this.hasClassName(element, 'transition-expand') || this.hasClassName(element, 'transition-expand-leave-active')) {
              // no longer transitioning or we've begun the leave transition, so clear the explicit max-height
              if (element.dataset['clearMaxHeight'] === 'true') {
                element.style.maxHeight = '';
              }
              return;
            }

            if (
              (this.hasClassName(element, 'transition-expand-leave') && !this.hasClassName(element, 'transition-expand-leave-active')) ||
              this.hasClassName(element, 'transition-expand-enter-active') ||
              this.hasClassName(element, 'transition-expand-appear-active')
            ) {
              // we set an explicit max-height when leave is about to being, and when enter/appear is active
              element.style.maxHeight = element.scrollHeight + 'px';
              element.dataset['clearMaxHeight'] = 'true';
            }
          }
        });
      });
      const node = ReactDOM.findDOMNode(this);
      this.observer.observe(node, {
        attributes: true,
        subtree: true,
      });
    }
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  }

  private hasClassName(element: HTMLElement, className: string): boolean {
    return element.className.indexOf(className) !== -1;
  }

  render() {
    const transitionKind = this.props.transition || 'fade';
    const animate = this.props.animate !== false;
    const animationTime = animate ? DefaultAnimationDurationMs : undefined;
    if (animationTime) {
      animationStatus.onAnimationBegin(animationTime);
    }

    if (transitionKind === 'cross-fade') {
      return (
        <ReactCSSTransitionReplace
          transitionName="transition-cross-fade"
          transitionEnterTimeout={animationTime}
          transitionLeaveTimeout={animationTime}
        >
          {this.props.children}
        </ReactCSSTransitionReplace>
      );
    }

    return (
      <CSSTransitionGroup
        transitionName={`transition-${transitionKind}`}
        transitionAppear={animate && !this.isInsideAnimation}
        transitionEnter={animate}
        transitionLeave={animate}
        transitionAppearTimeout={animationTime}
        transitionEnterTimeout={animationTime}
        transitionLeaveTimeout={animationTime}
        component={(this.props.tag as React.ReactType) || ('div' as React.ReactType)}
        className={`transition-${transitionKind}`}
        {...(!!this.props.markAsPresentation && { role: 'presentation' })}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </CSSTransitionGroup>
    );
  }
}

Transition['displayName'] = componentName;
Transition.contextTypes = Transition.childContextTypes;
