import * as React from 'react';
import * as styles from './badge.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { S_M_L_Size } from '../t-shirt-sizes';
import { useStylingContext } from '../../style-context/styling-context-provider';

const componentName = 'Badge';

type Variant = 'information' | 'informationSubtile' | 'success' | 'warning' | 'error' | 'disabled';

export interface BadgeProps {
  /**
   * The color used in the badge
   */
  variant?: Variant;
  /**
   * The text for the badge
   */
  text: React.ReactNode;
  /**
   * Optional <Icon /> in front of the text
   */
  icon?: React.ReactNode;

  iconAlignment?: 'left' | 'right';

  size?: S_M_L_Size;
}

export const Badge = (props: BadgeProps) => {
  const { styling } = useStylingContext();
  const { iconAlignment = 'left' } = props;
  const variant: Variant = props.variant || 'information';
  const size: S_M_L_Size = props.size || 'small';

  const classNames = [styles['component'], styles[variant], styles[styling], styles[size]].join(' ');
  return (
    <div data-component={componentName} className={classNames} {...getDataAttributes(props)}>
      <span>
        {iconAlignment === 'left' && props.icon}
        {props.text}
        {iconAlignment === 'right' && props.icon}
      </span>
    </div>
  );
};

Badge['displayName'] = componentName;
