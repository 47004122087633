import * as React from 'react';

interface ButtonFocusHandlerProps {
  initialFocus?: boolean;
  render: (props: RenderProps) => React.ReactElement;
}

interface RenderProps {
  hasFocus: boolean;
  hasHover: boolean;
  ref: React.MutableRefObject<HTMLDivElement>;
}

export interface ButtonFocusHandlerChildProps {
  hasHover?: boolean;
  hasFocus?: boolean;
}

// Wraps onFocus, onHover behavior for buttons and the like. Uses renderProps to inform child.
export function ButtonFocusHandler({ initialFocus = false, render }: ButtonFocusHandlerProps) {
  const [hasFocus, setHasFocus] = React.useState(initialFocus);
  const [hasHover, setHasHover] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  function handleMouseEnter(e: MouseEvent) {
    if (e.target !== e.currentTarget) {
      return;
    }
    setHasHover(true);
  }

  function handleMouseLeave(e: MouseEvent) {
    if (e.target !== e.currentTarget) {
      return;
    }
    setHasHover(false);
  }

  function handleFocus(e: FocusEvent) {
    if (e.target !== e.currentTarget) {
      return;
    }
    setHasFocus(true);
  }

  function handleBlur(e: FocusEvent) {
    if (e.target !== e.currentTarget) {
      return;
    }
    setHasFocus(false);
  }

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    ref.current.addEventListener('mouseenter', handleMouseEnter, true);
    ref.current.addEventListener('mouseleave', handleMouseLeave, true);
    ref.current.addEventListener('focus', handleFocus, true);
    ref.current.addEventListener('blur', handleBlur, true);

    // Cleanup
    return () => {
      if (!ref.current) {
        return;
      }
      ref.current.removeEventListener('mouseenter', handleMouseEnter, true);
      ref.current.removeEventListener('mouseleave', handleMouseLeave, true);
      ref.current.removeEventListener('focus', handleFocus, true);
      ref.current.removeEventListener('blur', handleBlur, true);
    };
  }); // No dependency, checked every render

  if (!render) {
    throw new Error('ButtonFocusHandler requires a render-prop');
  }

  return render({ hasFocus, hasHover, ref });
}
