import * as React from 'react';
import { getDataAttributes } from '../../util/attribute-util';

export interface TheadProps {
  children?: React.ReactNode;
}
const componentName = 'Thead';

export class Thead extends React.Component<TheadProps, any> {
  render() {
    return (
      <thead data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </thead>
    );
  }
}
Thead['displayName'] = componentName;
