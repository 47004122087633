import * as React from 'react';
import { Text } from '../text/text';
import styles from './paragraph.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export type TextType = 'font16' | 'font14' | 'font12';

const componentName = 'Paragraph';

export interface ParagraphProps extends React.PropsWithChildren<{}> {
  /**
   * Optional DOM id to assign to the paragraph.
   */
  id?: string;

  /**
   * The type of paragraph to show, e.g. 'medium', which is the default
   */
  type?: TextType;

  /**
   * Optional font color to use. The default text color is used when this props has not been specified.
   */
  color?: 'default' | 'secondary';

  /**
   * Optional style to apply to the paragraph. Note that the style excludes the properties controlled
   * by the component.
   */
  style?: Omit<React.CSSProperties, 'color' | 'fontSize' | 'fontFamily' | 'fontWeight' | 'lineHeight'>;

  /***
   * Optional serif. Defaults to false. This will use Nexus (sans-serif)
   * If true, then will use the Gulliver (serif) font
   */
  serif?: boolean;

  /***
   * Optional hypernation. Defaults to false. Long words will be split over two lines with a hyphens inserted where appropriate.
   */
  hyphenation?: boolean;
}

export const Paragraph: React.FunctionComponent<ParagraphProps> = function Paragraph({
  id,
  type = 'font16',
  color = 'default',
  style,
  serif,
  hyphenation,
  children,
  ...props
}) {
  const classes = [styles['component'], styles[type], styles[color]];
  return (
    <div id={id} data-component={componentName} className={classes.join(' ')} style={style} {...getDataAttributes(props)}>
      <Text size={type} serif={serif} hyphenation={hyphenation}>
        {children}
      </Text>
    </div>
  );
};

Paragraph['displayName'] = componentName;
