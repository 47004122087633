import * as React from 'react';
import styles from './display-table-placeholder-cell.module.scss';
import { DisplayTableCell } from '../display-table-cell';
import { getDataAttributes } from '../../../util/attribute-util';

const componentName = 'DisplayTablePlaceholderCell';
export class DisplayTablePlaceholderCell extends DisplayTableCell {
  render() {
    return (
      <div
        tabIndex={0}
        id={this.props.id}
        {...this.props.aria}
        role={'gridcell'}
        className={styles['component']}
        style={this.componentStyles}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </div>
    );
  }
}

DisplayTablePlaceholderCell['displayName'] = componentName;
