import * as React from 'react';
import { useRef, useState } from 'react';
import { Label } from '../label/label';
import styles from './slider.module.scss';

const componentName = 'Slider';

export interface SliderInternalProps {
  /** (Optional): value of the slider (updated on onChange)
   * Defaults to min value
   */
  value?: number;
  /** (Optional): Sets the label of the minimum value of the slider
   * Defaults to min value
   */
  labelMin?: string;
  /**
   * (Optional): Sets the label of the maximum value of the slider
   * Defaults to max value
   */
  labelMax?: string;
  onChange?: (value: number) => void;
}

type SliderNativeProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'style' | 'type' | 'value' | 'onChange' | 'ref'
>;

export interface SliderProps extends SliderInternalProps, SliderNativeProps {}
type RefType = React.LegacyRef<HTMLInputElement>;
export const Slider = React.forwardRef(
  ({ value, labelMin, labelMax, onChange, ...nativeProps }: SliderProps, ref: RefType): JSX.Element => {
    const classNames = [styles.component];
    const className = classNames.join(' ');
    const componentRef = useRef(null);

    // Parsing the value and safety fallback to default 0 and 100
    const minValue = +nativeProps.min || 0;
    const maxValue = +nativeProps.max || 100;

    const [sliderValue, setSliderValue] = useState(value >= minValue ? value : minValue);

    const newVal = Number(((sliderValue - minValue) * 100) / (maxValue - minValue));

    const changeCallback = (e) => {
      setSliderValue(e.target.value); // update the value of the slider
      onChange?.(e.target.value);
    };

    return (
      <div ref={componentRef} className={className}>
        <div
          className={styles['selected-value']}
          style={{
            left: `calc(${newVal}% + (${10 - newVal * 0.2}px))`, // make the value follow the handle
          }}
        >
          <Label className={styles['selected-value-label']} text={sliderValue.toString()} />
          <div className={styles['selected-value-line']}></div>
        </div>
        <input
          className={styles['slider']}
          style={{ '--value': sliderValue, '--min': minValue, '--max': maxValue } as React.CSSProperties}
          type={'range'}
          value={sliderValue}
          data-component={componentName}
          onChange={changeCallback}
          ref={ref}
          {...nativeProps}
        />
        <div className={styles['end-labels']}>
          <Label text={labelMin || minValue.toString()} />
          <Label text={labelMax || maxValue.toString()} />
        </div>
      </div>
    );
  }
);

Slider.displayName = componentName;
