import * as React from 'react';
import moment from 'moment';
import { Label } from '../label/label';
import styles from './date-picker-header.module.scss';
import { Navigation } from './date-picker-logic';
import { IconButton } from '../icon-button/icon-button';

export interface DatePickerProps {
  startDate: moment.Moment;
  onNavigate: (navigation: Navigation) => void;
}

const componentName = 'DatePickerHeader';

export class DatePickerHeader extends React.Component<DatePickerProps, any> {
  render() {
    const classes = [styles['component']];
    const date = this.props.startDate ?? moment(moment.now());
    const monthAndYear = date.format('MMMM YYYY');

    return (
      <div className={classes.join(' ')} data-component={componentName}>
        <div className={styles['button-spacing']}>
          <IconButton
            aria={{ 'aria-label': 'previous year' }}
            size={'small'}
            type={'button'}
            icon={'rewind'}
            onClick={() => this.props.onNavigate('prevYear')}
          />
          <IconButton
            aria={{ 'aria-label': 'previous month' }}
            size={'small'}
            type={'button'}
            icon={'navigate-left'}
            onClick={() => this.props.onNavigate('prevMonth')}
          />
        </div>

        <Label text={monthAndYear} type={'small'} />

        <div className={styles['button-spacing']}>
          <IconButton
            aria={{ 'aria-label': 'next month' }}
            size={'small'}
            type={'button'}
            icon={'navigate-right'}
            onClick={() => this.props.onNavigate('nextMonth')}
          />
          <IconButton
            aria={{ 'aria-label': 'next year' }}
            size={'small'}
            type={'button'}
            icon={'fast-forward'}
            onClick={() => this.props.onNavigate('nextYear')}
          />
        </div>
      </div>
    );
  }
}

DatePickerHeader['displayName'] = componentName;
