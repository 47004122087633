import { isSSR } from './ssr';

const ssr_aware_atob = isSSR ? (a) => Buffer.from(a ?? '', 'base64').toString('binary') : (a) => atob(a);

const ssr_aware_btoa = isSSR ? (b) => Buffer.from(b ?? '').toString('base64') : (b) => btoa(b);

/**
 * Utility to encode strings such that they can be used as DOM ID attributes
 */
export class IdEncoderUtil {
  public static prefix = 'EncodedId';

  /**
   * Encode a given id
   * @param {string} scope - A string defining your scope, such as a component name or an instance specific uuid.
   * @param {string} id - Any valid string. This will be base64 encoded to ensure that we escape all troublesome characters
   * @returns {string} A string on the form of EncodedId_{scope}_{base64}
   */
  public static encodeId(scope: string, id: string) {
    const encoded = ssr_aware_btoa(id).replace(/=/g, '-');
    return `${IdEncoderUtil.prefix}_${scope}_${encoded}`;
  }

  /**
   * Decode a given id
   * @param {string} encodedId - A string containing an encoded id
   * @returns {string} The original un-encoded id.
   */
  public static decodeId(encodedId: string) {
    const parts = encodedId.split('_');
    const baseEncoded = parts[parts.length - 1].replace(/-/g, '=');
    return ssr_aware_atob(baseEncoded);
  }
}
