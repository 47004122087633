import * as React from 'react';
import styles from './onboarding-block.module.scss';
import { Header } from '../header/header';
import { getPaletteClassName } from '../../util/theme-util';
import { PrimaryButton } from '../primary-button/primary-button';
import { SecondaryButton } from '../secondary-button/secondary-button';
import { Inline } from '../inline/inline';
import { getDataAttributes } from '../../util/attribute-util';

export interface OnboardingBlockProps {
  /**
   * Text string which is rendered in a header component.
   */
  header: string;
  /**
   * Function for the primary button. The function should make the OnboardingBlock go away when the button is pressed.
   */
  onPrimaryButtonClick: () => void;
  /**
   * Text string for the text that is displayed on the primary button.
   */
  primaryButtonText: string;
  /**
   * Text string for the text that is displayed on the secondary button. Must also provide onSecondaryButtonClick for the button to render.
   */
  secondaryButtonText?: string;
  /**
   * Function for the secondary button. Must provide OnSecondaryButtonClick as well for the secondary button to be rendered.
   */
  onSecondaryButtonClick?: () => void;
  /**
   * Onboarding block size, defaults to large if not provided.
   */
  size?: 'large' | 'small';

  children?: React.ReactNode;
}

const componentName = 'OnboardingBlock';

export class OnboardingBlock extends React.Component<OnboardingBlockProps, any> {
  static defaultProps: Partial<OnboardingBlockProps> = {
    size: 'large',
  };

  render() {
    return (
      <div
        className={this.classes() + ' ' + getPaletteClassName({ name: 'palette-dark' })}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <div className={styles['text-wrapper']}>
          <Header headerType="feature" headerLevel={2}>
            {this.props.header}
          </Header>
          <div>{this.props.children}</div>
        </div>
        <Inline horizontalAlignment={'right'} spacing={'8dp'}>
          {this.renderSecondary() && (
            <SecondaryButton
              size={this.props.size}
              type="button"
              onClick={this.props.onSecondaryButtonClick}
              label={this.props.secondaryButtonText}
            />
          )}
          <PrimaryButton
            type="button"
            size={this.props.size}
            onClick={this.props.onPrimaryButtonClick}
            label={this.props.primaryButtonText}
          />
        </Inline>
      </div>
    );
  }

  private renderSecondary(): boolean {
    if (this.props.onSecondaryButtonClick && this.props.secondaryButtonText) {
      return true;
    }
  }

  private classes(): string {
    const classes = [styles['container']];
    this.props.size && classes.push(styles[this.props.size]);
    return classes.join(' ');
  }
}

OnboardingBlock['displayName'] = componentName;
