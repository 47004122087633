import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styles from './editable-text.module.scss';
import { Label } from '../label/label';
import { TextField, Theme } from '../text-field/text-field';
import { IconButton } from '../icon-button/icon-button';
import { Inline } from '../inline/inline';
import { KeyCodes } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export interface EditableTextProps {
  showIcon?: boolean;
  text: string;
  textMaxLength?: number;
  type?: 'inline'; // multiline to be added later on
  textSize?: 'small' | 'medium';
  textAlign?: 'center' | 'right';
  border?: boolean;
  onTitleChanged: (newValue: string) => any;
}

let currentButtonId = 0;

const componentName = 'EditableText';
export class EditableText extends React.Component<
  EditableTextProps,
  { editTitle: boolean; titleIsSelected: boolean; inputElement?: HTMLInputElement }
> {
  static defaultProps: Partial<EditableTextProps> = {
    textSize: 'medium',
    type: 'inline',
    showIcon: true,
    border: false,
    text: '',
  };

  private buttonId = 'editable-text-icon-button' + currentButtonId++;

  constructor(props) {
    super(props);
    this.state = {
      editTitle: false,
      titleIsSelected: false,
    };
  }

  renderMultiline(): React.ReactNode {
    // To be implemented when ever we have made a multiline component
    console.error('type does not exist');
    return null;
  }

  textFieldOnClickHandler = () => {
    this.setState({
      editTitle: true,
    });
  };

  handleKeyDown = (e) => {
    switch (e.keyCode) {
      case KeyCodes.DOM_VK_RETURN:
      case KeyCodes.DOM_VK_ESCAPE:
        this.setState({
          editTitle: false,
          titleIsSelected: false,
        });
        break;
    }
  };

  renderInline() {
    let setTitleMaxLength = {};
    if (this.props.textMaxLength) {
      setTitleMaxLength = { maxLength: this.props.textMaxLength };
    }

    return (
      <TextField
        ref={(e) => {
          const elem = ReactDOM.findDOMNode(e) as HTMLInputElement;
          if (!this.state.inputElement && !!elem) {
            if (!this.state.titleIsSelected) {
              elem.setSelectionRange(0, this.props.text.length);
            }
            this.setState({
              titleIsSelected: true,
              inputElement: elem,
            });
          }
        }}
        theme={Theme.Transparent}
        onBlur={() => {
          this.setState({
            editTitle: false,
            titleIsSelected: false,
          });
        }}
        autoFocus={true}
        onChange={this.props.onTitleChanged}
        value={this.props.text}
        {...setTitleMaxLength}
      />
    );
  }

  render() {
    const styleClasses = [styles['component']];

    if (this.props.textSize === 'medium') {
      styleClasses.push(styles['medium']);
    }
    if (this.props.textSize === 'small') {
      styleClasses.push(styles['small']);
    }
    if (this.props.textAlign === 'center') {
      styleClasses.push(styles['center-align']);
    }
    if (this.props.textAlign === 'right') {
      styleClasses.push(styles['right-align']);
    }

    if (this.props.border === true) {
      styleClasses.push(styles['border']);
    }

    return (
      <div
        className={styleClasses.join(' ')}
        onClick={this.textFieldOnClickHandler}
        onKeyDown={(e) => this.handleKeyDown(e)}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <Inline spacing={'8dp'}>
          {this.state.editTitle ? (
            this.props.type === 'inline' ? (
              this.renderInline()
            ) : (
              this.renderMultiline()
            )
          ) : (
            <Label type={this.props.textSize} text={this.props.text} />
          )}
          {!this.state.editTitle && this.props.showIcon ? (
            <IconButton
              id={this.buttonId}
              type={'button'}
              size={'large'}
              aria={{ 'aria-label': this.props.text }}
              icon={'pencil'}
              onClick={() => {}}
            />
          ) : null}
        </Inline>
      </div>
    );
  }
}

EditableText['displayName'] = componentName;
