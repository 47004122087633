/**
 * Dispatch keydown event on target element
 * @param target
 * @param evt
 */
export function delegateKeyboardEvent(target: HTMLElement, evt: WindowEventMap['keydown']) {
  const event = new KeyboardEvent('keydown', {
    key: evt.key,
    code: evt.code,
    location: evt.location,
    altKey: evt.altKey,
    ctrlKey: evt.ctrlKey,
    metaKey: evt.metaKey,
    repeat: evt.repeat,
    shiftKey: evt.shiftKey,
    keyCode: evt.keyCode,
  } as KeyboardEventInit & { keyCode: any });
  target.dispatchEvent(event);
}

/**
 * Return the first parentElement with the attribute and optional value
 * @param elem
 * @param attr
 * @param stopConstraint
 * @param value
 */
export function getFirstParentWithAttribute(
  elem: HTMLElement,
  attr: string,
  stopConstraint: (elem: HTMLElement) => boolean,
  value?: string
) {
  if (!elem) {
    return;
  }
  let parent = elem.parentElement;
  while (!!parent && !stopConstraint(parent)) {
    if (parent.getAttribute(attr)) {
      //if the optional value is set, then compare.
      if (![null, undefined].some((v) => v === value)) {
        if (parent.getAttribute(attr) === value) {
          return parent;
        }
      } else {
        return parent;
      }
    }
    parent = parent.parentElement;
  }
  return null;
}

/**
 * Search for the previous element with the same role. Searches back through siblings and then up though the dom hierarchy.
 * @param elem
 * @param stopConstraint
 */
export function getPrevious(elem: HTMLElement, stopConstraint: (elem: HTMLElement) => boolean) {
  const role = elem.getAttribute('role');

  //search through siblings and their sub tree
  const getPreviousSibling = (el) => {
    let previous = el.previousElementSibling;
    while (previous) {
      if (previous.getAttribute('role') === role) {
        if (previous.getAttribute('aria-disabled') !== 'true') {
          return previous;
        }
      } else {
        const prevSubTree = previous.querySelectorAll(`*[role=${role}]`);
        if (prevSubTree.length > 0) {
          const prevSubTreeItem = prevSubTree.item(prevSubTree.length - 1);
          if (prevSubTreeItem.getAttribute('aria-disabled') !== 'true') {
            return prevSubTree.item(prevSubTree.length - 1);
          }
        }
      }
      previous = previous.previousElementSibling;
    }
    return null;
  };
  const sibling = getPreviousSibling(elem);
  if (sibling) {
    return sibling;
  }

  //else search upwards
  let parent = elem.parentElement;
  while (!!parent && !stopConstraint(parent)) {
    if (parent.getAttribute('role') === role) {
      return parent;
    }
    const previous = getPreviousSibling(parent);
    if (previous) {
      if (previous.getAttribute('aria-disabled') !== 'true') {
        return previous;
      }
    }
    parent = parent.parentElement;
  }
  return null;
}

/**
 * Search for the next element with the same role. Searches through siblings and then through the parent dom hierarchy.
 * @param elem
 * @param stopConstraint
 */
export function getNext(elem: HTMLElement, stopConstraint: (elem: HTMLElement) => boolean) {
  const role = elem.getAttribute('role');

  //search through siblings
  const getSibling = (el) => {
    let next = el.nextElementSibling;
    while (next) {
      if (next.getAttribute('role') === role) {
        if (next.getAttribute('aria-disabled') !== 'true') {
          return next;
        }
      } else {
        const desc = next.querySelector(`*[role=${role}]`);

        if (!!desc && desc.getAttribute('aria-disabled') !== 'true') {
          return desc;
        }
      }
      next = next.nextElementSibling;
    }
    return null;
  };
  const sibling = getSibling(elem);
  if (sibling) {
    return sibling;
  }

  //else search upwards
  let parent = elem.parentElement;
  while (!!parent && !stopConstraint(parent)) {
    const next = getSibling(parent);
    if (next) {
      return next;
    }
    parent = parent.parentElement;
  }
  return null;
}
