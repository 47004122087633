import { TreeNode } from './tree-node';
import { IconName } from '../components/icon/icon-SVGs';

/**
 * Represents a selectable item with a label and value, e.g. a menu item
 */
export interface SelectItem extends TreeNode<SelectItem> {
  label: string;

  value: string | number | boolean;

  icon: IconName;

  isSelectable: boolean;
  /**
   * For E2E testing
   */
  dataFeature: string;
  /**
   * An additional label to use for presenting
   */
  subLabel: string;
  /**
   * Whether this item represents a separator that is shown as a line
   */
  isSeparator: boolean;
  /**
   * Whether this item represents a heading for a group of items
   */
  isGroupHeading: boolean;
  /**
   * The template item type used with itemTemplate
   */
  templateItemType: string;
}

export const SelectItemFunctions = {
  createGroupHeading(headingLabel: string): SelectItem {
    return new SelectItemBuilder(headingLabel, 'groupHeading').withIsSelectable(false).withIsGroupHeading(true).build();
  },
  createSeparator(): SelectItem {
    return new SelectItemBuilder(null, 'separator').withIsSelectable(false).withIsSeparator(true).build();
  },
};

export class SelectItemBuilder {
  instance: SelectItem = {
    id: '',
    value: '',
    icon: null,
    children: [],
    dataFeature: '',
    isGroupHeading: false,
    isOpen: false,
    isSelectable: false,
    isSeparator: false,
    label: '',
    subLabel: '',
    templateItemType: 'selectItem',
  };
  constructor(
    label: string,
    value: string | number | boolean,
    icon?: IconName,
    children: SelectItem[] = [],
    isOpen = false,
    isSelectable = true
  ) {
    this.instance.id = value.toString();
    this.instance.isOpen = isOpen;
    this.instance.dataFeature = '';
    this.instance.children = children;
    this.instance.label = label;
    this.instance.subLabel = '';
    this.instance.isGroupHeading = false;
    this.instance.isSeparator = false;
    this.instance.value = value;
    this.instance.icon = icon;
    this.instance.isSelectable = isSelectable;
  }

  build(): SelectItem {
    return this.instance;
  }

  withIsGroupHeading(isGroupHeading: boolean): SelectItemBuilder {
    this.instance.isGroupHeading = isGroupHeading;
    return this;
  }
  withSubLabel(subLabel: string): SelectItemBuilder {
    this.instance.subLabel = subLabel;
    return this;
  }
  withIsSelectable(isSelectable: boolean): SelectItemBuilder {
    this.instance.isSelectable = isSelectable;
    return this;
  }
  withIsSeparator(isSeparator: boolean): SelectItemBuilder {
    this.instance.isSeparator = isSeparator;
    return this;
  }
  withDataFeature(dataFeature: string): SelectItemBuilder {
    this.instance.dataFeature = dataFeature;
    return this;
  }
  withSelectItemType(selectItemType: string): SelectItemBuilder {
    this.instance.templateItemType = selectItemType;
    return this;
  }
}

export const Separator = SelectItemFunctions.createSeparator();
export const GroupHeading = (headingLabel: string) => SelectItemFunctions.createGroupHeading(headingLabel);

/**
 * @param src The items to search
 * @returns This function returns a single flattened array of SelectItems
 */
export function flatSelect(src: SelectItem[]): SelectItem[] {
  const flatten = (acc: SelectItem[], cur: SelectItem): SelectItem[] => {
    let results: SelectItem[] = [];
    if (cur.children.length > 0) {
      results = cur.children.reduce(flatten, []);
    }
    return results.concat(cur, acc);
  };
  return src.reduce(flatten, []);
}
