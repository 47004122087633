import * as React from 'react';
import styles from './maximize.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

/**
 * Maximizes an immediate child such that it fills the entire view port.
 */
const componentName = 'Maximize';

export class Maximize extends React.Component<{ children: React.ReactNode }, {}> {
  render() {
    return (
      <div className={styles['normal']} data-component={componentName} {...getDataAttributes(this.props)}>
        {React.Children.only(this.props.children)}
      </div>
    );
  }
}

Maximize['displayName'] = componentName;
