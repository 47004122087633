import * as React from 'react';
import { ContextType } from 'react';
import styles from './search-field.module.scss';
import { KeyCodes, LangUtil } from '../../client-shared';
import { AriaSearchboxInterface } from '../AccessibilityProps';
import { IconButton } from '../icon-button/icon-button';
import { getDataAttributes } from '../../util/attribute-util';
import { Box } from '../box/box';
import StylingContext from '../../style-context/styling-context-provider';
import { windowSafe } from '../../util/ssr';
import { Icon } from '../icon/icon';

export interface SearchFieldProperties {
  /**
   * Callback to invoke when the user has typed in a search
   * @param text the currently typed search text
   */
  onSearch: (text: string) => void;

  /**
   * Label for aria-label value of clear button.
   */
  clearLabel: string;

  /**
   * Label for aria-label value of search button.
   */
  searchLabel?: string;

  /**
   * Whether to search automatically after the auto search delay. Defaults to true.
   * When false, the user must press enter to invoke the search callback.
   */
  autoSearch?: boolean;

  /**
   * The delay to wait before firing the onSearch callback in response to user typing. Defaults to 200 ms.
   */
  autoSearchDelay?: number;

  /**
   * Whether to auto-focus the search input field
   */
  autoFocus?: boolean;

  /**
   * The placeholder text to show as a hint in the search field when it's empty.
   */
  placeholder?: string;

  value?: string;

  className?: string;

  aria?: AriaSearchboxInterface;
}

const componentName = 'SearchField';

export class SearchField extends React.Component<SearchFieldProperties, { value: string }> {
  static defaultProps: SearchFieldProperties = {
    onSearch: null,
    autoSearch: true,
    autoSearchDelay: 200,
    clearLabel: '',
    searchLabel: '',
  };

  state = { value: this.props.value ?? '' };

  private input: HTMLInputElement;
  private searchTimer = null;
  private searchQueued = false;

  inputId = LangUtil.randomUuid();

  context: ContextType<typeof StylingContext>;

  // lifecycle
  componentDidUpdate(prevProps: Readonly<SearchFieldProperties>, prevState: Readonly<{ value: string }>, snapshot?: any): void {
    if (prevProps.value !== this.props.value && this.props.value) {
      if (this.searchQueued) {
        // the user is actively typing something so we should not take in the new input value now.
        // Doing so could discard the characters entered since the last props.onSearch invocation.
        // The props.value and typed value will be consistent again once the timer elapses and
        // props.onSearch is invoked again.
        return;
      }
      this.setState({ value: this.props.value });
    }
  }

  componentWillUnmount() {
    windowSafe().clearTimeout(this.searchTimer);
  }

  // events

  handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState(
      {
        value: (e.target as HTMLInputElement).value,
      },
      () => {
        if (this.props.autoSearch) {
          windowSafe().clearTimeout(this.searchTimer);
          this.searchTimer = windowSafe().setTimeout(this.handleOnSearch, this.props.autoSearchDelay);
          this.searchQueued = true;
        }
      }
    );
  };

  private handleKeyDown = (e: React.KeyboardEvent<any>) => {
    switch (e.keyCode) {
      case KeyCodes.DOM_VK_RETURN:
        this.handleOnSearch();
        return;
      case KeyCodes.DOM_VK_DOWN:
      case KeyCodes.DOM_VK_UP:
        break;
    }
  };

  private handleClear = () => {
    this.setState({ value: '' }, () => {
      this.input.focus();
      this.handleOnSearch();
    });
  };

  handleOnSearch = () => {
    this.searchQueued = false;
    clearTimeout(this.searchTimer);
    this.props.onSearch(this.state.value);
  };

  // render

  renderClear() {
    if (!this.state.value) {
      return null;
    }

    const { styling } = this.context;

    return (
      <Box className={styles['clear']}>
        <IconButton
          type="button"
          size={styling === 'brand' ? 'large' : 'small'}
          onClick={this.handleClear}
          aria={{ 'aria-label': this.props.clearLabel, 'aria-controls': this.inputId }}
          icon="delete"
        />
      </Box>
    );
  }

  render() {
    const { styling } = this.context;
    const searchFieldClassNames = [styles['component']];

    if (this.props.className) {
      searchFieldClassNames.push(styles[this.props.className]);
    }

    if (styling) {
      searchFieldClassNames.push(styles[styling]);
    }

    return (
      <div className={searchFieldClassNames.join(' ')} data-component={componentName}>
        <Box className={styles['search-icon']}>
          {styling === 'brand' ? (
            <IconButton
              type="button"
              size="large"
              onClick={this.handleOnSearch}
              aria={{ 'aria-label': this.props.searchLabel, 'aria-controls': this.inputId }}
              icon="search"
            />
          ) : (
            <Icon name={'search'} size={16} />
          )}
        </Box>
        <input
          id={this.inputId}
          role="searchbox"
          type="text"
          value={this.state.value}
          placeholder={this.props.placeholder}
          ref={(input) => (this.input = input)}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          autoFocus={this.props.autoFocus}
          {...this.props.aria}
          {...getDataAttributes(this.props)}
        />
        {this.renderClear()}
      </div>
    );
  }
}

SearchField['displayName'] = componentName;
SearchField.contextType = StylingContext;
