import * as React from 'react';
import * as styles from './label.module.scss';
import { Inline } from '../inline/inline';
import { Text, TextSizes } from '../text/text';
import { getDataAttributes } from '../../util/attribute-util';

export type TextType = 'medium' | 'small' | 'x-small' | 'field';

export type Theme = 'default' | 'inverse';

const componentName = 'Label';

export interface LabelProps {
  /**
   * (Optional): Id for the label
   */
  id?: string;
  /**
   * The text that should be displayed
   */
  text: string;
  /**
   * The type of the label
   */
  type: TextType;
  /**
   * (Optional): To overwrite style for the label
   */
  style?: React.CSSProperties;
  /**
   * (Optional): To setup the for attribute for label
   */
  htmlFor?: string;
  /**
   * (Optional): To add custom className to the label component
   */
  className?: string;
  /**
   * (Optional): Number of wrapped lines to show before ending an overflow with ellipsis.
   * By default, the label will not wrap beyond a single line.
   */
  maxLines?: number;
  /**
   * (Optional): This is used in the Field component to add an * at the end of the label, if a field is required
   */
  isRequired?: boolean;
}

const sizes = new Map<TextType, TextSizes>([
  ['medium', 'font16'],
  ['small', 'font14'],
  ['x-small', 'font12'],
  ['field', 'font14'],
]);

export class Label extends React.Component<LabelProps, {}> {
  render() {
    const classes = [styles['component'], styles[this.props.type], this.props.className];
    const maxLines = this.props.maxLines ?? 1;
    const size = sizes.get(this.props.type ?? 'medium');

    if (this.props.htmlFor) {
      return (
        <label
          id={this.props.id}
          title={this.props.text}
          htmlFor={this.props.htmlFor}
          className={classes.join(' ')}
          data-component={componentName}
          {...getDataAttributes(this.props)}
        >
          <Inline spacing={'4dp'}>
            <Text size={size} maxLines={maxLines}>
              {this.props.text}
            </Text>
            {this.props.isRequired && <span className={styles['required']}>*</span>}
          </Inline>
        </label>
      );
    } else {
      return (
        <div
          id={this.props.id}
          data-component={componentName}
          className={classes.join(' ')}
          title={this.props.text}
          {...getDataAttributes(this.props)}
        >
          <Inline spacing={'4dp'}>
            <Text size={size} maxLines={maxLines}>
              {this.props.text}
            </Text>
            {this.props.isRequired && <span className={styles['required']}>*</span>}
          </Inline>
        </div>
      );
    }
  }
}

Label['displayName'] = componentName;
