import * as React from 'react';
import styles from './toggle-button.module.scss';
import { LangUtil } from '../../client-shared';
import { SwitchProps } from '../toggle/switch';
import { IconName } from '../icon/icon-SVGs';
import { ButtonFocusHandler, ButtonFocusHandlerChildProps } from '../button-base/button-focus-handler';
import { getDataAttributes } from '../../util/attribute-util';
import { Icon } from '../icon/icon';

const componentName = 'ToggleButton';

export interface ToggleButtonProps extends SwitchProps {
  /**
   * Name of the Icon which will be rendered from the styleguide Icon List
   */
  checkedIcon: IconName;
  /**
   *  Name of the second Icon which will be rendered when the State is false.
   */
  uncheckedIcon: IconName;

  /**
   * (Optional): Default is fill-icon. fill-background changes the background color of the selected item instead of only the icon.
   */
  styling?: 'fill-icon' | 'fill-background';

  /**
   * (Optional): Label for an optional browser tooltip explaining the use, shown when hovering the icon
   */
  toolTipLabel?: string;

  /**
   * (Optional): If set to true the button will use $color-icon-interaction as the unchecked state so the button looks interactable, needs to either have the checked/unchecked icons different or fill-background in styling prop
   */
  interactiveButton?: boolean;
}

const InnerToggleButton = React.forwardRef<any, ToggleButtonProps & ButtonFocusHandlerChildProps>((props, ref) => {
  const attributes: React.HTMLAttributes<HTMLElement> = {};

  props.hasFocus && (attributes['data-focus'] = true);
  props.hasHover && (attributes['data-hover'] = true);

  const divNames = [];
  !props.isSelected &&
    props.interactiveButton &&
    (props.checkedIcon !== props.uncheckedIcon || props.styling === 'fill-background') &&
    divNames.push(styles['unchecked-interactive']);

  switch (props.styling) {
    case 'fill-icon': {
      divNames.push(styles['toggle-button-fill-icon']);
      break;
    }
    case 'fill-background': {
      !props.isDisabled ? divNames.push(styles['toggle-button-fill-background']) : divNames.push(styles['toggle-button-fill-icon']);
      break;
    }
  }

  divNames.push(styles[props.isSelected ? 'checked' : 'unchecked']);
  const styleNames = [styles['component'], styles[props.size]].join(' ');

  const ICON_SIZES = {
    large: 24,
    medium: 20,
    small: 16,
  };

  //note when changing the icon sizes also change the large/small class width/height in ToggleButton.scss
  const iconSize: number = ICON_SIZES[props.size];

  const iconName = props.isSelected ? props.checkedIcon : props.uncheckedIcon ?? props.checkedIcon;
  const buttonAriaLabel = props.aria ? props.aria['aria-label'] ?? 'toggle' : 'toggle';

  const onChange = (e) => props.onSelectedChanged && props.onSelectedChanged((e.target as HTMLInputElement).checked);

  return (
    <div className={styleNames} {...getDataAttributes(props)}>
      <input
        data-component={componentName}
        aria-label={buttonAriaLabel}
        title={props.toolTipLabel}
        ref={ref}
        id={LangUtil.randomUuid()}
        type="checkbox"
        checked={props.isSelected}
        disabled={props.isDisabled}
        onChange={onChange}
        {...props.aria}
      />
      <div className={divNames.join(' ')} {...attributes}>
        <Icon size={iconSize} name={iconName} />
      </div>
    </div>
  );
});

export class ToggleButton extends React.Component<ToggleButtonProps, {}> {
  static defaultProps: Partial<ToggleButtonProps> = {
    styling: 'fill-icon',
    size: 'small',
  };

  render() {
    return (
      <ButtonFocusHandler
        render={({ ref, hasHover, hasFocus }) => <InnerToggleButton ref={ref} {...this.props} hasFocus={hasFocus} hasHover={hasHover} />}
      />
    );
  }
}

ToggleButton['displayName'] = componentName;
