import * as React from 'react';
import styles from './table.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface TdProps {
  children?: React.ReactNode;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  padding?: string;
  colSpan?: number;
  clearBorder?: boolean;
}
const componentName = 'Td';

export class Td extends React.Component<TdProps, any> {
  render() {
    const classNames = [styles['td']];

    if (this.props.clearBorder) {
      classNames.push(styles['clear-border']);
    }

    return (
      <td
        className={classNames.join(' ')}
        data-component={componentName}
        style={{
          width: this.props.width,
          minWidth: this.props.minWidth,
          maxWidth: this.props.maxWidth,
          padding: this.props.padding,
        }}
        colSpan={this.props.colSpan}
        {...getDataAttributes(this.props)}
      >
        {this.props.children}
      </td>
    );
  }
}
Td['displayName'] = componentName;
