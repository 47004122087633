import * as React from 'react';
import styles from './metric-item.module.scss';
import { Label, TextType } from '../../label/label';
import { Icon } from '../../icon/icon';
import { MetricButton } from './metric-button';
import { ToolTip } from '../../tool-tip/tool-tip';
import { CommandButton } from '../../command-button/command-button';
import { MetricListItem } from '../metric-list';
import { Callout, CalloutProps } from '../../callout/callout';
import { LangUtil } from '../../../client-shared';
import { getDataAttributes } from '../../../util/attribute-util';

export type MetricItemSize = 'large' | 'small';

export type DefaultChangeHighlight = {
  value: number;
};

export type CustomChangeHighlight = {
  customHighlight: React.ReactNode;
};

export type MetricChangeHighlight = DefaultChangeHighlight | CustomChangeHighlight;

export type MetricValue = number | string;

export type CalloutOptions = Omit<CalloutProps, 'trigger' | 'asChild' | 'isOpen' | 'onClosed'>;

export interface MetricItemProps {
  /**
   * The label of the metric.
   */
  label: string;

  /**
   * Optional description of the label. The description will be available via an info icon button that displays
   * it as a component tooltip.
   */
  labelDescription?: string;

  /**
   * Optional max width to apply to the label before text ellipsis is used to manage overflow.
   * By default this is 7em.
   */
  labelMaxWidth?: string;

  /**
   * The value of the metric, e.g. the number 3 or the string '3 of 5'.
   */
  value: MetricValue;

  /**
   * When specified, the value metric will be rendered and placed in a `Callout` component that
   * can provide information about the metric item. The options are a relevant subset of the CalloutProps.
   */
  calloutOptions?: CalloutOptions;

  /**
   * The color associated with the metric.
   */
  color: string;

  /**
   * The value of the change. The default change will be displayed if the value != 0.
   */
  change?: MetricChangeHighlight;

  /**
   * The size of the metric item.
   */
  size?: MetricItemSize;

  /**
   * Text description of the metric item
   */
  screenReaderLabel: (item: MetricItemProps) => string;

  /**
   * Optional class name
   */
  className?: string;

  /**
   * Optional label control, where the label can be replaced by e.g. a popup-menu
   * @param item
   */
  labelControl?: (item: MetricListItem) => React.ReactNode;
}

const componentName = 'MetricItem';

export class MetricItem extends React.Component<MetricItemProps, {}> {
  static defaultProps = {
    change: 0,
    tooltipText: '',
  };
  state: {
    isCalloutOpen: false;
    isTooltipFocused: false;
  };
  tooltipId = LangUtil.randomUuid();

  get change(): number {
    if (LangUtil.isDefined(this.props.change) && this.isChangeTypeDefault(this.props.change)) {
      return this.props.change.value;
    }
    return null;
  }

  get customChange(): React.ReactNode {
    if (LangUtil.isDefined(this.props.change) && !this.isChangeTypeDefault(this.props.change)) {
      return this.props.change.customHighlight;
    }
  }

  get valueClass() {
    if (
      (this.isChangeTypeDefault(this.props.change) && this.change !== 0) ||
      (this.props.change && !this.isChangeTypeDefault(this.props.change))
    ) {
      return styles['valueWithChange'];
    }
    return styles['value'];
  }

  isChangeTypeDefault(change: MetricChangeHighlight): change is DefaultChangeHighlight {
    return (change as DefaultChangeHighlight).value !== undefined;
  }

  renderChange() {
    if (this.isChangeTypeDefault(this.props.change)) {
      let iconName;
      if (this.change !== 0) {
        if (this.change > 0) {
          iconName = 'up';
        } else if (this.change < 0) {
          iconName = 'down';
        }

        return (
          <React.Fragment>
            <Icon name={iconName} size={8} />
            <Label type="x-small" text={Math.abs(this.change).toString()} />
          </React.Fragment>
        );
      }
    } else {
      return this.customChange;
    }
  }

  renderMetricContent() {
    const labelType: TextType = this.props.size === 'small' ? 'x-small' : 'small';
    let value: React.ReactNode = this.props.value;
    if (this.props.calloutOptions) {
      const screenReaderLabel = this.props.screenReaderLabel(this.props);
      value = (
        <Callout
          {...this.props.calloutOptions}
          trigger={
            <CommandButton
              type={'button'}
              size={'large'}
              label={this.props.value.toString()}
              onClick={() => this.setState((p) => ({ ...p, isCalloutOpen: true }))}
            />
          }
          as={'span'}
          isOpen={this.state?.isCalloutOpen ?? false}
          onClosed={() => this.setState((p) => ({ ...p, isCalloutOpen: false }))}
        >
          <MetricButton label={value.toString()} screenReaderLabel={screenReaderLabel} size={this.props.size} />
        </Callout>
      );
    }

    const hasCallout = !!this.props.calloutOptions;

    const labelDescription = this.props.labelDescription;

    const titleClassNames = [styles['title']];
    if (labelDescription) {
      titleClassNames.push(styles['with-tooltip']);
    }

    const ariaHidden = (hidden) => hidden && { 'aria-hidden': hidden };
    const labelStyle = { maxWidth: this.props.labelMaxWidth || '7em' };

    return (
      <div className={styles['subTypes']} {...ariaHidden(!hasCallout)}>
        <div className={this.valueClass}>
          {value}
          <sup className={styles['change']} {...ariaHidden(hasCallout)}>
            {this.renderChange()}
          </sup>
        </div>
        <div className={titleClassNames.join(' ')} {...ariaHidden(hasCallout)}>
          {this.props.labelControl?.(this.props as MetricListItem) || (
            <div style={{ paddingTop: '6px' }}>
              <Label text={this.props.label} type={labelType} style={labelStyle} maxLines={2} />
            </div>
          )}
          {labelDescription && (
            <span
              tabIndex={0}
              onFocus={() => this.setState(() => ({ isTooltipFocused: true }))}
              onBlur={() => this.setState(() => ({ isTooltipFocused: false }))}
              aria-label={labelDescription}
            >
              <ToolTip id={this.tooltipId} content={labelDescription} forceShow={this.state?.isTooltipFocused}>
                <Icon name="information" size={14} fill={'interaction'} />
              </ToolTip>
            </span>
          )}
        </div>
      </div>
    );
  }

  renderScreenReaderLabel() {
    if (this.props.calloutOptions) {
      // the call out metric value will use the screen reader label
      return null;
    }
    return <div className={styles['screen-reader']}>{this.props.screenReaderLabel(this.props)}</div>;
  }

  render() {
    const classes = [styles['component']];

    if (this.props.size === 'small') {
      classes.push(styles['container-small']);
    } else {
      classes.push(styles['container-large']);
    }

    this.props.className && classes.push(this.props.className);

    return (
      <li
        style={{ borderLeftColor: this.props.color }}
        className={classes.join(' ')}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        {this.renderScreenReaderLabel()}
        {this.renderMetricContent()}
      </li>
    );
  }
}

MetricItem['displayName'] = componentName;
