// Purpose: Provide an abstraction layer for SelectItem to use PopupMenu

/**
 * Notes
 * - onItemsSelected usage in Pure is typically only interested in ONE selected element
 */

import { useEffect, useMemo, useRef } from 'react';
import { MenuItemAny } from './types';
import { SelectItem, Separator, SelectItemBuilder } from '../../models/select-item';
import { LangUtil } from '../../client-shared';
import { IconName } from '../icon/icon-SVGs';

type UseSelectionList = {
  // SelectItems
  items: SelectItem[];
  // Initial selection. We do not accept changes to this props.
  selectedItems?: SelectItem[];
  allowMultiple?: boolean;
  onItemsSelected?: (selected: SelectItem[], newItems: SelectItem[], removedItems: SelectItem[], isMouseEvent?: boolean) => any;
  onSelect?: (selected: SelectItem) => void;
  checkDecorator?: 'check';
};

type UseSelectionListReturn = [MenuItemAny[], (MenuItemAny) => void];

export function useSelectionList({
  items,
  onItemsSelected,
  allowMultiple,
  onSelect,
  selectedItems,
  checkDecorator,
}: UseSelectionList): UseSelectionListReturn {
  const touched = useRef(false);
  const initialSelection: Record<string, boolean> = {};
  selectedItems?.forEach((i) => {
    initialSelection[i.id] = true;
  });
  const internalSelection = useRef<Record<string, boolean>>(initialSelection);

  // Subscribe to prop selection changes
  useEffect(() => {
    if (!selectedItems) {
      return;
    }
    const newSelection: Record<string, boolean> = {};
    selectedItems.forEach((i) => {
      newSelection[i.id] = true;
    });
    internalSelection.current = newSelection;
  }, [selectedItems]);

  // TODO: Move this into, and reimplement how SelectionList handles selectedItems
  //const lastSelection = useRef<SelectItem[]>(); // Used to calculate difference between changes

  const handleMenuItemSelect = (item: MenuItemAny) => {
    touched.current = true;

    // Internal state (only used when we have to diff the selection)
    // internalSelection.current = {
    //     ...internalSelection.current,
    //     [item.id]: !internalSelection.current[item.id],
    // };

    if (selectedItems === undefined) {
      // When selectedItems is not set, we behave in a stateless manner
      onItemsSelected?.([menuItemToSelectItem(item)], [menuItemToSelectItem(item)], []);
      onSelect?.(menuItemToSelectItem(item));
      return;
    } else {
      // selectedItems is set. We need to store the difference in selection internally
      const siSelected = items.filter((i) => {
        // New selection
        if (item.id === i.id && !internalSelection.current[i.id]) {
          return true;
        }
        // Removal
        if (item.id === i.id && internalSelection.current[i.id]) {
          return false;
        }

        if (internalSelection.current[i.id]) {
          return true;
        }
        return false;
      });

      const siAdded = siSelected.filter((i) => {
        // For every current selection, that is not in an earlier selection
        if (!internalSelection.current) {
          return true; // No previous records, always true
        }
        if (i.id === item.id) {
          if (!internalSelection.current[i.id]) {
            return true; // Added
          }
          return false; // Removed
        }
        return false;
      });
      const siRemoved = items.filter((i) => {
        if (item.id === i.id && internalSelection.current[i.id]) {
          return true;
        }
        return false;
      });

      onItemsSelected?.(siSelected, siAdded, siRemoved);
      onSelect?.(menuItemToSelectItem(item));
    }
  };

  const menuItems: MenuItemAny[] = useMemo(
    () => items.map((selectItem) => selectItemToMenuItem(selectItem, selectedItems, allowMultiple, checkDecorator)),
    [internalSelection.current, items]
  );

  /*
      // Replicates behavior of old PopupMenu and notifies a callback
      useEffect(() => {
          if (!onItemsSelected) {
              return;
          }
          if (!allowMultiple) {
              return; // Not used in single-selection-mode
          }

          const siSelected = internalSelection;

          // History helpers
          const siAdded = siSelected.filter((item) => {
              // For every current selection, that is not in an earlier selection
              if (!lastSelection.current) {
                  return true; // No previous records, always true
              }
              return lastSelection.current.every((oldItem) => oldItem.id !== item.id);
          });
          const siRemoved = lastSelection.current
              ? // For every last selection that is no longer a selection
                lastSelection.current.filter((oldItem) => {
                    return siSelected.every((item) => item.id !== oldItem.id);
                })
              : [];

          // Only trigger on changes, not the initial state
          if (touched.current === true) {
              onItemsSelected?.(siSelected, siAdded, siRemoved);
          }

          lastSelection.current = siSelected;
      }, [internalSelection]);
      */

  return [menuItems, handleMenuItemSelect];
}

// Helper function to map SelectItem to MenuItemAny
function selectItemToMenuItem(
  selectItem: SelectItem,
  selectedItems: SelectItem[] = [],
  allowMultiple: boolean,
  checkDecorator: IconName
): MenuItemAny {
  if (selectItem.isSelectable) {
    return {
      id: selectItem.id,
      type: allowMultiple ? 'check' : 'plain',
      label: selectItem.label ?? '',
      items: selectItem.children?.map((child) => selectItemToMenuItem(child, selectedItems, allowMultiple, checkDecorator)),
      value: selectItem.value,
      isSelected: selectedItems?.some((i) => i.id === selectItem.id) || false,
      icon: selectItem.icon,
      checkDecorator,
    };
  }

  if (selectItem.isSeparator) {
    return {
      type: 'separator',
      id: LangUtil.randomUuid(), // All separators share the same id for some reason
      icon: selectItem.icon,
    };
  }

  if (selectItem.isGroupHeading) {
    return {
      type: 'label',
      id: selectItem.id,
      label: selectItem.label,
    };
  }

  return {
    id: selectItem.id,
    type: 'plain',
    value: selectItem.value,
    label: selectItem.label ?? '',
    items: selectItem.children?.map((child) => selectItemToMenuItem(child, selectedItems, allowMultiple, checkDecorator)),
    icon: selectItem.icon,
  };
}

function menuItemToSelectItem(menuItem: MenuItemAny): SelectItem {
  if (menuItem.type === 'separator') {
    return Separator;
  }
  const si = new SelectItemBuilder(
    menuItem?.label || '',
    menuItem?.value,
    menuItem?.icon,
    menuItem.items?.map((child) => menuItemToSelectItem(child))
  ).build();
  si.id = menuItem.id;
  switch (menuItem.type) {
    case 'check': {
      si.isSelectable = true;
      return si;
    }
  }
  return si;
}
