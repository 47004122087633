/**
 * Based on Braid Design System.
 * See https://github.com/seek-oss/braid-design-system/blob/master/LICENSE for legal terms
 */
export type ResponsiveArray<Value extends string | number> = ReadonlyArray<Value | null> & { length: 2 | 3 } & { 0: Value | null };

export const optimizeResponsiveArray = <Value extends string | number>(value: ResponsiveArray<Value>): ResponsiveArray<Value> => {
  let lastValue: Value | undefined;

  const values = value.map((v) => {
    if (v !== lastValue && v !== null) {
      lastValue = v;
      return v;
    }

    return null;
  });

  return [values[0] ?? null, values[1] ?? null, values[2] ?? null] as const;
};
