import * as React from 'react';
import { LegacyRef } from 'react';
import { getDataAttributes } from '../../util/attribute-util';

export interface TbodyProps {
  children?: React.ReactNode;
  assignRef?: LegacyRef<HTMLTableSectionElement>;
}

const componentName = 'Tbody';

export class Tbody extends React.Component<TbodyProps, any> {
  render() {
    const { assignRef, ...props } = this.props;
    return (
      <tbody ref={assignRef} data-component={componentName} {...props} {...getDataAttributes(this.props)}>
        {this.props.children}
      </tbody>
    );
  }
}

Tbody['displayName'] = componentName;
