import * as React from 'react';
import styles from './number-input.module.scss';
import { AriaTextboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import StylingContext from '../../style-context/styling-context-provider';
import { FormFieldValueProps } from '../../models/form-field-value-props';
import { KeyCodes, LangUtil, PixelConverterUtil } from '../../client-shared';

export enum Theme {
  Transparent,
  Default,
}

export interface NumberInputProps {
  /**
   * When user types in input, onChange will be fired
   * It will return the current value as a number or null if input is empty
   * @param newValue
   */
  onChange: (newValue: number | null) => void;
  onBlur?: () => any;
  onFocus?: () => any;
  onNavigationKey?: (keyCode: number) => any;
  delay?: number;
  placeholder?: string;
  maxWidth?: number;
  /**
   * Theme to use for input, which are "Transparent" or "Default"
   */
  theme?: Theme;
  style?: React.CSSProperties;
  value?: number;
  min?: number;
  max?: number;
  id?: string;
  step?: number;
  inputParser?: (valueString: string) => number;

  /**
   * Arbitrary attributes to add to the final element (such as "data-*" attributes)
   */
  data?: { [key: string]: string };

  aria?: AriaTextboxInterface;
}

const componentName = 'NumberInput';

export class NumberInput extends React.Component<
  NumberInputProps & FormFieldValueProps<number>,
  {
    value: string;
  }
> {
  static defaultProps = {
    step: 'any',
    min: 0,
  };
  /**
   * Styling context to handle 'brand' or 'classic' styling;
   */
  context: React.ContextType<typeof StylingContext>;
  timeout = 0;

  inputRef: HTMLInputElement;
  //last value sent to parent component
  memoizedValue: string = undefined;

  // TODO: Simplify debounce (STYLEGUIDE-173)
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value != null ? this.props.value.toLocaleString('en-GB', { useGrouping: false }) : '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value === state.value) {
      return null;
    } else if (state.value === '') {
      return { value: state.value };
    } else {
      return { value: props.value };
    }
  }

  public focus(): void {
    this.inputRef?.focus();
  }

  select() {
    this.inputRef.select();
  }

  render() {
    const { styling } = this.context;
    const classes = [styles['input'], styles[styling]];
    classes.push(this.props.theme != null ? styles[Theme[this.props.theme]] : styles['Default']);

    if (this.props.disabled) {
      classes.push(styles['disabled']);
    } else if (this.props.error) {
      classes.push(styles['error']);
    }

    let inlineStyle = {};
    if (this.props.maxWidth) {
      inlineStyle['maxWidth'] = PixelConverterUtil.dpToPx(this.props.maxWidth) + 'px';
    }
    inlineStyle = Object.assign({}, inlineStyle, this.props.style);
    return (
      <input
        id={this.props.id}
        ref={(c) => (this.inputRef = c)}
        className={classes.join(' ')}
        style={inlineStyle}
        type="number"
        step={this.props.step || 'any'}
        min={this.props.min}
        max={this.props.max}
        value={this.state.value}
        onChange={() => {
          /* use input events to update values. This stub suppresses React warnings*/
        }}
        onInput={this.handleInput}
        onBlur={this.handleBlur}
        onKeyDown={(e) => {
          this.onKeyDown(e.keyCode);
        }}
        disabled={this.props.disabled}
        {...this.props.data}
        {...this.props.aria}
        placeholder={this.props.placeholder}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeout);
  }

  onKeyDown(keyCode: number) {
    const navigationKeys = [KeyCodes.DOM_VK_UP, KeyCodes.DOM_VK_DOWN, KeyCodes.DOM_VK_ESCAPE, KeyCodes.DOM_VK_ENTER];

    if (this.props.onNavigationKey && navigationKeys.some((k) => k === keyCode)) {
      this.props.onNavigationKey(keyCode);
    }
  }

  //note that there is a known bug in EdgeHTML at least up to version 17 where the arrow keys do not fire input events. Currently fixed but not yet flighted.
  // https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/14678823/
  handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueString = (e.target as HTMLInputElement).value;
    this.setState({
      value: valueString,
    });
    //don't fire an input event if we will mess up the user input.
    if (valueString === '') {
      return;
    }
    if (valueString.length === 1 && valueString[0] === '.') {
      return;
    }
    if (valueString[valueString.length - 1] === '.') {
      return;
    }
    let sanVal = this.sanitizeValueString(valueString);
    sanVal = this.clampMin(this.clampMax(sanVal));
    this.setState({
      value: ![null, undefined].some((v) => v === sanVal) ? sanVal.toLocaleString('en-GB', { useGrouping: false }) : '',
    });

    this.notify(sanVal);
  };

  defaultParser = (valueString) => {
    let str = valueString;
    if (str.length > 0 && str[0] === '.') {
      str = '0' + str;
    }
    return parseFloat(str);
  };

  clampMin(val: number) {
    if (this.props.min !== undefined) {
      if (val < this.props.min) {
        return this.props.min;
      }
    }
    return val;
  }

  clampMax(val: number) {
    if (this.props.max !== undefined) {
      if (val > this.props.max) {
        return this.props.max;
      }
    }
    return val;
  }

  notify = (val) => {
    //Don't notify if we have already propagated this value the last time an update or blur was made
    if (this.memoizedValue === val) {
      return;
    }
    this.memoizedValue = val;
    this.timeout && clearTimeout(this.timeout);

    if (this.props.delay) {
      this.timeout = window.setTimeout(() => {
        this.props.onChange && this.props.onChange(val);
      }, this.props.delay);
    } else {
      this.props.onChange && this.props.onChange(val);
    }
  };

  private sanitizeValueString(str: string) {
    const inputParser: (valueString: string) => number = this.props.inputParser || this.defaultParser;

    return LangUtil.isDefined(str) ? inputParser(str) : null;
  }

  private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const valueString = (e.target as HTMLInputElement).value;
    if (valueString === '') {
      this.notify(null);
      return;
    }
    let sanVal = this.sanitizeValueString(valueString);
    sanVal = this.clampMin(this.clampMax(sanVal));
    this.setState({
      value: ![null, undefined].some((v) => v === sanVal) ? sanVal.toLocaleString('en-GB', { useGrouping: false }) : '',
    });
    this.notify(sanVal);
  };
}

NumberInput['displayName'] = componentName;
NumberInput.contextType = StylingContext;
