import * as React from 'react';
import styles from './date-picker-input.module.scss';
import moment from 'moment';
import { IconButton } from '../icon-button/icon-button';
import { Callout } from '../callout/callout';
import { DatePickerPanel, DatePickerPanelProps } from './date-picker-panel';
import { LangUtil } from '../../client-shared';
import { TextField } from '../text-field/text-field';

interface DatePickerInputProps {
  placeholder: string;
  value: string;
  format: string;
  toggleButtonAriaLabel: string;
  dateChanged: (newDate: moment.Moment) => void;
  onToggleButtonPressed: () => void;
  getFocusable: (focusable: { focus: () => void }) => void;
  datePickerPanelProps: DatePickerPanelProps;
  isCalloutOpen: boolean;
}

const componentName = 'DatePickerInput';

export class DatePickerInput extends React.Component<DatePickerInputProps, any> {
  private inputRef: TextField;

  constructor(props: DatePickerInputProps) {
    super(props);
    this.state = { value: props.value };
  }

  render() {
    return (
      <form className={styles['date-input']} onSubmit={(e) => this.handleSubmit(e)} data-component={componentName}>
        <TextField
          ref={(e) => {
            this.inputRef = e;
            this.props.getFocusable({ focus: () => this.focus() });
          }}
          value={this.state.value}
          placeholder={this.props.placeholder}
          onChange={(value) => this.setState({ value })}
          onBlur={() => this.handleNavigation()}
        />
        <Callout
          trigger={
            <IconButton
              type={'button'}
              size={'small'}
              icon={'calendar'}
              onClick={() => this.props.onToggleButtonPressed()}
              aria={{ 'aria-label': this.props.toggleButtonAriaLabel }}
              aria-expanded={this.props.isCalloutOpen}
            />
          }
          isOpen={this.state.calloutOpen}
          side={'bottom'}
          closeAriaLabel={'Close'}
          hideFooter
          hideArrow
          onCloseAutoFocus={() => {}}
          onClosed={() => this.props.onToggleButtonPressed()}
          stripPadding={true}
          align={'start'}
          alignOffset={-180}
        >
          <DatePickerPanel {...this.props.datePickerPanelProps} />
        </Callout>
      </form>
    );
  }

  componentWillReceiveProps(nextProps: Readonly<DatePickerInputProps>, nextContext: any) {
    this.setState({ value: nextProps.value });
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    this.validateState((validDate) => {
      setTimeout(() => this.focus(), 0);
      this.props.dateChanged(validDate);
    });
  }

  public focus(): void {
    this.inputRef.focus();
    this.inputRef.select();
  }

  private handleNavigation() {
    this.validateState((validDate) => this.props.dateChanged(validDate));
  }

  private validateState(validAction: (date) => void): boolean {
    const date: moment.Moment = moment(this.state.value, this.props.format);
    if (date.isValid()) {
      this.setState({ value: date.format(this.props.format) });
      validAction(date);
    }
    if (!LangUtil.isDefined(this.state.value)) {
      this.setState({ value: '' });
      validAction(undefined);
    }
    return date.isValid();
  }
}

DatePickerInput['displayName'] = componentName;
