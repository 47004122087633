import { Styling, StylingContextProvider } from '../style-context/styling-context-provider';
import * as React from 'react';
import { ErrorBoundaryContentLabelProps } from './error-boundary/error-boundary-content/error-boundary-content';
import { getPaletteClassName, Palette } from '../util/theme-util';
import { ToastFactory } from '../components/toast/toastFactory';

interface Props {
  palette?: Palette['name'];
  styling?: Styling;
  children: React.ReactNode;
  errorBoundary?: ErrorBoundaryContentLabelProps;
  portalDOMId?: string;
}

export const defaultErrorBoundary: ErrorBoundaryContentLabelProps = {
  errorBoundaryHeader: 'Oops! Something went wrong...',
  errorBoundaryCopyLabel: 'Copy to clipboard',
  errorBoundaryCloseLabel: 'Retry',
  errorBoundaryAccordionHeader: 'Expand to see error details for a JIRA ticket',
};

export const GrapheneRoot = ({
  palette = 'palette-light',
  styling = 'brand',
  children,
  errorBoundary = defaultErrorBoundary,
  portalDOMId,
}: Props) => (
  <StylingContextProvider styling={styling}>
    <ToastFactory>
      <div className={getPaletteClassName({ name: palette })}>{children}</div>
    </ToastFactory>
  </StylingContextProvider>
);
