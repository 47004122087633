import { StoreContext, StoreClass, onPropertyNameAssigned, onInitializeStore, InitializingStore } from './store-context';
import { getClassFunctionName } from '../util/browser';

/**
 * Default implementation of StoreContext
 */
export class DefaultStoreContext implements StoreContext {
  protected stores = {};

  /**
   * Gets a store instance based on the specified class. Stores are singleton.
   * @param storeClass class of the store to get or create
   * @param id optional id to access the store with, e.g. in case multiple stores use the same class
   * @param allowCreate whether the creation of the store, if it doesn't exist is allowed
   */
  get<T>(storeClass: StoreClass<T>, id?: string): T {
    const storeName = this.createStoreId(storeClass, id);
    const currentStore = this.stores[storeName];
    if (currentStore) {
      return currentStore;
    } else {
      throw new Error(`No "${storeClass.name}" exists in the store context. Did you forget to create it?`);
    }
  }

  create<T>(storeClass: StoreClass<T>, id?: string): T {
    const storeName = this.createStoreId(storeClass, id);
    const store = this.initializeStore<T>(new storeClass());
    (store as any).__storeName__ = storeName;
    this.stores[storeName] = store;
    return store;
  }

  /**
   * Gets whether this store context contains the specific store
   * @param storeClass the store class to check
   * @param id optional id for the store to check
   */
  contains<T>(storeClass: StoreClass<T>, id?: string): boolean {
    // eslint-disable-next-line no-prototype-builtins
    return this.stores.hasOwnProperty(this.createStoreId(storeClass, id));
  }

  protected createStoreId<T>(storeClass: StoreClass<T>, id?: string): string {
    let storeName = getClassFunctionName(storeClass);
    if (id) {
      storeName += ':' + id;
    }
    return storeName;
  }

  private initializeStore<T>(store): T {
    // TODO: add observables etc. for non-observed/non-computed properties
    if (store[onInitializeStore]) {
      (store as InitializingStore).onInitializeStore(this);
    }
    Object.getOwnPropertyNames(store).forEach((propertyName) => {
      const propertyValue = store[propertyName];
      if (propertyValue && propertyValue[onPropertyNameAssigned] instanceof Function) {
        propertyValue[onPropertyNameAssigned](propertyName, store);
      }
    });
    return store;
  }
}
