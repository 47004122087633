import * as React from 'react';
import { DispatchCustomEvent } from '../../browser-api-helpers/dispatch-custom-event';
import styles from './resizer.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { windowSafe } from '../../util/ssr';

export interface ResizerProps {
  /** Optional callback for when resizing begins */
  onResizeStart?: () => void;

  /** Optional callback for when resizing ends */
  onResizeEnd?: () => void;

  /**
   * Callback for when the resizer moves one or more pixels as a result of user dragging
   * @param pixelOffset pixels the resizer was moved. Can be negative. If baseOffsetOnParent is set, the distance from the resizer to the leftmost edge of the parent is added to the offset
   */
  onResize: (pixelOffset: number) => void;

  /** Indicates whether the resizer moves horizontally or vertically */
  direction?: 'horizontal' | 'vertical';

  /**
   * optional class names to append to the resizer
   */
  className?: string;

  /**
   * base the offset calculations on the position of the resize' parent element instead of the delta to the resize element.
   * Useful if your resizer can't move freely.
   */
  baseOffsetOnParent?: boolean;

  /**
   * whether the resizer is enabled and responds to interaction. Defaults to true
   */
  enabled?: boolean;

  style?: React.CSSProperties;

  /**
   * Boolean flag to suppress customEvents from firing.
   * Use this flag if you don't want to fire off a "resize" event but instead want to handle all side effects via the onResize callback.
   * Note that this might prevent siblings or adjacent domElements in other DOM branches to react to resize events.
   */
  suppressCustomEvent?: boolean;
}

const eventName = 'resize';
const componentName = 'Resizer';

export class Resizer extends React.Component<ResizerProps, {}> {
  /**
   * A draggable resizer which shows a <-|-> cursor between two resizable elements.
   */

  static defaultProps: ResizerProps = {
    direction: 'horizontal',
    onResize: undefined,
    enabled: true,
  };
  elem: HTMLElement;
  didUpdate: boolean;

  moveAnimationFrame = -1;
  private startX: number;
  private startY: number;

  constructor(props: ResizerProps, context: any) {
    super(props, context);
    this.didUpdate = false;
  }

  fireCustomResizeEvent() {
    if (!this.props.suppressCustomEvent) {
      DispatchCustomEvent.Dispatch(this.elem, eventName);
    }
  }

  render() {
    const classes = [styles[this.props.direction as string]];

    if (this.props.className) {
      classes.push(this.props.className);
    }

    if (!this.props.enabled) {
      classes.push(styles['disabled']);
    }

    return (
      <div
        ref={(c) => (this.elem = c)}
        style={this.props.style}
        className={classes.join(' ')}
        onMouseDown={this.handleMouseDown}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  // ---- event handlers ----

  componentWillUnmount() {
    this.removeListeners();
  }

  componentDidUpdate() {
    if (this.didUpdate) {
      this.fireCustomResizeEvent();
    }
  }

  private handleMouseDown = (e: React.MouseEvent<any>) => {
    e.preventDefault(); //prevent text selection
    this.addListeners();
    document.body.dataset['resizing'] = 'true';
    if (this.props.onResizeStart) {
      this.props.onResizeStart();
    }
    if (this.props.baseOffsetOnParent) {
      const resizer = e.target as HTMLElement;
      const rect = resizer.parentElement.getBoundingClientRect();
      this.updateStartPosition(rect.left, rect.top);
    } else {
      this.updateStartPosition(e.clientX, e.clientY);
    }
  };

  // ---- component lifecycle ----

  private handleMouseUp = (e: MouseEvent) => {
    this.removeListeners();
    windowSafe().cancelAnimationFrame(this.moveAnimationFrame);
    this.didUpdate = true;
    if (this.props.onResizeEnd) {
      this.props.onResizeEnd();
    }
    delete document.body.dataset['resizing'];
  };

  private handleMouseMove = (e: MouseEvent) => {
    if (document.body.dataset['resizing'] === 'true') {
      e.preventDefault();

      if (this.moveAnimationFrame > 0) {
        windowSafe().cancelAnimationFrame(this.moveAnimationFrame);
      }
      this.moveAnimationFrame = windowSafe().requestAnimationFrame(() => {
        let diff = 0;
        if (this.props.direction === 'horizontal') {
          diff = e.clientX - this.startX;
        } else if (this.props.direction === 'vertical') {
          diff = e.clientY - this.startY;
        }

        if (diff !== 0) {
          this.props.onResize(diff);
        }

        if (!this.props.baseOffsetOnParent) {
          this.updateStartPosition(e.clientX, e.clientY);
        }
        this.didUpdate = true;
        this.moveAnimationFrame = -1;
      });
    }
  };

  // --- instance methods ----

  private addListeners() {
    windowSafe().addEventListener('mouseup', this.handleMouseUp);
    windowSafe().addEventListener('mousemove', this.handleMouseMove);
  }

  private removeListeners() {
    windowSafe().removeEventListener('mouseup', this.handleMouseUp);
    windowSafe().removeEventListener('mousemove', this.handleMouseMove);
  }

  private updateStartPosition(x, y) {
    this.startX = x;
    this.startY = y;
  }
}

/**
 * Horizontal resizer utility based on width as a percentage of the window width or a DOM element.
 * Persisted using local storage.
 */
export const HorizontalResizer = {
  /**
   * Gets the default width for a resize scenario using a local storage key
   * @param key the local storage key to use
   * @param defaultWidth a default width in case no value has been persisted in the local storage
   */
  getLocalStorageDefaultWidth(key: string, defaultWidth: number) {
    const persistedWidth = typeof window === 'object' ? window.localStorage[key] : undefined;
    return persistedWidth ? Number(persistedWidth) : defaultWidth;
  },

  /**
   * Computes a new width from a pixel offset during a resize operation
   * @param key the local storage key whoose value should be updated
   */
  updateWidth(key: string, width: number) {
    localStorage[key] = width;
    return width;
  },
};

Resizer['displayName'] = componentName;
