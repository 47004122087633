import React from 'react';
import { LangUtil } from '../client-shared';

/**
 * This custom hook will allocate an html element id if the passed id is null or undefined.
 */

export function useId(id?: string): string {
  return React.useMemo(() => {
    return id ?? LangUtil.randomUuid();
  }, [id]);
}
