import * as React from 'react';
import styles from './text-field.module.scss';
import { AriaTextboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import StylingContext from '../../style-context/styling-context-provider';
import { FormFieldValueProps } from '../../models/form-field-value-props';
import { KeyCodes, PixelConverterUtil } from '../../client-shared';

export enum Theme {
  Transparent,
  Default,
}

export interface TextFieldBaseProps {
  /**
   * Optional DOM id to assign to the input element, e.g. to enable a field to focus the input
   */
  id?: string;

  /**
   * optional role property
   */
  role?: string;

  /**
   * Optional callback for when the text field blur event has occurred.
   */
  onBlur?: () => any;

  /**
   * Optional callback for when the text field focus event has occurred.
   */
  onFocus?: () => any;

  /**
   * Optional callback for when
   * @param keyCode
   */
  onNavigationKey?: (keyCode: number) => any;

  /**
   * Optionally sets the input autoFocus attribute
   */
  autoFocus?: boolean;

  /**
   * Optional delay to wait before firing onChange in response to text input
   */
  delay?: number;

  /**
   * Optional placeholder to show in the text field when its empty
   */
  placeholder?: string;

  /**
   * Optional max-width of the text field
   */
  maxWidth?: number;

  /**
   * Optional max-length of the text field
   */
  maxLength?: number;

  /**
   * Optional theme which controls the appearance of the text field.
   * Defaults to the 'Default' theme.
   */
  theme?: Theme;

  /**
   * Optional custom styles to apply to the text field
   */
  style?: React.CSSProperties;

  /**
   * Whether to render as a password field
   */
  password?: boolean;

  /**
   * Optional DOM id of a button that should be clicked when the user presses enter in the text field
   */
  submitButtonId?: string;

  /**
   * Ignore data entry
   */
  readOnly?: boolean;

  aria?: AriaTextboxInterface;

  /**
   * Optional if you want to turn off autoComplete or help the browser understand what to autoComplete, e.g. autoComplete="email"
   */
  autoComplete?: string;
}

export interface TextFieldProps extends TextFieldBaseProps, FormFieldValueProps<string> {
  /**
   * Callback for when the text has changed
   * @param newValue
   */
  onChange?: (newValue: string) => void;
}

const navigationKeys = [
  KeyCodes.DOM_VK_UP,
  KeyCodes.DOM_VK_DOWN,
  KeyCodes.DOM_VK_ESCAPE,
  KeyCodes.DOM_VK_RETURN,
  KeyCodes.DOM_VK_ENTER,
  KeyCodes.DOM_VK_TAB,
];

const componentName = 'TextField';

export class TextField extends React.Component<TextFieldProps, {}> {
  /**
   * Styling context to handle 'brand' or 'classic' styling;
   */
  context: React.ContextType<typeof StylingContext>;
  timeout = 0;
  state = {
    value: this.props.value ?? '',
  };

  input: any;

  /** derivedStateFromProps might be relevant. It's a breaking change when migraring React versions. **/
  componentDidUpdate(prevProps: Readonly<TextFieldProps>) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  public focus(): void {
    this.input?.focus();
  }

  select() {
    this.input.select();
  }

  render() {
    const { styling } = this.context;
    const classes = [styles['input'], styles[styling]];
    classes.push(this.props.theme !== undefined ? styles[Theme[this.props.theme]] : styles['Default']);

    if (this.props.disabled) {
      classes.push(styles['disabled']);
    } else if (this.props.error) {
      classes.push(styles['error']);
    }

    let inlineStyle = {};
    if (this.props.maxWidth) {
      inlineStyle['maxWidth'] = PixelConverterUtil.pxToDp(this.props.maxWidth) + 'px';
    }
    inlineStyle = Object.assign({}, inlineStyle, this.props.style);

    let setMaxLength = {};
    if (this.props.maxLength) {
      setMaxLength = { maxLength: this.props.maxLength };
    }

    const disabled = this.props.disabled ? { disabled: true } : null;

    let role = {};

    if (this.props.role) {
      role = { role: this.props.role };
    }

    return (
      <input
        id={this.props.id}
        {...role}
        value={this.state.value || ''}
        placeholder={this.props.placeholder}
        readOnly={this.props.readOnly}
        type={this.props.password ? 'password' : 'text'}
        {...disabled}
        ref={this.setRef}
        className={classes.join(' ')}
        style={inlineStyle}
        autoFocus={this.props.autoFocus}
        autoComplete={this.props.autoComplete}
        onChange={this.onHandleChange}
        onKeyDown={this.handleOnKeyDown}
        {...setMaxLength}
        onFocus={(event) => {
          if (!this.props.onFocus) {
            // Set selection to the end of the text-field when it is selected/focused
            // only if focus isn't otherwise overridden.
            const target = event.target as HTMLInputElement;
            target.selectionStart = target.value.length;
          }
          this.props.onFocus && this.props.onFocus();
        }}
        onBlur={() => {
          this.props.onBlur && this.props.onBlur();
        }}
        {...this.props.aria}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }

  setRef = (c: HTMLInputElement) => {
    this.input = c;
  };

  onHandleChange = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const value = (event.target as HTMLInputElement).value;

    this.setState({ value }, () => {
      clearTimeout(this.timeout);

      if (this.props.delay) {
        this.timeout = window.setTimeout(() => {
          this.props.onChange?.(value);
        }, this.props.delay);
      } else {
        this.props.onChange?.(value);
      }
    });
  };

  handleOnKeyDown = (e: React.KeyboardEvent<any>) => {
    if (this.props.readOnly) {
      return;
    }
    const keyCode = e.keyCode;
    if (this.props.onNavigationKey && navigationKeys.some((k) => k === keyCode)) {
      this.props.onNavigationKey(keyCode);
      if (keyCode !== KeyCodes.DOM_VK_TAB) {
        e.preventDefault();
      }
    }
    if (this.props.submitButtonId) {
      if (keyCode === KeyCodes.DOM_VK_ENTER || keyCode === KeyCodes.DOM_VK_RETURN) {
        const submitButton = document.getElementById(this.props.submitButtonId);
        if (submitButton) {
          submitButton.click();
        }
      }
    }
  };
}

TextField['displayName'] = componentName;
TextField.contextType = StylingContext;
