import * as React from 'react';
import { ContainerBox } from '../container-box/container-box';
import styles from './accordion-list.module.scss';
import itemStyles from './accordion-list.item.module.scss';
import { SelectDeleteLabel } from '../select-delete-label/select-delete-label';
import { SelectItem } from '../../models/select-item';
import StylingContext from '../../style-context/styling-context-provider';
import { Accordion } from '../accordion/accordion';
import { IconName } from '../icon/icon-SVGs';

export class AccordionSelectItem implements SelectItem {
  constructor(label: string, id: string, isOpen: boolean, public component: React.ReactElement<any> | string) {
    this.label = label;
    this.id = id;
    this.children = [];
    this.isOpen = isOpen;
    this.templateItemType = 'AccordionSelectItem';
  }
  label: string;
  value: string | number | boolean;
  icon: IconName;
  isSelectable: boolean;
  dataFeature: string;
  subLabel: string;
  isSeparator = false;
  isGroupHeading = false;
  id: string;
  isOpen: boolean;
  children: SelectItem[];
  templateItemType: string;
}

export interface AccordionListProps {
  /**
   * A list of accordion select items to render. Each select Item represents an accordion.
   */
  items: AccordionSelectItem[];
  onDelete?: (item: AccordionSelectItem) => any;
  onToggle?: (item: AccordionSelectItem) => any;
}

export class AccordionList extends React.PureComponent<AccordionListProps, { items: AccordionSelectItem[] }> {
  static defaultProps = {
    items: [],
  };

  state = {
    items: this.props.items,
  };

  context: React.ContextType<typeof StylingContext>;

  render() {
    const { styling } = this.context;

    if (this.state.items.length === 0) {
      return <div />;
    }

    const classNames = [styles['component'], styles[styling]].join(' ');

    return (
      <ContainerBox key="filterItemList" variant="light-grey-stack" className={classNames} role="tree">
        {this.state.items.map((itm) => (
          <ContainerBox key={itm.id} data-key={`AccordionList-${itm.id}`} variant="light-grey-stack">
            <Accordion
              id={itm.id}
              isOpen={itm.isOpen}
              heading={<SelectDeleteLabel label={itm.label} onDelete={() => this.deleteItemHandler(itm)} />}
              onToggle={() => this.toggleItem(itm)}
              className={itemStyles['component']}
            >
              {itm.component}
            </Accordion>
          </ContainerBox>
        ))}
      </ContainerBox>
    );
  }

  toggleItem(item: AccordionSelectItem) {
    this.setState(
      (state) => ({
        items: state.items.slice().map((v) => {
          if (v.id === item.id) {
            return { ...v, isOpen: !v.isOpen } as AccordionSelectItem;
          }
          return v;
        }),
      }),
      () => {
        this.props.onToggle && this.props.onToggle(item);
      }
    );
  }

  deleteItemHandler(itm: AccordionSelectItem) {
    this.props.onDelete && this.props.onDelete(itm);
  }
}

AccordionList.contextType = StylingContext;
