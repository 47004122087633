import React from 'react';
import styles from './link.module.scss';
import { Label } from '../label/label';
import { IconProps } from '../../components/icon/icon';
import { S_M_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'Link';

type Variant = 'default' | 'subtle' | 'alternate' | 'list';

export interface LinkInternalProps {
  /**
   * (Optional): Link UI sizing
   * Defaults to medium
   */
  size?: S_M_L_Size;

  /**
   * Value to be displayed
   */
  text: string;

  /**
   * (Optional): Is the link an inline or standalone link.
   * Defaults to false
   */
  isInline?: boolean;

  /**
   * (Optional): Variant of link
   * Defaults to "default"
   */
  variant?: Variant;

  /**
   * (Optional): To determine which fontFamily that should be used.
   * If true, then use Gulliver (serif) font and false to use Nexus(sans-serif)
   * Defaults to false
   */
  serif?: boolean;
  /**
   * (Optional): To display an icon to the left of the text
   * Defaults to no icon
   */
  iconLeftName?: IconProps['name'];
  /**
   * (Optional): To display an icon to the right of the text
   * Defaults to no icon
   */
  iconRightName?: IconProps['name'];
}

type RefType = React.LegacyRef<HTMLAnchorElement>;

type LinkNativeProps = Omit<
  React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  'style' | 'children' | 'type' | 'ref'
>;

export interface LinkProps extends LinkInternalProps, LinkNativeProps {}

export const Link = React.forwardRef(
  (
    { size = 'medium', variant = 'default', isInline = false, text, serif, iconLeftName, iconRightName, ...nativeProps }: LinkProps,
    ref: RefType
  ) => {
    const hasIcon = !!iconRightName || !!iconLeftName;

    const className = [styles['component'], styles[isInline ? 'inline' : 'standalone'], styles[size], styles[variant]];
    hasIcon && className.push(styles['has-icon']);
    nativeProps.className && className.push(nativeProps.className);

    return (
      <a data-component={componentName} {...nativeProps} className={className.join(' ')} ref={ref}>
        <Label
          text={text}
          size={size}
          serif={serif}
          iconLeft={
            iconLeftName &&
            isInline === false && {
              name: iconLeftName,
            }
          }
          iconRight={
            iconRightName &&
            isInline === false && {
              name: iconRightName,
            }
          }
        />
      </a>
    );
  }
);

Link.displayName = componentName;
