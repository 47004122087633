import * as React from 'react';
import { TreeSelection, TreeSelectionProps } from '../tree-selection/tree-selection';
import { SelectItem } from '../../models/select-item';
import { TreeSelectionSelectedBar } from './tree-selection-selected-bar';
import { TreeSelectionSelectedEmptyContent } from './tree-selection-selected-empty-content';
import { Label } from '../label/label';
import { ChildNodeFunctions } from '../../models/child-node';
import { getDataAttributes } from '../../util/attribute-util';

export interface TreeSelectionSelectedProps {
  items: SelectItem[];
  className?: string;
  headerLabel?: string;
  removeSelectedLabel?: string;
  removeAllLabel?: string;
  onSelectItemsRemoved?: (items: SelectItem[]) => any;
  emptyListLabel?: string;
  onDropCallback?: (values: string[]) => any;
  onItemsSelected?: (items: SelectItem[]) => any;

  /** enables you to populate a dropdown through which the user can select how the items should be interpreted (use value added, exclude values added)
   *
   */
  selectionTypes?: SelectItem[];
  selectedSelectionType?: SelectItem;
  onSelectionTypeChanged?: (type: SelectItem) => any;
  highlightDropArea?: boolean;

  itemTemplates?: TreeSelectionProps['itemTemplates'];
}

const componentName = 'TreeSelectionSelected';

export class TreeSelectionSelected extends React.Component<TreeSelectionSelectedProps, { selectedItems: SelectItem[] }> {
  state = {
    selectedItems: [],
  };

  flatten(items: SelectItem[]) {
    return items.reduce((p, c) => {
      return p.concat(ChildNodeFunctions.asFlat(c));
    }, []);
  }

  onRemoveAll = () => {
    this.setState({ selectedItems: [] }, () => {
      this.props.onSelectItemsRemoved && this.props.onSelectItemsRemoved(this.flatten(this.props.items));
    });
  };

  onRemoveSelected = () => {
    this.setState({ selectedItems: [] }, () => {
      this.props.onSelectItemsRemoved && this.props.onSelectItemsRemoved(this.state.selectedItems);
    });
  };

  handleSelectionChanged = (selectedItems: SelectItem[]) => {
    this.setState({ selectedItems }, () => {
      this.props.onItemsSelected && this.props.onItemsSelected(this.state.selectedItems);
    });
  };

  render() {
    return (
      <TreeSelection
        className={this.props.className}
        items={this.props.items}
        headerContent={<Label type="small" text={this.props.headerLabel} />}
        selectedItems={this.state.selectedItems}
        onSelectionChanged={this.handleSelectionChanged}
        noItemsContent={
          <TreeSelectionSelectedEmptyContent isHighlighted={this.props.highlightDropArea}>
            {this.props.emptyListLabel}
          </TreeSelectionSelectedEmptyContent>
        }
        onDropCallback={this.props.onDropCallback}
        dropEnabled={true}
        itemTemplates={this.props.itemTemplates}
        barContent={
          <TreeSelectionSelectedBar
            removeSelectedLabel={this.props.removeSelectedLabel}
            removeAllLabel={this.props.removeAllLabel}
            onRemoveAll={this.onRemoveAll}
            onRemoveSelected={this.onRemoveSelected}
            selectionTypes={this.props.selectionTypes}
            selectedSelectionType={this.props.selectedSelectionType}
            onSelectionTypeChanged={this.props.onSelectionTypeChanged}
            removeAllEnabled={this.props.items.length > 0}
            removeSelectedEnabled={this.state.selectedItems.length > 0}
          />
        }
        data-component={componentName}
        {...getDataAttributes(this.props)}
      />
    );
  }
}

TreeSelectionSelected['displayName'] = componentName;
