import * as React from 'react';
import { ContextType } from 'react';
import styles from './checkbox.module.scss';
import { Check, CheckProps } from '../check/check';
import { AriaCheckboxInterface } from '../AccessibilityProps';
import { getDataAttributes } from '../../util/attribute-util';
import StylingContext from '../../style-context/styling-context-provider';
import { LangUtil } from '../../client-shared';

export interface CheckboxProps extends CheckProps {
  /**
   * Optional DOM id to assign to the checkbox input, e.g. to associate a field element with it
   */
  id?: string;

  /**
   * The label to show next to the check box
   */
  label: string;

  aria?: AriaCheckboxInterface;
}

const componentName = 'Checkbox';
export class Checkbox extends React.PureComponent<CheckboxProps> {
  static defaultProps: CheckboxProps = {
    size: 'small',
    label: null,
  };
  context: ContextType<typeof StylingContext>;

  private checkId = LangUtil.randomUuid();

  private check: Check;

  render() {
    const { styling } = this.context;
    const classNames = [styles['component'], styles[this.props.size], styles[styling]];

    if (this.props.isDisabled) {
      classNames.push(styles['disabled']);
    }

    const className = classNames.join(' ');

    const domId = this.props.id || this.checkId;

    return (
      <div data-component={componentName} className={className} {...getDataAttributes(this.props)}>
        <Check
          id={domId}
          ref={(c) => (this.check = c)}
          size={this.props.size}
          aria={this.props.aria}
          isDisabled={this.props.isDisabled}
          isSelected={this.props.isSelected}
          onSelectedChanged={this.props.onSelectedChanged}
          {...getDataAttributes(this.props, '-check')}
        />
        <label htmlFor={domId} className={styles['label']} {...getDataAttributes(this.props, '-label')}>
          {this.props.label}
        </label>
      </div>
    );
  }

  focus() {
    this.check && this.check.focus();
  }
}

Checkbox.contextType = StylingContext;
Checkbox['displayName'] = componentName;
