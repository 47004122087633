import * as React from 'react';
import styles from './accordion.module.scss';

import { Button } from '../../components-base/button/button';
import { Inline } from '../../components/inline/inline';
import { useId } from '../../hooks/use-id';
import { getDataAttributes, getAllButDataAttributes } from '../../util/attribute-util';
import { S_M_L_Size, XS_S_M_L_Size } from '../../components/t-shirt-sizes';
import { Label } from '../../components-base/label/label';

export type Orientation = 'left' | 'right';
export interface InternalAccordionProps {
  /**
   * (Optional): Id to use with the accordion element. If not provided a random one is allocated.
   */
  id?: string;

  /**
   * Accordion text to be displayed
   */
  text: string;

  /**
   * (Optional): When true, the accordion will be open.
   */
  isOpen?: boolean;

  /**
   * (Optional): The side of the accordion to show the opening/closing button. Defaults to left.
   */
  orientation?: Orientation;

  /**
   * (Optional): When true, the accordion will be disabled. No opening or closing is possible.
   */
  disabled?: boolean;

  /**
   * (Optional): A custom react element to display to the right side of the accordion's header.
   */
  rightRender?: React.ReactElement;

  /**
   * (Optional): A callback the is invoked when the use clicks on the open/close button.
   */
  onToggle?: (isOpen) => void;

  /**
   * (Optional): The size of the accordion, default to medium.
   */
  size?: S_M_L_Size;

  children: React.ReactElement;
}

type ButtonNativeProps = Omit<
  React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  'style' | 'children' | 'ref'
>;

export interface AccordionProps extends InternalAccordionProps, ButtonNativeProps {}

type RefType = React.Ref<HTMLButtonElement>;

const componentName = 'Accordion';
export const Accordion = React.forwardRef((props: AccordionProps, ref: RefType): JSX.Element => {
  const { id, text, size = 'medium', rightRender, isOpen, onToggle, children, orientation = 'left', disabled, ...nativeProps } = props;
  const elementId = useId(id);
  const prevIsOpenState = React.useRef<'unset' | 'open' | 'closed'>('unset');

  const openCloseState = React.useMemo(() => {
    const result = `${prevIsOpenState.current}-${isOpen ? 'open' : 'closed'}`;
    prevIsOpenState.current = isOpen ? 'open' : 'closed';
    return result;
  }, [isOpen]);

  return (
    <div
      className={`${styles.component} ${styles[size]} ${styles[openCloseState]}`}
      data-component={componentName}
      {...getDataAttributes(nativeProps)}
    >
      <Inline spacing="8dp" verticalAlignment={'bottom'}>
        <header
          id={`header${elementId}`}
          className={`${styles.header} ${styles[orientation]} ${styles[size]}`}
          aria-expanded={isOpen}
          aria-controls={isOpen ? `panel${elementId}` : undefined}
        >
          <Button
            size={mapSizeToIconSize(size)}
            variant="tertiary"
            onClick={() => onToggle?.(!isOpen)}
            disabled={disabled}
            iconOnly
            iconOnlyName={'navigate-up'}
            ref={ref}
            {...getAllButDataAttributes(nativeProps)}
          />
          <button disabled={disabled} className={`${styles.text} ${styles[size]}`} tabIndex={-1} onClick={() => onToggle?.(!isOpen)}>
            <Label text={text} size={size} />
          </button>
        </header>
        {rightRender}
      </Inline>
      {isOpen ? (
        <section id={`panel${elementId}`} className={styles.body} aria-labelledby={`header${elementId}`}>
          {isOpen && children}
        </section>
      ) : null}
    </div>
  );
});

Accordion.displayName = componentName;

function mapSizeToIconSize(size: S_M_L_Size): XS_S_M_L_Size {
  if (size === 'small') {
    return 'xsmall';
  }
  if (size === 'medium') {
    return 'small';
  }
  return 'medium';
}
