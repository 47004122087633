import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Select, { components } from 'react-select';
import styles from './multi-select.module.scss';
import { Icon } from '../icon/icon';
import { SelectItem } from '../../models/select-item';
import { getPaletteClassFromElement } from '../../util/theme-util';
import { LangUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';
import { windowSafe } from '../../util/ssr';

const componentName = 'MultiSelect';

export interface MultiSelectProps {
  id?: string;
  options: SelectItem[]; // Items which may be selected
  selectedOptions?: SelectItem[]; // Currently selected items
  onSelectionChanged?: (ids: string[]) => any;
  placeholder: string;
  noOptionsMessage: () => string;
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  minMenuHeight?: number;
  isDisabled?: boolean;
  isLoading?: boolean;
  /**
   * Use this to be notified whenever the search string changes. It provides the new search string.
   */
  onInputChanged?: (searchString: string) => any;
  /**
   * debounce delay in ms before onItemChanged is fired after a selection.
   */
  selectionDelay?: number;
  autoFocus?: boolean;
  /**
   * May be used to override the standard Select components filter options
   * e.g. if you don't want the Select component to do any filtering then
   * set it to show all the values which is parsed to it by setting it to
   * (obj, str) => {return true;}
   */
  selectFilterOption?: (obj: Object, str: string) => boolean;

  /**
   * Label for screen readers when the clear button is in focus
   */
  clearIndicatorLabel: string;

  /**
   * Label for screen readers when the open button  is in focus
   */
  dropdownOpenIndicatorLabel: string;

  /**
   * Label for screen readers when the close button is in focus
   */
  dropdownCloseIndicatorLabel: string;
}

export class MultiSelect extends React.Component<MultiSelectProps, any> {
  static defaultProps = {
    closeMenuOnSelect: true,
    maxMenuHeight: 300,
    minMenuHeight: 140,
    isDisabled: false,
    isLoading: false,
    selectionDelay: 500,
    autoFocus: false,
  };
  private select: Select;

  private portalId = 'multiselect-' + LangUtil.randomUuid();
  private debounceId: number;

  get options() {
    return this.props.options.map((opt) =>
      opt.children?.length > 0
        ? { label: opt.label, options: opt.children, isDisabled: !opt.isSelectable }
        : { ...opt, isDisabled: !opt.isSelectable }
    );
  }

  ClearIndicator = (props) => {
    const {
      children = <Icon name={'delete'} size={16} />,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        key={'clearIndicator'}
        className={styles['clear-indicator']}
        {...restInnerProps}
        ref={ref}
        aria-label={this.props.clearIndicatorLabel}
      >
        {children}
      </div>
    );
  };

  DropdownIndicator = (props: { selectProps: { menuIsOpen: true } } & any) => {
    const {
      children = <Icon name={props.selectProps.menuIsOpen ? 'navigate-up' : 'navigate-down'} size={16} />,
      innerProps: { ref, ...restInnerProps },
    } = props;
    return (
      <div
        key={'indicator'}
        className={styles['clear-indicator']}
        {...restInnerProps}
        ref={ref}
        aria-label={props.selectProps.menuIsOpen ? this.props.dropdownCloseIndicatorLabel : this.props.dropdownOpenIndicatorLabel}
      >
        {children}
      </div>
    );
  };

  GroupHeading = (props) => {
    return (
      <div>
        <components.GroupHeading {...props} />
      </div>
    );
  };

  Option = ({ children, ...props }) => (
    <div data-component={'MultiSelectOption'} data-key={`multi-select-option-${props.data.id}`}>
      <components.Option {...props}>{children}</components.Option>
    </div>
  );

  render() {
    const props: Partial<{ value: SelectItem[]; filterOption: (obj: Object, str: string) => boolean; id: string }> = {};
    const selectedValues = {};

    if (this.props.selectedOptions) {
      props.value = this.props.selectedOptions.slice(0);
    }

    if (this.props.selectFilterOption) {
      props.filterOption = this.props.selectFilterOption;
    }

    if (this.props.id) {
      props.id = this.props.id;
    }

    const className = styles['component'];

    return (
      <div data-component={componentName} {...getDataAttributes(this.props)}>
        <Select
          ref={(c) => {
            this.select = c;
            if (c) {
              const element = ReactDOM.findDOMNode(c);
              if (element instanceof HTMLElement) {
                this.getPortalElement().setAttribute('class', getPaletteClassFromElement(element));
              }
            }
          }}
          options={this.options}
          {...selectedValues}
          aria-label={'Searchable list'}
          className={className}
          classNamePrefix={'react-select'}
          isMulti={true}
          components={{
            Option: this.Option,
            GroupHeading: this.GroupHeading,
            ClearIndicator: this.ClearIndicator,
            DropdownIndicator: this.DropdownIndicator,
          }}
          menuPortalTarget={this.getPortalElement()}
          menuPlacement={'auto'}
          placeholder={this.props.placeholder}
          noOptionsMessage={this.props.noOptionsMessage}
          closeMenuOnSelect={this.props.closeMenuOnSelect}
          maxMenuHeight={this.props.maxMenuHeight}
          minMenuHeight={this.props.minMenuHeight}
          isDisabled={this.props.isDisabled}
          isLoading={this.props.isLoading}
          onChange={this.changeHandler}
          onInputChange={this.inputChangeHandler}
          autoFocus={this.props.autoFocus}
          {...props}
        />
      </div>
    );
  }

  changeHandler = (value, action) => {
    this.props.onSelectionChanged && this.props.onSelectionChanged(value.map((itm) => itm.value));
  };

  inputChangeHandler = (value, action) => {
    if (this.props.onInputChanged && action.action === 'input-change') {
      if (this.props.selectionDelay > 0) {
        windowSafe().clearTimeout(this.debounceId);
        this.debounceId = windowSafe().setTimeout(() => {
          this.props.onInputChanged(value);
        }, this.props.selectionDelay);
      } else {
        this.props.onInputChanged(value);
      }
    }
  };

  public focus() {
    this.select.focus();
  }

  componentWillUnmount(): void {
    //do not perform a callback if we get unmounted
    windowSafe().clearTimeout(this.debounceId);
    const portalElement = this.getPortalElement(false);
    if (portalElement) {
      portalElement.parentNode.removeChild(portalElement);
    }
  }

  private getPortalElement(createIfNeeded = true): HTMLElement {
    let portalElement = document.getElementById(this.portalId);
    if (!portalElement && createIfNeeded) {
      portalElement = document.createElement('div');
      portalElement.setAttribute('id', this.portalId);
      document.body.appendChild(portalElement);
    }
    return portalElement;
  }
}

MultiSelect['displayName'] = componentName;
