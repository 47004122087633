import * as React from 'react';
import { Popup, PopupProps } from '../popup/popup';
import { S_M_L_Size } from '../../components/t-shirt-sizes';
import { ListProps } from '../list/list';
import styles from './popup-list.module.scss';

const componentName = 'PopupList';

export interface PopupListProps extends Omit<PopupProps, 'gapOffset'> {
  children: React.ReactElement<ListProps> | React.ReactElement<ListProps>[];
}

const getTriggerOffset = (size: S_M_L_Size) => {
  switch (size) {
    case 'small':
    case 'medium':
    default:
      return 8;

    case 'large':
      return 16;
  }
};

export const PopupList = (props: PopupListProps): JSX.Element => {
  const { children, className, size, ...rest } = props;

  const classNames = [styles.component];
  className && classNames.push(className);
  size && classNames.push(styles[size]);

  return (
    <Popup gapOffset={getTriggerOffset(size)} className={classNames.join(' ')} size={size} {...rest}>
      {children}
    </Popup>
  );
};

PopupList.defaultProps = {
  size: 'medium',
};

PopupList.displayName = componentName;
