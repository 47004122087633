import { ContentListFilterItem, ContentListFilterProps } from './content-list-filter';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { SelectItem, SelectItemBuilder } from '../../models/select-item';
import { LangUtil } from '../../client-shared';

export interface ContentListFilterState {
  items: ContentListFilterItem[];
  setItems: React.Dispatch<React.SetStateAction<ContentListFilterItem[]>>;
  selectedItem: ContentListFilterItem;
  setSelectedItem: React.Dispatch<React.SetStateAction<ContentListFilterItem>>;
  popUpItems: SelectItem[];
  setPopUpItems: React.Dispatch<React.SetStateAction<SelectItem[]>>;
  contentListFilterMap: Map<string | number | boolean, ContentListFilterItem>;
  selectIndex: number;
  setSelectIndex: React.Dispatch<React.SetStateAction<number>>;
  getIdFor: (index: number) => string;
  getFocusedId: () => string;
}

export const useContentListFilter = (params: ContentListFilterProps): ContentListFilterState => {
  const selectedItemInitialState = (): ContentListFilterItem => {
    if (params.presetFilters) {
      return items.find((i) => !i.disabled && i.selected === true);
    }
    return { selected: false, count: 0, SelectItem: new SelectItemBuilder('placeHolder', 0).build(), children: [] };
  };

  const [items, setItems] = useState<ContentListFilterItem[]>(() => (params.presetFilters ? params.presetFilters : []));
  const [selectedItem, setSelectedItem] = useState<ContentListFilterItem>(() => selectedItemInitialState());
  const [popUpItems, setPopUpItems] = useState<SelectItem[]>(
    params.contentListFilterItems.map((item) => !item.disabled && item.SelectItem)
  );
  const [selectIndex, setSelectIndex] = useState<number>(0);
  const [ids, setIds] = useState<string[]>([]);

  /* This allocates Ids for the filterChips and their buttons. Each gets a uniqueID */
  useEffect(() => {
    const numberOfIdsNeeded: number = items.length * 2;
    const numberOfIdsAllocated: number = ids.length;
    const numberOfIdsToAllocate = Math.max(numberOfIdsNeeded - numberOfIdsAllocated, 0);
    const newIds = [...new Array(numberOfIdsToAllocate)].map(() => LangUtil.randomUuid());
    setIds([...ids].concat(newIds));
  }, [items.length]);

  const contentListFilterMap = useMemo(() => {
    const filterMap = new Map<string | number | boolean, ContentListFilterItem>();
    const concatArray = params.presetFilters ? params.contentListFilterItems.concat(params.presetFilters) : params.contentListFilterItems;
    for (const i of concatArray) {
      filterMap.set(i.SelectItem.value, i);
    }
    return filterMap;
  }, [params.presetFilters, params.contentListFilterItems]);

  return {
    items,
    setItems,
    selectedItem,
    setSelectedItem,
    popUpItems,
    setPopUpItems,
    contentListFilterMap,
    selectIndex,
    setSelectIndex,
    getIdFor: (index: number) => {
      return ids[index]?.toString() ?? '';
    },
    getFocusedId: () => {
      if (selectIndex < 0) {
        return '';
      }
      return ids[selectIndex].toString();
    },
  };
};
