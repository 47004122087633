/**
 * Based on Braid Design System.
 * See https://github.com/seek-oss/braid-design-system/blob/master/LICENSE for legal terms
 */
import { normalizeResponsiveValue, OptionalResponsiveValue } from '../vanilla-extract/atoms/sprinkles.css';
import clsx from 'clsx';
import { optimizeResponsiveArray } from './optimize-responsive-array';

export const resolveResponsiveProp = <Keys extends string | number>(
  value: OptionalResponsiveValue<Keys>,
  mobileAtoms: Record<Keys, string>,
  tabletAtoms: Record<Keys, string>,
  desktopAtoms: Record<Keys, string>
) => {
  if (typeof value === 'string' || typeof value === 'number') {
    return mobileAtoms[value!];
  }

  const normalized = normalizeResponsiveValue(value);
  const [mobile, tablet, desktop] = optimizeResponsiveArray([
    normalized.mobile ?? null,
    normalized.tablet ?? null,
    normalized.desktop ?? null,
  ]);

  const mobileAtom = mobileAtoms[mobile!];
  const tabletAtom = tabletAtoms[tablet!];
  const desktopAtom = desktopAtoms[desktop!];

  return clsx(mobileAtom, tabletAtom, desktopAtom);
};
