import * as React from 'react';
import { ReactNode } from 'react';
import styles from './page-header.module.scss';
import { Header } from '../header/header';
import { getPaletteClassName } from '../../util/theme-util';
import { Label } from '../label/label';
import { IconButton } from '../icon-button/icon-button';
import { getDataAttributes } from '../../util/attribute-util';

export interface PageHeaderProps {
  onMenuClicked?: () => any;
  productName: string;
  universityName: string;
  style?: React.CSSProperties;
  showSuperscript?: boolean;
  superscriptLabel?: string;
  ariaLabel: string;
  children?: ReactNode;
}

const componentName = 'PageHeader';

export class PageHeader extends React.Component<PageHeaderProps, {}> {
  static defaultProps: Partial<PageHeaderProps> = {
    ariaLabel: '',
  };

  render() {
    return (
      <div
        className={styles['component'] + ' ' + getPaletteClassName({ name: 'palette-dark' })}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <div className={styles['left']}>
          <IconButton
            aria={{ 'aria-label': this.props.ariaLabel }}
            type={'button'}
            icon={'hamburger-menu'}
            onClick={this.props.onMenuClicked}
          />
          <div className={styles['labels']}>
            <Header headerType="feature" headerLevel={1} maxLines={1}>
              {this.props.productName}
            </Header>
            {this.props.showSuperscript ? (
              <Label text={this.props.superscriptLabel} type="field" className={styles['superscript-label']} />
            ) : null}
          </div>
        </div>
        <div className={styles['page-title']}>{this.props.children}</div>
        <div className={styles['right']}>
          <Label text={this.props.universityName} type="medium" maxLines={1} />
        </div>
      </div>
    );
  }
}

PageHeader['displayName'] = componentName;
