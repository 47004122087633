import * as React from 'react';
import { IconName } from '../../components/icon/icon-SVGs';
import { LangUtil } from '../../client-shared';
import { IdEncoderUtil } from '../../util/id-encoder-util';
import { Label } from '../label/label';
import styles from './radio-button.module.scss';
import { getDataAttributes } from '../../util/attribute-util';
import { S_M_L_Size } from '../../components/t-shirt-sizes';

const componentName = 'RadioButton';

export type RadioButtonSize = S_M_L_Size;

export interface RadioButtonInternalProps {
  /**
   * (Optional): Radio button UI sizing
   */
  size?: RadioButtonSize;
  /**
   * Radio button text to be displayed
   */
  text: string;
  /**
   * (Optional): To display an icon in front of the text
   */
  iconName?: IconName;
  /**
   * (Optional): To be triggered when the checkbox toggles between selected / deselected states;
   */
  onSelectedChanged?: (value: boolean) => void;
  /**
   * (Optional): Have a pre-selected state when mounting the component;
   */
  isSelected?: boolean;
  /**
   * (Optional): An error state for validation that makes the radio button turn red
   */
  hasError?: boolean;
}

type RadioButtonNativeProps = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'style' | 'children' | 'type' | 'size' | 'ref'
>;

export interface RadioButtonProps extends RadioButtonInternalProps, RadioButtonNativeProps {}
type RefType = React.LegacyRef<HTMLInputElement>;

export const RadioButton = React.forwardRef((props: RadioButtonProps, ref: RefType): JSX.Element => {
  const { size, text, iconName, isSelected, onSelectedChanged, hasError, id, ...nativeProps } = props;

  const uuid = React.useMemo(() => LangUtil.randomUuid(), []);
  const radioId = IdEncoderUtil.encodeId('radio_', id || uuid);
  const labelId = IdEncoderUtil.encodeId('label_', id || uuid);

  const classNames = [styles['component'], styles[size]];
  hasError && classNames.push(styles['error-state']);

  const labelClassNames = [styles['label-style']];
  nativeProps.disabled && labelClassNames.push(styles['disabled-label']);
  hasError && labelClassNames.push(styles['error-label']);

  const onChange = (event: React.ChangeEvent<HTMLInputElement> | React.MouseEvent) => {
    onSelectedChanged && onSelectedChanged((event.target as HTMLInputElement).checked);
  };

  return (
    <div data-component={componentName} {...getDataAttributes(props)} className={classNames.join(' ')}>
      <input
        id={radioId}
        type={'radio'}
        disabled={nativeProps.disabled}
        checked={isSelected}
        onChange={onChange}
        {...nativeProps}
        ref={ref}
      />
      <div className={styles['radio']} aria-hidden>
        <div className={styles['ball']} />
      </div>

      <Label
        htmlFor={radioId}
        id={labelId}
        text={text}
        size={size}
        wrap={false}
        className={labelClassNames.join(' ')}
        iconLeft={iconName ? { name: iconName } : undefined}
      />
    </div>
  );
});

RadioButton.defaultProps = {
  size: 'medium',
  disabled: false,
  hasError: false,
};

RadioButton.displayName = componentName;
