import * as React from 'react';
import * as PropTypes from 'prop-types';

/**
 * The property name in the React Context object where the StoreContext is available
 */
export const StoreContextPropertyName = 'storeContext';

/**
 * Creates a React context that provides access to the store context.
 * Example: class ReactComponent { static contextTypes = createReactStoreContext(); }
 */
export function createReactStoreContext(): React.ValidationMap<any> {
  return {
    [StoreContextPropertyName]: PropTypes.object.isRequired,
  };
}

/**
 * Contract for getting a singleton store based on a store identity.
 * A store identity is based on the class of the store, optionally combined with a string id,
 * e.g. class FooStore + id "foo1".
 */
export interface StoreContext {
  /**
   * Gets a store instance based on the specified class. Stores are singleton.
   * @param storeClass class of the store to get or create
   * @param id optional id to access the store with, e.g. in case multiple stores use the same class
   */
  get<T>(storeClass: StoreClass<T>, id?: string): T;

  /**
   * Creates a new store of the specified storeClass and optional id, storing it in this context
   * @param storeClass the class of the store
   * @param id optional id in case the store is not singleton in this scope
   */
  create<T>(storeClass: StoreClass<T>, id?: string): T;

  /**
   * Gets whether this store context contains the specific store
   * @param storeClass the store class to check
   * @param id optinal id for the store to check
   */
  contains<T>(storeClass: StoreClass<T>, id?: string): boolean;
}

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const onInitializeStore: string = 'onInitializeStore';
export interface InitializingStore {
  onInitializeStore(storeContext: StoreContext);
}

// eslint-disable-next-line @typescript-eslint/no-inferrable-types
export const onPropertyNameAssigned: string = 'onPropertyNameAssigned';
export interface PropertyNameAware {
  onPropertyNameAssigned(propertyName: string, owner);
}

/**
 * Contract for a store. Simply requires a parameter-less constructor.
 */
export interface StoreClass<T> {
  new (): T;
}
