import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';
import styles from './date-picker-panel.module.scss';
import { StartDay } from './date-picker';
import { DatePickerHeader } from './date-picker-header';
import { DatePickerDates } from './date-picker-dates';
import { DatePickerWeekDays } from './date-picker-week-days';
import { Navigation } from './date-picker-logic';
import { getDataAttributes } from '../../util/attribute-util';
import { KeyCodes } from '../../client-shared';

export interface DatePickerPanelProps {
  id: string;
  startDay: StartDay;
  startDate: moment.Moment;
  selectedDate?: moment.Moment;
  focusedCell?: moment.Moment;
  onDayClick: (date: moment.Moment, autoClose: boolean) => void;
  onNavigate: (action: Navigation) => void;
  onClose: () => void;
  getFocusable: (focusable: { focus: () => void }) => void;
}

const componentName = 'DatePickerPanel';

export function DatePickerPanel(props: React.PropsWithChildren<DatePickerPanelProps>) {
  const [hasFocus, setFocus] = useState(false);

  return (
    <div
      id={props.id}
      className={styles['component']}
      onKeyDown={(event) => handleNavigationAndClose(event, props)}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      <DatePickerHeader startDate={props.startDate} onNavigate={(navigation) => props.onNavigate(navigation)} />
      <div
        tabIndex={0}
        className={styles['grid']}
        onFocus={() => setFocus(true)}
        onKeyDown={(event) => handleSelection(event, props)}
        onBlur={() => setFocus(false)}
        ref={(e) => props.getFocusable({ focus: () => e && e.focus() })}
      >
        <DatePickerWeekDays startDay={props.startDay} />
        <DatePickerDates
          startDay={props.startDay}
          startDate={props.startDate}
          selectedDate={props.selectedDate}
          focusedCell={props.focusedCell}
          hasFocus={hasFocus}
          dayClick={(date, autoClose) => props.onDayClick(date, autoClose)}
        />
      </div>
    </div>
  );
}

function handleNavigationAndClose(event: React.KeyboardEvent<HTMLDivElement>, props: DatePickerPanelProps) {
  let navigation: Navigation | '' = '';

  switch (event.keyCode) {
    case KeyCodes.DOM_VK_DOWN:
      navigation = 'nextWeek';
      break;
    case KeyCodes.DOM_VK_UP:
      navigation = 'prevWeek';
      break;
    case KeyCodes.DOM_VK_LEFT:
      navigation = 'prevDay';
      break;
    case KeyCodes.DOM_VK_RIGHT:
      navigation = 'nextDay';
      break;
    case KeyCodes.DOM_VK_PAGE_UP:
      navigation = event.shiftKey ? 'prevYear' : 'prevMonth';
      break;
    case KeyCodes.DOM_VK_PAGE_DOWN:
      navigation = event.shiftKey ? 'nextYear' : 'nextMonth';
      break;
    case KeyCodes.DOM_VK_HOME:
      navigation = 'firstDay';
      break;
    case KeyCodes.DOM_VK_END:
      navigation = 'lastDay';
      break;
    case KeyCodes.DOM_VK_ESCAPE:
      props.onClose();
      return;
  }
  if (navigation !== '') {
    event.preventDefault();
    event.stopPropagation();
    props.onNavigate(navigation);
  }
}

function handleSelection(event: React.KeyboardEvent<HTMLDivElement>, props: DatePickerPanelProps) {
  switch (event.keyCode) {
    case KeyCodes.DOM_VK_RETURN:
    case KeyCodes.DOM_VK_SPACE:
      event.stopPropagation();
      event.preventDefault();
      props.onDayClick(props.focusedCell.clone(), true);
      break;
  }
}

DatePickerPanel['displayName'] = componentName;
