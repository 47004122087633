import { StartDay } from './date-picker';
import * as React from 'react';
import moment from 'moment';
import styles from './date-picker-week-days.module.scss';

export interface DatePickerWeekProps {
  startDay: StartDay;
}

const componentName = 'DatePickerWeekDays';

export function DatePickerWeekDays(props: React.PropsWithChildren<DatePickerWeekProps>) {
  return (
    <>
      {[0, 1, 2, 3, 4, 5, 6]
        .map((i) =>
          moment()
            .day(i + (props.startDay === 'Monday' ? 1 : 0))
            .format('ddd')
        )
        .map((day) => (
          <div className={styles['weekday']} key={`${day}`} data-key={`${componentName}-${day}`}>
            <span>{day}</span>
          </div>
        ))}
    </>
  );
}

DatePickerWeekDays['displayName'] = componentName;
