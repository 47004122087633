export type windowType = typeof window;

export const isCSR = typeof window === 'object';
export const isSSR = !isCSR;

export function windowSafe(): Window {
  return isCSR ? window : (SSRWIndow as any as Window);
}

/* eslint-disable */

const SSRWIndow = {
  clientInformation: undefined,
  closed: false,
  customElements: undefined,
  devicePixelRatio: 0,
  document: undefined,
  event: undefined,
  external: undefined,
  frameElement: undefined,
  frames: undefined,
  history: undefined,
  innerHeight: 0,
  innerWidth: 0,
  length: 0,
  location: undefined,
  locationbar: undefined,
  menubar: undefined,
  name: '',
  navigator: undefined,
  ondevicemotion: function (this: Window, ev: DeviceMotionEvent) {},
  ondeviceorientation: function (this: Window, ev: DeviceOrientationEvent) {},
  onorientationchange: function (this: Window, ev: Event) {},
  opener: undefined,
  orientation: 0,
  outerHeight: 0,
  outerWidth: 0,
  pageXOffset: 0,
  pageYOffset: 0,
  parent: undefined,
  personalbar: undefined,
  screen: undefined,
  screenLeft: 0,
  screenTop: 0,
  screenX: 0,
  screenY: 0,
  scrollX: 0,
  scrollY: 0,
  scrollbars: undefined,
  self: undefined,
  speechSynthesis: undefined,
  status: '',
  statusbar: undefined,
  toolbar: undefined,
  top: undefined,
  visualViewport: undefined,
  window: undefined,
  alert: function (message?: any): void {},
  blur: function (): void {},
  cancelIdleCallback: function (handle: number): void {},

  captureEvents: function (): void {},
  close: function (): void {},
  confirm: function (message?: string): boolean {
    return false;
  },
  focus: function (): void {},
  getComputedStyle: function (elt: Element, pseudoElt?: string): any {},
  getSelection: function (): Selection {
    return null;
  },
  matchMedia: function (query: string): MediaQueryList {
    return null;
  },
  moveBy: function (x: number, y: number): void {},
  moveTo: function (x: number, y: number): void {},
  open: function (url?: string | URL, target?: string, features?: string): Window {
    return null;
  },
  postMessage: function (): void {},
  print: function (): void {},
  prompt: function (message?: string, _default?: string): string {
    return null;
  },
  releaseEvents: function (): void {},
  requestIdleCallback: function (callback: IdleRequestCallback, options?: IdleRequestOptions): number {
    return null;
  },
  resizeBy: function (x: number, y: number): void {},
  resizeTo: function (width: number, height: number): void {},
  scroll: function (options?: any): void {},
  scrollBy: function (options?: any): void {},
  scrollTo: function (options?: any): void {},
  stop: function (): void {},
  addEventListener: function <K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => any,
    options?: boolean | AddEventListenerOptions
  ): void {},
  removeEventListener: function <K extends keyof WindowEventMap>(
    type: K,
    listener: (this: Window, ev: WindowEventMap[K]) => any,
    options?: boolean | EventListenerOptions
  ): void {},
  dispatchEvent: function (event: Event): boolean {
    return false;
  },
  cancelAnimationFrame: function (handle: number): void {},
  requestAnimationFrame: function (callback: FrameRequestCallback): number {
    return 0;
  },
  onabort: function (this: GlobalEventHandlers, ev: UIEvent) {},
  onanimationcancel: function (this: GlobalEventHandlers, ev: AnimationEvent) {},
  onanimationend: function (this: GlobalEventHandlers, ev: AnimationEvent) {},
  onanimationiteration: function (this: GlobalEventHandlers, ev: AnimationEvent) {},
  onanimationstart: function (this: GlobalEventHandlers, ev: AnimationEvent) {},
  onauxclick: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onblur: function (this: GlobalEventHandlers, ev: FocusEvent) {},
  oncanplay: function (this: GlobalEventHandlers, ev: Event) {},
  oncanplaythrough: function (this: GlobalEventHandlers, ev: Event) {},
  onchange: function (this: GlobalEventHandlers, ev: Event) {},
  onclick: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onclose: function (this: GlobalEventHandlers, ev: Event) {},
  oncontextmenu: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  oncuechange: function (this: GlobalEventHandlers, ev: Event) {},
  ondblclick: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  ondrag: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondragend: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondragenter: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondragleave: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondragover: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondragstart: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondrop: function (this: GlobalEventHandlers, ev: DragEvent) {},
  ondurationchange: function (this: GlobalEventHandlers, ev: Event) {},
  onemptied: function (this: GlobalEventHandlers, ev: Event) {},
  onended: function (this: GlobalEventHandlers, ev: Event) {},
  onerror: undefined,
  onfocus: function (this: GlobalEventHandlers, ev: FocusEvent) {},
  onformdata: function (this: GlobalEventHandlers, ev: FormDataEvent) {},
  ongotpointercapture: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  oninput: function (this: GlobalEventHandlers, ev: Event) {},
  oninvalid: function (this: GlobalEventHandlers, ev: Event) {},
  onkeydown: function (this: GlobalEventHandlers, ev: KeyboardEvent) {},
  onkeypress: function (this: GlobalEventHandlers, ev: KeyboardEvent) {},
  onkeyup: function (this: GlobalEventHandlers, ev: KeyboardEvent) {},
  onload: function (this: GlobalEventHandlers, ev: Event) {},
  onloadeddata: function (this: GlobalEventHandlers, ev: Event) {},
  onloadedmetadata: function (this: GlobalEventHandlers, ev: Event) {},
  onloadstart: function (this: GlobalEventHandlers, ev: Event) {},
  onlostpointercapture: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onmousedown: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmouseenter: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmouseleave: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmousemove: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmouseout: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmouseover: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onmouseup: function (this: GlobalEventHandlers, ev: MouseEvent) {},
  onpause: function (this: GlobalEventHandlers, ev: Event) {},
  onplay: function (this: GlobalEventHandlers, ev: Event) {},
  onplaying: function (this: GlobalEventHandlers, ev: Event) {},
  onpointercancel: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerdown: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerenter: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerleave: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointermove: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerout: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerover: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onpointerup: function (this: GlobalEventHandlers, ev: PointerEvent) {},
  onprogress: function (this: GlobalEventHandlers, ev: ProgressEvent<EventTarget>) {},
  onratechange: function (this: GlobalEventHandlers, ev: Event) {},
  onreset: function (this: GlobalEventHandlers, ev: Event) {},
  onresize: function (this: GlobalEventHandlers, ev: UIEvent) {},
  onscroll: function (this: GlobalEventHandlers, ev: Event) {},
  onsecuritypolicyviolation: function (this: GlobalEventHandlers, ev: SecurityPolicyViolationEvent) {},
  onseeked: function (this: GlobalEventHandlers, ev: Event) {},
  onseeking: function (this: GlobalEventHandlers, ev: Event) {},
  onselect: function (this: GlobalEventHandlers, ev: Event) {},
  onselectionchange: function (this: GlobalEventHandlers, ev: Event) {},
  onselectstart: function (this: GlobalEventHandlers, ev: Event) {},
  onslotchange: function (this: GlobalEventHandlers, ev: Event) {},
  onstalled: function (this: GlobalEventHandlers, ev: Event) {},
  onsubmit: function (this: GlobalEventHandlers, ev: SubmitEvent) {},
  onsuspend: function (this: GlobalEventHandlers, ev: Event) {},
  ontimeupdate: function (this: GlobalEventHandlers, ev: Event) {},
  ontoggle: function (this: GlobalEventHandlers, ev: Event) {},
  ontransitioncancel: function (this: GlobalEventHandlers, ev: TransitionEvent) {},
  ontransitionend: function (this: GlobalEventHandlers, ev: TransitionEvent) {},
  ontransitionrun: function (this: GlobalEventHandlers, ev: TransitionEvent) {},
  ontransitionstart: function (this: GlobalEventHandlers, ev: TransitionEvent) {},
  onvolumechange: function (this: GlobalEventHandlers, ev: Event) {},
  onwaiting: function (this: GlobalEventHandlers, ev: Event) {},
  onwebkitanimationend: function (this: GlobalEventHandlers, ev: Event) {},
  onwebkitanimationiteration: function (this: GlobalEventHandlers, ev: Event) {},
  onwebkitanimationstart: function (this: GlobalEventHandlers, ev: Event) {},
  onwebkittransitionend: function (this: GlobalEventHandlers, ev: Event) {},
  onwheel: function (this: GlobalEventHandlers, ev: WheelEvent) {},
  onafterprint: function (this: WindowEventHandlers, ev: Event) {},
  onbeforeprint: function (this: WindowEventHandlers, ev: Event) {},
  onbeforeunload: function (this: WindowEventHandlers, ev: BeforeUnloadEvent) {},
  ongamepadconnected: function (this: WindowEventHandlers, ev: GamepadEvent) {},
  ongamepaddisconnected: function (this: WindowEventHandlers, ev: GamepadEvent) {},
  onhashchange: function (this: WindowEventHandlers, ev: HashChangeEvent) {},
  onlanguagechange: function (this: WindowEventHandlers, ev: Event) {},
  onmessage: function (this: WindowEventHandlers, ev: MessageEvent<any>) {},
  onmessageerror: function (this: WindowEventHandlers, ev: MessageEvent<any>) {},
  onoffline: function (this: WindowEventHandlers, ev: Event) {},
  ononline: function (this: WindowEventHandlers, ev: Event) {},
  onpagehide: function (this: WindowEventHandlers, ev: PageTransitionEvent) {},
  onpageshow: function (this: WindowEventHandlers, ev: PageTransitionEvent) {},
  onpopstate: function (this: WindowEventHandlers, ev: PopStateEvent) {},
  onrejectionhandled: function (this: WindowEventHandlers, ev: PromiseRejectionEvent) {},
  onstorage: function (this: WindowEventHandlers, ev: StorageEvent) {},
  onunhandledrejection: function (this: WindowEventHandlers, ev: PromiseRejectionEvent) {},
  onunload: function (this: WindowEventHandlers, ev: Event) {},
  localStorage: {
    setItem: function (key: string, value: string) {},
    getItem: function (key: string) {},
  },
  caches: undefined,
  crossOriginIsolated: false,
  crypto: undefined,
  indexedDB: undefined,
  isSecureContext: false,
  origin: '',
  performance: undefined,
  atob: function (data: string): string {
    return null;
  },
  btoa: function (data: string): string {
    return null;
  },
  clearInterval: function (id: number): void {},
  clearTimeout: function (id: number): void {},
  createImageBitmap: function (): Promise<ImageBitmap> {
    return null;
  },
  fetch: function (input: URL | RequestInfo, init?: RequestInit): Promise<Response> {
    return null;
  },
  queueMicrotask: function (callback: VoidFunction): void {},
  reportError: function (e: any): void {},
  setInterval: function (handler: TimerHandler, timeout?: number, ...args: any[]): number {
    return 0;
  },
  setTimeout: function (handler: TimerHandler, timeout?: number, ...args: any[]): number {
    return 0;
  },

  sessionStorage: undefined,
  TrustedHTML: undefined,
  TrustedScript: undefined,
  TrustedScriptURL: undefined,
  TrustedTypePolicyFactory: undefined,
  TrustedTypePolicy: undefined,
};
