import * as React from 'react';
import { ContextType } from 'react';
import styles from './radio.module.scss';
import { AriaRadioInterface } from '../AccessibilityProps';
import StylingContext from '../../style-context/styling-context-provider';
import { S_M_L_Size } from '../t-shirt-sizes';
import { getDataAttributes } from '../../util/attribute-util';

export interface RadioProps {
  /**
   * To be triggered when the checkbox toggles
   * between selected / diselected states;
   */
  onSelectedChanged?: (value: boolean) => void;

  /**
   * Have a pre-selected state when
   * mounting the component;
   */
  isSelected?: boolean;

  /**
   * Have a pre-disabled state when
   * mounting the component;
   */
  isDisabled?: boolean;

  name: string;

  aria?: AriaRadioInterface;

  size?: S_M_L_Size;
}

const componentName = 'Radio';

export class Radio extends React.Component<RadioProps & { id: string }> {
  static defaultProps: RadioProps = {
    size: 'medium',
    name: null,
  };
  context: ContextType<typeof StylingContext>;

  input = null;

  setRef = (reference: HTMLInputElement) => {
    this.input = reference;
  };

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.onSelectedChanged && this.props.onSelectedChanged((event.target as HTMLInputElement).checked);
  };

  render() {
    const { styling } = this.context;
    const classNames = [styles['container'], styles[styling], styles[this.props.size]].join(' ');

    return (
      <div className={classNames} data-component={componentName} {...getDataAttributes(this.props)}>
        <input
          id={this.props.id}
          ref={this.setRef}
          name={this.props.name}
          type="radio"
          checked={this.props.isSelected}
          disabled={this.props.isDisabled}
          onChange={this.onChange}
          className={styles[this.props.size]}
          {...this.props.aria}
        />
        <div className={styles['radio']} aria-hidden>
          <div className={styles['ball']} />
        </div>
      </div>
    );
  }

  focus() {
    this.input && this.input.focus();
  }
}

Radio['displayName'] = componentName;
Radio.contextType = StylingContext;
