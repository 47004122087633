import * as React from 'react';
import styles from './checkbox-list.module.scss';
import { SelectItem, SelectItemBuilder } from '../../models/select-item';
import { Checkbox } from '../checkbox/checkbox';
import { Label } from '../label/label';
import { SearchField } from '../search-field/search-field';
import { ChildNodeFunctions } from '../../models/child-node';
import { getDataAttributes } from '../../util/attribute-util';
import { windowSafe } from '../../util/ssr';

export interface CheckboxListProps {
  /**
   * expects a jagged array of SelectItems, where each leaf is rendered as a checkbox while parents are rendered as categories
   */
  items: SelectItem[];
  selectedItems?: SelectItem[];
  searchString?: string;
  onSearchStringChanged?: (str: string) => any;
  searchPlaceholder?: string;
  showSearchFieldWhenCountIsAbove?: number;
  noResultsLabel?: string;
  onItemToggled?: (selected: SelectItem[], added: SelectItem[], removed: SelectItem[]) => any;
  /**
   * debounce delay in ms before onItemToggled is fired after a selection.
   */
  selectionDelay?: number;

  /**
   * number of permissible toggles. If limit is reached, on toggled checkboxes are disabled until a checkbox is untoggled.
   */
  selectionLimit?: number;

  /**
   * label provider which returns a localised string
   */
  selectionLimitLabelProvider?: (count: number, total: number) => string;

  /**
   * Aria label for the clear button in the search field
   */
  clearSearchLabel: string;
}

const componentName = 'CheckboxList';
export class CheckboxList extends React.Component<
  CheckboxListProps,
  {
    searchString: string;
    selectedItems: SelectItem[];
  }
> {
  static defaultProps = {
    showSearchFieldWhenCountIsAbove: 10,
    selectionDelay: 1500,
  };

  state = {
    searchString: this.props.searchString !== undefined ? this.props.searchString : '',
    selectedItems: this.props.selectedItems || [],
  };
  debounceId: number;
  removed = [];
  added = [];

  constructor(properties: CheckboxListProps) {
    super(properties);
  }

  get filteredSelectList(): SelectItem[] {
    return this.FilterOnSearchString(this.props.items, this.state.searchString);
  }

  get selectionLimitReached() {
    return this.props.selectionLimit && this.state.selectedItems.length >= this.props.selectionLimit;
  }

  get leafCount(): number {
    return this.props.items.map(this.getRecursiveLeafCount).reduce((p, c) => p + c, 0);
  }

  componentWillReceiveProps(newProps: CheckboxListProps) {
    if (newProps.searchString !== undefined) {
      this.setState((s) => ({
        ...s,
        searchString: newProps.searchString,
      }));
    }

    if (newProps.selectedItems !== undefined) {
      this.setState((s) => ({
        ...s,
        selectedItems: newProps.selectedItems || [],
      }));
    }
  }

  componentWillUnmount(): void {
    //do not perform a callback if we get unmounted
    windowSafe().clearTimeout(this.debounceId);
  }

  getRecursiveLeafCount = (item: SelectItem): number => {
    if (item.children.length === 0) {
      return 1;
    }
    let leafs = item.children.filter((c) => c.children.length === 0).length;
    leafs += item.children
      .filter((c) => c.children.length > 0)
      .reduce((p, c) => {
        return p + this.getRecursiveLeafCount(c);
      }, 0);

    return leafs;
  };

  checkboxClickedHandler = (id: string, val: boolean) => {
    const item = this.props.items
      .map((itm) => ChildNodeFunctions.asFlat(itm))
      .reduce((p, c) => p.concat(c), [])
      .map((item) => item as SelectItem)
      .find((itm) => itm.id === id);

    if (val) {
      //selected
      this.added.push(item);
      this.setState((s) => {
        const newVal = s.selectedItems.slice();
        newVal.push(item);
        return {
          searchString: s.searchString,
          selectedItems: newVal,
        };
      });
    } else {
      //deselected
      this.removed.push(item);
      this.setState((s) => {
        const newVal = s.selectedItems.slice();
        newVal.splice(s.selectedItems.indexOf(s.selectedItems.find((itm) => itm.value === item.value)), 1);
        return {
          searchString: s.searchString,
          selectedItems: newVal,
        };
      });
    }

    windowSafe().clearTimeout(this.debounceId);
    this.debounceId = windowSafe().setTimeout(() => {
      if (this.props.onItemToggled) {
        this.props.onItemToggled(this.state.selectedItems, this.added, this.removed);
        this.added = [];
        this.removed = [];
      }
    }, this.props.selectionDelay);
  };

  onSearchHandler = (str: string) => {
    if (this.props.onSearchStringChanged || this.props.searchString !== undefined) {
      this.props.onSearchStringChanged(str);
    } else {
      this.setState((s) => ({
        ...s,
        searchString: str,
      }));
    }
  };

  private FilterOnSearchString(selectList: SelectItem[], searchString: string): SelectItem[] {
    if (searchString.length === 0 && this.props.searchString !== undefined) {
      return this.props.items;
    }

    const searchStringLowerCase = this.state.searchString.toLocaleLowerCase();

    const matchesString = (item: SelectItem, str: string) => {
      return item.label.toLocaleLowerCase().includes(str);
    };

    /* match the item and all its children against the lowercase search string. return a new heirachy containing only items where the search string is a substring of the labels */
    const filter = (item) => {
      if (item.children.length === 0) {
        return matchesString(item, searchStringLowerCase) ? item : null;
      }

      const filtered = item.children.map(filter).filter((itm) => itm !== null);
      if (filtered.length === 0) {
        return null;
      }

      return new SelectItemBuilder(item.label, item.value, item.icon, filtered).build();
    };

    return this.props.items.map(filter).filter((itm) => itm !== null);
  }

  renderItem = (item: SelectItem) => {
    if (item.children.length > 0) {
      return (
        <div className={styles['list']} key={item.id} data-key={`CheckboxList-${item.id}`}>
          <Label text={item.label} type="x-small" />
          <div className={styles['children']}>{item.children.map(this.renderItem)}</div>
        </div>
      );
    }

    const isSelected = this.state.selectedItems.some((s) => s.id === item.id);

    return (
      <Checkbox
        label={item.label.toString()}
        key={item.id}
        data-key={`CheckboxList-checkbox-${item.id}`}
        isSelected={isSelected}
        isDisabled={!isSelected && this.selectionLimitReached}
        onSelectedChanged={(val) => this.checkboxClickedHandler(item.id, val)}
      />
    );
  };

  render() {
    return (
      <div role={'combobox'} className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {this.leafCount > this.props.showSearchFieldWhenCountIsAbove ? (
          <SearchField
            onSearch={this.onSearchHandler}
            placeholder={this.props.searchPlaceholder}
            value={this.state.searchString}
            clearLabel={this.props.clearSearchLabel}
          />
        ) : null}

        {this.props.selectionLimitLabelProvider ? (
          <Label text={this.props.selectionLimitLabelProvider(this.state.selectedItems.length, this.props.selectionLimit)} type="x-small" />
        ) : null}
        <div className={styles['items']} role={'tree'}>
          {this.filteredSelectList.map(this.renderItem)}
          {this.filteredSelectList.length === 0 ? <Label text={this.props.noResultsLabel} type="small" /> : null}
        </div>
      </div>
    );
  }
}

CheckboxList['displayName'] = componentName;
