import * as React from 'react';
import { AriaTextboxInterface } from '../AccessibilityProps';
import styles from './filter-search.module.scss';
import { SearchField, SearchFieldProperties } from '../search-field/search-field';
import { getDataAttributes } from '../../util/attribute-util';

export interface FilterSearchProps extends SearchFieldProperties {
  searchAria?: AriaTextboxInterface;
  searchWidth?: string;
}

const componentName = 'FilterSearch';

export const FilterSearch = (props: FilterSearchProps, {}) => {
  return (
    <div
      style={props.searchWidth ? { maxWidth: props.searchWidth } : { maxWidth: '100%' }}
      className={styles['component']}
      data-component={componentName}
      {...getDataAttributes(props)}
    >
      <SearchField
        onSearch={props.onSearch}
        aria={props.searchAria}
        autoSearch={props.autoSearch}
        value={props.value}
        clearLabel={props.clearLabel}
        autoSearchDelay={props.autoSearchDelay}
        autoFocus={props.autoFocus}
        placeholder={props.placeholder}
      />
    </div>
  );
};

FilterSearch['displayName'] = componentName;
