import * as React from 'react';
import styles from './legend-item.module.scss';
import { Label } from '../label/label';
import { Icon, IconProps } from '../icon/icon';
import { getDataAttributes } from '../../util/attribute-util';

const componentName = 'LegendItem';

export interface LegendItemProps {
  identifier: string;
  title?: string;
  explanation: string;
  iconName?: IconProps['name'];
  exampleClassName?: string;
}

export class LegendItem extends React.Component<LegendItemProps, {}> {
  render() {
    const symbol = this.props.iconName ? (
      <Icon name={this.props.iconName} size={16} />
    ) : (
      <span className={[styles['legend'], this.props.exampleClassName || ''].join(' ')} />
    );

    return (
      <div id={this.props.identifier} className={styles['component']} data-component={componentName} {...getDataAttributes(this.props)}>
        {symbol}
        {this.props.title && <Label text={this.props.title + ':'} type="small" className={styles['title']} />}
        <Label text={this.props.explanation} type={'small'} className={styles['explanation']} />
      </div>
    );
  }
}

LegendItem['displayName'] = componentName;
