import * as React from 'react';
import styles from './progress-bar.module.scss';
import { getDataAttributes } from '../../util/attribute-util';

export interface ProgressBarProps {
  /**
   * A number between 0 and maxProgress, representing the current progress
   */
  progress?: number;

  /**
   * The maximum progress. Defaults to 100.
   */
  maxProgress?: number;

  /**
   * Whether to show the progress as indeterminate, that is animated without a specific progress percentage
   */
  indeterminate?: boolean;
  /**
   * Delay(ms) before the component is shown, can be used to avoid flashing the user for very fast load times.
   */
  delay?: number;
}

// Accessibility: // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_progressbar_role

/**
 * A progress bar showing a percentage or indeterminate progress.
 */

const componentName = 'ProgressBar';

export class ProgressBar extends React.Component<ProgressBarProps, {}> {
  static defaultProps = {
    maxProgress: 100,
  };

  state = {
    hidden: true,
  };

  componentDidMount() {
    this.props.delay ? setTimeout(() => this.setState({ hidden: false }), this.props.delay) : this.setState({ hidden: false });
  }

  render() {
    let bar = null;
    if (this.props.indeterminate) {
      bar = <div className={styles['indeterminate']} role="progressbar" aria-label="progressbar" />;
    } else {
      const percent = Math.min(this.props.maxProgress, Math.max(0, this.props.progress)) * (100 / this.props.maxProgress);
      const width = percent + '%';
      bar = (
        <div>
          <div
            className={styles['determinate']}
            style={{ width: width }}
            role="progressbar"
            aria-valuenow={percent}
            aria-valuemin={0}
            aria-valuemax={100}
            aria-label="progressbar"
          />
          {percent === 0 && <div className={styles['determinate-slide']} />}
        </div>
      );
    }
    const classes: string[] = [styles['component']];
    this.state.hidden && classes.push(styles['hidden']);

    return (
      <div className={classes.join(' ')} data-component={componentName} {...getDataAttributes(this.props)}>
        <div className={styles['bar-outline']} />
        {bar}
      </div>
    );
  }
}

ProgressBar['displayName'] = componentName;
