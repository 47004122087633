import * as React from 'react';
import { Stack, StackChildren } from '../stack/stack';
import { getDataAttributes } from '../../util/attribute-util';

export type ContainerStackProps = StackChildren;

const componentName = 'ContainerStack';

export class ContainerStack extends React.Component<ContainerStackProps, any> {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    return (
      <Stack spacing="24dp" data-component={componentName} {...getDataAttributes(this.props)}>
        {this.props.children}
      </Stack>
    );
  }
}

ContainerStack['displayName'] = componentName;
