import * as React from 'react';
import styles from './spinner.module.scss';
import { PixelConverterUtil } from '../../client-shared';
import { getDataAttributes } from '../../util/attribute-util';

export interface SpinnerProps {
  id?: string;
  width?: number;
  height?: number;
  margin?: number;
  /**
   * Abstract status message presented to screen readers (e.g. "Loading").
   */
  label: string;
  /**
   * Delay(ms) before the component is shown, can be used to avoid flashing the user for very fast load times.
   */
  delay?: number;
}

interface SpinnerState {
  isMounted: boolean;
}

const componentName = 'Spinner';

export class Spinner extends React.PureComponent<SpinnerProps, SpinnerState> {
  state = {
    isMounted: false,
  };

  componentDidMount(): void {
    this.props.delay ? setTimeout(() => this.setState({ isMounted: true }), this.props.delay) : this.setState({ isMounted: true });
  }

  /**
   * Convert props to a JSX parsable style object based on keys;
   */
  getComponentStyle = (styleKeys: string[] = ['width', 'height', 'margin']): any => {
    const styles = {};

    for (let i = 0; i < styleKeys.length; i++) {
      const key = styleKeys[i];

      if (Object.prototype.hasOwnProperty.call(this.props, key)) {
        styles[key] = PixelConverterUtil.dpToPx(this.props[key]) + 'px';
      }
    }

    return styles;
  };

  render() {
    const classes: string[] = [styles['component'], styles['sk-double-bounce'], styles['margin']];

    !this.state.isMounted && classes.push(styles['hidden']);

    return (
      <div
        id={this.props.id}
        role="progressbar"
        aria-valuetext={this.props.label}
        aria-label={this.props.label}
        style={this.getComponentStyle()}
        className={classes.join(' ')}
        data-component={componentName}
        {...getDataAttributes(this.props)}
      >
        <div className={[styles['sk-child'], styles['blue']].join(' ')} />
        <div className={[styles['sk-child'], styles['sk-double-bounce2'], styles['blue']].join(' ')} />
      </div>
    );
  }
}

Spinner['displayName'] = componentName;
