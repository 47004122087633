import moment from 'moment';
import * as React from 'react';
import styles from './date-picker-days.module.scss';

export interface DaysProps {
  focusedCell?: moment.Moment;
  dates: moment.Moment[];
  selectedDate?: moment.Moment;
  isPrevOrNextDates: boolean;
  hasFocus: boolean;
  dayClick: (date: moment.Moment) => void;
}

const componentName = 'DatePickerDays';

export function DatePickerDays(props: React.PropsWithChildren<DaysProps>) {
  const compareFormat = 'YYYY/MM/DD';
  const selectedDate = props.selectedDate?.format(compareFormat) ?? '';
  const focusedDate = props.focusedCell?.format(compareFormat) ?? '';
  const todayDate = moment().format(compareFormat);
  const styleNames = props.isPrevOrNextDates ? [styles['prev-or-next-dates'], styles['day']] : [styles['day']];
  return (
    <>
      {props.dates.map((cellDate, i) => {
        const cellDateString = cellDate.format(compareFormat);
        const selected = cellDateString === selectedDate;
        const focus = cellDateString === focusedDate && props.hasFocus && !selected;
        const isToday = cellDateString === todayDate;
        const className = styleNames.concat(isToday ? [styles['today']] : []).join(' ');
        return (
          <div key={i} data-key={`${componentName}-${i}`}>
            <div
              className={className}
              data-hasfocus={focus}
              aria-selected={props.isPrevOrNextDates ? null : selected}
              onClick={() => props.dayClick(cellDate)}
            >
              {cellDate.date()}
            </div>
          </div>
        );
      })}
    </>
  );
}

DatePickerDays['displayName'] = componentName;
