import * as React from 'react';
import { FC } from 'react';
import styles from './field-control.module.scss';
import { IconButton, IconButtonProps } from '../icon-button/icon-button';
import { Inline } from '../inline/inline';
import { getDataAttributes } from '../../util/attribute-util';

export interface FieldControlProps {
  /** For each item a button will be available in the control. */
  items: IconButtonProps[];
  children: React.ReactNode;
}
const componentName = 'FieldControl';

export const FieldControl: FC<FieldControlProps> = ({ items, children, ...props }) => {
  return (
    <div className={styles['component']} data-component={componentName} {...getDataAttributes(props)}>
      {children}
      <div className={styles['controls']}>
        <Inline horizontalAlignment={'right'} spacing={'8dp'}>
          {items.map((item, idx) => (
            <IconButton size={'large'} data-key={`FieldControl-${idx}`} key={idx} {...item} />
          ))}
        </Inline>
      </div>
    </div>
  );
};

FieldControl['displayName'] = componentName;
